import _ from 'lodash';
import * as R from 'ramda';
import create, { StoreApi } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

import { Deployment, DeploymentCreate } from '@scale/llm-shared/interfaces/deployment';

import { client } from 'frontend/api/trpc';
import { StoredDeployment } from 'frontend/storesV2/types';
import { singleIndexById } from 'frontend/utils/object';

class DeploymentStore {
  deploymentById: Record<string, StoredDeployment> = {};
  deploymentPromiseById: Record<string, Promise<StoredDeployment>> = {};

  constructor(
    public set: StoreApi<DeploymentStore>['setState'],
    public get: StoreApi<DeploymentStore>['getState'],
  ) { }

  setDeploymentById = (byId: Record<string, StoredDeployment>) => this.set(R.set(indexLens, byId));
  setDeploymentPromiseById = (byId: Record<string, Promise<StoredDeployment>>) =>
    this.set(R.set(promiseIndexLens, byId));
  appendDeploymentById = (byId: Record<string, StoredDeployment>) =>
    this.set(R.over(indexLens, R.mergeLeft(byId)));
  appendDeploymentPromiseById = (byId: Record<string, Promise<StoredDeployment>>) =>
    this.set(R.over(promiseIndexLens, R.mergeLeft(byId)));

  createDeployment = (deploymentCreate: DeploymentCreate): Promise<Deployment> =>
    client
      // Create Deployment
      .mutation('v2.deployment.create', deploymentCreate)
      // Construct StoredDeployment
      .then(DeploymentStore.constructStoredDeployment)
      .then(
        R.tap(
          R.pipe(
            // Prepare to be stored
            singleIndexById,
            // Store in deploymentById
            this.appendDeploymentById,
          ),
        ),
      );

  getDeployment = (id: string): Promise<Deployment> => {
    const { deploymentById, deploymentPromiseById } = this.get();
    // Get deployment from cache
    const deployment = deploymentById[id];
    if (deployment) return Promise.resolve(deployment);
    // Get deployment from loading
    const deploymentPromise = deploymentPromiseById[id];
    if (deploymentPromise) return deploymentPromise;
    return (
      client
        // Load deployment from server
        .query('v2.deployment.findUnique', { id })
        // Construct StoredDeployment
        .then(DeploymentStore.constructStoredDeployment)
        .then(
          R.tap(
            R.pipe(
              // Prepare to be stored
              singleIndexById,
              // Store in deploymentById
              this.appendDeploymentById,
            ),
          ),
        )
    );
  };

  findAllDeployments = () => {
    return client
      // Load deployment from server
      .query('v2.deployment.findAll')
      .then(R.map(DeploymentStore.constructStoredDeployment))
      .then(
        R.tap(
          R.pipe(
            // Prepare to be stored
            R.indexBy(R.prop('id')),
            // Store in deploymentById
            this.appendDeploymentById,
          ),
        ),
      );
  };

  updateDeployment = (deployment: Deployment) => {
    return client.mutation('v2.deployment.update', deployment)
      .then(DeploymentStore.constructStoredDeployment)
      .then(
        R.tap(
          R.pipe(
            // Prepare to be stored
            singleIndexById,
            // Store in deploymentById
            this.appendDeploymentById,
          ),
        ),
      );
  };

  static constructStoredDeployment = (deployment: Deployment): StoredDeployment => {
    return deployment;
  };
}

const indexKey = 'deploymentById';
const indexLens = R.lensProp<DeploymentStore, typeof indexKey>(indexKey);

const promiseIndexKey = 'deploymentPromiseById';
const promiseIndexLens = R.lensProp<DeploymentStore, typeof promiseIndexKey>(promiseIndexKey);

export const useDeploymentStore = create<DeploymentStore>()(
  subscribeWithSelector((set, get) => new DeploymentStore(set, get)),
);

(window as any).useDeploymentStoreV2 = useDeploymentStore;
