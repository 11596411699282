import { BaseModelInternalId } from '@scale/llm-shared/modelProviders/types';

import FlexBox from 'frontend/components/FlexBox';
import MaxTokensSlider from 'frontend/components/playground/MaxTokensSlider';
import ModelSelector from 'frontend/components/playground/ModelSelector';
import PromptTemplateSelector from 'frontend/components/playground/PromptTemplateSelector';
import StopSequenceField from 'frontend/components/playground/StopSequenceField';
import TemperatureSlider from 'frontend/components/playground/TemperatureSlider';
import { IPromptPreset, RESPONSIVE_WIDTH_THRESHOLD } from 'frontend/consts/playground';

interface ISidebarProps {
  baseModel: BaseModelInternalId;
  models: BaseModelInternalId[];
  presets: IPromptPreset[];
  selectedPromptPreset: IPromptPreset | undefined;
  temperature: number;
  minTemperature: number;
  maxTemperature: number;
  maxTokens: number;
  minMaxTokens: number;
  maxMaxTokens: number;
  stopSequence: string;
  onChangeModel: (e: any) => void;
  onChangeSelectedPromptPreset: (presetTitle: string | undefined) => void;
  onChangeTemperature: (e: any, val: any) => void;
  onChangeMaxTokens: (e: any, val: any) => void;
  onChangeStopSequence: (val: string) => void;
  onClearPromptTemplate: () => void;
  isDialog: boolean;
}

const Sidebar = ({
  baseModel,
  models,
  presets,
  selectedPromptPreset,
  temperature,
  minTemperature,
  maxTemperature,
  maxTokens,
  minMaxTokens,
  maxMaxTokens,
  stopSequence,
  onChangeSelectedPromptPreset,
  onChangeModel,
  onChangeTemperature,
  onChangeMaxTokens,
  onChangeStopSequence,
  onClearPromptTemplate,
  isDialog,
}: ISidebarProps) => {
  return (
    <FlexBox
      sx={{
        flexDirection: 'column',
        margin: '0 auto',
        paddingRight: isDialog ? '0px' : '24px',
        gap: 4,
        [`@media (max-width:${RESPONSIVE_WIDTH_THRESHOLD}px)`]: {
          display: isDialog ? '' : 'none',
          transition: 'visibility 1s, opacity 0.5s linear',
        },
      }}
    >
      <ModelSelector models={models} baseModel={baseModel} onChangeModel={onChangeModel} />
      <PromptTemplateSelector
        presets={presets}
        selectedPromptPreset={selectedPromptPreset}
        onChangeSelectedPromptPreset={onChangeSelectedPromptPreset}
        onClearPromptTemplate={onClearPromptTemplate}
      />
      <TemperatureSlider
        temperature={temperature}
        onChangeTemperature={onChangeTemperature}
        minTemperature={minTemperature}
        maxTemperature={maxTemperature}
      />
      <MaxTokensSlider
        maxTokens={maxTokens}
        minMaxTokens={minMaxTokens}
        maxMaxTokens={maxMaxTokens}
        onChangeMaxTokens={onChangeMaxTokens}
      />
      <StopSequenceField stopSequence={stopSequence} onChangeStopSequence={onChangeStopSequence} />
    </FlexBox>
  );
};

export default Sidebar;
