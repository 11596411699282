import { useCallback, useMemo } from 'react';

import * as R from 'ramda';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  styled,
  Typography,
} from '@mui/material';
import { App } from '@prisma/client';
import { sortByUpdatedAt } from '@scale/llm-shared/utils/sorting';

import appScreenshot from 'frontend/assets/prompt-page-screenshot.png';
import { AppCard } from 'frontend/components/app/AppCard';
import { Container } from 'frontend/components/Container';
import FlexBox from 'frontend/components/FlexBox';
import PageTitle from 'frontend/components/PageTitle';
import { VSpace } from 'frontend/components/Spacer';
import { useLLMNavigation } from 'frontend/hooks/useLLMNavigation';
import { useAppsPageState } from 'frontend/models/useAppsPageState';
import { AppCreationWizard } from 'frontend/pages/AppsPage/AppCreationWizard/AppCreationWizard';
import PageContainer from 'frontend/pages/PageContainer/PageContainer';
import { useAppStore } from 'frontend/stores/AppStore';
import { StoredApp } from 'frontend/stores/types';
import { useUserStore } from 'frontend/stores/UserStore';
import { useSelectionStore as useSelectionStoreV2 } from 'frontend/storesV2/SelectionStore';
import theme, { Colors } from 'frontend/theme';
import { track } from 'frontend/utils/analytics';

// TODO move this to feature flags
const SHOULD_SHOW_PUBLIC_APPS = false;

function openDocumentation() {
  track('Documentation Opened');
  window.open('https://spellbook.readme.io/docs', '_blank');
}

function PublicAppsList() {
  if (!SHOULD_SHOW_PUBLIC_APPS) {
    return null;
  }
  return (
    <Container>
      <Typography variant="h3">Public Apps</Typography>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          gap: 1,
        }}
      >
        {FAKE_PUBLIC_APPS.map(app => {
          return (
            <Grid item xs={4} key={app.name}>
              <AppCard app={app} isTemplate />
            </Grid>
          );
        })}
      </Box>
    </Container>
  );
}

const IconContainer = styled('span')({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.spacing(0.5),
  backgroundColor: Colors.CoolGray20,
  height: 24,
  width: 24,
  fontSize: 10,
  marginRight: '1ex',
});

function GettingStartedGuides() {
  return (
    <Card variant="outlined" sx={{ overflow: 'inherit' }}>
      <CardContent>
        <Grid container p={2}>
          <Grid item xs={6}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Typography variant="h1">Getting Started with Spellbook</Typography>
              <Typography>
                Spellbook is a tool for quickly building and deploying applications on top of large
                language models. Large language models (LLMs) are modern, powerful AI models that
                can work with any kind of text, whether that is natural language and speech,
                spreadsheet data, or even markup languages and code. Example applications supported
                by Spellbook include:
              </Typography>
              <Typography>
                <IconContainer>
                  <FontAwesomeIcon icon="magnifying-glass-arrow-right" color={Colors.CoolGray50} />
                </IconContainer>
                Extraction: Finding the city name from a street address
              </Typography>
              <Typography>
                <IconContainer>
                  <FontAwesomeIcon icon="folder-tree" color={Colors.CoolGray50} />
                </IconContainer>
                Classification: Tagging support tickets with the correct department
              </Typography>
              <Typography>
                <IconContainer>
                  <FontAwesomeIcon icon="book" color={Colors.CoolGray50} />
                </IconContainer>
                Summarization: Automatically simplifying a news article to a single headline
              </Typography>
              <Typography>
                See our guide on preparing your data, interacting with LLMs, and deploying your
                custom application.
              </Typography>
              <Box>
                <Button variant="outlined" onClick={openDocumentation}>
                  View the Spellbook Guide
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <FlexBox sx={{ justifyContent: 'flex-end' }}>
              <Paper variant="outlined">
                <Box p={1}>
                  <img height={320} src={appScreenshot} />
                </Box>
              </Paper>
            </FlexBox>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

function CellbookLink() {
  return (
    <Paper variant="outlined">
      <Grid container p={2}>
        <FlexBox sx={{ justifyContent: 'space-between', width: '100%' }}>
          <FlexBox sx={{ gap: 2 }}>
            <FontAwesomeIcon icon="bullhorn" color={Colors.RemoBlue} />
            <Typography>
              Psst. Use Scale Spellbook models in Google Sheets, without writing a single line of
              code!
            </Typography>
          </FlexBox>
          <Button
            variant="outlined"
            onClick={() =>
              window.open(
                'https://workspace.google.com/marketplace/app/spellbook/831869312792',
                '_blank',
              )
            }
          >
            <FlexBox sx={{ gap: 1 }}>
              Try Now
              <FontAwesomeIcon icon="up-right-from-square" />
            </FlexBox>
          </Button>
        </FlexBox>
      </Grid>
    </Paper>
  );
}

export function AppsPage(): JSX.Element {
  const { goToAppsPage, goToHomePage } = useLLMNavigation();
  const { user } = useUserStore(R.pick(['user']));
  const { setSelectedAppId } = useSelectionStoreV2(R.pick(['setSelectedAppId']));
  const { listVisibleApps } = useAppStore(R.pick(['listVisibleApps']));

  const { showCloseDialog, setShowCloseDialog, showWizard, setShowWizard } = useAppsPageState();

  const handleClickCreateApp = useCallback(() => setShowWizard(true), [setShowWizard]);

  const handleCancel = useCallback(() => {
    // TODO: This needs to be in the app creation wizard
    // otherwise state changes from partially done wizards
    // will not reset.
    setShowCloseDialog(true);
  }, [setShowCloseDialog]);

  const handleCloseConfirm = useCallback(() => {
    track('Onboarding Canceled');
    setShowWizard(false);
    setShowCloseDialog(false);
    goToAppsPage();
  }, [setShowWizard, goToAppsPage]);

  const handleCardClick = useCallback(
    (app: App) => {
      track('App Selected', { app: app.id, app_name: app.name });
      setSelectedAppId(app.id);
      goToHomePage(app);
    },
    [setSelectedAppId, goToHomePage],
  );

  const apps = useMemo(() => {
    return listVisibleApps().sort((a1, a2) => sortByUpdatedAt(a1, a2));
  }, [listVisibleApps().map(app => app.id)]);

  if (showWizard) {
    return (
      <PageContainer page="apps" noRequireApp>
        <Dialog open={showCloseDialog}>
          <DialogTitle>
            <Typography variant="h2">Stop App Creation</Typography>
          </DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to stop app creation?</Typography>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => setShowCloseDialog(false)}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleCloseConfirm}>
              Stop App Creation
            </Button>
          </DialogActions>
        </Dialog>
        <AppCreationWizard setShowWizard={setShowWizard} onCancel={handleCancel} />
      </PageContainer>
    );
  }

  const renderAppsContainer = () => {
    if (apps.length) {
      return (
        <Container>
          <Typography variant="h3">Your Apps</Typography>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr',
              gap: 1,
            }}
          >
            {/* Create app button */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px dashed',
                borderColor: 'primary.main',
                borderRadius: 1,
                cursor: 'pointer',
                '&:hover': {
                  bgcolor: 'secondary.light',
                },
              }}
              onClick={() => {
                track('Onboarding Started');
                setShowWizard(true);
              }}
            >
              <Box
                sx={{
                  bgcolor: 'primary.light',
                  color: 'primary.main',
                  borderRadius: 1,
                  padding: 1.5,
                  marginBottom: 1,
                }}
              >
                <FontAwesomeIcon icon="robot" size="lg" />
              </Box>
              <Typography variant="body1" sx={{ color: 'primary.main' }}>
                Create a New App
              </Typography>
            </Box>
            {/* Current apps */}
            {apps.map(app => (
              <AppCard key={app.id} app={app} onClick={handleCardClick} />
            ))}
          </Box>
        </Container>
      );
    } else if (!user) {
      return <></>;
    } else {
      return (
        <Container
          sx={{
            padding: 6,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          <Box
            sx={{
              bgcolor: 'primary.light',
              color: 'primary.main',
              borderRadius: 1,
              padding: 1.5,
            }}
          >
            <FontAwesomeIcon icon="robot" size="lg" />
          </Box>
          <Typography variant="h1" align="center">
            Create a New App
          </Typography>
          <Typography variant="body2" sx={{ maxWidth: 600, marginBottom: 2 }} align="center">
            Set up your new app using a pre-trained large language model and dataset for few-shot
            prompting, fine-tuning, and evaluation.
          </Typography>
          <Button color="primary" variant="contained" onClick={handleClickCreateApp}>
            Create App
          </Button>
        </Container>
      );
    }
  };

  return (
    <PageContainer page="apps" noRequireApp>
      {/* Title */}
      <PageTitle title="Apps" />
      {/* Create apps container */}
      {renderAppsContainer()}
      <VSpace s={2} />
      {/* Public apps list */}
      <PublicAppsList />
      <GettingStartedGuides />
      <VSpace s={2} />
      <CellbookLink />
      <Box p={2} />
    </PageContainer>
  );
}

const FAKE_PUBLIC_APPS: StoredApp[] = [];
