import React from 'react';

import styled from '@emotion/styled';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Tooltip } from '@mui/material';
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';

import theme, { Colors } from 'frontend/theme';

interface HoverableInfoDialogProps {
  children?: React.ReactNode;
  iconSize?: SizeProp;
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    padding: theme.spacing(2),
  },
});

const HoverableInfoDialog: React.FC<HoverableInfoDialogProps> = ({ children, iconSize = 'lg' }) => {
  return (
    <Box m={1}>
      <LightTooltip title={<Box>{children}</Box>} placement={'right'}>
        <FontAwesomeIcon icon="circle-info" color={Colors.CoolGray40} size={iconSize} />
      </LightTooltip>
    </Box>
  );
};

export default HoverableInfoDialog;
