import { getNumGpt3Tokens } from '@scale/llm-shared/tokenizers/countTokens';
import { Ai21BaseModelDisplayName, Ai21BaseModelInternalId } from '../consts';
import { IAi21BaseModel } from '../types';

export const J2Large: IAi21BaseModel = {
  id: Ai21BaseModelInternalId.J2Large,
  displayName: Ai21BaseModelDisplayName[Ai21BaseModelInternalId.J2Large],
  minTokensAllowed: 0,
  maxTokensAllowed: 2048,

  minTemperatureAllowed: 0,
  maxTemperatureAllowed: 1,
  externalModelId: 'j2-large',
  costCentsPerMillionPromptTokens: 3,
  costCentsPerMillionCompletionTokens: 3,

  getNumTokens: (prompt: string) => {
    return getNumGpt3Tokens(prompt);
  },
};
