import _ from 'lodash';

import DatasetFullGridView from 'frontend/pages/v2/EvaluationSetsPage/DatasetFullGridView';
import { StoredData } from 'frontend/storesV2/types';

export function DataTable({
  dataset,
}: {
  dataset: Omit<StoredData, 'id' | 'name' | 'type' | 'userId' | 'createdAt'>;
}): JSX.Element {
  const { columns, rowByIndex } = dataset;
  const rows = Object.values(rowByIndex || {}).map(r => r.valueByName);
  const columnNames = columns.map(c => c.name);
  return <DatasetFullGridView rows={rows} columnNames={columnNames} highlightSelected />;
}
