import { useCallback, useMemo } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { App } from '@prisma/client';
import { getSpoofFromSearchParams } from '@scale/llm-shared/utils';

import { useSelectionStore as useSelectionStoreV2 } from 'frontend/storesV2/SelectionStore';
import { StoredDeployment } from 'frontend/storesV2/types';

export function useParamsNavigate() {
  const navigate = useNavigate();
  const location = useLocation();

  const paramsUrl = useCallback((path: string, extraParams: { [key: string]: string } = {}) => {
    // Put more params to read here
    const spoof = getSpoofFromSearchParams(location.search);

    // Put more params to propagate here
    const params = new URLSearchParams();
    spoof && params.set('spoof', spoof);

    for (const [key, value] of Object.entries(extraParams)) {
      params.set(key, value);
    }

    const paramsString = params.toString();
    return `${path}${paramsString ? `?${paramsString}` : ''}`;
  }, []);

  const paramsNavigate = useCallback(
    (path: string, extraParams: { [key: string]: string } = {}) => {
      navigate(paramsUrl(path, extraParams));
    },
    [navigate, paramsUrl],
  );

  return { paramsNavigate, paramsUrl };
}

export function useLLMNavigation() {
  const {
    selectedApp,
    setSelectedAppId,
    selectedDeploymentId,
    selectedAppDeployments,
    setSelectedDeploymentId,
  } = useSelectionStoreV2();

  const { paramsNavigate } = useParamsNavigate();

  const selectedDeployment = useMemo(() => {
    if (!selectedAppDeployments) return undefined;
    return selectedAppDeployments.filter(dep => dep.id === selectedDeploymentId)?.[0];
  }, [selectedAppDeployments, selectedDeploymentId]);

  const spoofAmpersandQuery = useMemo(() => {
    const search = location.search ?? '';
    const spoof = getSpoofFromSearchParams(search);
    return spoof ? `&spoof=${spoof}` : '';
  }, [location.search]);

  const goToPromptPage = useCallback(
    (variantId?: string, app?: App) => {
      const shortName = app?.shortName || selectedApp?.shortName;
      if (!shortName) {
        paramsNavigate('/');
        return;
      }
      if (variantId) {
        useSelectionStoreV2.getState().setSelectedVariantId(variantId);
      }
      if (app) setSelectedAppId(app.id);
      paramsNavigate(`/app/${shortName}/prompt`);
    },
    [paramsNavigate, selectedApp],
  );

  const goToDeploymentPage = useCallback(
    ({ app, deployment }: { app?: App; deployment?: StoredDeployment }) => {
      const shortName = app?.shortName || selectedApp?.shortName;
      if (!shortName) {
        paramsNavigate('/');
        return;
      }
      if (app) setSelectedAppId(app.id);
      if (deployment) {
        setSelectedDeploymentId(deployment.id);
        paramsNavigate(`/app/${shortName}/deployments`, { uri: deployment.uri });
      } else {
        paramsNavigate(`/app/${shortName}/deployments`);
      }
    },
    [paramsNavigate, selectedApp],
  );

  const goToEvaluationPage = useCallback(
    (app?: App) => {
      const shortName = app?.shortName || selectedApp?.shortName;
      if (!shortName) {
        paramsNavigate('/');
        return;
      }
      if (app) setSelectedAppId(app.id);
      paramsNavigate(`/app/${shortName}/evaluations`);
    },
    [paramsNavigate, selectedApp],
  );

  const goToAppsPage = useCallback(
    (additionalQueries?: string) => {
      paramsNavigate(`/${additionalQueries ?? ''}`);
    },
    [paramsNavigate],
  );

  const goToHomePage = useCallback(
    (app?: App) => {
      const shortName = app?.shortName || selectedApp?.shortName;
      if (!shortName) {
        paramsNavigate('/');
        return;
      }
      if (app) setSelectedAppId(app.id);
      paramsNavigate(`/app/${shortName}`);
    },
    [paramsNavigate],
  );

  const goToVariantsPage = useCallback(
    (app?: App) => {
      const shortName = app?.shortName || selectedApp?.shortName;
      if (!shortName) {
        paramsNavigate('/');
        return;
      }
      if (app) setSelectedAppId(app.id);
      paramsNavigate(`/app/${shortName}/app-variants`);
    },
    [paramsNavigate],
  );

  const goToSettingsPage = useCallback(() => {
    paramsNavigate(`/settings`);
  }, [paramsNavigate]);

  /**
   * Navigates to the path, prepending the selected app's shortname if one is selected.
   * If no app is selected, the user is redirected to /
   */
  const goToDynamicPage = useCallback(
    (path: string) => {
      const shortName = selectedApp?.shortName;
      if (!shortName) {
        paramsNavigate('/');
        return;
      }
      // TODO: we probably want to do a switch and use the above page-specific commands
      if (path === 'deployments' && selectedDeployment) {
        goToDeploymentPage({ deployment: selectedDeployment });
      } else {
        paramsNavigate(`/app/${shortName}/${path}`);
      }
    },
    [paramsNavigate, selectedDeployment],
  );

  const goToDataPage = useCallback(
    (dataId?: string) => {
      paramsNavigate('/data', dataId ? { dataId } : undefined);
    },
    [paramsNavigate],
  );

  const goToExplorePage = useCallback(() => {
    paramsNavigate(`/explore`);
  }, [paramsNavigate]);

  const goToAppExplorePage = useCallback(
    (app: App) => {
      paramsNavigate(`/explore/${app.shortName}`);
    },
    [paramsNavigate],
  );

  const goToPlaygroundPage = useCallback(() => {
    paramsNavigate(`/playground`);
  }, [paramsNavigate]);

  return {
    goToPromptPage,
    goToDeploymentPage,
    goToEvaluationPage,
    goToAppsPage,
    goToDataPage,
    goToHomePage,
    goToVariantsPage,
    goToDynamicPage,
    goToSettingsPage,
    goToExplorePage,
    goToAppExplorePage,
    goToPlaygroundPage,
    spoofAmpersandQuery,
  };
}
