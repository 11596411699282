import { MenuItem, Tooltip } from '@mui/material';

import VariantName from 'frontend/components/v2/variant/VariantName';
import { StoredVariant } from 'frontend/storesV2/types';

export function VariantSelectorItem(props: {
  variant: StoredVariant;
  disabledVariant:
    | {
        variantId?: string | undefined;
        reason?: string | undefined;
      }
    | undefined;
}) {
  const { variant, disabledVariant } = props;

  if (disabledVariant) {
    if (disabledVariant.reason)
      return (
        <Tooltip
          key={variant.id}
          title={disabledVariant.reason}
          placement="bottom"
          disableInteractive
        >
          <div style={{ display: 'inline-block' }}>
            <MenuItem value={variant.id} disabled>
              <VariantName name={variant.name} />
            </MenuItem>
          </div>
        </Tooltip>
      );
    return (
      <MenuItem key={variant.id} value={variant.id} disabled>
        <VariantName name={variant.name} />
      </MenuItem>
    );
  }
  return (
    <MenuItem key={variant.id} value={variant.id}>
      <VariantName name={variant.name} />
    </MenuItem>
  );
}
