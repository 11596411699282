import React from 'react';

import { Box, Container, SxProps, Typography } from '@mui/material';

import { VSpace } from 'frontend/components/Spacer';
import theme from 'frontend/theme';

interface PricingEstimateTableProps {
  totalVariants: number;
  evaluationItems: number;
  ratePerItem: number;
  timePerItem: number;
}

const PricingEstimateTable: React.FC<PricingEstimateTableProps> = ({
  totalVariants,
  evaluationItems,
  ratePerItem,
  timePerItem,
}) => {
  return (
    <Box>
      <Typography variant="h2"> Pricing Estimate </Typography>
      <Typography variant="body1"> A snapshot of what you might expect to be paying </Typography>
      <VSpace s={2} />
      <Box
        sx={{
          gap: 0,
          display: 'grid',
          alignContent: 'start',
          gridTemplateColumns: '1fr 1fr 2fr 2fr 2fr',
          border: `1px solid #E5E7EB`,
          borderRadius: 1,
          backgroundColor: theme.palette.background.paper,
          overflow: 'hidden',
        }}
      >
        <PricingEstimateTableHeader />
        <PricingEstimateTableRow
          totalVariants={totalVariants}
          evaluationItems={evaluationItems}
          ratePerItem={ratePerItem}
          timePerItem={timePerItem}
        />
      </Box>
    </Box>
  );
};

export default PricingEstimateTable;

const PricingEstimateTableHeader: React.FC = () => {
  return (
    <>
      <PricingEstimateTableCell headerCell>
        <Typography color={theme.palette.info.main}> VARIANTS</Typography>
      </PricingEstimateTableCell>
      <PricingEstimateTableCell headerCell>
        <Typography color={theme.palette.info.main}> ITEMS</Typography>
      </PricingEstimateTableCell>
      <PricingEstimateTableCell headerCell>
        <Typography color={theme.palette.info.main}> ESTIMATED TIME </Typography>
      </PricingEstimateTableCell>
      <PricingEstimateTableCell headerCell>
        <Typography color={theme.palette.info.main}> UNIT RATE </Typography>
      </PricingEstimateTableCell>
      <PricingEstimateTableCell headerCell>
        <Typography color={theme.palette.info.main}> ESTIMATED COST </Typography>
      </PricingEstimateTableCell>
    </>
  );
};

const PricingEstimateTableRow: React.FC<PricingEstimateTableProps> = ({
  totalVariants,
  evaluationItems,
  ratePerItem,
  timePerItem,
}) => {
  const nHours = Math.ceil(timePerItem * totalVariants * evaluationItems * 2) / 2 + 2; // rough estimation formula, round up to nearest half hour and add 2 hours for worker delay
  const totalCost = (ratePerItem * totalVariants * evaluationItems).toFixed(2);

  return (
    <>
      <PricingEstimateTableCell>
        <Typography> {totalVariants}</Typography>
      </PricingEstimateTableCell>
      <PricingEstimateTableCell>
        <Typography> {evaluationItems}</Typography>
      </PricingEstimateTableCell>
      <PricingEstimateTableCell>
        <Typography>{`${nHours.toFixed(1)} hours`}</Typography>
      </PricingEstimateTableCell>
      <PricingEstimateTableCell>
        <Typography> {`$${ratePerItem.toFixed(2)} per item per variant`} </Typography>
      </PricingEstimateTableCell>
      <PricingEstimateTableCell>
        <Typography fontWeight="bold"> {`$${totalCost}`} </Typography>
      </PricingEstimateTableCell>
    </>
  );
};

const PricingEstimateTableCell: React.FC<{
  children?: React.ReactNode;
  sx?: SxProps;
  headerCell?: boolean;
}> = ({ children, headerCell, sx }) => {
  return (
    <Box
      sx={{
        padding: 1,
        width: '100%',
        outline: `0.5px solid #E5E7EB`,
        backgroundColor: headerCell ? '#F3F4F6' : undefined,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
