import { z } from 'zod';

import { DataType } from '@prisma/client';
import { TerminalJobStatus, TokenProbs } from '@scale/llm-shared/types';

// Type

export const DataValue = z.object({
  value: z.string(),
  row: z.string(),
  dataColumnId: z.string(),
});

export const InferenceValue = DataValue.extend({
  tokenProbs: z.nullable(TokenProbs),
  status: z.nullable(z.nativeEnum(TerminalJobStatus)),
});

export const DataColumn = z.object({
  id: z.string(),
  name: z.string(),
  dataId: z.string(),
  values: z.array(DataValue),
});

export const InferenceColumn = z.object({
  id: z.string(),
  name: z.string(),
  dataId: z.string(),
  values: z.array(InferenceValue),
});

export const Data = z.object({
  id: z.string(),
  name: z.string(),
  columns: z.array(DataColumn),
  type: z.nativeEnum(DataType),
});

export const DataInfo = Data.omit({ columns: true }).extend({
  dataColumns: z.array(z.string()),
  numRows: z.number(),
});

export type DataValue = z.infer<typeof DataValue>;
export type InferenceValue = z.infer<typeof InferenceValue>;

export type DataColumn = z.infer<typeof DataColumn>;
export type InferenceColumn = z.infer<typeof InferenceColumn>;

export type Data = z.infer<typeof Data>;
export type DataInfo = z.infer<typeof DataInfo>;

// Create

export const DataValueCreate = DataValue.omit({ id: true, dataId: true, dataColumnId: true });
export const DataColumnCreate = DataColumn.omit({ id: true, dataId: true }).extend({
  values: z.array(DataValueCreate),
});
export const DataCreate = Data.omit({ id: true, columns: true }).extend({
  columns: z.array(DataColumnCreate),
});

export type DataValueCreate = z.infer<typeof DataValueCreate>;
export type DataColumnCreate = z.infer<typeof DataColumnCreate>;
export type DataCreate = z.infer<typeof DataCreate>;

// Row
export const DataRow = z.object({
  index: z.string(),
  valueByName: z.record(z.string(), z.string()),
});

export type DataRow = z.infer<typeof DataRow>;
