import { default as color } from 'color';

import { createTheme, ThemeOptions } from '@mui/material/styles';
import { TypographyStyleOptions } from '@mui/material/styles/createTypography';

// Extend Palette
declare module '@mui/material/styles' {
  interface Palette {
    userApp?: {
      light?: string;
      main: string;
      dark?: string;
      contrastText?: string;
    };
    infoSecondary?: {
      light?: string;
      main: string;
      dark?: string;
      contrastText?: string;
    };
  }
  interface PaletteOptions {
    userApp?: {
      light?: string;
      main: string;
      dark?: string;
      contrastText?: string;
    };
    infoSecondary?: {
      light?: string;
      main: string;
      dark?: string;
      contrastText?: string;
    };
  }
}

// Extend Typography
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    code1: true;
  }
}
declare module '@mui/material/styles/createTypography' {
  interface TypographyOptions {
    code1?: React.CSSProperties;
  }
}

// Extend Button
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    userApp: true;
    infoSecondary: true;
  }
}

export const Colors = {
  Black: '#000000',
  White: '#FFFFFF',
  Purple: '#6A5DF9',
  Blue: '#0076FF',
  Red: '#EF3D57',
  Yellow: '#FFCA28',
  Green: '#72CE7B',
  RemoBlue: '#2B99D8', // Buttons and links
  LightSkyBlue: '#BBDEF2',
  CoolBlue: '#943CFF',
  CoolBlue05: 'rgba(148, 60, 255, 0.05)',
  CoolBlue10: 'rgba(148, 60, 255, 0.10)',
  RemoGreen: '#29CD9C',
  RemoGreenLight: '#C1EAD9',
  RemoGold: '#F8BB06', // Warnings
  RemoOrange: '#F39212', // Warnings
  RemoRed: '#E8412A', // Errors
  RemoGray01: '#F8F8F8', // Background wash
  RemoGray05: '#F2F2F2', // Secondary wash
  RemoGray10: '#E6E6E6', // Borders
  RemoGray20: '#CCCCCC', // Disabled buttons
  RemoGray50: '#808080', // Secondary buttons
  RemoGray75: '#404040', // Text
  TrueGray50: '#FAFAFA',
  Periwinkle5: '#F0EFFE', // Lighter
  Periwinkle25: '#DAD7FD',
  Periwinkle50: '#B4AEFC',
  Periwinkle75: '#8F86FA',
  Periwinkle100: '#6A5DF9', // Darker
  Shadow: 'rgba(0, 0, 0, 0.3)',
  ShadowLight: 'rgba(0, 0, 0, 0.05)',
  CoolGray05: '#F9FAFB',
  CoolGray10: '#F3F4F6',
  CoolGray20: '#E5E7EB',
  CoolGray30: '#D1D5DB',
  CoolGray40: '#9CA3AF',
  CoolGray50: '#6B7280',
  CoolGray60: '#4B5563',
  CoolGray70: '#374151',
  CoolGray80: '#1F2937',
  CoolGray90: '#111827',
  Red20: '#EF9A9A',
};

export const toolbarTheme = createTheme({
  palette: {
    primary: {
      light: color(Colors.Black).lighten(0.2).toString(),
      main: Colors.White,
      contrastText: Colors.Black,
    },
    secondary: {
      main: Colors.CoolGray20,
      contrastText: Colors.Black,
    },
    background: {
      // Default background color
      default: Colors.TrueGray50,
      // Color of containers on top of background
      paper: 'white',
    },
  },
  typography: {
    allVariants: {
      fontFamily: "'Inter', -apple-system, Arial, Sans-Serif",
      color: Colors.CoolGray70,
      fontSize: 14,
    },
    h1: {
      fontFamily: "'Roboto', 'Inter', -apple-system, Arial, Sans-Serif",
      fontSize: 18,
      fontWeight: 500,
      lineHeight: 1.4,
    },
    button: {
      color: Colors.Black,
      textTransform: 'none',
      fontWeight: 500,
      letterSpacing: -0.5,
    },
  },
});

const theme = createTheme({
  palette: {
    // Dark purple
    primary: {
      light: color(Colors.Purple).lighten(0.43).toString(),
      main: Colors.Purple,
      dark: color(Colors.Purple).darken(0.08).toString(),
      contrastText: '#fff',
    },
    // Light purple
    secondary: {
      light: color(Colors.Purple).lighten(0.48).toString(),
      main: color(Colors.Purple).lighten(0.43).toString(),
      dark: color(Colors.Purple).lighten(0.4).toString(),
      contrastText: Colors.Purple,
    },
    // Green
    success: {
      light: color(Colors.RemoGreen).lighten(0.2).toString(),
      main: Colors.RemoGreen,
      dark: color(Colors.RemoGreen).darken(0.2).toString(),
      contrastText: '#fff',
    },
    // Gray
    info: {
      light: Colors.CoolGray20,
      main: Colors.CoolGray50,
      dark: Colors.CoolGray70,
      contrastText: '#fff',
    },
    // Light gray
    infoSecondary: {
      light: Colors.CoolGray10,
      main: Colors.CoolGray20,
      dark: Colors.CoolGray30,
      contrastText: Colors.CoolGray70,
    },
    // Blue (for user app cards)
    userApp: {
      light: color(Colors.Blue).lighten(0.9).toString(),
      main: Colors.Blue,
      dark: color(Colors.Blue).darken(0.2).toString(),
      contrastText: '#fff',
    },
    // Gold
    warning: {
      light: color(Colors.RemoGold).lighten(0.2).toString(),
      main: Colors.RemoGold,
      dark: color(Colors.RemoGold).darken(0.2).toString(),
      contrastText: '#fff',
    },
    error: {
      light: color(Colors.RemoRed).lighten(0.2).toString(),
      main: Colors.RemoRed,
      dark: color(Colors.RemoRed).darken(0.2).toString(),
      contrastText: '#fff',
    },
    background: {
      // Default background color
      default: Colors.TrueGray50,
      // Color of containers on top of background
      paper: 'white',
    },
  },
  shape: {
    borderRadius: 8,
  },
  shadows: [
    'none',
    '0px 10px 30px rgba(0, 0, 0, 0.05)',
    '0px 20px 40px rgba(0, 0, 0, 0.05)',
    '0px 30px 50px rgba(0, 0, 0, 0.05)',
    '0px 40px 60px rgba(0, 0, 0, 0.05)',
    '0px 20px 40px rgba(0, 0, 0, 0.15)',
    ...Array(19).fill('none'),
  ] as ThemeOptions['shadows'],
  typography: {
    fontFamily: "'Inter', -apple-system, Arial, Sans-Serif",
    h1: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: 1.4,
    },
    h2: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 1.4,
    },
    h3: {
      color: Colors.CoolGray90,
      fontSize: 14,
      fontWeight: 600,
    },
    h4: {
      fontSize: 12,
      fontWeight: 600,
    },
    h6: {
      color: '#fff',
      fontFamily: 'Avenir',
      fontSize: 16,
      fontWeight: 600,
      letterSpacing: 0.4,
    },
    body1: {
      color: Colors.CoolGray70,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.4,
    },
    body2: {
      color: Colors.CoolGray50,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.4,
    },
    subtitle1: {
      fontSize: 12,
    },
    subtitle2: {
      fontSize: 12,
      color: Colors.RemoGray50,
    },
    overline: {
      color: Colors.CoolGray50,
      textTransform: 'uppercase',
      fontSize: 12,
      lineHeight: 1.4,
      whiteSpace: 'pre-wrap',
    },
    button: {
      textTransform: 'none',
    },
    code1: {
      fontSize: 14,
      fontWeight: 600,
      fontFamily: 'monospace',
    },
  },
  components: {
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: 16,
          fontStyle: 'normal',
          fontWeight: 500,
          letterSpacing: 'inherit',
          fontFamily: "'Inter', -apple-system, Arial, Sans-Serif",
          color: Colors.CoolGray90,
          padding: '12px 16px',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '16px',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          '.MuiMenu-paper': {
            boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.15)',
          },
        },
      },
    },
    MuiPopper: {
      defaultProps: {
        sx: {
          '& .MuiTooltip-tooltip': {
            backgroundColor: Colors.Black,
            paddingY: 1,
            paddingX: 2,
            fontSize: 12,
            fontWeight: 500,
            lineHeight: '19.2px',
          },
          '& .MuiTooltip-tooltip a': {
            color: Colors.White,
            textDecorationColor: Colors.White,
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          '& .MuiSlider-thumb': {
            width: '15px',
            height: '15px',
          },
        },
      },
    },
  },
});

export default theme;
