import _ from 'lodash';

import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';

import { StoredDataColumn } from 'frontend/storesV2/types';
import theme, { Colors } from 'frontend/theme';

export function DataOutputColumnSelector({
  columns,
  value,
  onChange,
  variant = 'standard',
  size = 'small',
}: {
  columns?: StoredDataColumn[];
  value: StoredDataColumn | undefined;
  onChange: (newValue: StoredDataColumn) => void;
  variant?: 'standard' | 'outlined';
  size?: 'small' | 'medium';
}) {
  if (!columns) {
    return null;
  }
  const columnIdToColumn = _.keyBy(columns, 'id');
  return (
    <FormControl fullWidth size={size}>
      <Select
        variant={variant}
        value={value?.id ?? ''}
        onChange={e => onChange(columnIdToColumn[e.target.value])}
        sx={{
          '& .MuiInputBase-input':
            variant === 'standard'
              ? {
                  padding: 0,
                  border: 0,
                }
              : {},
        }}
      >
        {columns.map(column => (
          <MenuItem value={column.id} key={column.id}>
            <Typography
              sx={{
                color: Colors.CoolGray50,
                fontFamily: 'monospace',
                fontSize: theme.typography.body1.fontSize,
                fontWeight: theme.typography.fontWeightBold,
              }}
            >
              {column.name}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
