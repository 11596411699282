import React from 'react';

import _ from 'lodash';
import * as R from 'ramda';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MenuItem, Select, Typography } from '@mui/material';
import theme, { Colors } from 'frontend/theme';
import { useSelectionStore } from 'frontend/storesV2/SelectionStore';
import { VariantSelectorItem } from './VariantSelectorItem';

interface MultiVariantSelectorProps {
  value: string[];
  onChange: (values: string[]) => void;
  disabledVariants?: { variantId?: string; reason?: string }[];
  size?: 'small' | 'medium' | undefined;
  fullWidth?: boolean;
}

const MultiVariantSelector: React.FC<MultiVariantSelectorProps> = ({
  value,
  onChange,
  disabledVariants,
  size,
  fullWidth,
}) => {
  const { selectedAppVariants } = useSelectionStore(R.pick(['selectedAppVariants']));

  return (
    <Select
      fullWidth={fullWidth}
      displayEmpty
      value={value}
      onChange={e => onChange(e.target.value as string[])}
      size={size}
      multiple
      renderValue={
        !value.length
          ? () => <Typography sx={{ color: Colors.CoolGray40 }}>Select Variant(s)</Typography>
          : undefined
      }
    >
      <MenuItem onClick={() => onChange([])}>
        <span>
          <FontAwesomeIcon
            icon="square-minus"
            color={theme.palette.primary.main}
            size="lg"
            style={{ marginRight: '6px' }}
          />
          Clear all
        </span>
      </MenuItem>

      {selectedAppVariants?.map(variant => {
        const disabledVariant = disabledVariants?.find(
          disabled => disabled.variantId === variant.id,
        );
        // Call the function specifically rather than create a component since it seems MUI does not interact well with an intermediate component between the Select and MenuItem components
        return VariantSelectorItem({ variant, disabledVariant });
      })}
    </Select>
  );
};

export default MultiVariantSelector;
