import {
  CohereBaseModelDisplayName,
  CohereBaseModelInternalId,
} from '@scale/llm-shared/modelProviders/cohere/consts';
import { ICohereBaseModel } from '@scale/llm-shared/modelProviders/cohere/types';
import { getNumGpt3Tokens } from '@scale/llm-shared/tokenizers/countTokens';

export const CohereGenerateXLarge: ICohereBaseModel = {
  id: CohereBaseModelInternalId.CohereGenerateXLarge,
  displayName: CohereBaseModelDisplayName[CohereBaseModelInternalId.CohereGenerateXLarge],
  minTokensAllowed: 0,
  maxTokensAllowed: 2048,
  minTemperatureAllowed: 0,
  maxTemperatureAllowed: 1,
  externalModelId: 'xlarge',
  costCentsPerMillionPromptTokens: 15,
  costCentsPerMillionCompletionTokens: 15,

  getNumTokens: (prompt: string) => {
    return getNumGpt3Tokens(prompt);
  },
};
