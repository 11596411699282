import { useMemo, useState } from 'react';

import _ from 'lodash';

import { Box } from '@mui/material';
import { DataGrid, GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid';

import theme, { Colors } from 'frontend/theme';

type GrayDataGridProps<T extends GridValidRowModel = any> = React.ComponentProps<
  typeof DataGrid<T>
>;

const PAGE_OPTIONS = [10, 15, 20, 50, 100, 200, 500, 1000, 2000, 5000, 10000];
export default function GrayDataGrid<T extends GridValidRowModel = any>(
  props: GrayDataGridProps<T>,
) {
  // Do this to provide defaults
  const [pageSize, setPageSize] = useState<number>(props.pageSize ?? 10);
  const rowsPerPageOptions: number[] = useMemo(() => {
    if (props.rowsPerPageOptions) {
      return props.rowsPerPageOptions;
    }
    // Find the index of the first value higher than num of rows
    const idx = _.sortedIndex(PAGE_OPTIONS, props.rows.length);
    // Truncate the page options to before the first higher value, then append the row length to the end
    return PAGE_OPTIONS.slice(0, idx).concat([props.rows.length]);
  }, [props.rowsPerPageOptions, props.rows]);
  // Note that autoHeight cannot be used with autoPageSize: https://github.com/mui/mui-x/issues/5025
  const autoHeight: boolean = useMemo(() => {
    return props.autoHeight === undefined ? true : props.autoHeight;
  }, [props.autoHeight]);

  return (
    <DataGrid<T>
      showCellRightBorder={false}
      showColumnRightBorder={false}
      getRowHeight={() => 'auto'}
      onPageSizeChange={newPageSize => setPageSize(newPageSize)}
      sx={{
        borderRadius: 1,
        '&.MuiDataGrid-root': {
          bgcolor: 'background.paper',
        },
        '& .MuiDataGrid-row': {
          border: 'none',
        },
        '& .MuiDataGrid-columnHeaders': {
          color: Colors.CoolGray50,
          fontSize: theme.typography.subtitle2.fontSize,
          fontWeight: theme.typography.fontWeightBold,
          backgroundColor: Colors.CoolGray10,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
        },
        '& .MuiDataGrid-columnHeader': {
          boxSizing: 'border-box',
        },
        '& .MuiDataGrid-columnHeader.highlighted': {
          backgroundColor: Colors.CoolBlue10,
          border: `1px solid ${Colors.CoolBlue}`,
          borderBottom: `1px solid ${Colors.CoolGray30}`,
          borderTopLeftRadius: theme.shape.borderRadius,
          borderTopRightRadius: theme.shape.borderRadius,
        },
        '& .MuiDataGrid-cell.highlighted': {
          backgroundColor: Colors.CoolBlue05,
          borderLeft: `1px solid ${Colors.CoolBlue}`,
          borderRight: `1px solid ${Colors.CoolBlue}`,
        },
        '& .MuiDataGrid-row:last-child .MuiDataGrid-cell.highlighted': {
          border: `1px solid ${Colors.CoolBlue}`,
          borderTop: 'none',
          boxSizing: 'border-box',
        },
        '& .MuiDataGrid-columnSeparator': {
          visibility: 'hidden',
        },
        '& .MuiDataGrid-cell': {
          color: Colors.CoolGray70,
        },
        '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: 1 },
        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: 2 },
        '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: 3 },
      }}
      {...props}
      pageSize={pageSize}
      rowsPerPageOptions={rowsPerPageOptions}
      autoHeight={autoHeight}
    />
  );
}

export function renderCenteredWrappedText(children?: string | JSX.Element) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: 72,
        width: 360,
        overflowY: 'scroll',
        whiteSpace: 'normal',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      {children}
    </Box>
  );
}

export function renderWrappedText(children?: string | JSX.Element) {
  return (
    <Box
      sx={{
        height: 72,
        width: 360,
        overflowY: 'scroll',
        whiteSpace: 'normal',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      {children}
    </Box>
  );
}

export function renderSingleLineText(children?: string | JSX.Element) {
  return (
    <Box
      sx={{
        height: '1.5em',
        overflowY: 'hidden',
        textOverflow: 'ellipsis ellipsis',
        whiteSpace: 'normal',
      }}
    >
      {children}
    </Box>
  );
}

export function renderExpandableCell(params: GridRenderCellParams<string>) {
  return (
    <Box
      sx={
        params.hasFocus
          ? {
              whiteSpace: 'pre-wrap',
              overflowWrap: 'anywhere',
            }
          : {
              height: '1.5em',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }
      }
    >
      {params.value}
    </Box>
  );
}
