import { useCallback, useMemo } from 'react';

import * as R from 'ramda';

import { Box, Typography } from '@mui/material';
import { EvaluationMethod, HumanJobSource, HumanRanking } from '@prisma/client';
import { BaseEvaluationTypeV2, ExpertFeedbackType } from '@scale/llm-shared/types';

import { EvaluationIconName } from 'frontend/components/evaluation/EvaluationTypeIcon';
import { EvaluationWizardCell } from 'frontend/components/evaluation/EvaluationWizardLayout';
import { FEATURE_FLAG, FEATURE_FLAGS } from 'frontend/consts/featureFlags';
import { useUnleashFlags } from 'frontend/hooks/useUnleashFlags';
import { useEvaluationWizardState } from 'frontend/models/v2/useEvaluationWizardState';
import { useSelectionStore } from 'frontend/storesV2/SelectionStore';
import { useVariantStore } from 'frontend/storesV2/VariantStore';

import { SelectFeedbackTypeButton } from './SelectFeedbackTypeButton';
import { SelectVariantAndDatasetPanel } from './SelectVariantAndDatasetPanel';

const evaluationSelectorRenderInfo = {
  [ExpertFeedbackType.BINARY]: {
    titleText: 'Binary',
    bodyText: "Determine how well one variant's output meets your criteria.",
    isDisabled: true,
    iconName: EvaluationIconName.Binary,
  },
  [ExpertFeedbackType.RANKING]: {
    titleText: 'Ranking',
    bodyText:
      'Determine how several variants compare to each other and which one is consistently best.',
    isDisabled: true,
    iconName: EvaluationIconName.Ranking,
  },
  [ExpertFeedbackType.CUSTOM_HUMAN_EVAL]: {
    titleText: 'Custom Human Evaluation',
    bodyText: 'Set up your custom human evaluation task',
    isDisabled: false,
    iconName: EvaluationIconName.Mauve,
  },
  [EvaluationMethod.AIFeedback]: {
    titleText: 'AI Feedback',
    bodyText:
      'Measure how often your variant follows your manually defined criteria using the current state of the art Large Language Models.',
    isDisabled: false,
    iconName: EvaluationIconName.AIFeedback,
  },
  [EvaluationMethod.Classification]: {
    titleText: 'Classification',
    bodyText:
      'Exact matching your labels and outputs. Generates F1 score with overall and per class accuracy metrics.',
    isDisabled: false,
    iconName: EvaluationIconName.Classification,
  },
  [EvaluationMethod.Mauve]: {
    titleText: 'MAUVE',
    bodyText:
      'Measure distribution similarities between your labels and outputs. Useful for longer generations, but recommended to have many samples for a more precise score.',
    isDisabled: false,
    iconName: EvaluationIconName.Mauve,
  },
};

function renderEvaluationSelectorItem(
  buttonFeedbackType: BaseEvaluationTypeV2,
  selectedFeedbackType: BaseEvaluationTypeV2 | undefined,
  setFeedbackType: (
    state:
      | BaseEvaluationTypeV2
      | ((state: BaseEvaluationTypeV2 | undefined) => BaseEvaluationTypeV2 | undefined)
      | undefined,
  ) => void,
  overrideDisabled?: boolean,
  onClick?: (evalType: BaseEvaluationTypeV2) => void,
) {
  const buttonInfo = evaluationSelectorRenderInfo[buttonFeedbackType];
  if (overrideDisabled) {
    buttonInfo.isDisabled = false;
  }
  const handleClick = () => {
    setFeedbackType(buttonFeedbackType);
    onClick?.(buttonFeedbackType);
  };

  return (
    <SelectFeedbackTypeButton
      {...buttonInfo}
      isSelected={buttonFeedbackType === selectedFeedbackType}
      handleClick={handleClick}
    />
  );
}

type EvaluationWizardSetupBodyProps = {
  canPickVariantAndDataset: boolean;
};

export function EvaluationWizardSetupBody(input: EvaluationWizardSetupBodyProps) {
  const {
    feedbackType,
    setFeedbackType,
    variantIds,
    setVariantIds,
    dataId,
    setDataId,
    humanJobSource,
    setHumanJobSource,
    shouldStripWhitespace,
    setShouldStripWhitespace,
    selectedOutputColumn,
    setSelectedOutputColumn,
  } = useEvaluationWizardState();

  const { canPickVariantAndDataset } = input;

  const { variantById } = useVariantStore(R.pick(['variantById']));
  const { selectedAppVariants } = useSelectionStore(R.pick(['selectedAppVariants']));

  const shouldUseMultiVariantSelect = useMemo(() => {
    return feedbackType === ExpertFeedbackType.CUSTOM_HUMAN_EVAL;
  }, [feedbackType]);

  const handleVariantSelect = useCallback(
    (variantIds: string[]) => {
      setVariantIds(variantIds);

      // For convenience, we try to guess what dataset the user wants to choose
      if (variantIds.length === 1) {
        const variant = variantById[variantIds[0]];
        if (variant?.prompt.variablesSourceDataId) {
          setDataId(variant.prompt.variablesSourceDataId);
        }
      }
    },
    [feedbackType, setVariantIds],
  );

  const handleDataSelect = useCallback(
    (value: string | undefined | null) => {
      if (value === null) {
        // For some reason the DatasetSelectorAndUploader can pass in a null value, even though we only ever have string | undefined
        setDataId(undefined);
      } else {
        setDataId(value);
      }
      setSelectedOutputColumn(undefined);
    },
    [setDataId, setSelectedOutputColumn],
  );

  const onHumanJobSourceSelect = useCallback(
    (newSource: HumanJobSource) => {
      setHumanJobSource(newSource);
    },
    [setHumanJobSource],
  );

  const outputColumnRequired = isOutputColumnRequired(feedbackType);

  const disabledVariants = useMemo(() => {
    if (selectedAppVariants) {
      return FEATURE_FLAG.evalDisabledModels.filter(selectedAppVariants).map(variant => {
        return {
          variantId: variant.id,
          reason: "Evaluating using GPT 4 models disabled while it's in beta.",
        };
      });
    }
    return;
  }, [selectedAppVariants]);

  const evaluationSelectorRender = useMemo(() => {
    const handleClick = (evaluation: BaseEvaluationTypeV2) => {
      if (isOutputColumnRequired(evaluation) && FEATURE_FLAG.evalDisabledModels.enabled) {
        setVariantIds(variantIds => {
          return variantIds.filter(variantId => {
            return !FEATURE_FLAG.evalDisabledModels.filteredIds.has(
              variantById[variantId].modelParameters.modelId,
            );
          });
        });
      }
    };

    const binaryButton = renderEvaluationSelectorItem(
      ExpertFeedbackType.BINARY,
      feedbackType,
      setFeedbackType,
      true,
      handleClick,
    );
    const rankingButton = renderEvaluationSelectorItem(
      ExpertFeedbackType.RANKING,
      feedbackType,
      setFeedbackType,
      false,
      handleClick,
    );

    const customHumanEvaluationButton = renderEvaluationSelectorItem(
      ExpertFeedbackType.CUSTOM_HUMAN_EVAL,
      feedbackType,
      setFeedbackType,
      false,
      handleClick,
    );

    const aiFeedbackButton = renderEvaluationSelectorItem(
      EvaluationMethod.AIFeedback,
      feedbackType,
      setFeedbackType,
      false,
      handleClick,
    );
    const classificationButton = renderEvaluationSelectorItem(
      EvaluationMethod.Classification,
      feedbackType,
      setFeedbackType,
      false,
      handleClick,
    );
    const mauveButton = renderEvaluationSelectorItem(
      EvaluationMethod.Mauve,
      feedbackType,
      setFeedbackType,
      false,
      handleClick,
    );

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography>Human Evaluations</Typography>
          {binaryButton}
          {rankingButton}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography>Programmatic Evaluations</Typography>
          {FEATURE_FLAG.enableAiFeedback.enabled && aiFeedbackButton}
          {classificationButton}
          {mauveButton}
        </Box>
      </Box>
    );
  }, [feedbackType, setFeedbackType]);

  return (
    <>
      {/* Left */}
      <EvaluationWizardCell>{evaluationSelectorRender}</EvaluationWizardCell>
      {/* Right */}
      <EvaluationWizardCell>
        <SelectVariantAndDatasetPanel
          canPickVariantAndDataset={canPickVariantAndDataset}
          handleVariantSelect={handleVariantSelect}
          handleSelectData={handleDataSelect}
          selectedDataId={dataId}
          selectedVariantIds={variantIds}
          feedbackType={feedbackType}
          selectedHumanJobSource={humanJobSource}
          handleHumanJobSourceSelect={onHumanJobSourceSelect}
          shouldStripWhitespace={shouldStripWhitespace}
          setShouldStripWhitespace={setShouldStripWhitespace}
          outputColumnRequired={outputColumnRequired}
          selectedOutputColumn={selectedOutputColumn}
          setSelectedOutputColumn={setSelectedOutputColumn}
          disabledVariants={disabledVariants}
          shouldUseMultiVariantSelect={shouldUseMultiVariantSelect}
        />
      </EvaluationWizardCell>
    </>
  );
}

function isOutputColumnRequired(evaluationType?: BaseEvaluationTypeV2) {
  return (
    evaluationType === EvaluationMethod.Classification || evaluationType === EvaluationMethod.Mauve
  );
}
