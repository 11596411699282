import { createFromStates, state } from 'frontend/utils/store';

export const Tab = {
  Evaluations: { title: 'Evaluation Results', disabled: false },
  Evaluators: { title: 'Bulk Inferences', disabled: false },
} as const;

export type Tab = typeof Tab[keyof typeof Tab]['title'];

export const TABS = Object.entries(Tab).map(([, tab]) => ({
  text: tab.title,
  value: tab.title,
  disabled: tab.disabled,
}));

const states = [
  state('selectedTab', Tab.Evaluations.title as Tab),
  state('isCreateEvaluationWizardOpen', false),
  state('isCreateInferenceBatchOpen', false),
];

type Store = UnionToIntersection<ReturnType<typeof states[number]>>;

export const useEvaluationsPageState = createFromStates<Store>(states);
