import { useCallback, useMemo } from 'react';

import { formatISO9075 } from 'date-fns';
import _ from 'lodash';
import * as R from 'ramda';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DeploymentLog, JobStatus } from '@prisma/client';
import { parseQueryVariables } from '@scale/llm-shared/templating/promptsV2';
import { useQuery } from '@tanstack/react-query';

import { client } from 'frontend/api/trpc';
import { Container } from 'frontend/components/Container';
import { RenderResult } from 'frontend/components/evaluation/utils';
import FlexBox from 'frontend/components/FlexBox';
import { DownloadLogsAsCsv } from 'frontend/components/v2/deployment/DownloadLogsAsCsv';
import VariantName from 'frontend/components/v2/variant/VariantName';
import { StoredDeployment } from 'frontend/storesV2/types';
import { useVariantStore } from 'frontend/storesV2/VariantStore';
import theme, { Colors } from 'frontend/theme';
import { PairedRowStatus } from 'frontend/types/types';

export function DeploymentLogsTable({
  deployment,
  watch,
}: {
  deployment: StoredDeployment;
  watch?: boolean;
}): JSX.Element {
  // Get variant
  const { getVariant } = useVariantStore(R.pick(['getVariant']));
  const handleGetVariant = useCallback(() => {
    return getVariant(deployment.variantId);
  }, [deployment.variantId]);
  const variantQuery = useQuery(['getVariant', deployment.variantId], handleGetVariant);

  // Get logs
  const logsQuery = useQuery(
    ['v2.deployment.getRecentLogs', deployment.id],
    () => client.query('v2.deployment.getRecentLogs', { deploymentId: deployment.id }),
    {
      refetchInterval: 5000,
      refetchIntervalInBackground: true,
      cacheTime: 0,
    },
  );

  const recentLogs = useMemo(() => logsQuery.data?.logs || [], [logsQuery.data]);

  const handleClickRefetch = useCallback(() => {
    logsQuery.refetch({ cancelRefetch: false });
  }, [logsQuery.refetch]);

  return (
    <Container sx={{ width: '100%', height: '100%' }}>
      <FlexBox sx={{ justifyContent: 'space-between' }}>
        <FlexBox style={{ gap: 0 }}>
          <Typography variant="body1" sx={{ lineHeight: 1.8 }}>
            Recent Requests
          </Typography>
          <IconButton onClick={handleClickRefetch} disabled={logsQuery.isFetching}>
            <FontAwesomeIcon icon="refresh" style={{ fontSize: 16 }} />
          </IconButton>
        </FlexBox>
        <DownloadLogsAsCsv deploymentId={deployment.id} />
      </FlexBox>

      <TableContainer component={Paper} variant="outlined">
        <Table
          sx={{
            borderCollapse: 'separate',
            '& .MuiTableCell-row': {
              border: 'none',
            },
            '& .MuiTableHead-root .MuiTableCell-root': {
              color: Colors.CoolGray50,
              fontFamily: 'inherit',
              fontSize: theme.typography.subtitle2.fontSize,
              fontWeight: theme.typography.fontWeightBold,
              backgroundColor: Colors.CoolGray10,
              borderWidth: 1,
              borderColor: Colors.CoolGray30,
              paddingY: 0.5,
            },
            '& .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root': {
              borderBottom: 'none',
            },
            '& .MuiTableRow-root:hover': {
              backgroundColor: Colors.CoolGray10,
            },
            '& .MuiTableCell-root': {
              color: Colors.CoolGray70,
              fontFamily: 'monospace',
              borderRight: `1px solid ${Colors.CoolGray20}`,
            },
            '& .MuiTableCell-root:last-child': {
              borderRight: 'none',
              borderLeft: 'none',
            },

            '& .multiinput-inner-table .MuiTableCell-root': {
              borderBottom: 'none',
            },
            '& .custom-input-inner-table .MuiTableCell-root': {
              borderBottom: 'none',
            },

            // MultiInput display changes based on the hover and selection state of
            // the dataset row (darken borders, change base color to blue)
            // TODO: probably doable via theming
            '& .MuiTableRow-root.selected .multiinput-inner-table .MuiTableRow-root': {
              backgroundColor: 'transparent',
            },
            '& .MuiTableRow-root:hover .multiinput-inner-table .MuiTableCell-root': {
              borderColor: Colors.CoolGray20,
            },
            '& .MuiTableRow-root.selected .multiinput-inner-table .MuiTableCell-root': {
              borderColor: Colors.CoolBlue10,
            },

            // Selection colors -- change theme to blue and add border
            '& .MuiTableRow-root.selected': {
              backgroundColor: Colors.CoolBlue05,
            },
            '& .MuiTableRow-root.selected:hover': {
              backgroundColor: Colors.CoolBlue10,
            },
            '& .MuiTableCell-root.selected': {
              border: `1px double ${Colors.Blue}`,
            },
            '& .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root.selected': {
              borderBottom: `1px double ${Colors.Blue}`,
            },
          }}
        >
          <TableHead sx={{ display: 'auto' }}>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Input</TableCell>
              <TableCell>
                <VariantName name={variantQuery.data?.name || ''} />
              </TableCell>
              <TableCell>Response time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Log rows */}
            {logsQuery.isInitialLoading ? (
              <TableRow>
                <TableCell>
                  <Skeleton
                    sx={{ bgcolor: theme.palette.info.light }}
                    height={20}
                    variant="rounded"
                  />
                </TableCell>
                <TableCell>
                  <Skeleton
                    sx={{ bgcolor: theme.palette.info.light }}
                    height={20}
                    variant="rounded"
                  />
                </TableCell>
                <TableCell>
                  <Skeleton
                    sx={{ bgcolor: theme.palette.info.light }}
                    height={20}
                    variant="rounded"
                  />
                </TableCell>
                <TableCell>
                  <Skeleton
                    sx={{ bgcolor: theme.palette.info.light }}
                    height={20}
                    variant="rounded"
                  />
                </TableCell>
              </TableRow>
            ) : !recentLogs.length ? (
              <TableRow>
                <TableCell colSpan={4}>
                  <Typography align="center">No requests have been made recently.</Typography>
                </TableCell>
              </TableRow>
            ) : (
              recentLogs.map(log => {
                return (
                  <TableRow key={log.row}>
                    <TableCell>{formatISO9075(log.createdAt)}</TableCell>
                    <TableCell sx={{ whiteSpace: 'break-spaces' }}>
                      {JSON.stringify(log.inputByName, undefined, 2)}
                    </TableCell>
                    <TableCell>
                      <RenderResult
                        row={{
                          id: log.row.toString(),
                          status: JOB_STATUS_TO_ROW_STATUS[log.status],
                          input: '',
                          expectedOutput: '',
                          actualOutput: log.output || '',
                          tokenProbs: null,
                          finishReason: null,
                          error: log.errorMessage ?? null,
                        }}
                        inProgressElem={
                          <Box>
                            <Skeleton width={120} />
                            <Skeleton width={150} />
                          </Box>
                        }
                      />
                    </TableCell>
                    <TableCell>{log.responseTimeMs} ms</TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

const JOB_STATUS_TO_ROW_STATUS: Record<JobStatus, PairedRowStatus> = {
  [JobStatus.Pending]: PairedRowStatus.NOT_STARTED,
  [JobStatus.Running]: PairedRowStatus.IN_PROGRESS,
  [JobStatus.Errored]: PairedRowStatus.FAILED,
  [JobStatus.Completed]: PairedRowStatus.COMPLETE,
  [JobStatus.Cancelled]: PairedRowStatus.FAILED,
  [JobStatus.Backfilled]: PairedRowStatus.COMPLETE,
};
