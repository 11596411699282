export const ScaleLaunchBaseModelInternalId = {
  Flan_XXL: 'Flan_XXL',
  Dolly2: 'Dolly2',
  Mpt7B: 'Mpt7B',
  Mpt7BInstruct: 'Mpt7BInstruct',
} as const;

export const ScaleLaunchBaseModelDisplayName: Record<ScaleLaunchBaseModelInternalId, string> = {
  [ScaleLaunchBaseModelInternalId.Flan_XXL]: 'FLAN T5-XXL',
  [ScaleLaunchBaseModelInternalId.Dolly2]: 'Dolly v2',
  [ScaleLaunchBaseModelInternalId.Mpt7B]: 'MPT-7B',
  [ScaleLaunchBaseModelInternalId.Mpt7BInstruct]: 'MPT-7B Instruct',
} as const;

export type ScaleLaunchBaseModelInternalId =
  typeof ScaleLaunchBaseModelInternalId[keyof typeof ScaleLaunchBaseModelInternalId];

export type ScaleLaunchBaseModelDisplayName =
  typeof ScaleLaunchBaseModelDisplayName[keyof typeof ScaleLaunchBaseModelDisplayName];

export function isScaleLaunchBaseModelInternalId(
  modelId: string,
): modelId is ScaleLaunchBaseModelInternalId {
  return modelId in ScaleLaunchBaseModelInternalId;
}
