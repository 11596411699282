import { useCallback, useMemo } from 'react';

import _ from 'lodash';
import * as R from 'ramda';
import { Link } from 'react-router-dom';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Typography,
} from '@mui/material';

import { useDatasetPageState } from 'frontend/models/v2/useDatasetPageState';
import { useAppStore } from 'frontend/stores/AppStore';
import { useDataStore } from 'frontend/storesV2/DataStore';
import { useVariantStore } from 'frontend/storesV2/VariantStore';
import { Colors } from 'frontend/theme';

export const DatasetArchiveModal = () => {
  const { archiveModalDataId: dataId, setArchiveModalDataId } = useDatasetPageState();
  const open = !!dataId;

  const onClose = useCallback(() => {
    setArchiveModalDataId(null);
  }, [setArchiveModalDataId]);

  const { archiveData, dataInfoById } = useDataStore(R.pick(['archiveData', 'dataInfoById']));
  const { variantById } = useVariantStore();
  const { appById } = useAppStore();

  const dataName = (dataId && dataInfoById[dataId]?.name) || '';

  const usedInApps = useMemo(
    () =>
      _.compact(
        _.uniq(
          Object.values(variantById || {})
            .filter(v => v.prompt.variablesSourceDataId === dataId)
            .map(v => appById[v.appId]) || [],
        ),
      ),
    [dataId, variantById, appById],
  );

  const archiveDataset = useCallback(() => {
    if (!dataId) {
      return;
    }
    archiveData(dataId)
      .then(() => {
        onClose();
      })
      .catch(e => console.error(e));
  }, [dataId, archiveData, onClose]);

  return (
    <Dialog open={open} onClose={onClose}>
      {usedInApps.length > 0 ? (
        <>
          <DialogTitle>Cannot Archive Dataset</DialogTitle>
          <DialogContent dividers>
            <Typography sx={{ maxWidth: 420 }}>
              The dataset &ldquo;{dataName}&rdquo; cannot be archived because it is being used in
              the following apps:
            </Typography>
            <List>
              {usedInApps.map(app => (
                <ListItem key={app.id} sx={{ p: 0, m: 0.5, ml: 1 }}>
                  <Typography>
                    <Link to={`/app/${app.shortName}`}>{app.name}</Link>
                  </Typography>
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={onClose}>
              Close
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>
            <Typography variant="h2" color={Colors.Black}>
              Are you sure you want to archive this dataset?
            </Typography>
          </DialogTitle>
          <DialogContent dividers>
            <Typography sx={{ maxWidth: 360 }}>
              Archiving a dataset will remove it from all pages. This action cannot be undone.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="contained" onClick={archiveDataset}>
              Confirm
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
