import { useCallback } from 'react';

import { Button, ButtonGroup } from '@mui/material';

type Label<T> = {
  text: string;
  value: T;
  disabled?: boolean;
};

export function SegmentedButtons<T>({
  labels,
  selectedLabel,
  onClick,
}: {
  labels: Readonly<Label<T>[]>; // Can also make this an object with label and value
  selectedLabel?: T;
  onClick?: (label: T) => void;
}) {
  return (
    <ButtonGroup>
      {labels.map(label => (
        <SegmentedButton
          key={label.text}
          selected={label.value === selectedLabel}
          label={label}
          onClick={onClick}
          disabled={label.disabled}
        />
      ))}
    </ButtonGroup>
  );
}

function SegmentedButton<T>({
  selected,
  label,
  onClick,
  disabled,
}: {
  selected: boolean;
  label: Label<T>;
  onClick?: (label: T) => void;
  disabled?: boolean;
}) {
  const handleOnClick = useCallback(() => {
    onClick?.(label.value);
  }, [onClick, label.value]);

  if (selected) {
    return (
      <Button variant="contained" onClick={handleOnClick} disabled={disabled}>
        {label.text}
      </Button>
    );
  }
  return (
    <Button variant="outlined" onClick={handleOnClick} disabled={disabled}>
      {label.text}
    </Button>
  );
}
