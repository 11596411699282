import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MOBILE_WIDTH_THRESHOLD } from 'frontend/consts/playground';

const PromptFieldActionsLayout = styled(Box)(() => ({
  display: 'flex',
  gap: 10,
  padding: '20px 0px',
  marginTop: 'auto',
  [`@media (max-width:${MOBILE_WIDTH_THRESHOLD}px)`]: {
    paddingLeft: '14px',
  },
}));

export default PromptFieldActionsLayout;
