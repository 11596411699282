import { Chip } from '@mui/material';
import { JobStatus } from '@prisma/client';

export const STATUS_COLORS = {
  [JobStatus.Completed]: 'primary',
  [JobStatus.Errored]: 'error',
  [JobStatus.Pending]: 'info',
  [JobStatus.Running]: 'info',
  [JobStatus.Cancelled]: 'error',
  [JobStatus.Backfilled]: 'primary',
  'No Status': 'default',
  Postprocessing: 'primary',
} as const;

export type StatusColor = keyof typeof STATUS_COLORS;

export type ChipColor = typeof STATUS_COLORS[StatusColor];

export function JobStatusChip({ status = 'No Status' }: { status?: StatusColor }): JSX.Element {
  const color = STATUS_COLORS[status];

  return <Chip size="small" label={status} color={color} variant="outlined" />;
}
