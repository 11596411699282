import { z } from 'zod';

// Type

export const Prompt = z.object({
  id: z.string(),
  template: z.string(),
  exampleVariables: z.record(z.string(), z.string()),
  variablesSourceDataId: z.string().optional(),
  systemMessage: z.optional(z.string()),
});
export type Prompt = z.infer<typeof Prompt>;

// Create

export const PromptCreate = Prompt.omit({ id: true });
export type PromptCreate = z.infer<typeof PromptCreate>;

// Run

export const PromptRun = Prompt.omit({ id: true, variablesSourceDataId: true });
export type PromptRun = z.infer<typeof PromptRun>;
