import { useCallback, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Tooltip,
  Typography
} from '@mui/material';
import { App } from '@prisma/client';

import { VSpace } from 'frontend/components/Spacer';
import theme from 'frontend/theme';
import { getPublicAppUrl } from 'frontend/utils/explore';

export function PublicAppShareModal({
  open,
  setOpen,
  app,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  app: App;
}) {
  const [showCopied, setShowCopied] = useState<boolean>(false);

  const handleClose = useCallback(
    (e: React.MouseEvent) => {
      setOpen(false);
      e.stopPropagation();
    },
    [setOpen],
  );

  const appUrl = getPublicAppUrl(app);

  const copyUrlToClipboard = useCallback(
    (e: React.MouseEvent) => {
      navigator.clipboard.writeText(appUrl);
      setShowCopied(true);
      setTimeout(() => setShowCopied(false), 2000);
      e.stopPropagation();
    },
    [appUrl, setShowCopied],
  );

  // HACK: For some reason on the apps page, onClicks cause a re-render
  // of this component, causing it to re-render as closed. Stopping onClick
  // propagation until root caused.
  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle onClick={e => e.stopPropagation()}>
        <Typography variant="h2">Share {app.name}</Typography>
      </DialogTitle>
      <DialogContent onClick={e => e.stopPropagation()}>
        <Typography variant="h4">Public Link</Typography>
        <VSpace s={1} />
        <OutlinedInput
          endAdornment={
            <InputAdornment position="end">
              <Divider
                orientation="vertical"
                sx={{ height: theme.spacing(4.5), marginRight: theme.spacing(1.5) }}
              />
              <Tooltip title={showCopied ? 'Copied!' : 'Copy URL to clipboard'} placement="top">
                <IconButton size="small" onClick={copyUrlToClipboard}>
                  <FontAwesomeIcon size="sm" icon={['far', 'copy']} />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          }
          fullWidth
          size="small"
          value={appUrl}
          inputProps={{ readOnly: true }} // TODO: remove
        />
      </DialogContent>
    </Dialog>
  );
}
