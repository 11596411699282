import { useCallback, useMemo } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Chip, Tooltip, Typography } from '@mui/material';
import { AppVersion, TaskType } from '@prisma/client';
import { FrontendFeatureFlag } from '@scale/llm-shared/featureFlags';

import { AppEditIconButton } from 'frontend/components/app/AppEditIconButton';
import { PublicOrPrivateChip } from 'frontend/components/app/PublicOrPrivateChip';
import { TaskTypeChip, TextGenerationChip } from 'frontend/components/app/TaskTypeChip';
import { Container } from 'frontend/components/Container';
import FlexBox from 'frontend/components/FlexBox';
import { useUnleashFlags } from 'frontend/hooks/useUnleashFlags';
import { StoredApp } from 'frontend/stores/types';

function LegacyVersionChip() {
  return (
    <Tooltip
      title={
        'Legacy apps use the original prompt template syntax, and ' +
        'are restricted to a single input value during evaluation ' +
        'and deployment.'
      }
    >
      <Chip color="info" size="small" variant="outlined" label="Legacy Version" />
    </Tooltip>
  );
}

export function AppCard({
  app,
  isTemplate,
  onClick,
}: {
  app: StoredApp;
  isTemplate?: boolean;
  onClick?: (app: StoredApp) => void;
}): JSX.Element {
  const { name, description, taskType, modifiedAt, isPublic } = app;
  const icon = 'robot';
  const color = isTemplate ? 'info' : 'userApp';

  const { checkUnleashFlag } = useUnleashFlags();
  const publicAppsEnabled = checkUnleashFlag(FrontendFeatureFlag.SpellbookPublicAppsEnabled);

  const handleAppCardClick = useCallback(() => {
    onClick?.(app);
  }, [onClick]);

  const maybeHoverStyle = useMemo(() => {
    if (!onClick) {
      return {};
    }
    return {
      cursor: 'pointer',
      '&:hover': {
        bgcolor: 'background.default',
      },
    };
  }, [onClick, color]);

  return (
    <>
      <Container
        sx={{
          height: '100%',
          gap: 1,
          flexDirection: 'row',
          ...maybeHoverStyle,
          '.extra-actions-button': {
            opacity: 0,
          },
          '&:hover .extra-actions-button': {
            opacity: 1.0,
          },
        }}
        onClick={handleAppCardClick}
      >
        <FlexBox sx={{ flexDirection: 'column', gap: 1, alignItems: 'flex-start', width: '100%' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
              <Box
                sx={{
                  bgcolor: color + '.light',
                  color: color + '.main',
                  borderRadius: 1,
                  paddingTop: 1,
                  paddingBottom: 1,
                  // Extra padding because the icon is not square
                  paddingLeft: 1.2,
                  paddingRight: 1.2,
                  marginRight: 1,
                  width: 'fit-content',
                }}
              >
                <FontAwesomeIcon icon={icon} size="sm" />
              </Box>
              {publicAppsEnabled && <PublicOrPrivateChip isPublic={isPublic} />}
            </Box>
            <AppEditIconButton app={app} />
          </Box>
          <Typography variant="body1">{name}</Typography>
          <FlexBox>
            <TextGenerationChip />
          </FlexBox>
          {description && (
            <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
              {description}
            </Typography>
          )}
          <Typography variant="subtitle2" sx={{ marginTop: 'auto' }}>
            Last Updated {modifiedAt.toLocaleDateString()}
          </Typography>
        </FlexBox>
      </Container>
    </>
  );
}
