import { capitalize } from 'lodash';

import { Box, Typography } from '@mui/material';

import theme from 'frontend/theme';

type CreditCard = {
  last4: string;
  exp_month: number;
  exp_year: number;
  brand: string;
};

export function CreditCardCard({ creditCard }: { creditCard: CreditCard }): JSX.Element {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      p: 2,
      backgroundColor: theme.palette.info.light,
      height: 80,
      borderRadius: 1,
    }}>
      <Typography variant="h3">
        {capitalize(creditCard.brand)} ending in {creditCard.last4}
      </Typography>
      <Typography variant="body1">
        Expires {creditCard.exp_month}/{creditCard.exp_year}
      </Typography>
    </Box>
  );
}
