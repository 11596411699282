
export enum BinaryResult {
  TRUE_POSITIVE = 'TP',
  TRUE_NEGATIVE = 'TN',
  FALSE_POSITIVE = 'FP',
  FALSE_NEGATIVE = 'FN',
}

export type ConfusionMatrix2D = {
  [statType in BinaryResult]: number;
};


export type ConfusionMatrix2DByClass = { [className: string]: ConfusionMatrix2D; };

export type NumericalStatByClass = { [className: string]: number; };