export enum ServerFeatureFlag {
  BlockedFromSpellbook = 'spellbook-block-user',
  IgnoreOpenAiProxy = 'spellbook-ignore-openai-proxy',
  BypassUsageViolation = 'spellbook-bypass-usage-violation',
  EnabledDeploymentRateLimit = 'spellbook-enabled-deployment-rate-limit',
  EnabledDataLocking = 'spellbook-enabled-data-locking',
  SkipDeploymentLogging = 'spellbook-skip-deployment-logging',
  CanUseStreaming = 'spellbook-can-use-streaming-deployments',
}

export const DEFAULT_SERVER_FEATURE_FLAG_VALUE: Record<ServerFeatureFlag, boolean> = {
  [ServerFeatureFlag.BlockedFromSpellbook]: false,
  [ServerFeatureFlag.IgnoreOpenAiProxy]: !!process.env.IS_VPC_DEPLOYMENT, // For now, we want to disable the OpenAi proxy on VPC
  [ServerFeatureFlag.BypassUsageViolation]: false,
  [ServerFeatureFlag.EnabledDeploymentRateLimit]: true,
  [ServerFeatureFlag.EnabledDataLocking]: false,
  [ServerFeatureFlag.SkipDeploymentLogging]: false,
  [ServerFeatureFlag.CanUseStreaming]: true,
};

const ServerFeatureFlagSet = new Set(Object.values(ServerFeatureFlag));

export function isServerFeatureFlag(flag: string): flag is ServerFeatureFlag {
  return ServerFeatureFlagSet.has(flag as ServerFeatureFlag);
}
