import localforage from 'localforage';
import superjson from 'superjson';
import { StateStorage } from 'zustand/middleware';

export enum ClientSideStorageMode {
  Local = 'Local',
  Session = 'Session',
  IndexdDb = 'IndexedDb',
}

const PERSISTENCE_MODE = ClientSideStorageMode.IndexdDb;

function getStorageObject(clientSideStorageMode: ClientSideStorageMode): StateStorage {
  switch (clientSideStorageMode) {
    case ClientSideStorageMode.Local: {
      return localStorage;
    }
    case ClientSideStorageMode.Session: {
      return sessionStorage;
    }
    case ClientSideStorageMode.IndexdDb: {
      // Can eventually try something like this: https://github.com/churichard/zustand-persist-bug/blob/master/src/store.js
      // EQ tried this but there seemed to be overwriting of storage on reload before hydration could properly occur.
      return localStorage;
    }
    default: {
      return localStorage;
    }
  }
}

export const ZUSTAND_MIDDLEWARE_STORAGE_OBJECT: StateStorage = getStorageObject(PERSISTENCE_MODE);

export const LOCAL_STORAGE_STORE_OPTIONS = (storeName: string) => {
  return {
    name: storeName,
    getStorage: () => ZUSTAND_MIDDLEWARE_STORAGE_OBJECT,
    serialize: (state: any) => {
      return superjson.stringify(state);
    },
    deserialize: (str: string) => {
      return superjson.parse<any>(str);
    },
  };
};

localforage.config({
  driver: localforage.INDEXEDDB,
  name: 'instant-llm',
  version: 1.0,
  storeName: 'instant-llm',
});

export const INDEXEDDB_STORAGE_STORE_OPTIONS = (storeName: string) => {
  const storage: StateStorage = {
    getItem: async (name: string) => {
      return (await localforage.getItem(name)) as any;
    },
    setItem: async (name: string, value: any) => {
      (await localforage.setItem(name, value)) as any;
    },
    removeItem: async (name: string) => {
      await localforage.removeItem(name);
    },
  };

  return {
    name: storeName,
    getStorage: () => storage,
    serialize: (state: any) => {
      return superjson.stringify(state);
    },
    deserialize: (str: string) => {
      return superjson.parse<any>(str);
    },
  };
};

export const DEFAULT_STORE_OPTIONS =
  PERSISTENCE_MODE === ClientSideStorageMode.IndexdDb
    ? INDEXEDDB_STORAGE_STORE_OPTIONS
    : LOCAL_STORAGE_STORE_OPTIONS;
