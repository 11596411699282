import { useMemo } from 'react';

import _ from 'lodash';

/**
 * Hook wrapper around lodash's _.get
 */
export function useGet<T extends Record<string, any>>(
  obj: T,
  path: string | undefined | null,
): T[string] | undefined {
  const value = useMemo(() => {
    if (!path) {
      return;
    }
    return _.get(obj, path);
  }, [obj, path]);
  return value;
}
