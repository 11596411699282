import { useCallback, useEffect, useMemo, useState } from 'react';

import _ from 'lodash';
import * as R from 'ramda';
import { useDebounce } from 'react-use';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popover,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  AI_FEEDBACK_DATA_CRITERIA_COLUMN_NAME,
  AI_FEEDBACK_DATA_INPUT_COLUMN_NAME,
  AI_FEEDBACK_DATA_OUTPUT_COLUMN_NAME,
} from '@scale/llm-shared/consts/aiFeedbackEvaluation';
import { DataRow } from '@scale/llm-shared/interfaces/data';
import { EvaluationType } from '@scale/llm-shared/interfaces/evaluation';
import { parseQueryVariables, renderPromptWithQuery } from '@scale/llm-shared/templating';
import { intToStringWithPadding } from '@scale/llm-shared/utils';
import { MutationStatus, useMutation, useQuery } from '@tanstack/react-query';

import { RenderResult } from 'frontend/components/evaluation/utils';
import FlexBox from 'frontend/components/FlexBox';
import { showGlobalSnackbarError } from 'frontend/components/GlobalSnackbar';
import { VSpace } from 'frontend/components/Spacer';
import VariantName from 'frontend/components/v2/variant/VariantName';
import useRandom from 'frontend/hooks/useRandom';
import {
  useAIFeedbackWizardState,
  useEvaluationWizardState,
} from 'frontend/models/v2/useEvaluationWizardState';
import { useMultiInputEvaluationState } from 'frontend/models/v2/useMultiInputEvaluationState';
import { useSettingsStore } from 'frontend/stores/SettingsStore';
import { useDataStore } from 'frontend/storesV2/DataStore';
import { useEvaluationStore } from 'frontend/storesV2/EvaluationStore';
import { useVariantStore } from 'frontend/storesV2/VariantStore';
import theme, { Colors } from 'frontend/theme';
import { PairedRowStatus } from 'frontend/types/types';
import { track } from 'frontend/utils/analytics';

// The ratio for the multi-input sub-column's key and value width
const INPUT_KEY_WIDTH = '25%';
const INPUT_VALUE_WIDTH = '75%';

function ColumnNameSelector({
  columns,
  value,
  onChange,
}: {
  columns?: string[];
  value: string;
  onChange: (newValue: string) => void;
}) {
  if (!columns) {
    return null;
  }
  return (
    <Select
      variant="standard"
      size="small"
      fullWidth
      value={value}
      onChange={e => onChange(e.target.value)}
      label="Expected Output"
      sx={{
        padding: 0,
        '& .MuiInputBase-input': {
          padding: 0,
          border: 0,
        },
      }}
    >
      {columns.map(column => (
        <MenuItem value={column} key={column}>
          <Typography
            sx={{
              margin: 0,
              color: Colors.CoolGray50,
              fontFamily: 'monospace',
              fontSize: theme.typography.body1.fontSize,
              fontWeight: theme.typography.fontWeightBold,
            }}
          >
            {column}
          </Typography>
        </MenuItem>
      ))}
    </Select>
  );
}

// Options for showing number of example rows
const ROW_COUNT_OPTIONS = [1, 5, 10, 20];

export function AIFeedbackWizardPreview() {
  const { variantIds, dataId, shouldStripWhitespace } = useEvaluationWizardState();
  const { run, variantById } = useVariantStore(R.pick(['run', 'variantById']));
  const { runEvaluation } = useEvaluationStore(R.pick(['runEvaluation']));
  const variant = variantById[variantIds[0]];
  const { evaluationCriteria } = useAIFeedbackWizardState();

  const { random } = useRandom();
  const { getData } = useDataStore(R.pick(['getData']));

  // Custom input
  const [customInput, setCustomInput] = useState<DataRow>({ index: '-1', valueByName: {} });
  const customInputNonEmpty = useMemo(
    () => _.some(Object.values(customInput.valueByName), val => !!val),
    [customInput],
  );

  // Parsed input column names from prompt
  const [inputNames, setInputNames] = useState<string[]>([]);
  useDebounce(
    () => {
      // Get the union and intersection of all the template input names
      // from all the variants
      const variantInputNames = parseQueryVariables(variant.prompt.template);
      setInputNames(variantInputNames);
    },
    500,
    [variant],
  );

  // Data injection manipulation
  const [inputRowsFull, setInputRows] = useState<DataRow[]>([]);
  const dataRes = useQuery(['getData', dataId], async () => {
    return dataId ? getData(dataId) : null;
  });
  const data = dataRes.data || undefined;
  const [rowCount, setRowCount] = useState<number>(10);
  const getRandomRows = useCallback(() => {
    if (!data) {
      return [];
    }
    return random.sampleSize(Object.values(data.rowByIndex), _.max(ROW_COUNT_OPTIONS)!);
  }, [random, data, rowCount]);

  const inputRows = inputRowsFull.slice(0, rowCount);

  // Run inference
  const batchRun = useCallback(
    // padLeft is there so that we can ignore the custom inputs if they are unset
    // they default to a non-output
    ({ inputs, padLeft = 0 }: { inputs: Parameters<typeof run>[]; padLeft?: number }) => {
      return Promise.all(
        inputs.map(async input => {
          const result = await run(...input);
          const padding = _.range(padLeft).map(() => '');
          return {
            outputs: [...padding, ...result.map(r => r.output)],
            tokenProbs: [...padding.map(() => null), ...result.map(r => r.tokenProbs)],
            finishReasons: [...padding.map(() => null), ...result.map(r => r.finishReason)],
            statuses: [...padding.map(() => null), ...result.map(r => r.status)],
          };
        }),
      );
    },
    [run],
  );
  const batchRunMutation = useMutation({
    mutationFn: batchRun,
  });

  // Inference for ai feedback
  const batchRunEvaluation = useCallback(
    // padLeft is there so that we can ignore the custom inputs if they are unset
    // they default to a non-output
    ({ inputs, padLeft = 0 }: { inputs: Parameters<typeof runEvaluation>[]; padLeft?: number }) => {
      return Promise.all(
        inputs.map(async input => {
          const result = await runEvaluation(...input);
          const padding = _.range(padLeft).map(() => '');
          return {
            outputs: [...padding, ...result.outputs],
            tokenProbs: [...padding.map(() => null), ...result.tokenProbs],
            finishReasons: [...padding.map(() => null), ...result.finishReasons],
          };
        }),
      );
    },
    [runEvaluation],
  );
  const batchRunEvaluationMutation = useMutation({
    mutationFn: batchRunEvaluation,
  });

  const handleRunAIFeedback = useMemo(
    () => (batchRunMutationData: typeof batchRunMutation.data) => {
      if (!batchRunMutationData) {
        return;
      }
      const allInputRows = customInputNonEmpty ? [customInput, ...inputRows] : inputRows;
      // Oh boy here we go...
      const inputs = allInputRows.map(inputRow =>
        renderPromptWithQuery(variant.prompt, inputRow.valueByName),
      );
      const outputs = batchRunMutationData[0].outputs;
      const inputsToAiFeedback = inputs.map((input, i) => ({
        index: intToStringWithPadding(i),
        valueByName: {
          [AI_FEEDBACK_DATA_CRITERIA_COLUMN_NAME]: evaluationCriteria,
          [AI_FEEDBACK_DATA_INPUT_COLUMN_NAME]: input,
          [AI_FEEDBACK_DATA_OUTPUT_COLUMN_NAME]: shouldStripWhitespace
            ? outputs[customInputNonEmpty ? i : i + 1].trim()
            : outputs[customInputNonEmpty ? i : i + 1],
        },
      }));

      batchRunEvaluationMutation
        .mutateAsync({
          padLeft: customInputNonEmpty ? 0 : 1,
          inputs: [
            [EvaluationType.AIFeedback, inputsToAiFeedback] as Parameters<typeof runEvaluation>,
          ],
        })
        .catch(showGlobalSnackbarError);
    },
    [evaluationCriteria, customInput, inputRows, shouldStripWhitespace],
  );

  const handleRunEvaluation = useCallback(() => {
    const allInputRows = customInputNonEmpty ? [customInput, ...inputRows] : inputRows;
    if (!evaluationCriteria || !allInputRows) {
      return;
    }
    if (!batchRunMutation.data || batchRunMutation.data.length === 0) {
      batchRunMutation
        .mutateAsync({
          padLeft: customInputNonEmpty ? 0 : 1,
          inputs: [
            [
              _.omit(variant, ['prompt', 'modelParameters']),
              _.omit(variant.prompt, ['variablesSourceData']),
              variant.modelParameters,
              allInputRows,
            ] as Parameters<typeof run>,
          ],
        })
        .then(batchRunMutationData => {
          handleRunAIFeedback(batchRunMutationData);
        })
        .catch(showGlobalSnackbarError);
    } else {
      handleRunAIFeedback(batchRunMutation.data);
    }
  }, [evaluationCriteria, batchRunMutation, variant, customInput, inputRows]);

  const shuffleInputRows = useCallback(() => {
    track('Prompt Items Shuffled', { view: 'Evaluation' });
    setInputRows(getRandomRows());
    batchRunMutation.reset();
    batchRunEvaluationMutation.reset();
  }, [getRandomRows, setInputRows]);

  // Shuffle items when data is loaded
  useEffect(() => {
    shuffleInputRows();
  }, [dataRes.data]);

  // Reset ai feedback eval when criteria changes
  useEffect(() => {
    batchRunEvaluationMutation.reset();
  }, [evaluationCriteria]);

  // Reset everything when custom input changes or row count changes. this is a lazy fix
  useEffect(() => {
    batchRunMutation.reset();
    batchRunEvaluationMutation.reset();
  }, [customInput, rowCount]);

  const canRunAIFeedback = useMemo(
    () => evaluationCriteria,
    [batchRunMutation, evaluationCriteria],
  );

  // Selection state
  const [selectedRow, setSelectedRow] = useState<number>(-1);
  const [selectedColumn, setSelectedColumn] = useState<number>(-1);
  const cellSelectionProps = useCallback(
    (row: number, col: number) => ({
      onMouseDown: (e: React.MouseEvent<HTMLTableCellElement>) => {
        setSelectedRow(row);
        setSelectedColumn(col);
        e.stopPropagation();
      },
      className: row === selectedRow && col === selectedColumn ? 'selected' : '',
    }),
    [selectedRow, setSelectedRow, selectedColumn, setSelectedColumn],
  );

  // Global listeners
  useEffect(() => {
    const resetSelection = () => {
      setSelectedRow(-1);
      setSelectedColumn(-1);
    };
    window.addEventListener('mousedown', resetSelection);
    return () => window.removeEventListener('mousedown', resetSelection);
  }, [setSelectedColumn, setSelectedRow]);

  const dataHasColumns = !!data?.columns;
  const {
    showExpectedColumn,
    setShowExpectedColumn,
    expectedOutputColumn,
    setExpectedOutputColumn,
    showWhitespace,
    setShowWhitespace,
  } = useMultiInputEvaluationState();

  // TODO: Deprecate this once V1 is deprecated
  const { settings, setSettings } = useSettingsStore();
  const { showTokenProbabilities } = settings;

  const [viewSettingsAnchorEl, setViewSettingsAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleSetShowExpectedOutput = useCallback(
    (show: boolean) => {
      if (!show) {
        setShowExpectedColumn(false);
        return;
      }
      setShowExpectedColumn(true);
      if (expectedOutputColumn === '' && dataHasColumns) {
        setExpectedOutputColumn(data.columns[0].name);
      }
    },
    [setShowExpectedColumn, expectedOutputColumn, setExpectedOutputColumn],
  );

  const handleExpectedOutputColumnChange = useCallback(
    (newValue: string) => {
      setExpectedOutputColumn(newValue);
    },
    [setExpectedOutputColumn],
  );

  const handleSetShowTokenProbabilities = useCallback(
    (show: boolean) => {
      setSettings({ ...settings, showTokenProbabilities: show });
    },
    [settings, setSettings],
  );

  // All columns are equally sized, but some columns are more equal than others
  // Input column gets extra width because it's actually two columns
  const inputColumnPadding = 15;
  const numColumns = 3 + (showExpectedColumn && dataHasColumns ? 1 : 0);
  const columnWidthVal = Math.floor((100.0 - inputColumnPadding) / numColumns);
  const columnWidth = `${columnWidthVal}%`;
  const inputColumnWidth = `${inputColumnPadding + columnWidthVal}%`;

  return (
    <>
      <FlexBox sx={{ justifyContent: 'flex-end', my: 2 }}>
        <Button
          aria-describedby="view-settings-popover-button"
          onClick={e => setViewSettingsAnchorEl(e.currentTarget)}
        >
          View Settings
        </Button>
        <Popover
          id="view-settings-popover-button"
          open={!!viewSettingsAnchorEl}
          anchorEl={viewSettingsAnchorEl}
          onClose={() => setViewSettingsAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          elevation={5}
        >
          <MenuList dense>
            <Tooltip
              placement="left"
              title={
                <>
                  When a dataset is assigned, determines the number of rows used for sample inputs.
                </>
              }
            >
              <Box px={2} py={1}>
                <Typography variant="overline">Show Dataset Rows</Typography>
              </Box>
            </Tooltip>
            <Divider />
            {ROW_COUNT_OPTIONS.map(opt => {
              const text = `${opt} ${opt === 1 ? 'row' : 'rows'}`;
              return (
                <MenuItem
                  key={`select-rows-${opt}`}
                  onClick={() => {
                    setRowCount(opt);
                    setViewSettingsAnchorEl(null);
                  }}
                >
                  {rowCount === opt ? (
                    <>
                      <ListItemIcon>
                        <FontAwesomeIcon icon="check" style={{ fontSize: 16 }} />
                      </ListItemIcon>
                      {text}
                    </>
                  ) : (
                    <ListItemText inset>{text}</ListItemText>
                  )}
                </MenuItem>
              );
            })}
            <Box px={2} py={1}>
              <Typography variant="overline">Text Display</Typography>
            </Box>
            <Divider />
            <Tooltip
              placement="left"
              title={<>Show extra indicators in your output for where whitespace appears.</>}
            >
              <MenuItem onClick={() => setShowWhitespace(!showWhitespace)}>
                {showWhitespace ? (
                  <>
                    <ListItemIcon>
                      <FontAwesomeIcon icon="check" style={{ fontSize: 16 }} />
                    </ListItemIcon>
                    Show Output Whitespace
                  </>
                ) : (
                  <ListItemText inset>Show Output Whitespace</ListItemText>
                )}
              </MenuItem>
            </Tooltip>
            <Tooltip
              placement="left"
              title={
                <>
                  Display token probabilities and alternative choices for each token in the output.
                </>
              }
            >
              <MenuItem onClick={() => handleSetShowTokenProbabilities(!showTokenProbabilities)}>
                {showTokenProbabilities ? (
                  <>
                    <ListItemIcon>
                      <FontAwesomeIcon icon="check" style={{ fontSize: 16 }} />
                    </ListItemIcon>
                    Show Token Probabilities
                  </>
                ) : (
                  <ListItemText inset>Show Token Probabilities</ListItemText>
                )}
              </MenuItem>
            </Tooltip>
          </MenuList>
        </Popover>
        {canRunAIFeedback ? (
          <Button onClick={handleRunEvaluation} variant="outlined">
            <FontAwesomeIcon icon="refresh" style={{ marginRight: '1ex' }} />
            Preview AI Feedback
          </Button>
        ) : (
          <Tooltip
            placement="top-start"
            title={<>Add a custom criteria to generate AI feedback.</>}
          >
            <div>
              <Button variant="outlined" disabled>
                <FontAwesomeIcon icon="refresh" style={{ marginRight: '1ex' }} />
                Preview AI Feedback
              </Button>
            </div>
          </Tooltip>
        )}
      </FlexBox>
      <TableContainer component={Paper} variant="outlined">
        <Table
          sx={{
            borderCollapse: 'separate',
            '& .MuiTableCell-row': {
              border: 'none',
            },
            '& .MuiTableHead-root .MuiTableCell-root': {
              color: Colors.CoolGray50,
              fontFamily: 'inherit',
              fontSize: theme.typography.subtitle2.fontSize,
              fontWeight: theme.typography.fontWeightBold,
              backgroundColor: Colors.CoolGray10,
              borderWidth: 1,
              borderColor: Colors.CoolGray30,
              paddingY: 0.5,
            },
            '& .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root': {
              borderBottom: 'none',
            },
            '& .MuiTableRow-root:hover': {
              backgroundColor: Colors.CoolGray10,
            },
            '& .MuiTableCell-root': {
              color: Colors.CoolGray70,
              fontFamily: 'monospace',
              borderRight: `1px solid ${Colors.CoolGray20}`,
            },
            '& .MuiTableCell-root:last-child': {
              borderRight: 'none',
              borderLeft: 'none',
            },

            '& .multiinput-inner-table .MuiTableCell-root': {
              borderBottom: 'none',
            },
            '& .custom-input-inner-table .MuiTableCell-root': {
              borderBottom: 'none',
            },

            // MultiInput display changes based on the hover and selection state of
            // the dataset row (darken borders, change base color to blue)
            // TODO: probably doable via theming
            '& .MuiTableRow-root.selected .multiinput-inner-table .MuiTableRow-root': {
              backgroundColor: 'transparent',
            },
            '& .MuiTableRow-root:hover .multiinput-inner-table .MuiTableCell-root': {
              borderColor: Colors.CoolGray20,
            },
            '& .MuiTableRow-root.selected .multiinput-inner-table .MuiTableCell-root': {
              borderColor: Colors.CoolBlue10,
            },

            // Selection colors -- change theme to blue and add border
            '& .MuiTableRow-root.selected': {
              backgroundColor: Colors.CoolBlue05,
            },
            '& .MuiTableRow-root.selected:hover': {
              backgroundColor: Colors.CoolBlue10,
            },
            '& .MuiTableCell-root.selected': {
              border: `1px double ${Colors.Blue}`,
            },
            '& .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root.selected': {
              borderBottom: `1px double ${Colors.Blue}`,
            },
          }}
        >
          <TableHead sx={{ display: 'auto' }}>
            <TableRow>
              {!!inputNames.length && data?.columns ? (
                <TableCell width={inputColumnWidth}>
                  Input
                  <Tooltip title="Randomize items used for testing" placement="top">
                    <IconButton onClick={shuffleInputRows}>
                      <FontAwesomeIcon size="xs" icon="shuffle" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              ) : (
                <TableCell>Input</TableCell>
              )}
              {dataHasColumns && showExpectedColumn && (
                <TableCell width={columnWidth}>
                  <ColumnNameSelector
                    columns={data?.columns.map(column => column.name)}
                    value={expectedOutputColumn}
                    onChange={handleExpectedOutputColumnChange}
                  />
                </TableCell>
              )}
              <TableCell width={columnWidth}>
                <VariantName name={variant.name} />
              </TableCell>
              <TableCell width={columnWidth}>
                <VariantName name="AI Feedback Preview" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Custom input row */}
            <TableRow>
              {!!inputNames.length && (
                <TableCell sx={{ padding: 0 }}>
                  <Table className="custom-input-inner-table">
                    {inputNames.map((columnName, index) => (
                      <TableRow key={columnName}>
                        <TableCell width={INPUT_KEY_WIDTH}>
                          <Typography variant="code1">{columnName}</Typography>
                        </TableCell>
                        <TableCell width={INPUT_VALUE_WIDTH}>
                          <TextField
                            fullWidth
                            size="small"
                            multiline
                            maxRows={10}
                            placeholder="Enter a custom input"
                            value={customInput.valueByName[columnName] || ''}
                            onChange={e =>
                              setCustomInput(currentInput => ({
                                ...currentInput,
                                valueByName: {
                                  ...currentInput.valueByName,
                                  [columnName]: e.target.value,
                                },
                              }))
                            }
                            InputProps={{
                              sx: {
                                fontFamily: 'monospace',
                              },
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </TableCell>
              )}
              {dataHasColumns && showExpectedColumn && <TableCell />}
              <TableCell colSpan={1}>
                {customInputNonEmpty && (
                  <RenderResult
                    row={{
                      id: variant.id,
                      status:
                        batchRunMutation.data?.[0].statuses[0] ??
                        QUERY_STATUS_TO_ROW_STATUS[batchRunMutation.status],
                      input: '',
                      expectedOutput: '',
                      actualOutput: batchRunMutation.data?.[0].outputs[0] || '',
                      tokenProbs: batchRunMutation.data?.[0].tokenProbs[0] || null,
                      finishReason: batchRunMutation.data?.[0].finishReasons[0] || null,
                      error: (batchRunMutation.error as Error)?.message,
                    }}
                    inProgressElem={
                      <Box>
                        <Skeleton width={120} />
                        <Skeleton width={150} />
                      </Box>
                    }
                  />
                )}
              </TableCell>
              <TableCell colSpan={1}>
                {customInputNonEmpty && (
                  <RenderResult
                    row={{
                      id: variant.id,
                      status:
                        batchRunMutation.data?.[0].statuses[0] ??
                        QUERY_STATUS_TO_ROW_STATUS[batchRunMutation.status],
                      input: '',
                      expectedOutput: '',
                      actualOutput: batchRunEvaluationMutation.data?.[0].outputs[0] || '',
                      tokenProbs: batchRunEvaluationMutation.data?.[0].tokenProbs[0] || null,
                      finishReason: batchRunEvaluationMutation.data?.[0].finishReasons[0] || null,
                      error: (batchRunEvaluationMutation.error as Error)?.message,
                    }}
                    inProgressElem={
                      <Box>
                        <Skeleton width={120} />
                        <Skeleton width={150} />
                      </Box>
                    }
                  />
                )}
              </TableCell>
            </TableRow>
            {/* Data input rows */}
            {dataRes.isLoading ? (
              <TableRow>
                <TableCell>
                  <Skeleton
                    sx={{ bgcolor: theme.palette.info.light }}
                    height={20}
                    variant="rounded"
                  />
                </TableCell>
                <TableCell>
                  <Skeleton
                    sx={{ bgcolor: theme.palette.info.light }}
                    height={20}
                    variant="rounded"
                  />
                </TableCell>
                <TableCell>
                  <Skeleton
                    sx={{ bgcolor: theme.palette.info.light }}
                    height={20}
                    variant="rounded"
                  />
                </TableCell>
              </TableRow>
            ) : (
              inputRows.map((inputRow, row) => (
                <TableRow
                  key={inputRow.index}
                  className={selectedRow === row + 1 ? 'selected' : ''}
                >
                  <TableCell {...cellSelectionProps(row + 1, 0)} sx={{ padding: 0 }}>
                    <MultiInputValues valueByName={inputRow.valueByName} columnNames={inputNames} />
                  </TableCell>
                  {dataHasColumns && showExpectedColumn && (
                    <TableCell>{inputRow.valueByName[expectedOutputColumn] || ''}</TableCell>
                  )}
                  <TableCell>
                    <RenderResult
                      row={{
                        id: variant.id,
                        status: QUERY_STATUS_TO_ROW_STATUS[batchRunMutation.status],
                        input: '',
                        expectedOutput: '',
                        actualOutput: batchRunMutation.data?.[0].outputs[row + 1] || '',
                        tokenProbs: batchRunMutation.data?.[0].tokenProbs[row + 1] || null,
                        finishReason: batchRunMutation.data?.[0].finishReasons[row + 1] || null,
                        error: (batchRunMutation.error as Error)?.message,
                      }}
                      inProgressElem={
                        <Box>
                          <Skeleton width={120} />
                          <Skeleton width={150} />
                        </Box>
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <RenderResult
                      row={{
                        id: variant.id,
                        status: QUERY_STATUS_TO_ROW_STATUS[batchRunEvaluationMutation.status],
                        input: '',
                        expectedOutput: '',
                        actualOutput: batchRunEvaluationMutation.data?.[0].outputs[row + 1] || '',
                        tokenProbs:
                          batchRunEvaluationMutation.data?.[0].tokenProbs[row + 1] || null,
                        finishReason:
                          batchRunEvaluationMutation.data?.[0].finishReasons[row + 1] || null,
                        error: (batchRunEvaluationMutation.error as Error)?.message,
                      }}
                      inProgressElem={
                        <Box>
                          <Skeleton width={120} />
                          <Skeleton width={150} />
                        </Box>
                      }
                    />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <VSpace s={1} />
    </>
  );
}

export function MultiInputValues(props: {
  valueByName: Record<string, string>;
  columnNames: string[];
}) {
  const queryValueByName = R.fromPairs(
    R.zip(props.columnNames, R.props(props.columnNames, props.valueByName)),
  );
  return (
    <TableContainer>
      <Table className="multiinput-inner-table">
        <TableBody>
          {_.toPairs(queryValueByName).map(([columnName, value]) => (
            <TableRow key={`${columnName}-${value}`}>
              <TableCell width={INPUT_KEY_WIDTH}>
                <Box sx={{ fontWeight: 600 }}>{columnName}</Box>
              </TableCell>
              <TableCell width={INPUT_VALUE_WIDTH}>
                {value != null ? (
                  value
                ) : (
                  // TODO: center the icon. use HoverableInfoDialog for prettier tooltip.
                  <Box m={1}>
                    <Tooltip
                      title={`Could not find a column named "${columnName}" in your dataset, so no value will be inserted!`}
                      placement={'right'}
                    >
                      <FontAwesomeIcon icon="warning" color={Colors.RemoGold} />
                    </Tooltip>
                  </Box>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export const QUERY_STATUS_TO_ROW_STATUS: Record<MutationStatus, PairedRowStatus> = {
  error: PairedRowStatus.FAILED,
  success: PairedRowStatus.COMPLETE,
  loading: PairedRowStatus.IN_PROGRESS,
  idle: PairedRowStatus.NOT_STARTED,
};
