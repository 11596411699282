import { useCallback, useEffect, useMemo, useState } from 'react';

import _ from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';

import FlexBox from 'frontend/components/FlexBox';
import { SettingsContainer, SettingsHeader } from 'frontend/components/Settings';
import { VSpace } from 'frontend/components/Spacer';
import { DataOutputColumnSelector } from 'frontend/components/v2/data/DataColumnNameSelector';
import { DataSelectorAndUploader } from 'frontend/components/v2/data/DataSelectorAndUploader';
import { usePromptPageState } from 'frontend/models/v2/usePromptPageState';
import { useDataStore } from 'frontend/storesV2/DataStore';
import { StoredDataColumn } from 'frontend/storesV2/types';
import { Colors } from 'frontend/theme';

function TaxonomyBox({ taxonomy }: { taxonomy: string[] }) {
  const [maxVisibleValues, setMaxVisibleValues] = useState<number>(10);
  return (
    <Box my={2}>
      <Typography>Taxonomy Values</Typography>
      <FlexBox sx={{ flexWrap: 'wrap', my: 1 }}>
        {taxonomy.slice(0, maxVisibleValues).map(value => (
          <Chip key={value} variant="outlined" label={value} sx={{ fontFamily: 'monospace' }} />
        ))}
        {taxonomy.length > maxVisibleValues && (
          <Button variant="text" onClick={() => setMaxVisibleValues(taxonomy.length)}>
            See all {taxonomy.length} values
          </Button>
        )}
      </FlexBox>
    </Box>
  );
}

function TaxonomyViewerDialog({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const { unsavedVariant, setUnsavedVariant } = usePromptPageState();
  const taxonomy = unsavedVariant.taxonomy;

  const handleRemoveTaxonomy = useCallback(async () => {
    setOpen(false);
    await new Promise(resolve => setTimeout(resolve, 200));
    setUnsavedVariant(variant => ({
      ...variant,
      taxonomy: undefined,
    }));
  }, [setUnsavedVariant]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="lg">
      <DialogTitle>
        Output Taxonomy
        <VSpace s={1} />
        <Typography>A taxonomy can be used to constrain the output of your variants.</Typography>
      </DialogTitle>
      <DialogContent dividers sx={{ width: 720 }}>
        {!taxonomy && <Typography>No taxonomy is defined for this variant.</Typography>}
        {taxonomy && taxonomy.length > 0 && <TaxonomyBox taxonomy={taxonomy} />}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={handleRemoveTaxonomy}>
          Remove Taxonomy
        </Button>
        <Button variant="contained" onClick={() => setOpen(false)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function TaxonomyCreationDialog({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const { dataById, getData } = useDataStore();
  const { setUnsavedVariant, unsavedPrompt } = usePromptPageState();
  const [selectedDataId, setSelectedDataId] = useState<string | undefined>(undefined);
  const [selectedColumn, setSelectedColumn] = useState<StoredDataColumn | undefined>(undefined);

  const dataColumns = useMemo(() => {
    if (!selectedDataId) {
      return undefined;
    }
    const data = dataById[selectedDataId];
    if (!data) {
      // TODO(albert): Probably need to hold temp state here
      getData(selectedDataId);
      return undefined;
    }
    return data.columns;
  }, [selectedDataId, dataById]);

  // Default this to the value in the sidebar if possible, only if
  // we don't have a selected column
  useEffect(() => {
    if (unsavedPrompt.variablesSourceDataId && !selectedColumn) {
      setSelectedDataId(unsavedPrompt.variablesSourceDataId);
    }
  }, [unsavedPrompt.variablesSourceDataId, selectedColumn, setSelectedDataId]);

  const taxonomyCount = useMemo(() => {
    if (!selectedColumn) {
      return {};
    }
    return _.countBy(selectedColumn.values, 'value');
  }, [selectedColumn]);

  const taxonomy = Object.keys(taxonomyCount);

  const handleSaveTaxonomyToVariant = useCallback(() => {
    if (!selectedColumn) {
      console.error('Attempting to save taxonomy when no selectedColumn is defined');
      return;
    }
    setUnsavedVariant(variant => ({
      ...variant,
      taxonomy,
    }));
    setOpen(false);
  }, [taxonomy, setUnsavedVariant]);

  useEffect(() => {
    setSelectedColumn(undefined);
  }, [selectedDataId]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth={720 as any}>
      <DialogTitle>
        Create Output Taxonomy
        <VSpace s={1} />
        <Typography>A taxonomy can be used to constrain the output of your variants.</Typography>
      </DialogTitle>
      <DialogContent dividers sx={{ width: 720 }}>
        <FlexBox>
          <SettingsContainer sx={{ width: '50%' }}>
            <SettingsHeader title="Dataset" />
            <DataSelectorAndUploader
              allowNoDataset={false}
              dataId={selectedDataId}
              setDataId={newId => setSelectedDataId(newId ?? undefined)}
            />
          </SettingsContainer>
          <SettingsContainer sx={{ width: '50%' }}>
            <SettingsHeader title="Output Column" />
            {dataColumns ? (
              <DataOutputColumnSelector
                variant="outlined"
                columns={dataColumns}
                value={selectedColumn}
                onChange={setSelectedColumn}
              />
            ) : (
              <Select size="small" fullWidth disabled />
            )}
          </SettingsContainer>
        </FlexBox>
        {taxonomy.length > 0 && <TaxonomyBox taxonomy={taxonomy} />}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSaveTaxonomyToVariant}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function PromptTaxonomySelectorButton() {
  const { unsavedVariant, setUnsavedVariant } = usePromptPageState();
  const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false);
  const [viewDialogOpen, setViewDialogOpen] = useState<boolean>(false);
  const hasTaxonomy = !!unsavedVariant.taxonomy;

  const handleRemoveTaxonomy = useCallback(() => {
    setUnsavedVariant(variant => ({
      ...variant,
      taxonomy: undefined,
    }));
  }, [setUnsavedVariant]);

  return (
    <>
      <TaxonomyCreationDialog
        open={!hasTaxonomy && createDialogOpen}
        setOpen={setCreateDialogOpen}
      />
      <TaxonomyViewerDialog open={hasTaxonomy && viewDialogOpen} setOpen={setViewDialogOpen} />
      {hasTaxonomy ? (
        <FlexBox>
          <Button variant="outlined" onClick={() => setViewDialogOpen(true)}>
            See Taxonomy ({unsavedVariant.taxonomy?.length} values)
          </Button>
          <Tooltip placement="top" title="Remove the existing taxonomy">
            <IconButton size="small" onClick={handleRemoveTaxonomy}>
              <FontAwesomeIcon icon="circle-xmark" size="xs" color={Colors.CoolGray40} />
            </IconButton>
          </Tooltip>
        </FlexBox>
      ) : (
        <Button variant="outlined" onClick={() => setCreateDialogOpen(true)}>
          Add Output Taxonomy
        </Button>
      )}
    </>
  );
}
