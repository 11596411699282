import { unparse } from 'papaparse';

export function downloadAsCsv(data: unknown[], filename: string) {
  const csv = unparse(data);
  const link = document.createElement('a');
  link.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
