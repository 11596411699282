import {
  AnthropicBaseModelDisplayName,
  AnthropicBaseModelInternalId,
} from '@scale/llm-shared/modelProviders/anthropic/consts';
import { IAnthropicBaseModel } from '@scale/llm-shared/modelProviders/anthropic/types';
import { getNumGpt3Tokens } from '@scale/llm-shared/tokenizers/countTokens';

export const ClaudeV1: IAnthropicBaseModel = {
  id: AnthropicBaseModelInternalId.ClaudeV1,
  displayName: AnthropicBaseModelDisplayName[AnthropicBaseModelInternalId.ClaudeV1],
  minTokensAllowed: 0,
  maxTokensAllowed: 2048,
  minTemperatureAllowed: 0,
  maxTemperatureAllowed: 1,
  externalModelId: 'claude-v1',
  costCentsPerMillionPromptTokens: 11_02,
  costCentsPerMillionCompletionTokens: 32_68,

  getNumTokens: (prompt: string) => {
    return getNumGpt3Tokens(prompt);
  },
};
