import {
  Alert,
  AlertTitle,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Link,
  Typography
} from '@mui/material';

export function PublicAppTermsCheckboxes({
  agreedToShare,
  setAgreedToShare,
  agreedToTerms,
  setAgreedToTerms,
  isFreePlan,
}: {
  agreedToShare: boolean;
  setAgreedToShare: React.Dispatch<React.SetStateAction<boolean>>;
  agreedToTerms: boolean;
  setAgreedToTerms: React.Dispatch<React.SetStateAction<boolean>>;
  isFreePlan: boolean;
}) {
  return (
    <>
      <Alert severity="warning">
        <Typography variant="h2">You’re {isFreePlan ? 'creating' : 'sharing'} a Public App.</Typography>
      </Alert>
      <Box py={1} px={2}>
        <FormGroup sx={{ pointerEvents: 'none' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={agreedToShare || false}
                onChange={e => setAgreedToShare(e.target.checked)}
                sx={{ pointerEvents: 'auto' }}
              />
            }
            label={
              isFreePlan
              ? <Typography>
                As a user on a pay as you go plan, I understand that <strong>all apps</strong> that
                I create will be publicly accessible, including the data that I’ve uploaded.
              </Typography>
              : <Typography>
                I understand that any app I make public will be accessible by anyone,
                including the data that I’ve uploaded.
              </Typography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={agreedToTerms || false}
                onChange={e => setAgreedToTerms(e.target.checked)}
                sx={{ pointerEvents: 'auto' }}
              />
            }
            label={
              <Typography>
                I agree to Scale’s{' '}
                <Link
                  onMouseDown={e => {
                    e.preventDefault();
                    window.open('https://www.scale.com/legal/product-terms', '_blank');
                  }}
                  sx={{ pointerEvents: 'auto' }}
                >
                  Product Terms
                </Link>
                .
              </Typography>
            }
          />
        </FormGroup>
      </Box>
    </>
  );
}
