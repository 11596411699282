import { useMemo } from 'react';
import _ from 'lodash';

import { Link, Typography } from '@mui/material';
import { Prompt } from '@scale/llm-shared/interfaces/prompt';

import GrayDataGrid from 'frontend/components/datagrid/GrayDataGrid';
import FlexBox from 'frontend/components/FlexBox';

const TEMPLATE_VAR_COLS = [
  { field: 'token', headerName: 'Token', flex: 0.2 },
  { field: 'value', headerName: 'Value', flex: 0.8 },
];

export function FewShotExamplesGrid({
  dataId,
  prompt,
}: {
  dataId?: string | null;
  prompt: Partial<Prompt>;
}) {
  const templateVarRows = useMemo(() => {
    const exampleVariables = prompt.exampleVariables as Record<string, string> | undefined;
    if (!exampleVariables) {
      return [];
    }

    return Object.entries(exampleVariables).map(([token, value]) => ({
      id: token,
      token,
      value: value === '' ? '(empty string)' : value ?? '[[ unset ]]',
    }));
  }, [prompt.exampleVariables]);

  if (!dataId) {
    return (
      <FlexBox height="100%" alignItems="stretch" justifyContent="center">
        <FlexBox
          maxWidth={540}
          flexDirection="column"
          alignItems="center"
          justifyContent="flex-start"
        >
          <Typography>
            Select or upload a CSV or JSONL dataset to use few-shot examples. See{' '}
            <Link
              href="https://spellbook.readme.io/docs/prompt-engineering#variables"
              target="_blank"
              rel="noopener noreferrer"
            >
              our documentation on few-shot variables
            </Link>{' '}
            for more information on using existing data to improve your prompt performance.
          </Typography>
        </FlexBox>
      </FlexBox>
    );
  }

  return (
    <GrayDataGrid
      autoHeight
      rows={templateVarRows}
      columns={TEMPLATE_VAR_COLS}
      pageSize={10}
      rowsPerPageOptions={[5, 10]}
      density="compact"
    />
  );
}
