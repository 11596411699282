import * as R from 'ramda';

import { Box, TextField, Typography } from '@mui/material';
import {
  EvaluationWizardCell,
  EvaluationWizardGrid,
} from 'frontend/components/evaluation/EvaluationWizardLayout';
import {
  useCustomHumanEvaluationWizardState,
  useEvaluationWizardState,
} from 'frontend/models/v2/useEvaluationWizardState';
import { useVariantStore } from 'frontend/storesV2/VariantStore';
import React, { useCallback, useState } from 'react';
import { StoredVariant } from 'frontend/storesV2/types';
import { HSpace, VSpace } from 'frontend/components/Spacer';

export function CustomHumanEvaluationWizardRunVariantSpecStep() {
  return (
    <EvaluationWizardGrid
      sx={{
        gridTemplateColumns: '1fr',
      }}
    >
      <CustomHumanEvaluationWizardRunVariantSpecHeader />
      <CustomHumanEvaluationWizardRunVariantSpecBody />
    </EvaluationWizardGrid>
  );
}

const CustomHumanEvaluationWizardRunVariantSpecHeader: React.FC = () => {
  return (
    <>
      <EvaluationWizardCell sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography variant="h2">Specify the details of your Custom Human Evaluation</Typography>
      </EvaluationWizardCell>
    </>
  );
};

function RunVariantSpecRow(props: {
  variant: StoredVariant;
  handleRunVariantSpecChange: (variantId: string, numTimesToRun?: number) => void;
  numTimesToRun?: number;
}) {
  const { variant, handleRunVariantSpecChange, numTimesToRun } = props;

  const onNumTimesToRunChange = useCallback(
    (newNumTimesToRun?: number) => {
      handleRunVariantSpecChange(variant.id, newNumTimesToRun);
    },
    [handleRunVariantSpecChange, variant.id],
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <Typography variant="body2">{variant.name}:</Typography>
      <HSpace s={2} />
      <TextField
        value={!numTimesToRun ? '' : `${numTimesToRun}`}
        size="small"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          if (event.target.value === '') {
            onNumTimesToRunChange(undefined);
            return;
          }
          const parsedInt = parseInt(event.target.value);
          if (parsedInt < 1 || isNaN(parsedInt)) {
            onNumTimesToRunChange(undefined);
            return;
          }

          onNumTimesToRunChange(parsedInt);
        }}
        inputProps={{ inputMode: 'numeric' }}
      />
    </Box>
  );
}

const CustomHumanEvaluationWizardRunVariantSpecBody: React.FC = () => {
  const { runVariantSpec, setRunVariantSpec } = useCustomHumanEvaluationWizardState(
    R.pick(['runVariantSpec', 'setRunVariantSpec']),
  );
  const { variantIds } = useEvaluationWizardState(R.pick(['variantIds']));
  const { variantById } = useVariantStore(R.pick(['variantById']));
  const handleRunVariantSpecChange = useCallback(
    (variantId: string, numTimesToRun?: number) => {
      setRunVariantSpec(prevSpec => {
        if (numTimesToRun) {
          prevSpec[variantId] = numTimesToRun;
        } else {
          delete prevSpec[variantId];
        }
        return prevSpec;
      });
    },
    [variantIds, variantById, runVariantSpec, setRunVariantSpec],
  );
  return (
    <>
      <EvaluationWizardCell sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography variant="body1">How many times do you want to run each variant?</Typography>
        <VSpace s={1} />
        {variantIds.map(variantId => {
          return (
            <RunVariantSpecRow
              key={variantId}
              variant={variantById[variantId]}
              handleRunVariantSpecChange={handleRunVariantSpecChange}
              numTimesToRun={runVariantSpec[variantId]}
            />
          );
        })}
      </EvaluationWizardCell>
    </>
  );
};
