import React, { useCallback } from 'react';

import { Box, IconButton, LinearProgress, Typography } from '@mui/material';

import theme from 'frontend/theme';

export function InputContainer({
  error,
  children,
}: {
  error?: string;
  children?: React.ReactNode;
}): JSX.Element {
  return (
    <Box
      tabIndex={-1}
      sx={{
        position: 'relative',
        width: '100%',
        borderRadius: 1,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.info.light,
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: 1.5,
        paddingRight: 1.5,
        '&:hover': {
          borderColor: theme.palette.info.main,
        },
        '&:focus-within': {
          borderColor: theme.palette.primary.main,
        },
      }}
    >
      {children}
      {error && (
        <Typography variant="subtitle2" color="red" sx={{ position: 'absolute', bottom: 0 }}>
          {error}
        </Typography>
      )}
    </Box>
  );
}
