import { v4 } from 'uuid';

export function uniqueId(prefix = '') {
  return prefix + v4();
}

export function round(value: number, digits = 2) {
  const exp = Math.pow(10, digits);
  return Math.round(value * exp) / exp;
}

export function toPercent(value: number, digits = 0) {
  return `${round(value * 100, digits)}%`;
}
