import { ClaudeV1 } from '@scale/llm-shared/modelProviders/anthropic/models/ClaudeV1';
import { ClaudeInstantV1 } from '@scale/llm-shared/modelProviders/anthropic/models/ClaudeInstantV1';
import { IAnthropicBaseModel } from '@scale/llm-shared/modelProviders/anthropic/types';

import { AnthropicBaseModelInternalId } from '../consts';

export const GetAnthropicBaseModelFromInternalId: Record<
  AnthropicBaseModelInternalId,
  IAnthropicBaseModel
> = {
  [AnthropicBaseModelInternalId.ClaudeInstantV1]: ClaudeInstantV1,
  [AnthropicBaseModelInternalId.ClaudeV1]: ClaudeV1,
};
