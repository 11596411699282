import { Ai21BaseModelInternalId } from '@scale/llm-shared/modelProviders/ai21/consts';
import { IAi21BaseModel } from '@scale/llm-shared/modelProviders/ai21/types';
import { AnthropicBaseModelInternalId } from '@scale/llm-shared/modelProviders/anthropic/consts';
import { IAnthropicBaseModel } from '@scale/llm-shared/modelProviders/anthropic/types';
import { CohereBaseModelInternalId } from '@scale/llm-shared/modelProviders/cohere';
import { ICohereBaseModel } from '@scale/llm-shared/modelProviders/cohere/types';
import { OpenAiBaseModelInternalId } from '@scale/llm-shared/modelProviders/openAi/consts';
import { IOpenAiBaseModel } from '@scale/llm-shared/modelProviders/openAi/types';
import { ScaleLaunchBaseModelInternalId } from '@scale/llm-shared/modelProviders/scaleLaunch';
import { IScaleLaunchBaseModel } from '@scale/llm-shared/modelProviders/scaleLaunch/types';

export interface IBaseTextGenerationModel {
  minTokensAllowed: number;
  maxTokensAllowed: number;
  minTemperatureAllowed: number;
  maxTemperatureAllowed: number;
  externalModelId: string;
}

export const BaseModelInternalId = {
  ...OpenAiBaseModelInternalId,
  ...AnthropicBaseModelInternalId,
  ...ScaleLaunchBaseModelInternalId,
  ...CohereBaseModelInternalId,
  ...Ai21BaseModelInternalId,
} as const;

export type BaseModelInternalId = typeof BaseModelInternalId[keyof typeof BaseModelInternalId];

export type IBaseModel =
  | IOpenAiBaseModel
  | IAnthropicBaseModel
  | IScaleLaunchBaseModel
  | ICohereBaseModel
  | IAi21BaseModel;
