import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@mui/material';
import { TaskType } from '@prisma/client';

import { Colors } from 'frontend/theme';
import { TASK_TYPE_ICON } from 'frontend/utils/taskType';

export function TaskTypeChip({ taskType }: { taskType: TaskType }) {
  return (
    <Box
      sx={{
        bgcolor: 'info.light',
        color: 'info.main',
        borderRadius: 8,
        paddingTop: 0.3,
        paddingBottom: 0.3,
        paddingLeft: 1.1,
        paddingRight: 1.1,
        width: 'fit-content',
      }}
    >
      <Typography variant="subtitle2">
        <FontAwesomeIcon icon={TASK_TYPE_ICON[taskType]} style={{ marginRight: 4 }} />
        {taskType}
      </Typography>
    </Box>
  );
}

export function TextGenerationChip() {
  return (
    <Box
      sx={{
        bgcolor: 'info.light',
        color: 'info.main',
        borderRadius: 8,
        paddingTop: 0.3,
        paddingBottom: 0.3,
        paddingLeft: 1.1,
        paddingRight: 1.1,
        width: 'fit-content',
      }}
    >
      <Typography variant="subtitle2">
        <FontAwesomeIcon icon="gears" style={{ marginRight: 4 }} />
        Text Generation
      </Typography>
    </Box>
  );
}

export function TaskTypeChipLarge({ taskType }: { taskType: TaskType }) {
  return (
    <Box
      sx={{
        bgcolor: 'info.light',
        color: 'info.main',
        borderRadius: '8px',
        paddingTop: 1.1,
        paddingBottom: 1.1,
        paddingLeft: 1.5,
        paddingRight: 1.5,
        width: 'fit-content',
      }}
    >
      <Typography sx={{ fontSize: 12, fontWeight: 500, color: Colors.CoolGray50 }}>
        <FontAwesomeIcon icon={TASK_TYPE_ICON[taskType]} style={{ marginRight: 8 }} />
        {taskType}
      </Typography>
    </Box>
  );
}
