import { Box } from '@mui/material';

import theme, { Colors } from 'frontend/theme';

interface SidebarNumberInputHeaderProps {
  title: string;
  noMargin?: boolean;
  value: number;
  onChangeInput: (e: any, val: any) => void;
}

const SidebarNumberInputHeader = ({
  title,
  noMargin,
  value,
  onChangeInput,
}: SidebarNumberInputHeaderProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        fontSize: 14,
        fontWeight: 500,
        color: Colors.CoolGray70,
        marginBottom: noMargin ? 0 : theme.spacing(1),
      }}
    >
      <Box>{title}</Box>
      <input
        className="input-header"
        type="number"
        value={value as number}
        onChange={e => onChangeInput(e, e.target.value)}
      ></input>
      <style>
        {`
        .input-header {
            width: 50px;
            height: 30px;
            border: 1px solid #E6E6E6;
            border-radius: 8px;
            text-align:center;
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */
        input[type=number] {
            -moz-appearance: textfield;
        }
        `}
      </style>
    </Box>
  );
};

export default SidebarNumberInputHeader;
