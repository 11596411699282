import { IAi21BaseModel } from '@scale/llm-shared/modelProviders/ai21/types';

import { Ai21BaseModelInternalId } from '../consts';
import { J1Grande } from './J1Grande';
import { J1Jumbo } from './J1Jumbo';
import { J1Large } from './J1Large';
import { J2Jumbo } from './J2Jumbo';
import { J2Grande } from './J2Grande';
import { J2Large } from './J2Large';
import { J2GrandeInstruct } from './J2GrandeInstruct';
import { J2JumboInstruct } from './J2JumboInstruct';

export const GetAi21BaseModelFromInternalId: Record<Ai21BaseModelInternalId, IAi21BaseModel> = {
  [Ai21BaseModelInternalId.J1Large]: J1Large,
  [Ai21BaseModelInternalId.J1Grande]: J1Grande,
  [Ai21BaseModelInternalId.J1Jumbo]: J1Jumbo,
  [Ai21BaseModelInternalId.J2Large]: J2Large,
  [Ai21BaseModelInternalId.J2Grande]: J2Grande,
  [Ai21BaseModelInternalId.J2Jumbo]: J2Jumbo,
  [Ai21BaseModelInternalId.J2GrandeInstruct]: J2GrandeInstruct,
  [Ai21BaseModelInternalId.J2JumboInstruct]: J2JumboInstruct,
};
