import { TokenProbs } from '@scale/llm-shared/types/requests';

export type HasId = {
  id: string;
};
// ****** DATA *******

export interface Input {
  input: string;
}

export interface ExpectedOutput {
  expectedOutput: string;
}

export interface ActualOutput {
  actualOutput: string;
  tokenProbs: TokenProbs | null | undefined;
  finishReason: string | null | undefined;
  error: string | null | undefined;
}

export interface LabeledTextData extends Input, ExpectedOutput {}
export interface MaybeLabeledTextData extends Input, Partial<ExpectedOutput> {}

export interface InferenceResult extends LabeledTextData, ActualOutput {} // Maybe can make you only infer on data where we have labels

export interface InferenceResultRow extends InferenceResult {
  id: string;
  status: PairedRowStatus;
}
export enum JobInferenceItemStatus {
  InProgress = 'In Progress',
  Complete = 'Complete',
  Failed = 'Failed',
  NotStarted = 'Not Started',
}
export interface JobInferenceItem extends InferenceResult {
  status: JobInferenceItemStatus;
}

export enum PairedRowStatus {
  IN_PROGRESS = 'In Progress',
  COMPLETE = 'Complete',
  FAILED = 'Failed',
  NOT_STARTED = 'Not Started',
}

// Display settings in prompts page
export type DisplaySettings = {
  showProbabilities: boolean;
};
