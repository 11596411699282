import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';

interface EvaluationWizardInitJobSkipDeduplicationPopupProps {
  open: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
}

export function EvaluationWizardInitJobSkipDeduplicationPopup(
  props: EvaluationWizardInitJobSkipDeduplicationPopupProps,
) {
  const { open, handleClose, handleSubmit } = props;
  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ color: 'black' }}>
          {'There is an existing human evaluation with the existing variant + dataset combination.'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Continue anyways?</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleSubmit}>Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
