import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import star from 'frontend/assets/star.svg';
import FlexBox from 'frontend/components/FlexBox';
import SettingsDialogButton from 'frontend/components/playground/SettingsDialogButton';
import { Colors } from 'frontend/theme';

interface ISubHeader {
  onClickSettings: () => void;
}

const Star = styled('img')(() => ({
  height: 24,
}));

const SubHeader = ({ onClickSettings }: ISubHeader) => {
  return (
    <FlexBox
      sx={{
        flexDirection: 'row',
        gap: 2,
        justifyContent: 'space-between',
        width: '100vw',
        paddingRight: '24px',
      }}
    >
      <FlexBox
        sx={{
          flexDirection: 'row',
          gap: 2,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Star src={star} />
        <Box
          sx={{
            justifyContent: 'space-between',
            fontSize: 14,
            fontWeight: 500,
            color: Colors.CoolGray70,
            marginLeft: -1,
            whiteSpace: 'nowrap',
          }}
        >
          {'Try out FLAN!'}
        </Box>
      </FlexBox>
      <SettingsDialogButton onClick={onClickSettings} />
    </FlexBox>
  );
};

export default SubHeader;
