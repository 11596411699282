import { ModelHost } from '@prisma/client';
import { BaseModelInternalId } from '@scale/llm-shared/modelProviders/types';

export const DEFAULT_MODEL_ID = BaseModelInternalId.GPT3TextDavinci003;
export const DEFAULT_MODEL_HOST = ModelHost.OpenAi; // HACK: remove this and rely on the model object in the frontend

export const HIDDEN_V2_MODEL_IDS = [
  BaseModelInternalId.GPT3_5Turbo0301,
  BaseModelInternalId.GPT3_5Turbo16k,
];
