import { Drawer, Toolbar } from '@mui/material';

import theme from 'frontend/theme';

interface PageActionsDrawerProps {
  open: boolean;
  children?: React.ReactNode;
}

export default function PageActionsDrawer(props: PageActionsDrawerProps) {
  const { open, children } = props;
  return (
    <>
      {open && <Toolbar />}
      <Drawer open={open} variant="persistent" anchor="bottom">
        <Toolbar sx={{ justifyContent: 'flex-end' }}>{children}</Toolbar>
      </Drawer>
    </>
  );
}
