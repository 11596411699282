import { createFromStates, state } from 'frontend/utils/store';

const states = [
  state('isModalOpen', false as boolean),
  state('renameModalDataId', undefined as string | undefined | null),
  state('archiveModalDataId', undefined as string | undefined | null),
  state('dataId', undefined as string | undefined | null),
];

type Store = UnionToIntersection<ReturnType<typeof states[number]>>;

export const useDatasetPageState = createFromStates<Store>(states);
