import produce from 'immer';
import _ from 'lodash';

import { Prompt } from '@scale/llm-shared/interfaces/prompt';

import { Data } from '../interfaces/data';
import { parse, render } from './parser';

export const exampleKeywordRe = /examples\[(.+)\]\.(.+)/;

function parseVariables(template: string) {
  const tokens = parse(template);
  return [...new Set(tokens.filter(t => t.type === 'variable').map(t => t.value))];
}

export function parseExampleVariables(template: string) {
  return parseVariables(template).filter(v => v.match(exampleKeywordRe));
}

export function parseQueryVariables(template: string) {
  return parseVariables(template).filter(v => !v.match(exampleKeywordRe));
}

/**
 * Combines query variables with the prompt variables, then inserts all vars (few shots examples + query) into template.
 */
export function renderPromptWithQuery(
  prompt: Pick<Prompt, 'exampleVariables' | 'template'>,
  query: Record<string, string>,
) {
  const exampleVars = prompt.exampleVariables;
  const exampleVarsWithQuery = produce(exampleVars, (draftExampleVars: Record<string, string>) => {
    Object.keys(query).forEach(k => (draftExampleVars[k] = query[k]));
  }) as any;
  const fullPrompt = render(prompt.template, exampleVarsWithQuery);
  // logger.debug('full prompt', fullPrompt)
  return fullPrompt;
}

/**
 * Validates that at least one query variable is in the prompt, and that all example variables can be fetched from the dataset.
 * This function must run very quickly, since we run it every time the prompt template is edited.
 * @param prompt Prompt object passed from frontend
 * @returns Error string if error is found, otherwise null
 */
export function validateTemplateVariables(
  prompt: Partial<Prompt>,
  sourceData?: Data | null | undefined,
): string | null {
  try {
    const systemMessageVars = parseVariables(prompt.systemMessage ?? '');
    if (systemMessageVars.length) {
      return `The system message does not yet support template variables. Variable {{ ${systemMessageVars[0]} }} must be removed.`;
    }

    if (!prompt.template) {
      return null;
    }

    const queryVars = parseQueryVariables(prompt.template);
    if (!queryVars.length) {
      return 'You must include at least one query variable in your prompt template, e.g. {{ input }}.';
    }

    const oldExampleVars = prompt.exampleVariables ?? {};
    const newExampleVars = parseExampleVariables(prompt.template);

    const unmappedExampleVars = _.difference(newExampleVars, Object.keys(oldExampleVars));
    if (!unmappedExampleVars.length && !queryVars.length) {
      return null;
    }

    const rowIdSet = new Set<string>();
    const sourceNumRows = sourceData?.columns[0].values.length ?? 0;
    const sourceColNameSet = new Set<string>(sourceData?.columns.map(c => c.name) ?? []);
    for (const fullName of unmappedExampleVars) {
      const [, rowId, colName] = fullName.match(exampleKeywordRe) ?? [];

      if (!rowId || !colName) {
        return `Failed to parse variable {{ ${fullName} }}. Few shot example variables must formatted like {{ examples[0].columnName }}.`;
      }

      if (!sourceData) {
        return (
          `{{ ${fullName} }} is a template variable reserved for inserting few shot examples` +
          '. You must either have a dataset to use few shot examples, or remove the template variable.'
        );
      }

      rowIdSet.add(rowId);
      if (rowIdSet.size > sourceNumRows) {
        return (
          `{{ ${fullName} }} is a template variable reserved for inserting few shot examples` +
          ', but could not be set because there are not enough rows in your dataset.'
        );
      }

      if (!sourceColNameSet.has(colName)) {
        return `Could not find a column named "${colName}" in your dataset; consider editing {{ ${fullName} }} to contain a valid column name.`;
      }
    }
    for (const fullName of queryVars) {
      if (sourceData && !sourceColNameSet.has(fullName)) {
        return `Could not find a column named "${fullName}" in your dataset.`;
      }
    }

    return null;
  } catch (err) {
    return (err as Error).message;
  }
}
