/* Implementation of a pseudorandom number generator based on cyrb128 hashing */

import _ from 'lodash';

export function cyrb128(str: string) {
  let h1 = 1779033703,
    h2 = 3144134277,
    h3 = 1013904242,
    h4 = 2773480762;
  for (let i = 0, k; i < str.length; i++) {
    k = str.charCodeAt(i);
    h1 = h2 ^ Math.imul(h1 ^ k, 597399067);
    h2 = h3 ^ Math.imul(h2 ^ k, 2869860233);
    h3 = h4 ^ Math.imul(h3 ^ k, 951274213);
    h4 = h1 ^ Math.imul(h4 ^ k, 2716044179);
  }
  h1 = Math.imul(h3 ^ (h1 >>> 18), 597399067);
  h2 = Math.imul(h4 ^ (h2 >>> 22), 2869860233);
  h3 = Math.imul(h1 ^ (h3 >>> 17), 951274213);
  h4 = Math.imul(h2 ^ (h4 >>> 19), 2716044179);
  return [(h1 ^ h2 ^ h3 ^ h4) >>> 0, (h2 ^ h1) >>> 0, (h3 ^ h1) >>> 0, (h4 ^ h1) >>> 0];
}

export interface RandomGenerator {
  randomInt(start: number, end: number): number;
  choice<T>(arr: T[]): T;
  shuffle<T>(arr: T[]): void;
  sampleSize<T>(arr: T[], size: number): T[];
  sampleObject<T>(obj?: { [key: string]: T; }, size?: number): { [key: string]: T; };
}

export class Cryb128Generator implements RandomGenerator {
  private index = 0;
  constructor(public seed: number | string) { }

  reset() {
    this.index = 0;
  }

  setSeed(newSeed: number | string) {
    console.info(`Resetting pseudorandom seed to ${newSeed}`);
    this.index = 0;
    this.seed = newSeed;
  }

  random() {
    const s = `s${this.seed}idx${this.index}`;
    this.index += 1;
    return cyrb128(s)[0];
  }

  // Returns a random number between start and end, inclusive
  randomInt(start: number, end: number) {
    const r = this.random();
    const d = end - start + 1;
    return (r % d) + start;
  }

  choice<T>(arr: T[]) {
    const index = this.randomInt(0, arr.length - 1);
    return arr[index];
  }

  shuffle<T>(arr: T[]) {
    for (let i = 0; i < arr.length; i++) {
      const swapIndex = this.randomInt(i, arr.length - 1);
      if (swapIndex !== i) {
        const swap = arr[swapIndex];
        arr[swapIndex] = arr[i];
        arr[i] = swap;
      }
    }
  }

  sampleSize<T>(arr: T[], size: number) {
    if (size >= arr.length) {
      return arr;
    }
    const newArr = [...arr];
    this.shuffle(newArr);
    return newArr.slice(0, size);
  }

  sampleObject<T>(obj?: { [key: string]: T; }, size = 1) {
    if (!obj) {
      return {};
    }
    const keys = Object.keys(obj);
    const sampleKeys = this.sampleSize(keys, size);
    return _.pick(obj, sampleKeys);
  }
}

class NativeRandomGenerator implements RandomGenerator {
  randomInt(start: number, end: number) {
    return _.random(start, end);
  }

  choice<T>(arr: T[]) {
    const index = this.randomInt(0, arr.length - 1);
    return arr[index];
  }

  shuffle<T>(arr: T[]) {
    _.shuffle(arr);
  }

  sampleSize<T>(arr: T[], size: number) {
    return _.sampleSize(arr, size);
  }

  sampleObject<T>(obj?: { [key: string]: T; }, size = 1) {
    if (!obj) {
      return {} as { [key: string]: T; };
    }
    const keys = Object.keys(obj);
    const sampleKeys = this.sampleSize(keys, size);
    return _.pick(obj, sampleKeys);
  }
}

export const pseudoRandom = new Cryb128Generator(1001);
export const nativeRandom = new NativeRandomGenerator();
