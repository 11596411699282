import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Paper, ThemeProvider, Typography } from '@mui/material';

import FlexBox from 'frontend/components/FlexBox';
import { SpellbookLogo } from 'frontend/components/SpellbookLogo';
import { LOGIN_REDIRECT_URL, SIGNUP_REDIRECT_URL } from 'frontend/consts/authRedirectUrls';
import { LoadingState, useUserStore } from 'frontend/stores/UserStore';
import theme, { Colors } from 'frontend/theme';

const AUTOREDIRECT_ON_LOGGED_OUT = process.env.REACT_APP_AUTOREDIRECT_ON_LOGGED_OUT;

export default function LoggedOutPage() {
  const { loadingState } = useUserStore();
  if (AUTOREDIRECT_ON_LOGGED_OUT) {
    window.location.href = LOGIN_REDIRECT_URL;
  }
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          height: '100vh',
          width: '100vw',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Paper variant="outlined">
          <Box px={2} py={4} sx={{ width: 540, textAlign: 'center' }}>
            {loadingState === LoadingState.Unauthenticated && (
              <FlexBox sx={{ flexDirection: 'column', gap: 2 }}>
                <SpellbookLogo />
                <Typography>You will need to log in to Scale to access Spellbook.</Typography>
                <FlexBox sx={{ gap: 1 }}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      window.location.href = LOGIN_REDIRECT_URL;
                    }}
                  >
                    Log in to Scale
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      window.location.href = SIGNUP_REDIRECT_URL;
                    }}
                  >
                    Sign up
                  </Button>
                </FlexBox>
              </FlexBox>
            )}
            {loadingState === LoadingState.Error && (
              <FlexBox sx={{ flexDirection: 'column', gap: 2 }}>
                <FontAwesomeIcon icon="circle-xmark" style={{ color: Colors.Red, fontSize: 48 }} />
                <Typography>
                  There was an error fetching from the API. Please check your server connection and
                  reload the page.
                </Typography>
              </FlexBox>
            )}
          </Box>
        </Paper>
      </Box>
    </ThemeProvider>
  );
}
