import { useCallback, useState } from 'react';

import { last } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from '@mui/material';
import { DeploymentLog } from '@scale/llm-shared/interfaces/deployment';

import { client } from 'frontend/api/trpc';
import { track } from 'frontend/utils/analytics';
import { downloadAsCsv } from 'frontend/utils/downloadAsCsv';

const ROWS_PER_PAGE = 1000;

export function DownloadLogsAsCsv({ deploymentId }: { deploymentId: string }) {
  const [downloading, setDownloading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);

  const downloadLogsAsCsv = useCallback(async () => {
    setProgress(0);
    setDownloading(true);
    const data: DeploymentLog[] = [];
    const take = ROWS_PER_PAGE;
    const stats = await client.query('v2.deployment.getDeploymentStats', { deploymentId });
    const { totalCount } = stats;
    // Only show the download dialog if there are a large number of rows to download.
    if (totalCount > ROWS_PER_PAGE) {
      setOpen(true);
    }
    while (data.length < totalCount) {
      const cursor = last(data);
      const newData = await client.query('v2.deployment.getLogs', {
        deploymentId,
        take,
        cursorId: cursor?.id,
      });
      data.push(...newData);
      setProgress(Math.floor(data.length / totalCount));
      if (newData.length === 0) {
        // Failsafe to make sure we don't spin on data inconsistencies
        setProgress(1.0);
        break;
      }
    }
    setOpen(false);
    setDownloading(false);
    const filename = `deployment-${deploymentId}.csv`;
    track('Deployment Logs Downloaded', { deployment: deploymentId });
    downloadAsCsv(
      data.map(row => {
        return {
          createdAt: row.createdAt,
          id: row.id,
          row: row.row,
          input: JSON.stringify(row.inputByName, null, 2),
          output: row.output,
          responseTimeMs: row.responseTimeMs,
          status: row.status,
          errorMessage: row.errorMessage,
        };
      }),
      filename,
    );
  }, [deploymentId]);

  return (
    <>
      <Dialog open={open}>
        <DialogTitle>
          <Typography variant="h3">Downloading deployment logs as CSV</Typography>
        </DialogTitle>
        <DialogContent>
          <LinearProgress variant="determinate" value={progress * 100} />
        </DialogContent>
      </Dialog>
      <Button size="small" variant="outlined" onClick={downloadLogsAsCsv} disabled={downloading}>
        <FontAwesomeIcon icon="download" style={{ marginRight: '1ex' }} />
        Download CSV
      </Button>
    </>
  );
}
