import { useCallback, useState } from 'react';

import { useToggle } from 'react-use';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { useQuery } from '@tanstack/react-query';

import { client } from 'frontend/api/trpc';
import { CreditCardCard } from 'frontend/components/CreditCardCard';
import { InputContainer } from 'frontend/components/InputContainer';

export function CreditCardInput({ title }: { title?: string }): JSX.Element {
  const [addCreditCardModalOpen, toggleAddCreditCardModalOpen] = useToggle(false);

  const getCreditCardRes = useQuery(
    ['getCreditCard'],
    () => {
      return client.query('billing.getCreditCard');
    },
    { staleTime: Infinity, retry: false },
  );

  return (
    <>
      {title && <Typography variant="h2">{title}</Typography>}
      {getCreditCardRes.isFetched ? (
        getCreditCardRes.data ? (
          <CreditCardCard creditCard={getCreditCardRes.data.card} />
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px dashed',
              borderColor: 'primary.main',
              borderRadius: 1,
              bgcolor: 'primary.light',
              color: 'primary.main',
              height: 80,
              cursor: 'pointer',
              '&:hover': {
                bgcolor: 'secondary.light',
              },
            }}
            onClick={toggleAddCreditCardModalOpen}
          >
            <Typography variant="body2" color="primary">
              + Add Credit Card
            </Typography>
          </Box>
        )
      ) : (
        <Skeleton
          variant="rectangular"
          height={96}
          sx={{ borderRadius: 1, bgcolor: 'info.light' }}
        />
      )}
      <AddCreditCardModal
        open={addCreditCardModalOpen}
        onClose={toggleAddCreditCardModalOpen}
        onSuccess={getCreditCardRes.refetch}
      />
    </>
  );
}

function AddCreditCardModal({
  open,
  onClose,
  onSuccess,
}: {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}): JSX.Element {
  const stripe = useStripe();
  const elements = useElements();

  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState<string | undefined>();

  const handleChange = useCallback(
    (event: StripeCardElementChangeEvent) => {
      setSubmitError(undefined);
      setSubmitDisabled(!event.complete);
    },
    [setSubmitDisabled],
  );

  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!stripe || !elements) {
        return;
      }

      const cardElement = elements.getElement(CardElement);
      if (!cardElement) {
        return;
      }

      const formData = new FormData(event.currentTarget);

      setSubmitting(true);

      try {
        // Get intent
        const intent = await client.mutation('billing.intendAddCreditCard');
        if (!intent) throw Error('Failed to add credit card information');
        const { clientSecret, stripeCustomerId } = intent;

        // Create payment method
        const createRes = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            name: String(formData.get('name')),
            email: String(formData.get('email')),
          },
        });
        if (!createRes.paymentMethod) throw createRes.error;

        // Confirm payment method
        const confirmRes = await stripe.confirmCardSetup(clientSecret, {
          payment_method: createRes.paymentMethod.id,
        });
        if (!confirmRes.setupIntent?.payment_method) throw confirmRes.error;
        const paymentMethodId = confirmRes.setupIntent.payment_method as string;

        // Store payment method
        await client.mutation('billing.confirmAddCreditCard', {
          stripeCustomerId,
          paymentMethodId,
        });

        onSuccess();
        onClose();
      } catch (e) {
        if (e instanceof Error) {
          setSubmitError(e.message);
        } else {
          setSubmitError('An error occured');
        }
      } finally {
        setSubmitting(false);
      }
    },
    [stripe, elements, onSuccess],
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <DialogTitle variant="h1">Add Credit Card</DialogTitle>
        <DialogContent>
          <Box sx={{ width: 400, display: 'flex', flexDirection: 'column', gap: 2, paddingTop: 1 }}>
            <TextField
              name="name"
              label={<Typography>Full Name</Typography>}
              type="text"
              fullWidth
            />
            <TextField name="email" label={<Typography>Email</Typography>} type="email" fullWidth />
            <InputContainer error={submitError}>
              <CardElement onChange={handleChange} />
            </InputContainer>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" disabled={submitting || submitDisabled}>
            Add credit card
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
