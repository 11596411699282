// Deployment route config
export const DEPLOYMENT_ROUTE = 'app';
export const DEPLOYMENT_ROUTE_V2 = 'v2/deploy';

// Everything after here is only needed by server

// Launch
export const LAUNCH_INFERENCE_URL = 'https://launch.ml-serving-internal.scale.com/v1/'; // prod cluster

// Scale train (for fine tuning)
export const SCALE_TRAIN_URL = 'https://scale-train-server-new.ml-serving-internal.scale.com';

// Launch endpoint returns
export const LAUNCH_FAILURE_STATE = 'FAILURE';
export const LAUNCH_SUCCESS_STATE = 'SUCCESS';
export const LAUNCH_STARTED_STATE = 'STARTED';
export const LAUNCH_PENDING_STATE = 'PENDING';

// Inference job states
export const INFERENCE_JOB_START_FAILED_MESSAGE = 'INFERENCE LAUNCH FAILED';

// TODO: turn these things into zod schemas maybe and interfaces

export const INFERENCE_JOB_FAILED_STATE = 'INFERENCE FAILED' as const;
export const INFERENCE_JOB_SUCCESS_STATE = 'INFERENCE SUCCESS' as const;
export const INFERENCE_JOB_PENDING_STATE = 'INFERENCE PENDING' as const;

export const INFERENCE_JOB_FAILED_DATUM_OUPUT = 'Inference failed...';

export const POLL_INFERENCE_PROGRESS_EVERY_X_MS = 2000;
