import { BaseModelInternalId } from '@scale/llm-shared/modelProviders/types';

// Base models that do not support token probabilities
export const NoTokenProbabilityModels: BaseModelInternalId[] = [
  BaseModelInternalId.GPT4,
  BaseModelInternalId.GPT4_32K,
  BaseModelInternalId.J1Large,
  BaseModelInternalId.J1Grande,
  BaseModelInternalId.J1Jumbo,
  BaseModelInternalId.J2Large,
  BaseModelInternalId.J2Grande,
  BaseModelInternalId.J2Jumbo,
  BaseModelInternalId.J2GrandeInstruct,
  BaseModelInternalId.J2JumboInstruct,
  BaseModelInternalId.CohereGenerateMedium,
  BaseModelInternalId.CohereGenerateXLarge,
];
