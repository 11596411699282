import _ from 'lodash';
import * as R from 'ramda';
import { Box, Chip, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { EvaluationMethod } from '@prisma/client';
import {
  CompletedClassificationEvaluation,
  CompletedHumanEvaluation,
  EvaluationType,
} from '@scale/llm-shared/interfaces/evaluation';
import { isNotUndefinedOrNull } from '@scale/llm-shared/utils';

import { Container } from 'frontend/components/Container';
import GrayDataGrid from 'frontend/components/datagrid/GrayDataGrid';
import FlexBox from 'frontend/components/FlexBox';
import { HSpace } from 'frontend/components/Spacer';
import DownloadEvaluationResultsButton from 'frontend/components/v2/evaluation/DownloadEvaluationResultsButton';
import VariantName from 'frontend/components/v2/variant/VariantName';
import { useDataStore } from 'frontend/storesV2/DataStore';
import { StoredEvaluation } from 'frontend/storesV2/types';
import { useVariantStore } from 'frontend/storesV2/VariantStore';
import { round, toPercent } from 'frontend/utils';

const EVALUATION_ACCURACY_DESCRIPTION_TEXT: Record<EvaluationType, string> = {
  [EvaluationType.ClassificationEvaluation]: 'Classification',
  [EvaluationType.HumanEvaluation]: 'Human Evaluation',
  [EvaluationType.MauveEvaluation]: 'Mauve Score',
  [EvaluationType.AIFeedback]: 'AI Feedback Agreement',
};

export function HumanEvaluationResultsCard({ evaluation }: { evaluation: StoredEvaluation }) {
  const { variantById } = useVariantStore(R.pick(['variantById']));
  const { dataById } = useDataStore(R.pick(['dataById']));

  const variantName = variantById[evaluation.variantId].name;
  const dataName = dataById[evaluation.inputDataId]?.name ?? 'Unknown Dataset';
  const stats = (evaluation as CompletedHumanEvaluation).stats;
  const hitRate = toPercent(stats.hitRate);

  // TODO: scope this properly
  const evaluationAccuracyDescriptorText = EVALUATION_ACCURACY_DESCRIPTION_TEXT[evaluation.type];

  return (
    <>
      <Container sx={{ minWidth: '1000px' }}>
        <Box sx={{ width: '100%', padding: '8px' }}>
          <FlexBox sx={{ justifyContent: 'space-between', marginBottom: '16px' }}>
            <FlexBox>
              <Typography sx={{ fontWeight: 600 }}>
                <VariantName name={variantName} />
              </Typography>
            </FlexBox>
          </FlexBox>
          <FlexBox>
            <Box sx={{ width: '16px' }} />
            <FlexBox>
              <Typography variant="subtitle1">Status:</Typography>
              <Chip size="small" label={evaluation.status} color="success"></Chip>
            </FlexBox>
            <HSpace />
            <FlexBox>
              <Typography variant="subtitle1">Method:</Typography>
              <Typography variant="body1">{evaluation.type}</Typography>
            </FlexBox>
            <HSpace />
            <FlexBox>
              <Typography variant="subtitle1">Dataset:</Typography>
              <Chip size="small" label={dataName}></Chip>
            </FlexBox>
            <HSpace />
            <FlexBox sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography variant="subtitle1">Hit rate:</Typography>
              <Typography variant="body1">{hitRate}</Typography>
            </FlexBox>
            <HSpace />
            <FlexBox sx={{ marginLeft: 'auto' }}>
              <DownloadEvaluationResultsButton
                evaluationId={evaluation.id}
                evaluationType={evaluation.type}
              />
            </FlexBox>
          </FlexBox>
        </Box>
      </Container>
    </>
  );
}
