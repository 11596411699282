import { Button } from '@mui/material';
import FlexBox from 'frontend/components/FlexBox';
import { RESPONSIVE_WIDTH_THRESHOLD, MOBILE_WIDTH_THRESHOLD } from 'frontend/consts/playground';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ISettingsDialogButton {
  onClick: () => void;
}

const SettingsDialogButton = ({ onClick }: ISettingsDialogButton) => {
  return (
    <FlexBox
      sx={{
        width: '100%',
        flexDirection: 'row-reverse',
        paddingRight: '18px',
        visibility: 'hidden',
        [`@media (min-width:${RESPONSIVE_WIDTH_THRESHOLD}px)`]: {
          visibility: 'hidden',
        },
        [`@media (max-width:${RESPONSIVE_WIDTH_THRESHOLD}px)`]: {
          visibility: 'visible',
        },
        [`@media (max-width:${MOBILE_WIDTH_THRESHOLD}px)`]: {
          paddingRight: '8px',
          visibility: 'visible',
        },
      }}
    >
      <Button variant="contained" color="secondary" onClick={onClick} style={{ height: '35px' }}>
        <FontAwesomeIcon icon="gear" style={{ fontSize: 18 }} />
      </Button>
    </FlexBox>
  );
};

export default SettingsDialogButton;
