import React, { useCallback, useMemo, useState } from 'react';

import * as R from 'ramda';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import { VSpace } from 'frontend/components/Spacer';
import { useSelectionStore } from 'frontend/storesV2/SelectionStore';
import { useVariantStore } from 'frontend/storesV2/VariantStore';
import { track } from 'frontend/utils/analytics';

export const CreateVariantButton = React.forwardRef(function CreateVariantButton(
  {
    disabled,
    onCreateVariant,
    promptError,
  }: {
    disabled: boolean;
    onCreateVariant: (name: string) => void;
    promptError: string | null;
  },
  ref,
) {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const { selectedApp } = useSelectionStore(R.pick(['selectedApp']));
  const selectedAppId = selectedApp?.id;

  const { getVariantsByAppId } = useVariantStore(R.pick(['getVariantsByAppId']));
  const variants = useMemo(
    () => (selectedAppId ? getVariantsByAppId(selectedAppId) : []),
    [getVariantsByAppId, selectedAppId],
  );
  const variantNamesSet = useMemo(() => new Set(variants.map(e => e.name)), [variants]);
  const createNewVariantName = useCallback(() => {
    let index = variantNamesSet.size + 1;
    while (variantNamesSet.has(`Variant ${index}`)) {
      index += 1;
    }
    return `Variant ${index}`;
  }, [variantNamesSet]);

  const [draftName, setDraftName] = useState<string>(createNewVariantName());
  const nameTaken = useMemo(
    () => draftName && variantNamesSet.has(draftName),
    [draftName, variantNamesSet],
  );
  const invalidName = useMemo(() => !draftName, [draftName]);

  const handleCreateVariant = useCallback(() => {
    setDialogOpen(false);
    onCreateVariant(draftName);
  }, [draftName, onCreateVariant, setDialogOpen]);

  return (
    <>
      <Dialog fullWidth open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>
          <Typography>Add a name for your new variant:</Typography>
        </DialogTitle>
        <DialogContent>
          {nameTaken && (
            <Box mx={1}>
              <Typography variant="h4" color="error">
                This variant name is already in use.
              </Typography>
              <VSpace s={2} />
            </Box>
          )}
          <Box mt={1}>
            <TextField
              fullWidth
              label="Variant Name"
              value={draftName}
              onChange={e => setDraftName(e.target.value)}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  handleCreateVariant();
                }
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setDialogOpen(false)}>
            Cancel
          </Button>
          <Button
            disabled={nameTaken || invalidName}
            variant="contained"
            onClick={handleCreateVariant}
          >
            Save New Variant
          </Button>
        </DialogActions>
      </Dialog>
      <Tooltip
        title={
          promptError
            ? 'You must resolve an issue with your prompt to continue.'
            : 'Save your prompt variant in order to evaluate and deploy your endpoint.'
        }
        enterDelay={500}
      >
        <span>
          <Button
            disabled={disabled}
            onClick={() => {
              track('Prompt Save New Variant Clicked');
              setDialogOpen(true);
            }}
            variant={'contained'}
          >
            Save New Variant
          </Button>
        </span>
      </Tooltip>
    </>
  );
});
