import { useCallback, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Tooltip,
  Typography
} from '@mui/material';
import { App } from '@prisma/client';

import { VSpace } from 'frontend/components/Spacer';
import { PublicAppShareModal } from 'frontend/pages/ExplorePage/PublicAppShareModal';
import theme from 'frontend/theme';
import { getPublicAppUrl } from 'frontend/utils/explore';

export function PublicAppShareButton({
  app,
  variant = 'contained',
  color = 'secondary',
}: {
  app: App;
  variant?: 'text' | 'outlined' | 'contained';
  color?: 'primary' | 'secondary';
}) {
  const [open, setOpen] = useState<boolean>(false);

  const handleClick = useCallback((e: React.MouseEvent) => {
    setOpen(true);
    e.stopPropagation();
  }, []);

  return (
    <>
      <PublicAppShareModal open={open} setOpen={setOpen} app={app} />
      <Button
        color={color}
        variant={variant}
        onClick={handleClick}
        sx={variant === 'text' ? { margin: 0 } : {}}
      >
        <FontAwesomeIcon icon="share-nodes" style={{ marginRight: '1ex' }} /> Share
      </Button>
    </>
  );
}
