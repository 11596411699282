import { useMemo } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Typography } from '@mui/material';

import FlexBox from 'frontend/components/FlexBox';
import HoverableInfoDialog from 'frontend/components/HoverableInfoDialog';
import theme, { Colors } from 'frontend/theme';
import { track } from 'frontend/utils/analytics';

interface PageTitleProps {
  title: string;
  subtitle?: JSX.Element;
  children?: React.ReactNode;
  documentationLink?: string;
  hoverableInfoDialog?: React.ReactNode;
}

export default function PageTitle(props: PageTitleProps) {
  const { documentationLink, title, subtitle, children, hoverableInfoDialog } = props;

  const openDocumentation = useMemo(
    () => () => {
      if (!documentationLink) {
        return;
      }
      track('Documentation Opened');
      window.open(documentationLink, '_blank');
    },
    [documentationLink],
  );

  return (
    <>
      <Box sx={{ minHeight: subtitle ? theme.spacing(10) : theme.spacing(8) }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <FlexBox gap={0.5} alignItems="center">
            <Typography variant="h1" sx={{ fontWeight: 600 }}>
              {title}
            </Typography>
            {hoverableInfoDialog && (
              <HoverableInfoDialog>{hoverableInfoDialog}</HoverableInfoDialog>
            )}
            {documentationLink && (
              <Button onClick={openDocumentation}>
                Documentation
                <FontAwesomeIcon
                  icon="up-right-from-square"
                  size="sm"
                  style={{
                    marginLeft: '1ex',
                    color: Colors.CoolGray50,
                  }}
                />
              </Button>
            )}
          </FlexBox>
          {children}
        </Box>
        {subtitle && (
          <Box mt={0.25} mb={3}>
            <Typography variant="body1" sx={{ color: Colors.CoolGray50 }}>
              {subtitle}
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
}
