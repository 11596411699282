import { useMemo } from 'react';

import _ from 'lodash';
import * as R from 'ramda';

import { Box, Paper, Typography } from '@mui/material';
import { JobStatus } from '@prisma/client';
import { EvaluationType } from '@scale/llm-shared/interfaces/evaluation';
import { isTerminalStatus } from '@scale/llm-shared/types';

import { VSpace } from 'frontend/components/Spacer';
import { useEvaluationStore } from 'frontend/storesV2/EvaluationStore';
import { useJobPollingStore } from 'frontend/storesV2/JobPollingStore';
import { useSelectionStore } from 'frontend/storesV2/SelectionStore';
import { StoredEvaluation } from 'frontend/storesV2/types';
import { useVariantStore } from 'frontend/storesV2/VariantStore';
import theme from 'frontend/theme';

import { HumanEvaluationResultsCard } from './HumanEvaluationResultsCard';
import { JobStatusDisplay } from './JobStatusDisplay';
import { ProgrammaticEvaluationResultsCard } from './ProgrammaticEvaluationResultsCard';

const PROGRAMMATIC_EVALUATIONS_TYPES: EvaluationType[] = [
  EvaluationType.ClassificationEvaluation,
  EvaluationType.MauveEvaluation,
  EvaluationType.AIFeedback,
];
const HUMAN_EVALUATIONS_TYPES: EvaluationType[] = [EvaluationType.HumanEvaluation];

export const EvaluationResultsModal: React.FC = () => {
  const { selectedAppId } = useSelectionStore(R.pick(['selectedAppId']));
  const { getVariantsByAppId } = useVariantStore();
  const { evaluationById } = useEvaluationStore(R.pick(['evaluationById']));
  const { polledJobById } = useJobPollingStore();

  const variants = getVariantsByAppId(selectedAppId ?? '');
  const variantIds = useMemo(() => {
    return variants.map(variant => variant.id);
  }, [variants]);
  const allEvaluations = useMemo(() => {
    return _.sortBy(
      Object.values(evaluationById).filter(evaluation => variantIds.includes(evaluation.variantId)),
      evaluation => evaluation.id,
    ).reverse();
  }, [evaluationById, variantIds]);

  const programmaticEvaluations = useMemo(() => {
    return allEvaluations.filter(evaluation =>
      PROGRAMMATIC_EVALUATIONS_TYPES.includes(evaluation.type),
    );
  }, [allEvaluations]);

  const humanEvaluations = useMemo(() => {
    return allEvaluations.filter(evaluation => HUMAN_EVALUATIONS_TYPES.includes(evaluation.type));
  }, [allEvaluations]);

  if (!selectedAppId) return null; // TODO: fix

  const evaluationToJob = (evaluation: StoredEvaluation) => {
    return polledJobById[evaluation.id];
  };

  return (
    <>
      {!humanEvaluations.length && !programmaticEvaluations.length && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            paddingBlock: 30,
          }}
        >
          <Typography variant="h3" color={theme.palette.info.main}>
            No evaluations yet, create an evaluation to get started!
          </Typography>
        </Box>
      )}

      {!!humanEvaluations.length && (
        <Box>
          <VSpace s={4} />
          <Typography variant="h3">Human Evaluations</Typography>
          <VSpace s={2} />
          {humanEvaluations.map(evaluation => {
            const job = evaluationToJob(evaluation);
            if (evaluation.status !== JobStatus.Completed && job) {
              return (
                <Box key={evaluation.id} my={1}>
                  <JobStatusDisplay job={job} evaluation={evaluation} />
                </Box>
              );
            } else if (evaluation.status === JobStatus.Completed) {
              return (
                <Box key={evaluation.id} my={1}>
                  <HumanEvaluationResultsCard evaluation={evaluation} />
                </Box>
              );
            }
          })}
        </Box>
      )}

      {!!programmaticEvaluations.length && (
        <Box>
          <VSpace s={4} />
          <Typography variant="h3">Programmatic Evaluations</Typography>
          <VSpace s={2} />
          {programmaticEvaluations.map(evaluation => {
            const job = evaluationToJob(evaluation);
            if (job && !isTerminalStatus(evaluation.status)) {
              return (
                <Box key={evaluation.id} my={1}>
                  <JobStatusDisplay job={job} evaluation={evaluation} />
                </Box>
              );
            } else {
              return (
                <Box key={evaluation.id} my={1}>
                  <ProgrammaticEvaluationResultsCard evaluation={evaluation} />
                </Box>
              );
            }
          })}
        </Box>
      )}
    </>
  );
};

export default EvaluationResultsModal;
