import { useCallback, useEffect, useMemo, useState } from 'react';

import _ from 'lodash';

import { TextField } from '@mui/material';

import { Colors } from 'frontend/theme';

export default function PromptTextField({
  value,
  onChange,
  rows,
  readOnly = false,
  debounce = 0,
  disabled = false,
  error = false,
  helperText = '',
}: {
  value: string;
  onChange?: (value: string) => void;
  rows?: number;
  readOnly?: boolean;
  debounce?: number;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
}) {
  const [currentValue, setCurrentValue] = useState(value);
  useEffect(() => setCurrentValue(value), [value]);

  const debouncedOnChange = useMemo(
    () => (debounce ? onChange && _.debounce(onChange, debounce) : onChange),
    [onChange, debounce],
  );
  const handleChange = useCallback(
    (e: any) => {
      setCurrentValue(e.target.value);
      debouncedOnChange?.(e.target.value);
    },
    [debouncedOnChange],
  );

  return (
    <TextField
      label="Prompt Template"
      value={currentValue}
      multiline
      fullWidth
      rows={rows}
      disabled={disabled}
      error={error}
      helperText={helperText}
      onChange={readOnly ? undefined : handleChange}
      InputProps={{
        readOnly,
        style: {
          backgroundColor: Colors.White,
          fontFamily: "'IBM Plex Mono', 'Consolas', monospace",
        },
      }}
    />
  );
}
