import { useCallback, useMemo, useState } from 'react';

import * as R from 'ramda';

import { Box, Button, LinearProgress, Typography } from '@mui/material';
import { neverfail } from '@scale/llm-shared/utils/type';
import { useQuery } from '@tanstack/react-query';

import { Container } from 'frontend/components/Container';
import { DataChip } from 'frontend/components/data/dataChip';
import { ErrorMessage } from 'frontend/components/ErrorMessage';
import { JobStatusChip } from 'frontend/components/JobStatusChip';
import { ProgressBar } from 'frontend/components/ProgressBar';
import VariantName from 'frontend/components/v2/variant/VariantName';
import { useGet } from 'frontend/hooks/useGet';
import { useLLMNavigation } from 'frontend/hooks/useLLMNavigation';
import { useDataStore } from 'frontend/storesV2/DataStore';
import { useInferenceBatchStore } from 'frontend/storesV2/InferenceBatchStore';
import { StoredInferenceBatch } from 'frontend/storesV2/types';
import { useVariantStore } from 'frontend/storesV2/VariantStore';
import theme from 'frontend/theme';

export function InferenceBatchCard({
  inferenceBatch,
}: {
  inferenceBatch: StoredInferenceBatch;
}): JSX.Element {
  const { variantById } = useVariantStore(R.pick(['variantById']));
  const variant = useGet(variantById, inferenceBatch.variantId);

  const { dataInfoById } = useDataStore(R.pick(['dataInfoById']));
  const dataInfo = useGet(dataInfoById, inferenceBatch.inputDataId);

  const [disableDownload, setDisableDownload] = useState(false);
  const [downloadError, setDownloadError] = useState<Error | undefined>();

  const { getInferenceBatchResults } = useInferenceBatchStore(R.pick(['getInferenceBatchResults']));
  const handleDownload = useCallback(() => {
    setDisableDownload(true);
    setDownloadError(undefined);
    getInferenceBatchResults(inferenceBatch.id)
      .then(resultUrl => {
        window.open(resultUrl, '_blank');
      })
      .catch(err => {
        setDownloadError(err);
      })
      .finally(() => {
        setDisableDownload(false);
      });
  }, [inferenceBatch.id, getInferenceBatchResults]);

  const { goToDataPage } = useLLMNavigation();
  const handleViewResults = useCallback(() => {
    goToDataPage(inferenceBatch.inputDataId || undefined);
  }, [inferenceBatch.inputDataId, goToDataPage]);

  const renderActions = () => {
    switch (inferenceBatch.status) {
      case 'Pending':
      case 'Running':
        return (
          <Box>
            <ProgressBar progress={inferenceBatch.progress} />
          </Box>
        );
      case 'Errored':
        return (
          <Box sx={{ alignSelf: 'end' }}>
            <Typography variant="subtitle1" color={theme.palette.error.main}>
              {inferenceBatch.statusMessage}
            </Typography>
          </Box>
        );
      case 'Completed':
      case 'Backfilled':
        return (
          <Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
              <Button
                color="infoSecondary"
                variant="contained"
                onClick={handleDownload}
                disabled={disableDownload}
              >
                Download
              </Button>
              {/* DISABLED FOR NOW BECAUSE EVALUATION SET COLUMNS ARENT MANIPABLE
            <Button color="secondary" variant="contained" onClick={handleViewResults}>
              View Results
            </Button>
            */}
            </Box>
            <ErrorMessage error={downloadError} />
          </Box>
        );
      case 'Cancelled':
        return;
      default:
        neverfail(inferenceBatch.status);
    }
  };

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {/* Content */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {/* Title row */}
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <VariantName name={variant?.name || 'No variant name'} />
          <Typography variant="subtitle2">{inferenceBatch.id}</Typography>
          <JobStatusChip status={inferenceBatch.status} />
        </Box>
        {/* Details row */}
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <DataChip dataInfo={dataInfo} maxLen={30} />
          <Typography variant="subtitle1">
            Created at: {inferenceBatch.createdAt.toLocaleString()}
          </Typography>
        </Box>
      </Box>
      {/* Actions */}
      {renderActions()}
    </Container>
  );
}
