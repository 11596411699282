export function capitalize(s: string): string {
  return s[0].toUpperCase() + s.substring(1);
}

export function uppercase(s: string): string {
  return s.toLocaleUpperCase();
}

export function isLocalhost(s: string): boolean {
  return s === 'localhost' || s === '127.0.0.1';
}

export function insertBefore(
  wholeStr: Maybe<string>,
  beforeStr: string,
  insertedStr: string,
): string {
  if (!wholeStr) {
    return insertedStr + beforeStr;
  }
  const wholeStrSplit = wholeStr.split(beforeStr, 2);
  if (wholeStrSplit.length > 2) {
    wholeStrSplit.push('');
  }
  return wholeStrSplit.join(insertedStr + beforeStr);
}

export function truncate(s: string, maxLen: number) {
  return s.slice(0, maxLen);
}
