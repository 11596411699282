import { useCallback, useState } from 'react';

import create from 'zustand';

import { nativeRandom, pseudoRandom, RandomGenerator } from 'frontend/utils/random';

export enum RandomMode {
  PseudoRandom = 'PseudoRandom',
  NativeRandom = 'NativeRandom',
}

interface RandomStore {
  // Data
  random: RandomGenerator;
  setRandom: (r: RandomGenerator) => void;
}

export const useRandomStore = create<RandomStore>((set, get) => ({
  random: nativeRandom,
  setRandom: (random: RandomGenerator) => set({ random }),
}));

export default function useRandom() {
  const { random, setRandom } = useRandomStore();

  const setPseudoRandom = useCallback(
    (seed: number | string) => {
      setRandom(pseudoRandom);
      pseudoRandom.setSeed(seed);
    },
    [setRandom],
  );

  const setNativeRandom = useCallback(() => {
    setRandom(nativeRandom);
  }, [setRandom]);

  return {
    random,
    setPseudoRandom,
    setNativeRandom,
  };
}
