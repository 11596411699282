import React, { useMemo } from 'react';

import * as R from 'ramda';

import { Box, MenuItem, Select, styled, Typography } from '@mui/material';
import { GridValidRowModel } from '@mui/x-data-grid';

import { Container } from 'frontend/components/Container';
import GrayDataGrid, { renderExpandableCell } from 'frontend/components/datagrid/GrayDataGrid';

interface DatasetFullGridViewProps {
  rows: GridValidRowModel[];
  columnNames: string[];
  highlightSelected?: boolean;
}

const PadlessSelect = styled(Select)({
  borderRadius: 12,
});

function StatsLabel({ children }: { children: React.ReactNode }) {
  return (
    <Box my={0.5}>
      <Typography variant="overline" sx={{ fontWeight: 'bold', mb: 1.5 }}>
        {children}
      </Typography>
    </Box>
  );
}

function stringsToMenuItem(str: string): JSX.Element {
  return (
    <MenuItem key={str} value={str}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          minWidth: 120,
          maxWidth: 240,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {str}
      </Box>
    </MenuItem>
  );
}

export default function DatasetFullGridView(props: DatasetFullGridViewProps) {
  const { rows, columnNames, highlightSelected } = props;

  // const emptyInputRowCount = useMemo(() => {
  //   if (!inputColumn) {
  //     return;
  //   }
  //   return rows?.reduce((acc, row) => acc + (row[inputColumn] ? 0 : 1), 0);
  // }, [rows, inputColumn]);

  // const avgInputTextLength = useMemo(() => {
  //   if (!inputColumn) {
  //     return;
  //   }
  //   return R.mean(rows?.map(row => row[inputColumn]?.length || 0) || []);
  // }, [rows, inputColumn]);

  // const emptyOutputRowCount = useMemo(() => {
  //   if (!outputColumn) {
  //     return;
  //   }
  //   return rows?.reduce((acc, row) => acc + (row[outputColumn] ? 0 : 1), 0);
  // }, [rows, outputColumn]);

  // const avgOutputTextLength = useMemo(() => {
  //   if (!outputColumn) {
  //     return;
  //   }
  //   return R.mean(rows?.map(row => row[outputColumn]?.length || 0) || []);
  // }, [rows]);

  const gridRows = useMemo(() => {
    return (
      rows.map((row, i) => {
        return {
          id: i,
          ...row,
        };
      }) || []
    );
  }, [rows]);

  const gridColsHeaders = useMemo(
    () =>
      columnNames.map(name => {
        return {
          field: name,
          flex: 1,
          renderCell: renderExpandableCell,
          minWidth: 120,
        };
      }),
    [columnNames, highlightSelected],
  );

  const adjustedPageSize = useMemo(() => {
    // Just do this once, no need to recalculate on layout changes
    if (window.innerHeight > 1100) {
      return 20;
    } else if (window.innerHeight > 900) {
      return 15;
    }
    return 10;
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      {/* <Box */}
      {/*   sx={{ */}
      {/*     display: 'flex', */}
      {/*     flexDirection: 'row', */}
      {/*     justifyContent: 'space-between', */}
      {/*     gap: 2, */}
      {/*   }} */}
      {/* > */}
      {/*   <Container */}
      {/*     sx={{ */}
      {/*       display: 'flex', */}
      {/*       flexDirection: 'row', */}
      {/*       flexGrow: 1, */}
      {/*     }} */}
      {/*   > */}
      {/*     {editable && ( */}
      {/*       <Box> */}
      {/*         <StatsLabel>Define input</StatsLabel> */}
      {/*         <PadlessSelect */}
      {/*           size="small" */}
      {/*           value={inputColumn} */}
      {/*           onChange={e => { */}
      {/*             if (onInputColumnChange) { */}
      {/*               onInputColumnChange(e.target.value as string); */}
      {/*             } */}
      {/*           }} */}
      {/*           disabled={!editable} */}
      {/*         > */}
      {/*           {columnNames.map(stringsToMenuItem)} */}
      {/*         </PadlessSelect> */}
      {/*       </Box> */}
      {/*     )} */}
      {/*     <Box> */}
      {/*       <StatsLabel>No. of empty rows</StatsLabel> */}
      {/*       {emptyInputRowCount != null && ( */}
      {/*         <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'end' }}> */}
      {/*           <Typography variant="h1">{emptyInputRowCount}</Typography> */}
      {/*           <Typography variant="subtitle1">rows</Typography> */}
      {/*         </Box> */}
      {/*       )} */}
      {/*     </Box> */}
      {/*     <Box> */}
      {/*       <StatsLabel>Average text length</StatsLabel> */}
      {/*       {avgInputTextLength != null && ( */}
      {/*         <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'end' }}> */}
      {/*           <Typography variant="h1">{Math.round(avgInputTextLength).toString()}</Typography> */}
      {/*           <Typography variant="subtitle1">characters</Typography> */}
      {/*         </Box> */}
      {/*       )} */}
      {/*     </Box> */}
      {/*   </Container> */}
      {/*   <Container */}
      {/*     sx={{ */}
      {/*       display: 'flex', */}
      {/*       flexDirection: 'row', */}
      {/*       flexGrow: 1, */}
      {/*     }} */}
      {/*   > */}
      {/*     {editable && ( */}
      {/*       <Box> */}
      {/*         <StatsLabel>Define output</StatsLabel> */}
      {/*         <PadlessSelect */}
      {/*           displayEmpty */}
      {/*           size="small" */}
      {/*           value={outputColumn || ''} */}
      {/*           onChange={e => { */}
      {/*             if (onOutputColumnChange) { */}
      {/*               onOutputColumnChange(e.target.value as string); */}
      {/*             } */}
      {/*           }} */}
      {/*           disabled={!editable} */}
      {/*         > */}
      {/*           <MenuItem value=""> */}
      {/*             <em>(none)</em> */}
      {/*           </MenuItem> */}
      {/*           {columnNames.map(stringsToMenuItem)} */}
      {/*         </PadlessSelect> */}
      {/*       </Box> */}
      {/*     )} */}
      {/*     <Box> */}
      {/*       <StatsLabel>No. of empty rows</StatsLabel> */}
      {/*       {emptyOutputRowCount != null && ( */}
      {/*         <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'end' }}> */}
      {/*           <Typography variant="h1">{emptyOutputRowCount}</Typography> */}
      {/*           <Typography variant="subtitle1">rows</Typography> */}
      {/*         </Box> */}
      {/*       )} */}
      {/*     </Box> */}
      {/*     <Box> */}
      {/*       <StatsLabel>Average text length</StatsLabel> */}
      {/*       {avgOutputTextLength != null && ( */}
      {/*         <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'end' }}> */}
      {/*           <Typography variant="h1">{Math.round(avgOutputTextLength).toString()}</Typography> */}
      {/*           <Typography variant="subtitle1">characters</Typography> */}
      {/*         </Box> */}
      {/*       )} */}
      {/*     </Box> */}
      {/*   </Container> */}
      {/* </Box> */}
      <Box
        sx={{
          flexShrink: 1,
        }}
      >
        <GrayDataGrid
          pageSize={adjustedPageSize}
          rows={gridRows}
          columns={gridColsHeaders}
          density="compact"
        />
      </Box>
    </Box>
  );
}
