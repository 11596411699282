import { useCallback, useEffect, useMemo, useState } from 'react';

import _ from 'lodash';
import * as R from 'ramda';
import { useDebounce } from 'react-use';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Chip, Link, Slider, TextField, Tooltip, Typography } from '@mui/material';
import { ModelParametersCreate } from '@scale/llm-shared/interfaces/modelParameters';
import { PromptCreate } from '@scale/llm-shared/interfaces/prompt';
import { GetBaseModelFromInternalId } from '@scale/llm-shared/modelProviders/getModels';
import { BaseModelInternalId } from '@scale/llm-shared/modelProviders/types';
import { parseExampleVariables, parseQueryVariables } from '@scale/llm-shared/templating';
import { logger } from '@scale/llm-shared/utils/logging';

import FlexBox from 'frontend/components/FlexBox';
import ModelSelector from 'frontend/components/model/ModelSelector';
import { VSpace } from 'frontend/components/Spacer';
import { DataSelectorAndUploader } from 'frontend/components/v2/data/DataSelectorAndUploader';
import { useDebounceState } from 'frontend/hooks/useDebounceState';
import { usePromptPageState } from 'frontend/models/v2/usePromptPageState';
import { PromptPresetSelector } from 'frontend/pages/v2/PromptPage/PromptPresetSelector';
import { PromptTaxonomySelectorButton } from 'frontend/pages/v2/PromptPage/PromptTaxonomySelectorButton';
import {
  PromptVariableChip,
  PromptVariableChipProps,
} from 'frontend/pages/v2/PromptPage/PromptVariableChip';
import { useModelStore } from 'frontend/stores/ModelStore';
import { useDataStore } from 'frontend/storesV2/DataStore';
import { StoredData } from 'frontend/storesV2/types';
import theme, { Colors } from 'frontend/theme';
import { track } from 'frontend/utils/analytics';
import { getDataColumnNames } from 'frontend/utils/data';

import { SettingsContainer, SettingsEditor, SettingsHeader } from './PromptParameterSetting';

const TAXONOMY_DISABLED_MODELS: BaseModelInternalId[] = [
  BaseModelInternalId.GPT3_5Turbo0301,
  BaseModelInternalId.GPT3_5Turbo16k,
  BaseModelInternalId.GPT4,
  BaseModelInternalId.GPT4_32K,
];

export function PromptParametersEditor({
  usingFinetunedModel = false,
}: {
  usingFinetunedModel?: boolean;
}) {
  const {
    unsavedPrompt,
    setUnsavedPrompt,
    unsavedModelParameters,
    setUnsavedModelParameters,
    setRecentlySetFromTemplate,
    setUnsavedVariant,
  } = usePromptPageState();

  const { getData } = useDataStore(R.pick(['getData']));
  const { modelId, temperature, maxTokens, stop } = unsavedModelParameters;
  const { variablesSourceDataId } = unsavedPrompt;

  const setUnsavedModelParametersProp = useCallback(
    <K extends keyof typeof unsavedModelParameters>(
      prop: K,
      value: typeof unsavedModelParameters[K],
    ) => {
      // Explicit sets from this page should also flip the 'recentlySet' bit.
      setRecentlySetFromTemplate(false);
      setUnsavedModelParameters(R.set(R.lensProp<Partial<ModelParametersCreate>>(prop), value));
    },
    [setUnsavedModelParameters, setRecentlySetFromTemplate],
  );

  const { modelById } = useModelStore(R.pick(['modelById']));
  const model = useMemo(() => {
    return modelId ? modelById[modelId] : undefined;
  }, [modelId, modelById]);

  const handleSelectModel = useCallback(
    (modelId: string) => {
      // TODO: do something safer like have a set of selectable models and map their names for MenuItem, but find them by ID here
      const model = modelById[modelId];
      if (!model) {
        logger.warn(`Model id ${modelId} not found. No model set.`);
        return;
      }
      track('Prompt Model Selected', { model: modelId, model_name: model.name });
      setUnsavedModelParametersProp('modelId', model.id);
      setUnsavedModelParametersProp('modelType', model.host);
    },
    [setUnsavedModelParametersProp, modelById],
  );

  const modelMaxTokens = useMemo(() => {
    if (!model) {
      logger.warn('No model selected. Defaulting to 2048 model max tokens.');
      return 2048;
    }
    const newMaxTokensLookup =
      GetBaseModelFromInternalId[model.baseModelId as BaseModelInternalId].maxTokensAllowed;
    return newMaxTokensLookup;
  }, [model?.baseModelId]);

  const [variablesSourceData, setVariablesSourceData] = useState<StoredData | null>(null);
  const [promptVariables, setPromptVariables] = useState<PromptVariableChipProps[]>([]);

  const handleSetDataId = useCallback(
    async (dataId: string | null | undefined) => {
      // On dataset change, clear existing example variables
      setUnsavedPrompt(R.set(R.lensProp('exampleVariables'), {}));
      setUnsavedPrompt(R.set(R.lensProp<any>('variablesSourceDataId'), dataId));
      if (dataId) {
        const data = await getData(dataId);
        setVariablesSourceData(data);
      } else {
        setVariablesSourceData(null);
      }
    },
    [setUnsavedPrompt],
  );

  const handleAddVariableToPrompt = useCallback(
    (name: string) => {
      const template = (unsavedPrompt.template || '').trimEnd() + ` {{ ${name} }}`;
      setUnsavedPrompt(R.set(R.lensProp('template'), template));
    },
    [unsavedPrompt.template, setUnsavedPrompt],
  );

  const handleAddExampleVariableToPrompt = useCallback(
    (name: string) => {
      const templateStart = (unsavedPrompt.template || '').trimEnd();
      const variables = parseExampleVariables(templateStart);
      let ii = 0;
      while (variables.includes(`examples[${ii}].${name}`)) {
        ii += 1;
      }
      const template = templateStart + ` {{ examples[${ii}].${name} }}`;
      setUnsavedPrompt(R.set(R.lensProp('template'), template));
    },
    [unsavedPrompt.template, setUnsavedPrompt],
  );

  const dataVariables = useMemo(
    () => getDataColumnNames(variablesSourceData),
    [variablesSourceData],
  );

  useDebounce(
    () => {
      const dataVariablesSet = new Set<string>(dataVariables);
      const queryVariablesSet = new Set<string>();
      if (unsavedPrompt.template) {
        try {
          const queryVariables = parseQueryVariables(unsavedPrompt.template);
          for (const queryVariable of queryVariables) {
            queryVariablesSet.add(queryVariable);
          }
        } catch (err) {
          // Probably an unclosed bracket
        }
      }
      const variables: PromptVariableChipProps[] = [];
      for (const queryVariable of queryVariablesSet) {
        variables.push({
          name: queryVariable,
          isDataVariable: dataVariablesSet.has(queryVariable),
          isPromptVariable: true,
        });
      }
      for (const dataVariable of dataVariablesSet) {
        if (!queryVariablesSet.has(dataVariable)) {
          variables.push({
            name: dataVariable,
            isDataVariable: true,
            isPromptVariable: false,
          });
        }
      }
      setPromptVariables(variables);
    },
    200,
    [unsavedPrompt, setPromptVariables, dataVariables],
  );

  // HACK: Ensure that on page load, we still populate the data variables
  useDebounce(
    () => {
      if (!variablesSourceData && unsavedPrompt.variablesSourceDataId) {
        handleSetDataId(unsavedPrompt.variablesSourceDataId);
      }
    },
    200,
    [handleSetDataId, variablesSourceData, unsavedPrompt.variablesSourceDataId],
  );

  useEffect(() => {
    if (modelId && TAXONOMY_DISABLED_MODELS.includes(modelId as BaseModelInternalId)) {
      setUnsavedVariant(variant => ({
        ...variant,
        taxonomy: undefined,
      }));
    }
  }, [modelId, setUnsavedVariant]);

  return (
    <FlexBox sx={{ flexDirection: 'column', alignItems: 'stretch', gap: 3 }}>
      <SettingsContainer>
        <SettingsHeader
          title="Templates"
          tooltip={
            <>
              Get started quickly by using a pre-defined prompt template or cloning an existing
              variant.
            </>
          }
        />
        <SettingsEditor>
          <PromptPresetSelector
            disabled={usingFinetunedModel}
            dataVariables={dataVariables}
            fullWidth
          />
        </SettingsEditor>
      </SettingsContainer>

      <SettingsContainer>
        <SettingsHeader
          title="Dataset"
          tooltip={
            <>
              Datasets are used to provide few-shot examples to your prompt and for evaluating your
              prompt.
            </>
          }
        />
        <SettingsEditor>
          <DataSelectorAndUploader
            dataId={variablesSourceDataId}
            setDataId={handleSetDataId}
            allowNoDataset
          />
        </SettingsEditor>
      </SettingsContainer>

      <SettingsContainer>
        <SettingsHeader
          title="Variables"
          tooltip={
            <>
              Variables are used to parameterize your prompt. They are pieces of text that can be
              used as stand-ins for different values extracted from your data, or when calling your
              app from the API.
            </>
          }
        />
        <SettingsEditor>
          <FlexBox sx={{ gap: 1 }} flexWrap="wrap">
            {promptVariables.map(variable => (
              <PromptVariableChip
                key={variable.name + variable.isDataVariable + variable.isPromptVariable}
                {...variable}
                isLinkRequired={!!variablesSourceData}
                handleAddVariableToPrompt={handleAddVariableToPrompt}
                handleAddExampleVariableToPrompt={handleAddExampleVariableToPrompt}
              />
            ))}
          </FlexBox>
        </SettingsEditor>
      </SettingsContainer>

      {(!modelId || !TAXONOMY_DISABLED_MODELS.includes(modelId as BaseModelInternalId)) && (
        <SettingsContainer>
          <SettingsHeader
            title="Output Taxonomy"
            tooltip={<>A taxonomy can be used to constrain the output of your variants.</>}
          />
          <SettingsEditor>
            <PromptTaxonomySelectorButton />
          </SettingsEditor>
        </SettingsContainer>
      )}

      <SettingsContainer>
        <SettingsHeader
          title="Model"
          tooltip={
            <>
              Different models will have different behaviors, as well as different levels of quality
              and cost.
              <Box>
                <Link href="https://spellbook.readme.io/docs/base-model-guide" target="_blank">
                  See our Base Model Guide
                </Link>
              </Box>
            </>
          }
        />
        <SettingsEditor>
          <ModelSelector selectedModelId={modelId} onChange={handleSelectModel} />
        </SettingsEditor>
      </SettingsContainer>

      <TemperatureSetting
        temperature={temperature}
        setTemperature={val => setUnsavedModelParametersProp('temperature', val)}
        maxTemperature={model && model.host === 'OpenAi' ? 2 : 1}
      />

      <MaxTokensSetting
        modelMaxTokens={modelMaxTokens}
        maxTokens={maxTokens}
        setMaxTokens={val => setUnsavedModelParametersProp('maxTokens', val)}
      />

      <SettingsContainer>
        <SettingsHeader
          title="Stop Sequence"
          tooltip={
            <>
              A sequence that will tell the API when to stop generating further tokens. The returned
              text will not contain the stop sequence.
            </>
          }
        />
        <SettingsEditor pl={1}>
          <TextField
            value={stop}
            onChange={e => {
              setUnsavedModelParametersProp('stop', e.target.value);
            }}
            size="small"
          />
        </SettingsEditor>
      </SettingsContainer>
    </FlexBox>
  );
}

function TemperatureSetting({
  temperature,
  setTemperature,
  maxTemperature = 1,
}: {
  temperature: number | undefined;
  setTemperature: (value: number) => void;
  maxTemperature?: number;
}) {
  const [editedTemperature, setEditedTemperature] = useState<number | undefined>(temperature ?? 0);
  useDebounce(
    () => {
      if (editedTemperature == null) {
        return;
      }
      setTemperature(editedTemperature);
    },
    500,
    [editedTemperature],
  );

  useEffect(() => {
    if (editedTemperature == null || editedTemperature <= maxTemperature) {
      return;
    }
    setEditedTemperature(maxTemperature);
    setTemperature(maxTemperature);
  }, [maxTemperature]); // no dep on editedTemperature, only update when maxTemperature changes (model switched)

  useEffect(() => {
    setEditedTemperature(temperature);
  }, [temperature]);

  return (
    <Box>
      <SettingsHeader
        title="Temperature"
        tooltip={
          <>
            Higher temperatures will produce more random results. Setting the temperature to zero
            will make the results deterministic.
          </>
        }
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            '& svg': {
              marginRight: 1,
            },
          }}
        >
          {maxTemperature > 1 && (temperature ?? 0) > 1 && (
            <Tooltip title="Temperatures greater than 1 can lead to gibberish results!">
              <FontAwesomeIcon icon="warning" color={Colors.RemoGold} />
            </Tooltip>
          )}
          <TextField
            type="number"
            size="small"
            value={editedTemperature}
            inputProps={{
              inputMode: 'numeric',
              min: 0.0,
              max: maxTemperature,
              step: 0.01,
              sx: {
                padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
                fontSize: 12,
              },
            }}
            onChange={e => {
              if (e.target.value === '') {
                setEditedTemperature(undefined);
                return;
              }
              const val = Number.parseFloat(e.target.value);
              if (val < 0 || val !== val) {
                return;
              }
              setEditedTemperature(val);
            }}
            onFocus={e => e.target.select()}
            onBlur={() => {
              if (editedTemperature == null) {
                setEditedTemperature(temperature ?? 0);
                return;
              }
              setEditedTemperature(Math.min(maxTemperature, editedTemperature));
            }}
          />
        </Box>
      </SettingsHeader>
      <SettingsContainer>
        <SettingsEditor>
          <Box pr={1}>
            <Slider
              color={'info' as any}
              value={editedTemperature ?? temperature}
              onChange={(_, val) => setEditedTemperature(val as number)}
              step={0.01}
              min={0}
              max={maxTemperature}
              size="medium"
            />
          </Box>
        </SettingsEditor>
      </SettingsContainer>
    </Box>
  );
}

const TOKEN_STEPS = [10, 25, 50, 100, 250, 512, 1024, 2048, 4096, 8192, 16384, 32768];

function sliderPositionToMaxTokens(pos: number) {
  return TOKEN_STEPS[pos];
}
function maxTokensToSliderPosition(maxTokens: number) {
  if (maxTokens < TOKEN_STEPS[0]) {
    return 0;
  }
  for (let pos = 0; pos < TOKEN_STEPS.length - 1; pos++) {
    if (maxTokens >= TOKEN_STEPS[pos] && maxTokens < TOKEN_STEPS[pos + 1]) {
      return pos;
    }
  }
  return TOKEN_STEPS.length - 1;
}
// calculate up to what element to include in slider
function totalTokensToInclude(maxTokens: number) {
  for (let pos = 0; pos < TOKEN_STEPS.length; pos++) {
    if (maxTokens <= TOKEN_STEPS[pos]) {
      return pos;
    }
  }
  return TOKEN_STEPS.length;
}

function MaxTokensSetting({
  modelMaxTokens,
  maxTokens,
  setMaxTokens,
}: {
  modelMaxTokens: number;
  maxTokens: number | undefined;
  setMaxTokens: (value: number) => void;
}) {
  const [editedMaxTokens, setEditedMaxTokens] = useState<number | undefined>(maxTokens ?? 0);
  const [sliderPos, setSliderPos] = useState<number>(maxTokens ?? 0);
  useDebounce(
    () => {
      if (editedMaxTokens == null) {
        return;
      }
      setMaxTokens(editedMaxTokens);
    },
    500,
    [editedMaxTokens],
  );

  // keep token count below maximum when changing models
  useDebounce(
    () => {
      if (editedMaxTokens == null) {
        return;
      }
      setEditedMaxTokens(Math.min(editedMaxTokens, modelMaxTokens));
    },
    500,
    [editedMaxTokens, modelMaxTokens],
  );

  const setEditedMaxTokensAndSliderPos = useCallback(
    (val: number) => {
      setEditedMaxTokens(val);
      setSliderPos(maxTokensToSliderPosition(val));
    },
    [setEditedMaxTokens, setSliderPos],
  );

  useEffect(() => {
    if (maxTokens !== undefined) {
      setEditedMaxTokensAndSliderPos(maxTokens);
    }
  }, [maxTokens]);

  const maxTokensIncludedForSlider = useMemo(
    () => totalTokensToInclude(modelMaxTokens),
    [modelMaxTokens],
  );

  return (
    <SettingsContainer>
      <SettingsHeader
        title="Maximum Tokens"
        tooltip={
          <>
            The maximum number of tokens to be consumed. Requests can use up to 2,048 or 4,000
            tokens shared between prompt and completion. The exact limit varies by model.
            {maxTokens && (
              <Box sx={{ mt: 1, fontWeight: 500 }}>
                {maxTokens} tokens is approximately {Math.floor(maxTokens * 0.75)} words.
              </Box>
            )}
          </>
        }
      >
        <TextField
          type="number"
          size="small"
          value={editedMaxTokens}
          inputProps={{
            inputMode: 'numeric',
            min: 0,
            max: modelMaxTokens,
            sx: {
              padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
              fontSize: 12,
            },
          }}
          onChange={e => {
            if (e.target.value === '') {
              setEditedMaxTokens(undefined);
              return;
            }
            const val = Number.parseInt(e.target.value);
            if (val < 0 || val !== val) {
              return;
            }
            setEditedMaxTokensAndSliderPos(val);
          }}
          onFocus={e => e.target.select()}
          onBlur={() => {
            if (editedMaxTokens == null) {
              setEditedMaxTokensAndSliderPos(maxTokens ?? 10);
              return;
            }
            setEditedMaxTokensAndSliderPos(Math.min(modelMaxTokens, editedMaxTokens));
          }}
        />
      </SettingsHeader>
      <SettingsEditor>
        <Box pr={1}>
          <Slider
            value={sliderPos}
            onChange={(_, val) => {
              setEditedMaxTokens(sliderPositionToMaxTokens(val as number));
              setSliderPos(val as number);
            }}
            color={'info' as any}
            step={1}
            min={0}
            max={maxTokensIncludedForSlider}
            scale={sliderPositionToMaxTokens}
            size="medium"
            marks
          />
        </Box>
      </SettingsEditor>
    </SettingsContainer>
  );
}
