import * as R from 'ramda';

import Alert from '@mui/material/Alert';
import Snackbar, { SnackbarOrigin, SnackbarProps } from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';

import { ErrorMessage } from 'frontend/components/ErrorMessage';
import { createFromStates, state } from 'frontend/utils/store';

export function GlobalSnackbar() {
  const { props, children } = useGlobalSnackbarStore();
  return (
    <Snackbar
      open={props.open}
      autoHideDuration={props.autoHideDuration}
      anchorOrigin={props.anchorOrigin}
    >
      {children}
    </Snackbar>
  );
}

const states = [
  state('props', {} as SnackbarProps),
  state('children', undefined as JSX.Element | undefined),
];
type Store = UnionToIntersection<ReturnType<typeof states[number]>>;

export const useGlobalSnackbarStore = createFromStates<Store>(states);

export function showGlobalSnackbarError(error?: any) {
  const { setProps, setChildren } = useGlobalSnackbarStore.getState();

  setChildren(
    <Alert
      severity="error"
      onClose={() => setProps(R.set(R.lensProp('open'), false))}
      sx={{ alignItems: 'center' }}
    >
      <ErrorMessage error={error} />
    </Alert>,
  );
  setProps({
    open: true,
    autoHideDuration: undefined,
    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
  });
}
