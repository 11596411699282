import * as R from 'ramda';
import create, { UseBoundStore } from 'zustand';
import { persist, subscribeWithSelector } from 'zustand/middleware';

import { capitalize } from '@scale/llm-shared/utils/string';

import { LOCAL_STORAGE_STORE_OPTIONS } from 'frontend/clientSideStorage';

/**
 * Adds fn to the store to be called when hydration completes. If the hydration has already completed, fn will be called directly
 */
export function ifOrOnFinishHydration(store: UseBoundStore<any>, fn: () => void) {
  if (store.persist.hasHydrated()) {
    fn();
  }
  return store.persist.onFinishHydration(fn);
}

type StateOrDispatch<T> = T | ((state: T) => T);

export function setStateAction<T extends Record<string, any>, K extends keyof T>(
  set: (state: StateOrDispatch<T>) => void,
  prop: K,
  { onSet }: { onSet?: (state: T[K]) => T[K] } = {},
) {
  return function (stateOrSetState: T[K] | ((state: T[K]) => T[K])) {
    const mapper = typeof stateOrSetState === 'function' ? R.over : R.set;
    const fn = onSet ? R.pipe(onSet, stateOrSetState) : stateOrSetState;
    return set(mapper(R.lensProp(prop), fn));
  };
}

/**
 * Represents a state object with
 *  {
 *    [key]: value;
 *    [setKey]: setter for value;
 *  }
 */
type ValueAndSetter<K extends string, T> = Record<K, T> &
  Record<`set${Capitalize<K>}`, (state: T | ((state: T) => T)) => void>;

/**
 * Zustand mini state factory.
 * @param k name of the value
 * @param defaultValue default value of the value
 * @returns a function that requires a Zustand `set` and returns a mini Zustand store def
 *  with `k` as the property key and `set${k}` as the setter. e.g.
 *    params:
 *      k = 'modalOpen', defaultValue = false
 *    creates:
 *      {
 *        modalOpen: false,
 *        setModalOpen: modalOpen => set({ modalOpen }),
 *      }
 */
export function state<K extends string, T>(k: K, defaultValue: T) {
  return function (set: (partial: StateOrDispatch<ValueAndSetter<K, T>>) => void) {
    return {
      [k]: defaultValue,
      [`set${capitalize(k)}`]: setStateAction(set, k),
    } as ValueAndSetter<K, T>;
  };
}

/**
 * Creates a Zustand store from a list of mini states.
 *
 * Note(arno): Couldn't figure out how to do the generics correctly here.
 */
export function createFromStates<T extends Record<string, any>>(states: any[]) {
  return create<T>()(subscribeWithSelector(set => R.mergeAll(states.map(s => s(set))) as T));
}

/**
 * Creates a Zustand store from a list of mini states with storage.
 *
 * Note(arno): Couldn't figure out how to do the generics correctly here.
 */
export function createPersistFromStates<T extends Record<string, any>>(states: any[]) {
  return create<T>()(
    subscribeWithSelector(
      persist(set => R.mergeAll(states.map(s => s(set))) as T, {
        ...LOCAL_STORAGE_STORE_OPTIONS('settings'),
      }),
    ),
  );
}
