import { Box, Typography } from '@mui/material';

import { EvaluationWizardCell } from 'frontend/components/evaluation/EvaluationWizardLayout';
import { VSpace } from 'frontend/components/Spacer';

export function EvaluationWizardSetupHeader(inputs: { canPickVariantAndDataset: boolean }) {
  const { canPickVariantAndDataset } = inputs;
  return (
    <>
      {/* Left */}
      <EvaluationWizardCell>
        <Typography variant="h2">Select Evaluation Type</Typography>
        <VSpace s={0.3} />
        <Typography>Choose how you would want to evaluate your variants.</Typography>
      </EvaluationWizardCell>
      {/* Right */}
      <EvaluationWizardCell>
        <Typography variant="h2">Set Evaluation Configs</Typography>
        {canPickVariantAndDataset && (
          <>
            <VSpace s={0.3} />
            <Typography>Select your dataset and variants to evaluate</Typography>
          </>
        )}
      </EvaluationWizardCell>
    </>
  );
}
