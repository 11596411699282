import { useEffect, useRef } from 'react';

import { useForceUpdate } from 'frontend/hooks/useForceUpdate';

const ref: Record<string, { var: string, passed: boolean, code: string }> = {};

/**
 * Returns true if a secret code has been typed out once.
 * @param name name of the session, e.g. feature-flags
 * @param code the secret code a user has to type out
 */
export function useCodePassed(name: string, code: string) {
  const forceUpdate = useForceUpdate();

  if (!ref[name]) {
    ref[name] = { var: '', passed: false, code };
  }

  ref[name].code = code;

  useEffect(() => {
    const keyLogger = (e: KeyboardEvent) => {
      // If passed, short circuit
      if (ref[name].passed) {
        return;
      }
      // Add tracked key
      ref[name].var += e.key;
      // String match check if passed
      if (ref[name].var.endsWith(ref[name].code)) {
        ref[name].passed = true;
        forceUpdate();
        document.removeEventListener('keypress', keyLogger);
      }
      // Truncate var if too long
      if (ref[name].var.length > ref[name].code.length ** 2) {
        ref[name].var = ref[name].var.slice(ref[name].var.length - ref[name].code.length);
      }
    };
    document.addEventListener('keypress', keyLogger);
    return () => document.removeEventListener('keypress', keyLogger);
  }, []);

  return ref[name].passed;
}
