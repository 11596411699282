import * as R from 'ramda';
import { useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Box, Button, CircularProgress, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import FlexBox from 'frontend/components/FlexBox';
import { DataTable } from 'frontend/components/v2/data/DataTable';
import { DataUploadModal } from 'frontend/components/v2/data/DataUploadModal';
import { useLLMNavigation } from 'frontend/hooks/useLLMNavigation';
import { useDatasetPageState } from 'frontend/models/v2/useDatasetPageState';
import PageContainer from 'frontend/pages/PageContainer/PageContainer';
import { DataActionsButton } from 'frontend/pages/v2/EvaluationSetsPage/DataActionsButton';
import { EvaluationSetTableView } from 'frontend/pages/v2/EvaluationSetsPage/EvaluationSetTableView';
import { useDataStore } from 'frontend/storesV2/DataStore';

import { DatasetArchiveModal } from './DatasetArchiveModal';
import { DatasetRenameModal } from './DatasetRenameModal';

export function EvaluationSetsPage() {
  const { getData, dataIsHydrated, dataInfoById } = useDataStore(
    R.pick(['getData', 'dataInfoById', 'dataIsHydrated']),
  );
  const { goToDataPage } = useLLMNavigation();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const dataId = params.get('dataId');

  const dataRes = useQuery(['getData', dataId], async () => {
    return dataId ? getData(dataId) : null;
  });
  const dataInfo = dataId ? dataInfoById[dataId] : null;
  const dataset = dataRes.data || undefined;

  const { isModalOpen, setIsModalOpen } = useDatasetPageState();

  return (
    // TODO: upload zone if actually no datasets (but not if loading datasets)
    <PageContainer page="data" noRequireApp>
      {dataInfo ? (
        <Box>
          <Button sx={{ ml: -1 }} onClick={() => goToDataPage()}>
            <FontAwesomeIcon icon="chevron-left" style={{ marginRight: '1ex' }} />
            Back
          </Button>
          <FlexBox mb={2} sx={{ alignItems: 'flex-end', justifyContent: 'space-between' }}>
            <Typography variant="h1">{dataInfo.name}</Typography>
            <Box>
              <DataActionsButton dataId={dataInfo.id} />
            </Box>
          </FlexBox>
        </Box>
      ) : (
        <Box>
          <FlexBox mt={4} mb={2} sx={{ alignItems: 'flex-end', justifyContent: 'space-between' }}>
            <Typography variant="h1">Evaluation Sets</Typography>
            <Box>
              <Button
                onClick={() => setIsModalOpen(true)}
                size="medium"
                variant="contained"
                fullWidth
              >
                <FontAwesomeIcon icon="upload" style={{ marginRight: '1ex' }} />
                Upload File
              </Button>
            </Box>
          </FlexBox>
        </Box>
      )}
      <DataUploadModal open={isModalOpen} setOpen={setIsModalOpen} setDataId={goToDataPage} />
      <DatasetRenameModal />
      <DatasetArchiveModal />
      {dataIsHydrated && !dataId && <EvaluationSetTableView />}
      {dataId && !dataset && (
        <Box my={2}>
          <Alert variant="outlined" color="info" icon={false}>
            <FlexBox>
              <CircularProgress size={12} />
              Loading dataset from the server...
            </FlexBox>
          </Alert>
        </Box>
      )}
      {dataId && dataset && (
        <>
          <DataTable dataset={dataset} />
        </>
      )}
    </PageContainer>
  );
}
