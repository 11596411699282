import { Alert, Box } from '@mui/material';

export default function PromptErrorMessage({ promptError }: { promptError: string | null }) {
  if (!promptError) {
    return null;
  }
  return (
    <Box my={1}>
      <Alert severity="error">{promptError}</Alert>
    </Box>
  );
}
