import { BaseModelInternalId } from '@scale/llm-shared/modelProviders/types';

export interface IPromptPreset {
  title: string;
  prompt: string;
}

export const RESPONSIVE_WIDTH_THRESHOLD = 1024;
export const MOBILE_WIDTH_THRESHOLD = 650;

export const MODEL_OUTPUT_SUCCESS_ID = 'model-output-success';
export const MODEL_OUTPUT_ERROR_ID = 'model-output-error';
export const NEWLINE_HTML = '<br>';

export const QUERY_INPUT_PLACEHOLDER = 'Your Input Here';
export const QUERY_INPUT_ID = 'query-input';

export const PRESET_CHANGE_CONFIRMATION_TEXT =
  'Changing presets will discard any unsaved changes, do you want to continue?';

export const PROMPT_PRESETS: IPromptPreset[] = [
  {
    title: 'Question Answer',
    prompt: `
      <div>Prompt: Ask me a question and I will provide an answer.</div>
      <br>
      <div>Input: What is the capital of France?</div>
      <div>Output: The capital of France is Paris.</div>
      <br>
      <div>Input: What is the population of the United States?</div>
      <div>Output: The population of the United States is approximately 328.2 million people.</div>
      <br>
      <div>Input: What is the fastest land animal?</div>
      <div>Output: The fastest land animal is the cheetah, which can reach speeds of up to 75 mph.</div>
      <br>
      <div>Input: What is the longest river in the world?</div>
      <div>Output: The longest river in the world is the Nile, which is 4,132 miles long.</div>
      <br>
      <div>Input: What is the average lifespan of a human?</div>
      <div>Output: The average lifespan of a human is approximately 78.7 years.</div>
      <br>
      <div>Input: <span id="${QUERY_INPUT_ID}">${QUERY_INPUT_PLACEHOLDER}</span></div>
      <div>Output:</div>`,
  },
  {
    title: 'Translate English to Spanish',
    prompt: `
      <div>Prompt: Please provide an English phrase and I will translate it to Spanish.</div>
      <br>
      <div>Input: How are you?</div>
      <div>Output: ¿Cómo estás?</div>
      <br>
      <div>Input: What is your name?</div>
      <div>Output: ¿Cuál es tu nombre?</div>
      <br>
      <div>Input: I love you</div>
      <div>Output: Te amo</div>
      <br>
      <div>Input: What time is it?</div>
      <div>Output: ¿Qué hora es?</div>
      <br>
      <div>Input: Goodbye</div>
      <div>Output: Adiós</div>
      <br>
      <div>Input: <span id="${QUERY_INPUT_ID}">${QUERY_INPUT_PLACEHOLDER}</span></div>
      <div>Output:</div>`,
  },
  {
    title: 'Convert English to SQL',
    prompt: `
      <div>Prompt: Convert natural language english to SQL queries</div>
      <br>
      <div>Input: Show me all the books with more than 500 pages</div>
      <div>Output: SELECT * FROM books WHERE pages > 500;</div>
      <br>
      <div>Input: Give me the authors of books published in the last 10 years</div>
      <div>Output: SELECT author FROM books WHERE published > DATE_SUB(NOW(), INTERVAL 10 YEAR);</div>
      <br>
      <div>Input: Get me the titles and authors of books published before 2005</div>
      <div>Output: SELECT title, author FROM books WHERE published < 2005;</div>
      <br>
      <div>Input: Find me all the books with fewer than 200 pages</div>
      <div>Output: SELECT * FROM books WHERE pages < 200;</div>
      <br>
      <div>Input: Give me the titles of books with more than 300 pages</div>
      <div>Output: SELECT title FROM books WHERE pages > 300;</div>
      <br>
      <div>Input: <span id="${QUERY_INPUT_ID}">${QUERY_INPUT_PLACEHOLDER}</span></div>
      <div>Output:</div>`,
  },
  {
    title: 'Write Marketing Outreach Email Template',
    prompt: `
      <div>Prompt: Write a marketing outreach email template that can be used to reach out to potential customers.</div>
      <br>
      <div>Input: Hi, my name is [Name] and I'm the [Position] of [Company].</div>
      <div>Output: Hi [Name], </div>
      <div>I'm [Position] of [Company], and I'm reaching out to you because I think our products and services could be of great value to you. We offer [list products/services], and I'm sure you could benefit from at least one of them.</div>
      <div>I'd love to schedule a call or video conference to discuss how our products and services could help you. Please let me know what works best for you.</div>
      <br>
      <div>Input: I am [Name] from [Company] and I am interested in [Product/Service].</div>
      <div>Output: Hi [Name],</div>
      <div>I'm glad you're interested in [Product/Service]! We offer a wide range of [Product/Service] and I'm sure you'll find one that meets your needs.</div>
      <div>I'd love to schedule a call or video conference to discuss how our [Product/Service] could help you. Please let me know what works best for you.</div>
      <br>
      <div>Input: I am looking for a [Product/Service] that can help me [Task].</div>
      <div>Output: Hi there,</div>
      <div>I understand you're looking for a [Product/Service] that can help you [Task]. We offer a wide range of [Product/Service] and I'm sure you'll find one that meets your needs.</div>
      <div>I'd love to schedule a call or video conference to discuss how our [Product/Service] could help you with [Task]. Please let me know what works best for you.</div>
      <br>
      <div>Input: I am interested in learning more about [Product/Service].</div>
      <div>Output: Hi there,</div>
      <div>I'm glad you're interested in learning more about our [Product/Service]! We offer a wide range of [Product/Service] and I'm sure you'll find one that meets your needs.</div>
      <div>I'd love to schedule a call or video conference to discuss how our [Product/Service] could help you. Please let me know what works best for you.</div>
      <br>
      <div>Input: What services do you offer?</div>
      <div>Output: Hi there,</div>
      <div>We offer a wide range of services, including [list services]. We specialize in [list specialties], and are confident that our services can help you achieve your goals.</div>
      <div>I'd love to schedule a call or video conference to discuss how our services could help you. Please let me know what works best for you.</div>
      <div>Input: <span id="${QUERY_INPUT_ID}">${QUERY_INPUT_PLACEHOLDER}</span></div>
      <div>Output:</div>`,
  },
  {
    title: 'Write a Haiku',
    prompt: `
      <div>Prompt: Write a haiku that captures the beauty of nature.</div>
      <br>
      <div>Input: A crisp autumn morning</div>
      <div>Output: The sun peeks through the trees,<br/>A chill in the air, autumn comes alive.</div>
      <br>
      <div>Input: A wildflower blooms</div>
      <div>Output: A single petal blooms,<br/>Bright and vibrant in the sun,<br/>Nature's beauty revealed.</div>
      <br>
      <div>Input: A summer sunset</div>
      <div>Output: Colors of the sky,<br/>Vibrant oranges and pinks,<br/>A beautiful sight.</div>
      <br>
      <div>Input: A gentle breeze</div>
      <div>Output: Softly rustling leaves,<br/>A gentle breeze on my skin,<br/>Calm and peaceful still.</div>
      <br>
      <div>Input: A snow-covered scene</div>
      <div>Output: A blanket of white,<br/>Softly covering the ground,<br/>Beauty in its purest form.</div>
      <br>
      <div>Input: <span id="${QUERY_INPUT_ID}">${QUERY_INPUT_PLACEHOLDER}</span></div>
      <div>Output:</div>`,
  },
  {
    title: 'Python Interview Question Generator',
    prompt: `
      <div>Prompt: What is the best way to answer a Python programming interview question?</div>
      <br>
      <div>Input: What is the difference between a list and a tuple?</div>
      <div>Output: The main difference between a list and a tuple is that a list is mutable (i.e. it can be changed) whereas a tuple is immutable (i.e. it cannot be changed).</div>
      <br>
      <div>Input: What is the purpose of a function in Python?</div>
      <div>Output: The purpose of a function in Python is to group together related code and allow it to be executed multiple times without having to rewrite it each time. Functions can also accept parameters which can be used to customize the behavior of the function.</div>
      <br>
      <div>Input: How do you create a dictionary in Python?</div>
      <div>Output: To create a dictionary in Python, you use the dict() constructor or the {} curly braces. The dict() constructor takes a list of key-value pairs as an argument and returns a dictionary. The {} curly braces take a comma-separated list of key-value pairs and return a dictionary.</div>
      <br>
      <div>Input: What is the difference between a for loop and a while loop in Python?</div>
      <div>Output: The main difference between a for loop and a while loop in Python is that a for loop iterates over a sequence of items, while a while loop executes until a given condition is met. A for loop is used when you know the exact number of iterations you need to perform, while a while loop is used when you don't know how many iterations you need to perform.</div>
      <br>
      <div>Input: What is the syntax for a try-except statement in Python?</div>
      <div>Output: The syntax for a try-except statement in Python is as follows:<br/><br/>try:<br/>    # code to try<br/>except ExceptionType:<br/>    # code to execute if an exception is raised</div>
      <br>
      <div>Input: <span id="${QUERY_INPUT_ID}">${QUERY_INPUT_PLACEHOLDER}</span></div>
      <div>Output:</div>`,
  },
];

export const MODELS: BaseModelInternalId[] = [BaseModelInternalId.Flan_XXL];
