import * as R from 'ramda';

import { Model, ModelHost } from '@prisma/client';
import { FrontendFeatureFlag } from '@scale/llm-shared/featureFlags';
import { AnthropicBaseModelInternalId } from '@scale/llm-shared/modelProviders/anthropic';

import { EVALUATION_PAGE_TITLE, NavigationPage } from 'frontend/components/navigation';
import { UserBooleanFeatureFlags } from 'frontend/stores/UserStore';
import { StoredVariant } from 'frontend/storesV2/types';

/**
 * Shape of feature flags
 * @param name name of the feature flag
 * @param description description of the feature flag
 * @param enabled whether or not feature flag is enabled
 * @param hidden hides the flag in the UI forever
 */
type FeatureFlag = {
  name?: string;
  description?: string;
  enabled: boolean;
  hidden?: boolean;
};

const ANTHROPIC_MODEL_IDS = [
  AnthropicBaseModelInternalId.ClaudeV1,
  AnthropicBaseModelInternalId.ClaudeInstantV1,
];

export const FEATURE_FLAG = {
  hideModels: new (class implements FeatureFlag {
    name = 'Hide models';
    description = 'Feature flag to limit which foundation models are NOT available to pick from';
    enabled = true;
    hidden = false;
    /**
     * Add or remove model ids here
     */
    filteredIds = new Set<string>(['GPT3TextDavinci001', 'GPT4_32K', ...ANTHROPIC_MODEL_IDS]);
    /**
     * Function that returns if a model should be filtered out or not
     */
    predicate: (model: Model) => boolean = model =>
      this.enabled ? !this.filteredIds.has(model.id) : true;
    /**
     * Function that filters list of models
     */
    filter: (models: Model[]) => Model[] = R.filter<Model>(this.predicate);
    /**
     * Function that filters a map of Record<string, Model>
     */
    omit: (modelMap: SRecord<Model>) => SRecord<Model> = R.pickBy<Model>(
      this.predicate,
    ) as Identity<SRecord<Model>>;
  })(),
  filterFineTuningModels: new (class implements FeatureFlag {
    name = 'Filter fine tunable models';
    description = 'Feature flag to limit which base models are NOT fine tunable';
    enabled = true;
    /**
     *  Add or remove model ids here
     */
    filteredIds = new Set<string>([
      'GPT3TextDavinci002',
      'GPT3TextDavinci003',
      'GPT3_5Turbo16k',
      'GPT4',
      'GPT4_32K',
      'Dolly2',
    ]);
    /**
     * Add or remove model hosts here
     */
    filteredHosts = new Set<ModelHost>([ModelHost.Ai21, ModelHost.Cohere]);
    /**
     * Function that returns if a model should be filtered out or not
     */
    predicate = (model: Model) =>
      this.enabled ? !this.filteredIds.has(model.id) && !this.filteredHosts.has(model.host) : true;
    /**
     * Function that filters list of models
     */
    filter = R.filter<Model>(this.predicate);
    /**
     * Function that filters a map of Record<string, Model>
     */
    omit = R.pickBy<Model>(this.predicate) as Identity<SRecord<Model>>;
  })(),
  evalDisabledModels: new (class implements FeatureFlag {
    name = 'Disable models from evaluating';
    description = 'Disables some models from being run as part of an evaluation';
    enabled = true;
    /**
     * Add or remove model ids here
     */
    filteredIds = new Set<string>(['GPT4', 'GPT4_32K']);
    /**
     * Function that returns if a variant should be filtered out or not based on model
     */
    predicate = (variant: StoredVariant) =>
      this.enabled && this.filteredIds.has(variant.modelParameters.modelId);
    /**
     * Function that filters list of variants
     */
    filter = R.filter<StoredVariant>(this.predicate);
  })(),
  enableAiFeedback: new (class implements FeatureFlag {
    name = 'Allow AI feedback in evaluations';
    enabled = false;
  })(),
};

/**
 * Changes feature flags by reflecting Unleash feature flags
 */
export function loadBooleanFeatureFlags(booleanFeatureFlags: UserBooleanFeatureFlags) {
  if (booleanFeatureFlags['can-use-spellbook-gpt-4-eval']) {
    FEATURE_FLAG.evalDisabledModels.filteredIds.delete('GPT4');
    FEATURE_FLAG.evalDisabledModels.filteredIds.delete('GPT4_32K');
  }
  if (booleanFeatureFlags['can-use-spellbook-ai-feedback']) {
    FEATURE_FLAG.enableAiFeedback.enabled = true;
  }
  if (booleanFeatureFlags[FrontendFeatureFlag.CanUseAnthropic]) {
    const filteredIdsNoAnthropic = Array.from(FEATURE_FLAG.hideModels.filteredIds).filter(
      id => !ANTHROPIC_MODEL_IDS.includes(id as AnthropicBaseModelInternalId),
    );
    FEATURE_FLAG.hideModels.filteredIds = new Set<string>(filteredIdsNoAnthropic);
  }
}

export const FEATURE_FLAGS: FeatureFlag[] = Object.values(FEATURE_FLAG);

type Identity<T> = (val: T) => T;
type SRecord<T> = Record<string, T>;
type ARecord<T> = T[];
