import React, { useEffect, useRef, useState } from 'react';

import axios from 'axios';
import { HotKeys } from 'react-hotkeys';

import { ThemeProvider } from '@mui/material/styles';
import { GetBaseModelFromInternalId } from '@scale/llm-shared/modelProviders/getModels';
import { BaseModelInternalId } from '@scale/llm-shared/modelProviders/types';

import { absoluteApiHref } from 'frontend/api/trpc';
import FlexBox from 'frontend/components/FlexBox';
import Header from 'frontend/components/playground/Header';
import Layout from 'frontend/components/playground/Layout';
import PromptField from 'frontend/components/playground/PromptField';
import SettingsDialog from 'frontend/components/playground/SettingsDialog';
import SettingsUpdatedSnackbar from 'frontend/components/playground/SettingsUpdatedSnackbar';
import Sidebar from 'frontend/components/playground/Sidebar';
import {
  IPromptPreset,
  MODEL_OUTPUT_ERROR_ID,
  MODEL_OUTPUT_SUCCESS_ID,
  MODELS,
  PRESET_CHANGE_CONFIRMATION_TEXT,
  PROMPT_PRESETS,
  QUERY_INPUT_ID,
  RESPONSIVE_WIDTH_THRESHOLD,
} from 'frontend/consts/playground';
import {
  getQueryInputHTML,
  insertHighlightStyle,
  stripHighlightedOutputStyle,
  stripHTML,
} from 'frontend/helpers/playground';
import useWindowSize from 'frontend/hooks/useWindowSize';
import theme, { Colors } from 'frontend/theme';

export default function PlaygroundPage() {
  const [width, _] = useWindowSize();
  const [baseModel, setBaseModel] = useState<BaseModelInternalId>(MODELS[0]);

  const [prompt, setPrompt] = useState<string>('');
  const promptRef = useRef(prompt);
  const [selectedPromptPreset, setSelectedPromptPreset] = useState<IPromptPreset | undefined>();
  const [isQueryInputModified, setIsQueryInputModified] = useState(false);

  const setPromptWithRef = (newPrompt: string) => {
    setPrompt(newPrompt);
    promptRef.current = newPrompt;
  };

  const [modelOutput, setModelOutput] = useState<string>('');
  const [isModelOutputModified, setIsModelOutputModified] = useState(false);
  const [promptBeforeModelOutput, setPromptBeforeModelOutput] = useState<string>('');

  const [maxTokens, setMaxTokens] = useState<number>(256);
  const [temperature, setTemperature] = useState<number>(0.7);
  const [stopSequence, setStopSequence] = useState<string>('');

  const [isLoading, setIsLoading] = useState(false);
  const [isSettingsDialogOpen, setIsSettingsDialogOpen] = useState(false);
  const [isUpdatedSnackbarOpen, setIsUpdatedSnackbarOpen] = useState(false);
  const [isModelOutputError, setIsModelOutputError] = useState(false);

  const minTemperature = 0;
  const maxTemperature = 1;
  const minMaxTokens = 0;
  const maxMaxTokens = GetBaseModelFromInternalId[baseModel].maxTokensAllowed;

  useEffect(() => {
    if (width > RESPONSIVE_WIDTH_THRESHOLD) {
      setIsSettingsDialogOpen(false);
    }
  }, [width]);

  const hotKeyMap = {
    SUBMIT: ['command+enter', 'ctrl+enter'],
    UNDO_LAST: ['command+shift+u', 'ctrl+shift+u'],
    REGENERNATE: ['command+shift+enter', 'ctrl+shift+enter'],
  };

  const hotKeyHandlers = {
    SUBMIT: (_: any) => getPromptResponse(),
    UNDO_LAST: (_: any) => handleOnClickUndoLast(),
    REGENERNATE: (_: any) => handleOnClickRegenerate(),
  };

  const getPromptResponse = async (isRegenerate = false) => {
    const currentModelOutputHTML = insertHighlightStyle(modelOutput);
    if (prompt === promptBeforeModelOutput + currentModelOutputHTML) {
      setIsLoading(false);
      return;
    }
    const promptInput =
      isRegenerate || isModelOutputError ? promptBeforeModelOutput : promptRef.current;
    const sanitizedInput = stripHTML(promptInput);
    const sanitizedStopSequence = stopSequence.replace('⏎', '\n');
    let response;
    let modelOutputHTML;
    try {
      setIsLoading(true);
      response = await axios(`${absoluteApiHref}/playground`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify({
          modelId: baseModel,
          prompt: sanitizedInput,
          temperature,
          maxTokens,
          stopSequence: sanitizedStopSequence,
        }),
      }).then(res => res.data);
      modelOutputHTML = insertHighlightStyle(response);
      setIsModelOutputError(false);
    } catch (error) {
      response = 'The model failed to produce an output. Please try again!';
      modelOutputHTML = insertHighlightStyle(response, false);
      setIsModelOutputError(true);
    } finally {
      const previousPrompt = stripHighlightedOutputStyle(promptInput);
      setModelOutput(response);
      setPromptWithRef(previousPrompt + modelOutputHTML);
      setPromptBeforeModelOutput(previousPrompt);
      setIsLoading(false);
    }
  };

  const handlePromptFieldHotKeys = (e: any) => {
    const isMetaKeyDown = e.metaKey || e.ctrlKey;
    if (isMetaKeyDown && e.shiftKey && e.code === 'Enter') {
      handleOnClickRegenerate();
    } else if (isMetaKeyDown && e.shiftKey && e.code === 'KeyU') {
      handleOnClickUndoLast();
    } else if (isMetaKeyDown && e.code === 'Enter') {
      getPromptResponse();
    }
  };

  const handlePromptChange = (e: any) => {
    const newPrompt = e.target.value;
    checkAndHandleModelOutputChange(newPrompt);
    checkAndHandlePromptPresetQueryInputChange(newPrompt);
    setPromptWithRef(newPrompt);
  };

  const checkAndHandleModelOutputChange = (newPrompt: string) => {
    if (modelOutput) {
      const modelOutputId = isModelOutputError ? MODEL_OUTPUT_ERROR_ID : MODEL_OUTPUT_SUCCESS_ID;
      const modelOutputHTML = insertHighlightStyle(modelOutput);
      const modelOutputElement = document.getElementById(modelOutputId);
      if (modelOutputElement) {
        if (
          !newPrompt.includes(modelOutputHTML) ||
          !newPrompt.includes(promptBeforeModelOutput + modelOutputHTML)
        ) {
          setIsModelOutputModified(true);
        } else {
          setIsModelOutputModified(false);
        }
      }
    }
  };

  const checkAndHandlePromptPresetQueryInputChange = (newPrompt: string) => {
    if (selectedPromptPreset) {
      const queryInputElement = document.getElementById(QUERY_INPUT_ID);
      if (queryInputElement) {
        const queryInputHTML = getQueryInputHTML();
        if (!newPrompt.includes(queryInputHTML)) {
          setIsQueryInputModified(true);
        } else {
          setIsQueryInputModified(false);
        }
      }
    }
  };

  const handleOnClickUndoLast = () => {
    if (!isLoading && modelOutput) {
      setModelOutput('');
      setPromptBeforeModelOutput('');
      setPromptWithRef(promptBeforeModelOutput);
    }
  };

  const handleOnClickRegenerate = async () => {
    if (!isLoading && modelOutput) {
      setPromptWithRef(promptBeforeModelOutput);
      await getPromptResponse(true);
    }
  };

  const handleSelectedPromptPresetChange = (presetTitle: string | undefined) => {
    if (
      !prompt ||
      prompt === selectedPromptPreset?.prompt ||
      confirm(PRESET_CHANGE_CONFIRMATION_TEXT)
    ) {
      if (presetTitle) {
        const matchingPresets = PROMPT_PRESETS.filter(p => p.title === presetTitle);
        if (matchingPresets.length) {
          const preset = matchingPresets[0];
          setSelectedPromptPreset(preset);
          setPromptWithRef(preset.prompt);
          setIsUpdatedSnackbarOpen(true);
        }
      } else {
        setSelectedPromptPreset(undefined);
        setPromptWithRef('');
        setIsUpdatedSnackbarOpen(true);
      }
      setIsQueryInputModified(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <HotKeys keyMap={hotKeyMap} handlers={hotKeyHandlers}>
        <Layout>
          <SettingsUpdatedSnackbar
            isOpen={isUpdatedSnackbarOpen}
            onClose={() => setIsUpdatedSnackbarOpen(false)}
          />
          <SettingsDialog
            isOpen={isSettingsDialogOpen}
            models={MODELS}
            presets={PROMPT_PRESETS}
            selectedPromptPreset={selectedPromptPreset}
            baseModel={baseModel}
            temperature={temperature}
            minTemperature={minTemperature}
            maxTemperature={maxTemperature}
            maxTokens={maxTokens}
            minMaxTokens={minMaxTokens}
            maxMaxTokens={maxMaxTokens}
            stopSequence={stopSequence}
            onChangeSelectedPromptPreset={handleSelectedPromptPresetChange}
            onChangeModel={e => setBaseModel(e.target.value as BaseModelInternalId)}
            onChangeTemperature={(e, val) =>
              setTemperature(Math.min(val as number, maxTemperature))
            }
            onChangeMaxTokens={(e, val) => setMaxTokens(Math.min(val as number, maxMaxTokens))}
            onChangeStopSequence={e => setStopSequence(e.target.value)}
            onClearPromptTemplate={() => handleSelectedPromptPresetChange(undefined)}
            onClose={() => setIsSettingsDialogOpen(false)}
          />
          <Header onClickSettings={() => setIsSettingsDialogOpen(true)} />
          <FlexBox sx={{ flexDirection: 'row', alignItems: 'flex-start', flex: '1' }}>
            <PromptField
              isLoading={isLoading}
              prompt={prompt}
              modelOutput={modelOutput}
              selectedPromptPreset={selectedPromptPreset}
              onSubmit={e => getPromptResponse()}
              onUndoLast={handleOnClickUndoLast}
              onRegenerate={handleOnClickRegenerate}
              onPromptChange={handlePromptChange}
              onKeyDown={handlePromptFieldHotKeys}
            />
            <Sidebar
              isDialog={false}
              models={MODELS}
              presets={PROMPT_PRESETS}
              selectedPromptPreset={selectedPromptPreset}
              baseModel={baseModel}
              temperature={temperature}
              minTemperature={minTemperature}
              maxTemperature={maxTemperature}
              maxTokens={maxTokens}
              minMaxTokens={minMaxTokens}
              maxMaxTokens={maxMaxTokens}
              stopSequence={stopSequence}
              onChangeSelectedPromptPreset={handleSelectedPromptPresetChange}
              onChangeModel={e => setBaseModel(e.target.value as BaseModelInternalId)}
              onChangeTemperature={(e, val) =>
                setTemperature(Math.min(val as number, maxTemperature))
              }
              onChangeMaxTokens={(e, val) => setMaxTokens(Math.min(val as number, maxMaxTokens))}
              onChangeStopSequence={val => setStopSequence(val)}
              onClearPromptTemplate={() => handleSelectedPromptPresetChange(undefined)}
            />
          </FlexBox>
          <style>{`
            #${QUERY_INPUT_ID} {
              background: ${isQueryInputModified ? 'none' : Colors.CoolGray30};
            }
            #${MODEL_OUTPUT_SUCCESS_ID} {
              background: ${isModelOutputModified ? 'none' : Colors.RemoGreenLight};
            }
            #${MODEL_OUTPUT_ERROR_ID} {
              background: ${isModelOutputModified ? 'none' : Colors.Red};
            }
          `}</style>
        </Layout>
      </HotKeys>
    </ThemeProvider>
  );
}
