import { useMemo } from 'react';

import _ from 'lodash';
import * as R from 'ramda';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { Box } from '@mui/material';
import { DeploymentLog } from '@prisma/client';
import { useQuery } from '@tanstack/react-query';

import { client } from 'frontend/api/trpc';
import { HourlyDeploymentStats } from 'frontend/stores/types';
import { StoredDeployment } from 'frontend/storesV2/types';
import theme, { Colors } from 'frontend/theme';
import { dateToHourString, rangePreviousHours } from 'frontend/utils/time';

function computeLatencyData(stats: HourlyDeploymentStats[], hoursPrior = 12) {
  // Filter to timestamps where the rounded hour is up to hoursPrior
  // hours ago from the current time's rounded hour
  const statsByHour = _.fromPairs(stats.map(s => [dateToHourString(s.hour), s.latencyAverageMs]));
  const now = new Date();
  const previousHours = rangePreviousHours(now, hoursPrior);
  return previousHours.map(h => {
    const latencyMs = statsByHour[h.toISOString()] || 0;
    return {
      name: h.getHours(),
      'Average latency (s)': Math.round(latencyMs) / 1000,
    };
  });
}

export function DeploymentLatencyChart({ deployment }: { deployment: StoredDeployment }) {
  const deploymentStatsQuery = useQuery(
    ['v2.deployment.getDeploymentStats', deployment.id],
    () => {
      return client.query('v2.deployment.getDeploymentStats', { deploymentId: deployment.id });
    },
    {
      refetchInterval: 5000,
      refetchIntervalInBackground: true,
      cacheTime: 0,
    },
  );

  const data = useMemo(() => {
    return deploymentStatsQuery.data
      ? computeLatencyData(deploymentStatsQuery.data.statsByHour)
      : [];
  }, [deploymentStatsQuery.data]);

  return (
    <Box
      sx={{
        '& .recharts-text': {
          fontSize: 12,
          fontFamily: theme.typography.fontFamily,
          color: Colors.CoolGray70,
        },
        '& .recharts-label': {
          fontSize: 14,
        },
        '& .recharts-default-tooltip': {
          border: 'none',
        },
      }}
    >
      <ResponsiveContainer width="100%" height={240}>
        <BarChart data={data} margin={{ top: 12, right: 12, bottom: 16, left: 24 }}>
          <XAxis dataKey="name">
            <Label value="Hour" position="bottom" />
          </XAxis>
          <YAxis>
            <Label value="Latency (s)" position="insideBottomLeft" angle={-90} />
          </YAxis>
          <CartesianGrid stroke={Colors.CoolGray30} />
          <Tooltip
            labelStyle={{ display: 'none' }}
            wrapperStyle={{
              border: 'none',
              strokeWidth: 0,
              boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.15)',
            }}
            contentStyle={{ border: 'none', color: Colors.CoolGray70, fontSize: 12 }}
            animationDuration={0}
          />
          <Bar dataKey="Average latency (s)" fill={Colors.Blue} animationDuration={0} />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
}
