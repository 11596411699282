import { useCallback, useEffect, useMemo, useState } from 'react';

import cuid from 'cuid';
import _ from 'lodash';
import * as R from 'ramda';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
} from '@mui/material';
import { DeploymentCreate } from '@scale/llm-shared/interfaces/deployment';
import { EvaluationType } from '@scale/llm-shared/interfaces/evaluation';
import { logger } from '@scale/llm-shared/utils/logging';

import { client } from 'frontend/api/trpc';
import FlexBox from 'frontend/components/FlexBox';
import { HSpace, VSpace } from 'frontend/components/Spacer';
import VariantName from 'frontend/components/v2/variant/VariantName';
import { useLLMNavigation } from 'frontend/hooks/useLLMNavigation';
import PromptTextField from 'frontend/pages/PromptPage/PromptTextField';
import { useModelStore } from 'frontend/stores/ModelStore';
import { useDataStore } from 'frontend/storesV2/DataStore';
import { useDeploymentStore } from 'frontend/storesV2/DeploymentStore';
import { useSelectionStore } from 'frontend/storesV2/SelectionStore';
import { StoredVariant } from 'frontend/storesV2/types';
import { Colors } from 'frontend/theme';

function SettingsView(props: { setting: { setting: string; value: any }; spaced: boolean }) {
  const { setting, spaced } = props;
  return (
    <Box my={1} sx={{ display: 'flex', justifyContent: spaced ? 'space-between' : 'flex-start' }}>
      <Box mr={2} sx={{ color: Colors.CoolGray50, fontSize: 14, fontWeight: 500 }}>
        {setting.setting}
      </Box>
      <Box sx={{ fontSize: 14 }}>{setting.value}</Box>
    </Box>
  );
}

const PRIMARY_SETTINGS = ['Model', 'Id'];

export function VariantSummary({
  variant,
  size = 'large',
  defaultExpanded = true,
}: {
  variant: StoredVariant;
  size?: string;
  defaultExpanded?: boolean;
}) {
  const { getModel } = useModelStore(R.pick(['getModel']));
  const { selectedApp, setSelectedDeploymentId } = useSelectionStore(
    R.pick(['selectedApp', 'setSelectedDeploymentId']),
  );
  const { createDeployment, deploymentById } = useDeploymentStore(
    R.pick(['createDeployment', 'deploymentById']),
  );
  const settings = useMemo(() => {
    // TODO: demux by model host for specific parameters
    const model = getModel(variant.modelParameters.modelId);
    if (!model) {
      logger.error(`Variant ${variant.name} does not have a model!`);
      return [];
    }
    const baseSettings = [
      { setting: 'Id', value: variant.id },
      { setting: 'Model', value: model.name },
      { setting: 'Temperature', value: variant.modelParameters.temperature },
      { setting: 'Stop Sequences', value: variant.modelParameters.stop },
      { setting: 'Max Tokens', value: variant.modelParameters.maxTokens },
    ];
    return baseSettings;
  }, [selectedApp?.taskType, variant]);
  const variantName = variant.name || '(Unnamed Variant)';

  const { dataById } = useDataStore(R.pick(['dataById']));
  const { goToPromptPage } = useLLMNavigation();

  const [dataId, setDataId] = useState<string | undefined | null>(undefined);
  const dataset = useMemo(() => (dataId ? dataById[dataId] : undefined), [dataId, dataById]);

  const [isDeploymentModalOpen, setIsDeploymentModalOpen] = useState<boolean>(false);

  useEffect(() => {
    // dataset = undefined -> unset; null -> explicitly set to None by user
    dataset === undefined && setDataId(_.values(dataById)[0]?.id);
  }, [dataById]);

  const onCloneVariantClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation(); // prevents button from triggering accordion expand/collapse
      goToPromptPage(variant.id);
    },
    [goToPromptPage, variant.id],
  );

  const { goToDeploymentPage, goToEvaluationPage } = useLLMNavigation();
  const primarySettings = settings.filter(s => PRIMARY_SETTINGS.includes(s.setting));
  const secondarySettings = settings.filter(s => !PRIMARY_SETTINGS.includes(s.setting));
  const settingsLeft = secondarySettings.filter((__, i) => i % 2 === 0);
  const settingsRight = secondarySettings.filter((__, i) => i % 2 === 1);

  const existingDeploymentForVariant = useMemo(() => {
    return Object.values(deploymentById).find(deployment => deployment.variantId === variant.id);
  }, [deploymentById, variant.id]);

  const handleDeployToNew = useCallback(() => {
    const draftSlug = cuid.slug();
    const newDeployment: DeploymentCreate = {
      name: 'Deployment ' + draftSlug,
      uri: draftSlug,
      variantId: variant.id,
    };
    createDeployment(newDeployment).then(newStoredDeployment => {
      if (newStoredDeployment) {
        goToDeploymentPage({ deployment: newStoredDeployment });
      }
    });
  }, [variant.id, createDeployment, goToDeploymentPage, setSelectedDeploymentId]);

  const onDeployClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation(); // prevents button from triggering accordion expand/collapse
      if (!existingDeploymentForVariant) {
        handleDeployToNew();
        return;
      }
      setIsDeploymentModalOpen(true);
      goToDeploymentPage({ deployment: existingDeploymentForVariant });
    },
    [handleDeployToNew, goToDeploymentPage, existingDeploymentForVariant, setSelectedDeploymentId],
  );

  const onEvaluateClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation(); // prevents button from triggering accordion expand/collapse
      goToEvaluationPage();
    },
    [goToEvaluationPage],
  );

  return (
    <Box>
      <Accordion sx={{ borderRadius: 1 }} variant="outlined" defaultExpanded={defaultExpanded}>
        <AccordionSummary expandIcon={<FontAwesomeIcon icon="caret-down" />}>
          <Box sx={{ paddingTop: 1, paddingBottom: 0.5 }}>
            <Box sx={{ display: 'flex', alignItems: 'flex-center', width: '100%' }}>
              <Typography variant="h3" sx={{ display: 'flex', alignItems: 'center' }}>
                <VariantName name={variantName} />
              </Typography>
              <HSpace />
            </Box>
            <br />
            <Box>
              <FlexBox>
                <Button variant="outlined" onClick={onCloneVariantClick} style={{ width: 90 }}>
                  <FontAwesomeIcon icon="code-fork" style={{ marginRight: '1ex' }} />
                  Fork
                </Button>
                <Button variant="outlined" onClick={onDeployClick} style={{ width: 90 }}>
                  <FontAwesomeIcon icon="upload" style={{ marginRight: '1ex' }} />
                  Deploy
                </Button>
                <Button variant="outlined" onClick={onEvaluateClick} style={{ width: 220 }}>
                  <FontAwesomeIcon icon="book-open-reader" style={{ marginRight: '1ex' }} />
                  View or Run Evaluations
                </Button>
              </FlexBox>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <VSpace s={1} />
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <PromptTextField readOnly value={variant.prompt.template} rows={5} />
            </Grid>
            {size === 'small' ? (
              <Grid item xs={4}>
                {primarySettings.map(setting => (
                  <SettingsView key={setting.setting} setting={setting} spaced={false} />
                ))}
                {settingsLeft.map(setting => (
                  <SettingsView key={setting.setting} setting={setting} spaced={true} />
                ))}
                {settingsRight.map(setting => (
                  <SettingsView key={setting.setting} setting={setting} spaced={true} />
                ))}
              </Grid>
            ) : (
              <Grid item xs={4}>
                {primarySettings.map(setting => (
                  <SettingsView key={setting.setting} setting={setting} spaced={false} />
                ))}
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    {settingsLeft.map(setting => (
                      <SettingsView key={setting.setting} setting={setting} spaced={true} />
                    ))}
                  </Grid>
                  <Grid item xs={6}>
                    {settingsRight.map(setting => (
                      <SettingsView key={setting.setting} setting={setting} spaced={true} />
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
