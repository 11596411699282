import { useCallback } from 'react';

import Bluebird from 'bluebird';
import _ from 'lodash';
import * as R from 'ramda';

import { JobStatus } from '@prisma/client';
import { EvaluationType } from '@scale/llm-shared/interfaces/evaluation';
import { FinetuneType } from '@scale/llm-shared/interfaces/finetune';
import { isEvaluationJobType, isFinetuneJobType } from '@scale/llm-shared/utils';

import { client } from 'frontend/api/trpc';
import { useModelStore } from 'frontend/stores/ModelStore';
import { useEvaluationStore } from 'frontend/storesV2/EvaluationStore';
import { useFinetuneStore } from 'frontend/storesV2/FinetuneStore';
import { useJobPollingStore } from 'frontend/storesV2/JobPollingStore';

export function useAsyncAndPollV2() {
  const { polledJobById, addPolledJob } = useJobPollingStore(
    R.pick(['polledJobById', 'addPolledJob']),
  );

  const { storeEvaluation } = useEvaluationStore(R.pick(['storeEvaluation']));

  const { storeFinetune } = useFinetuneStore(R.pick(['storeFinetune']));

  const { appendModelById } = useModelStore(R.pick(['appendModelById']));

  const handleJobPollCycle = useCallback(async () => {
    // Only take those in a non-terminal state
    const jobsToPoll = Object.values(polledJobById).filter(
      job => job.status === JobStatus.Pending || job.status === JobStatus.Running,
    );

    await Bluebird.Promise.map(
      jobsToPoll,
      async jobToPoll => {
        const polledJob = await client.query('v2.jobPolling.get', {
          id: jobToPoll.id,
          type: jobToPoll.type,
        });
        // Does it make more sense to have this logic in addPolledJob?
        if (polledJob.status !== JobStatus.Pending && polledJob.status !== JobStatus.Running) {
          // Is now in a terminal state
          // we love ifs baby
          if (isEvaluationJobType(polledJob.type)) {
            client
              .query('v2.evaluation.get', {
                id: jobToPoll.id,
                type: jobToPoll.type as EvaluationType,
              })
              .then(storeEvaluation);
          } else if (isFinetuneJobType(polledJob.type)) {
            client
              .query('v2.finetune.get', {
                id: jobToPoll.id,
                type: jobToPoll.type as FinetuneType,
              })
              .then(finetune => {
                if (finetune.modelId) {
                  client
                    .query('model.findById', {
                      id: finetune.modelId,
                    })
                    .then(model => model && appendModelById({ [model.id]: model }));
                }
                storeFinetune(finetune);
              });
          }
        }
        if (polledJob.status !== jobToPoll.status) {
          // Status has changed, we need to update
          if (isFinetuneJobType(polledJob.type)) {
            client
              .query('v2.finetune.get', {
                id: jobToPoll.id,
                type: jobToPoll.type as FinetuneType,
              })
              .then(storeFinetune);
          }
        }
        addPolledJob(polledJob);
      },
      { concurrency: 100 },
    );
  }, [polledJobById, addPolledJob, storeEvaluation, storeFinetune]);

  return { handleJobPollCycle };
}
