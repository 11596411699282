import { z } from 'zod';

import { JobStatus } from '@prisma/client';
import { FinetuneJobMetadata } from '@scale/llm-shared/interfaces/finetuneJobMetadata';
import {
  FinetuneParameters,
  FinetuneParametersCreate,
} from '@scale/llm-shared/interfaces/finetuneParameters';

export const FinetuneType = {
  OpenAIFinetune: 'OpenAIFinetune',
  ScaleTrainFinetune: 'ScaleTrainFinetune',
} as const;

export type FinetuneType = typeof FinetuneType[keyof typeof FinetuneType];

// Type
export const FinetuneBase = z.object({
  id: z.string(),
  createdAt: z.date(),
  status: z.nativeEnum(JobStatus),
  statusMessage: z.string().optional(),
  updatedAt: z.date(),
  polling: z.number(),
  lockedUntil: z.date(),
  appId: z.string(),
  modelId: z.string().optional(),
  finetuneParameterId: z.string(),
  finetuneJobId: z.string().optional(),
  progress: z.number(),
  finetuneJobMetadata: FinetuneJobMetadata.optional(),
  finetuneParameters: FinetuneParameters,
});
export const FinetuneScaleTrain = FinetuneBase.extend({
  scaleTrainFinetuneJobMetadataId: z.string().optional(),
  type: z.literal(FinetuneType.ScaleTrainFinetune),
});
export const FinetuneOpenAI = FinetuneBase.extend({
  type: z.literal(FinetuneType.OpenAIFinetune),
});
export const Finetune = z.union([FinetuneScaleTrain, FinetuneOpenAI]);
export type FinetuneScaleTrain = z.infer<typeof FinetuneScaleTrain>;
export type FinetuneOpenAI = z.infer<typeof FinetuneOpenAI>;
export type Finetune = z.infer<typeof Finetune>;

// Create
export const FinetuneScaleTrainCreate = FinetuneScaleTrain.omit({
  id: true,
  createdAt: true,
  updatedAt: true,
  lockedUntil: true,
  polling: true,
  finetuneParameterId: true,
  finetuneJobId: true,
  modelId: true,
  scaleTrainFinetuneJobMetadataId: true,
  status: true,
  finetuneJobMetadata: true,
  finetuneParameters: true,
}).extend({
  finetuneParameters: FinetuneParametersCreate,
});
export const FinetuneOpenAICreate = FinetuneOpenAI.omit({
  id: true,
  createdAt: true,
  updatedAt: true,
  lockedUntil: true,
  polling: true,
  finetuneParameterId: true,
  finetuneJobId: true,
  modelId: true,
  status: true,
  finetuneJobMetadata: true,
  finetuneParameters: true,
}).extend({
  finetuneParameters: FinetuneParametersCreate,
});
export const FinetuneCreate = z.union([FinetuneScaleTrainCreate, FinetuneOpenAICreate]);
export type FinetuneScaleTrainCreate = z.infer<typeof FinetuneScaleTrainCreate>;
export type FinetuneOpenAICreate = z.infer<typeof FinetuneOpenAICreate>;
export type FinetuneCreate = z.infer<typeof FinetuneCreate>;

export const SuccessfulFinetuneCreateResult = z.object({
  success: z.literal(true),
  finetune: Finetune,
});
export type SuccessfulFinetuneCreateResult = z.infer<typeof SuccessfulFinetuneCreateResult>;

export const UnsuccessfulFinetuneCreateResult = z.object({
  success: z.literal(false),
  message: z.string(),
});

export type UnsuccessfulFinetuneCreateResult = z.infer<typeof UnsuccessfulFinetuneCreateResult>;

export const FinetuneCreateResult = z.union([
  SuccessfulFinetuneCreateResult,
  UnsuccessfulFinetuneCreateResult,
]);

export type FinetuneCreateResult = z.infer<typeof FinetuneCreateResult>;
// Get
export const GetFinetune = z.object({
  id: z.string(),
  type: z.nativeEnum(FinetuneType),
});
export type GetFinetune = z.infer<typeof GetFinetune>;
