export type HasUpdatedAt = {
  modifiedAt: Date;
};
export type HasCreatedAt = {
  createdAt: Date;
};

export function sortBCreatedAt(obj1: HasCreatedAt, obj2: HasCreatedAt, decreasing: boolean = true) {
  const compareResult = compareDates(obj1.createdAt, obj2.createdAt);
  return decreasing ? compareResult : -compareResult;
}

export function sortByUpdatedAt(
  obj1: HasUpdatedAt,
  obj2: HasUpdatedAt,
  decreasing: boolean = true,
) {
  const compareResult = compareDates(obj1.modifiedAt, obj2.modifiedAt);
  return decreasing ? compareResult : -compareResult;
}

/**
 *
 * @param date1
 * @param date2
 * @returns 1 if date1 is less recent than date2; 0 if date1 and date2 are equal; -1 if date1 is more recent than date2;
 */
function compareDates(date1: Date, date2: Date) {
  if (date1 > date2) return -1;
  if (date1 === date2) return 0;
  return 1;
}
