import _ from 'lodash';

import { BillingPlan } from '@prisma/client';

type PlanRestriction = {
  dataRowLimit: number;
  enabledDeploymentCap: number;
  allowPrivateApps: boolean;
  allowFineTuning: boolean;
};

export const RESTRICTIONS_BY_PLAN: Record<BillingPlan, PlanRestriction> = {
  Free: {
    dataRowLimit: 500,
    enabledDeploymentCap: Infinity,
    allowPrivateApps: true,
    allowFineTuning: true,
  },
  Team: {
    dataRowLimit: 3000,
    enabledDeploymentCap: 5,
    allowPrivateApps: true,
    allowFineTuning: false,
  },
  Pro: {
    dataRowLimit: 3000,
    enabledDeploymentCap: Infinity,
    allowPrivateApps: true,
    allowFineTuning: true,
  },
  // Should be same as pro
  Pilot: {
    dataRowLimit: 3000,
    enabledDeploymentCap: Infinity,
    allowPrivateApps: true,
    allowFineTuning: true,
  },
  Enterprise: {
    dataRowLimit: 3000,
    enabledDeploymentCap: Infinity,
    allowPrivateApps: true,
    allowFineTuning: true,
  },
};
