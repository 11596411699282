import z from 'zod';

const LaunchTaskResponseSuccess = z.object({
  status: z.literal('SUCCESS'),
  result: z.any(),
});
const LaunchTaskResponseFailure = z.object({
  status: z.literal('FAILURE'),
  traceback: z.string(),
});
const LaunchTaskResponsePending = z.object({
  status: z.literal('PENDING'),
});
const LaunchTaskResponseStarted = z.object({
  status: z.literal('STARTED'),
});
const LaunchTaskResponse = z.union([
  LaunchTaskResponseSuccess,
  LaunchTaskResponseFailure,
  LaunchTaskResponsePending,
  LaunchTaskResponseStarted,
]);
export type LaunchTaskResponse = z.infer<typeof LaunchTaskResponse>;

export type LaunchRequestData = {
  args: {
    prompts: string[];
    max_new_tokens: number;
    temperature?: number;
    do_sample?: boolean;
    token_probs?: boolean;
    stop_sequence?: string;
  };
  return_pickled: boolean;
};

export const TokenProbs = z.object({
  token_probs: z.number().array(),
  tokens: z.string().array(),
  top_probs: z.record(z.number()).array(),
});

export type TokenProbs = z.infer<typeof TokenProbs>;

export type LaunchResponseData = {
  text: string;
  tokens_consumed: number;
  token_probs: null | TokenProbs;
  error: string | null; // null if no error
};

export type MauveResponseData = {
  mauve_score: number;
  tokens_consumed: number;
};

// Short eval endpoints are always online, long eval endpoints do cold starts
export enum LaunchEndpointType {
  Short = 'short',
  Long = 'long',
  Test = 'test',
  Streaming = 'streaming',
}

// Finetuning methods for our models
export enum ScaleFinetuningMethod {
  IA3 = 'IA3',
}
