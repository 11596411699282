import { z } from 'zod';

import { ModelHost, Prisma } from '@prisma/client';
import { BaseModelInternalId } from '@scale/llm-shared/modelProviders/types';

// Type

type ModelHostKeys = keyof typeof ModelHost;
type ModelHostValues = typeof ModelHost[ModelHostKeys];

export const ModelType = z.nativeEnum(ModelHost);
export type ModelType = ModelHostValues;

export const Model = z.object({
  id: z.string(),
  name: z.string(),
  modelType: z.nativeEnum(ModelHost),
  externalModelId: z.string(),
  baseModelId: z.nativeEnum(BaseModelInternalId),
});

// Create

export type Model = z.infer<typeof Model>;

export const ModelCreate = Model.omit({ id: true });

export type ModelCreate = z.infer<typeof ModelCreate>;
