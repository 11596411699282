import React from 'react';

import { neverfail } from '@scale/llm-shared/utils/type';

export enum EvaluationIconName {
  Binary = 'Binary',
  Ranking = 'Ranking',
  Classification = 'Classification',
  Mauve = 'Mauve',
  AIFeedback = 'AIFeedback',
}

interface EvaluationTypeIconProps {
  iconName: EvaluationIconName;
  color?: string;
  size?: number;
}

const EvaluationTypeIcon: React.FC<EvaluationTypeIconProps> = ({ iconName, color, size }) => {
  switch (iconName) {
    case EvaluationIconName.Binary:
      return <EvaluationBinaryTypeIcon color={color} size={size} />;
    case EvaluationIconName.Ranking:
      return <EvaluationRankingTypeIcon color={color} size={size} />;
    case EvaluationIconName.Classification:
      return <EvaluationClassificationIcon color={color} size={size} />;
    case EvaluationIconName.Mauve:
      return <EvaluationMauveIcon color={color} size={size} />;
    case EvaluationIconName.AIFeedback:
      return <EvaluationAIFeedbackIcon color={color} size={size} />;
    default:
      neverfail(iconName, `Invalid evaluation icon name: ${iconName}`);
  }
};

interface EvaluationTypeIconChildProps {
  color?: string;
  size?: number;
}

const EvaluationBinaryTypeIcon: React.FC<EvaluationTypeIconChildProps> = ({ color, size }) => {
  return (
    <svg height={size ?? 45} viewBox="0 0 39 42" fill={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.50237 11.2578H6.01418C6.47996 11.2579 6.91935 11.0416 7.20342 10.6725L10.1929 6.78749C10.3947 6.52529 10.5041 6.20374 10.5042 5.8729V3.0079V3.0079C10.4981 2.59974 10.8241 2.26395 11.2323 2.25789C11.2396 2.25778 11.247 2.25779 11.2544 2.2579C12.2168 2.2579 13.505 3.3829 13.505 5.6329V5.6329C13.5538 6.94577 13.3442 8.25545 12.888 9.48748C12.8431 9.58181 12.8833 9.69463 12.9776 9.73946C13.002 9.75105 13.0286 9.75729 13.0556 9.75775H17.945C18.7023 9.74491 19.3568 10.2839 19.4895 11.0296C19.5851 11.6546 19.2761 12.272 18.7185 12.5702C19.4441 12.9576 19.7184 13.8598 19.3311 14.5854C19.1543 14.9165 18.8591 15.1687 18.5044 15.2915C18.7772 15.484 18.9765 15.7635 19.0697 16.0841V16.0841C19.1664 16.4467 19.1235 16.8325 18.9495 17.165C18.7489 17.6373 18.2978 17.9551 17.7856 17.985C17.9467 18.2462 18.0226 18.551 18.0027 18.8572V18.8572C17.9187 19.6654 17.2294 20.2743 16.4171 20.2579H10.8208C9.63594 20.2579 8.47758 19.9073 7.49165 19.2502L7.13097 19.0098C6.8845 18.8456 6.59493 18.7579 6.29873 18.7579H4.50248C4.08827 18.7581 3.75238 18.4224 3.75226 18.0082C3.75226 18.0081 3.75226 18.008 3.75226 18.0079V12.0079L3.75226 12.0078C3.75226 11.5936 4.08804 11.2578 4.50226 11.2578C4.50235 11.2578 4.50243 11.2578 4.50252 11.2578L4.50237 11.2578Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5023 23.2581H21.2986H21.2986C21.5948 23.2582 21.8843 23.1705 22.1308 23.0062L22.4915 22.7658L22.4915 22.7658C23.4774 22.1087 24.6358 21.7581 25.8206 21.7581H31.417C32.2294 21.7417 32.9187 22.3507 33.0027 23.1588V23.1588C33.0226 23.4651 32.9467 23.7699 32.7855 24.031C33.2978 24.061 33.7488 24.3786 33.9495 24.8508V24.8508C34.1235 25.1834 34.1663 25.5692 34.0695 25.9318C33.9763 26.2524 33.777 26.5319 33.5042 26.7244C34.2815 26.9934 34.6935 27.8415 34.4245 28.6188C34.3018 28.9736 34.0495 29.2689 33.7183 29.4456C34.276 29.7439 34.5851 30.3614 34.4895 30.9866C34.3568 31.7323 33.7023 32.2712 32.945 32.2583H28.0555C27.9511 32.2601 27.8679 32.3462 27.8697 32.4506C27.8702 32.4776 27.8764 32.5042 27.888 32.5286C28.3442 33.7607 28.5538 35.0704 28.5049 36.3833C28.5049 38.6333 27.2166 39.7583 26.2542 39.7583C25.8461 39.7645 25.5102 39.4386 25.504 39.0304C25.5039 39.0231 25.5039 39.0157 25.504 39.0083V36.1433V36.1434C25.504 35.8125 25.3945 35.4909 25.1927 35.2288L22.2034 31.3438C21.9193 30.9746 21.4798 30.7583 21.014 30.7583H19.5023C19.0881 30.7583 18.7523 30.4225 18.7523 30.0083V24.0083V24.0081C18.7523 23.5939 19.0881 23.2581 19.5023 23.2581C19.5024 23.2581 19.5024 23.2581 19.5025 23.2581L19.5023 23.2581Z"
      />
    </svg>
  );
};

const EvaluationRankingTypeIcon: React.FC<EvaluationTypeIconChildProps> = ({ color, size }) => {
  return (
    <svg height={size ?? 45} viewBox="0 0 24 25" fill={color}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 13C18.5 14.1528 19.4966 14.0026 19.4966 14.5833C19.4966 14.8226 19.3734 15 19.0427 15C18.4186 15.0209 17.8261 14.7257 17.4668 14.215C16.6516 15.4849 15.3817 16.3952 13.9172 16.7594C13.6383 17.2969 13.4951 17.8945 13.5 18.5C13.5 20.4344 16.0921 21.7 16.2825 22.5V22.5C16.41 23.0921 16.4828 23.6946 16.5 24.3C16.5 24.727 16.0026 25 15.5 25H8.5C7.9973 25 7.5 24.727 7.5 24.3V24.3C7.51714 23.6946 7.58998 23.092 7.7176 22.5C7.90795 21.7 10.5 20.4344 10.5 18.5V18.5C10.505 17.8944 10.3617 17.2969 10.0827 16.7594V16.7594C8.61821 16.3952 7.34833 15.4849 6.53305 14.215C6.17385 14.7257 5.58134 15.0209 4.9573 15C4.62655 15 4.5034 14.8226 4.5034 14.5833C4.5034 14.0026 5.5 14.1528 5.5 13C5.5 10.5217 0 9.83208 0 6.24998C0 4.45853 1.48145 2.99998 3.5 2.99998C4.03194 2.98467 4.55569 3.13359 5 3.42648V1.99998V1.99998C4.96689 1.48081 5.36091 1.0331 5.88008 0.999983C5.92001 0.997436 5.96007 0.997436 6 0.999983H18C18.5192 0.966927 18.9669 1.36105 19 1.88028C19.0025 1.92014 19.0025 1.96012 19 1.99998V3.42649V3.42648C19.4443 3.13355 19.9681 2.98463 20.5 2.99998C22.5186 2.99998 24 4.45854 24 6.24999C24 9.83209 18.5 10.5217 18.5 13H18.5ZM3.75 4.49999C2.95186 4.4697 2.28028 5.09216 2.25 5.89031C2.24861 5.92685 2.24861 5.96344 2.25 5.99999C2.25 7.74324 3.75325 8.52779 5.06845 9.47374C5.0277 8.99999 5 8.51354 5 7.99999V5.49999C4.93324 4.88049 4.37691 4.4324 3.75741 4.49916C3.75494 4.49943 3.75247 4.4997 3.75 4.49999H3.75ZM20.25 4.49999C19.631 4.42911 19.0717 4.87346 19.0008 5.49247C19.0005 5.49497 19.0003 5.49748 19 5.49998V7.99998C19 8.51353 18.9723 8.99998 18.9316 9.47373C20.2467 8.52778 21.75 7.74323 21.75 5.99998C21.7803 5.20181 21.1577 4.53024 20.3596 4.49998C20.3231 4.4986 20.2865 4.4986 20.25 4.49998L20.25 4.49999Z"
      />
    </svg>
  );
};

const EvaluationClassificationIcon: React.FC<EvaluationTypeIconChildProps> = ({ color, size }) => {
  return (
    <svg height={size ?? 45} viewBox="0 0 40 40" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path d="M6 25V12H3C1.34312 12 0 13.3431 0 15V33C0 34.6569 1.34312 36 3 36H29C30.6569 36 32 34.6569 32 33V30H11C8.24312 30 6 27.7569 6 25ZM37 8H25L21 4H11C9.34312 4 8 5.34312 8 7V25C8 26.6569 9.34312 28 11 28H37C38.6569 28 40 26.6569 40 25V11C40 9.34312 38.6569 8 37 8Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.5303 12.9697C30.8232 13.2626 30.8232 13.7374 30.5303 14.0303L22.2803 22.2803C21.9874 22.5732 21.5126 22.5732 21.2197 22.2803L17.4697 18.5303C17.1768 18.2374 17.1768 17.7626 17.4697 17.4697C17.7626 17.1768 18.2374 17.1768 18.5303 17.4697L21.75 20.6893L29.4697 12.9697C29.7626 12.6768 30.2374 12.6768 30.5303 12.9697Z"
        fill="white"
      />
    </svg>
  );
};

const EvaluationMauveIcon: React.FC<EvaluationTypeIconChildProps> = ({ color, size }) => {
  return (
    <svg height={size ?? 45} viewBox="0 0 40 32" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path d="M36 12C38.2062 12 40 10.2062 40 8C40 5.79375 38.2062 4 36 4C33.7938 4 32 5.79375 32 8C32 10.2062 33.7938 12 36 12ZM4 15C1.79375 15 0 16.7937 0 19C0 21.2062 1.79375 23 4 23C6.20625 23 8 21.2062 8 19C8 16.7937 6.20625 15 4 15ZM32.1 12.675L30.9 11.075L27.9 13.325L29.1 14.925L32.1 12.675ZM36 24C35.1 24 34.275 24.3125 33.6063 24.8125L27.6063 21.2125C27.8438 20.5125 28 19.775 28 18.9937C28 15.125 24.8687 11.9937 21 11.9937C20.475 11.9937 19.9625 12.0625 19.475 12.175L16.925 6.7125C17.5875 6 18 5.05 18 4C18 1.79375 16.2062 0 14 0C11.7937 0 10 1.79375 10 4C10 6.20625 11.7937 8 14 8C14.0688 8 14.1312 7.98125 14.2 7.98125L16.7625 13.4687C15.0938 14.7437 14 16.7375 14 19C14 22.8687 17.1313 26 21 26C23.0063 26 24.8 25.1437 26.075 23.7937L32.0625 27.3875C32.0312 27.5875 32 27.7937 32 28C32 30.2062 33.7938 32 36 32C38.2062 32 40 30.2062 40 28C40 25.7937 38.2062 24 36 24ZM21 22C19.3438 22 18 20.6562 18 19C18 17.3437 19.3438 16 21 16C22.6562 16 24 17.3437 24 19C24 20.6562 22.6562 22 21 22ZM9.5 20H12.5V18H9.5V20Z" />
    </svg>
  );
};

// TODO (jihan / george): change this or remove comment
const EvaluationAIFeedbackIcon: React.FC<EvaluationTypeIconChildProps> = ({ color, size }) => {
  return (
    <svg height={size ?? 45} viewBox="0 0 640 512" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path d="M320 0c17.7 0 32 14.3 32 32V96H472c39.8 0 72 32.2 72 72V440c0 39.8-32.2 72-72 72H168c-39.8 0-72-32.2-72-72V168c0-39.8 32.2-72 72-72H288V32c0-17.7 14.3-32 32-32zM208 384c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H208zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H304zm96 0c-8.8 0-16 7.2-16 16s7.2 16 16 16h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H400zM264 256a40 40 0 1 0 -80 0 40 40 0 1 0 80 0zm152 40a40 40 0 1 0 0-80 40 40 0 1 0 0 80zM48 224H64V416H48c-26.5 0-48-21.5-48-48V272c0-26.5 21.5-48 48-48zm544 0c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H576V224h16z" />
    </svg>
  );
};

export default EvaluationTypeIcon;
