import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Colors } from 'frontend/theme';

export default function VariantName(props: { name: string }) {
  return (
    <span style={{ textTransform: 'none' }}>
      <FontAwesomeIcon icon="layer-group" style={{ color: Colors.CoolGray50, marginRight: 6 }} />
      {props.name}
    </span>
  );
}
