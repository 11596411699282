import { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, IconButton, MenuItem, MenuList, Popover } from '@mui/material';

import { useDatasetPageState } from 'frontend/models/v2/useDatasetPageState';
import { Colors } from 'frontend/theme';

export function DataActionsButton({
  dataId,
  variant = 'button',
}: {
  dataId: string;
  variant?: 'button' | 'icon';
}) {
  const { setRenameModalDataId, setArchiveModalDataId } = useDatasetPageState();
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLButtonElement | null>(null);
  return (
    <>
      {variant === 'button' ? (
        <Button
          variant="outlined"
          color="primary"
          onClick={e => setPopoverAnchorEl(e.currentTarget)}
          sx={{ width: '100%' }}
        >
          File Actions
          <FontAwesomeIcon icon="caret-down" style={{ marginLeft: '1ex' }} />
        </Button>
      ) : (
        <IconButton onClick={e => setPopoverAnchorEl(e.currentTarget)}>
          <FontAwesomeIcon size="xs" icon="ellipsis-vertical" color={Colors.CoolGray50} />
        </IconButton>
      )}
      <Popover
        open={!!popoverAnchorEl}
        anchorEl={popoverAnchorEl}
        onClose={() => setPopoverAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={5}
      >
        <MenuList>
          <MenuItem
            onClick={() => {
              setPopoverAnchorEl(null);
              setRenameModalDataId(dataId);
            }}
          >
            Rename Dataset
          </MenuItem>
          <MenuItem
            onClick={() => {
              setPopoverAnchorEl(null);
              setArchiveModalDataId(dataId);
            }}
          >
            Archive Dataset
          </MenuItem>
        </MenuList>
      </Popover>
    </>
  );
}
