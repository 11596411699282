import { EvaluationWizardGrid } from 'frontend/components/evaluation/EvaluationWizardLayout';
import { useEvaluationWizardState } from 'frontend/models/v2/useEvaluationWizardState';

import { EvaluationWizardSetupBody } from './EvaluationWizardSetupBody';
import { EvaluationWizardSetupHeader } from './EvaluationWizardSetupHeader';

export function EvaluationWizardSetupStep() {
  const { feedbackType } = useEvaluationWizardState();

  return (
    <>
      <EvaluationWizardGrid>
        <EvaluationWizardSetupHeader canPickVariantAndDataset={!!feedbackType} />
        <EvaluationWizardSetupBody canPickVariantAndDataset={!!feedbackType} />
      </EvaluationWizardGrid>
    </>
  );
}
