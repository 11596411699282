import { ScaleLaunchBaseModelInternalId } from '../consts';
import { Dolly2 } from './Dolly2';
import { FlanXXL } from './FlanXXL';
import { Mpt7B } from './Mpt7B';
import { Mpt7BInstruct } from './Mpt7BInstruct';

export const GetScaleLaunchBaseModelFromInternalId = {
  [ScaleLaunchBaseModelInternalId.Flan_XXL]: FlanXXL,
  [ScaleLaunchBaseModelInternalId.Dolly2]: Dolly2,
  [ScaleLaunchBaseModelInternalId.Mpt7B]: Mpt7B,
  [ScaleLaunchBaseModelInternalId.Mpt7BInstruct]: Mpt7BInstruct,
};
