import { datadogRum } from '@datadog/browser-rum';

export function initializeDatadog() {
  datadogRum.init({
    applicationId: 'b2821cef-b085-4442-8d11-35236af5c4ad',
    clientToken: 'pub56a02abc865e31bba75ec47e2d11f29c',
    site: 'datadoghq.com',
    service: 'spellbook',
    env: process.env.NODE_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sampleRate: 100,
    sessionReplaySampleRate: 20,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum.startSessionReplayRecording();
}
