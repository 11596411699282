import React from 'react';

import { Box, SxProps } from '@mui/material';

import theme from 'frontend/theme';

import { Container } from '../Container';

// Stylistic components to maintain alignment along cells, pass cells into the grid
export const EvaluationWizardCell: React.FC<{ children?: React.ReactNode; sx?: SxProps }> = ({
  children,
  sx,
}) => {
  return (
    <Box
      sx={{
        paddingBlock: 2,
        paddingInline: 3,
        width: '100%',
        outline: `0.5px solid ${theme.palette.info.light}`,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export const EvaluationWizardGrid: React.FC<{ children?: React.ReactNode; sx?: SxProps }> = ({
  children,
  sx,
}) => {
  return (
    <Box
      sx={{
        gap: 0,
        display: 'grid',
        alignContent: 'start',
        gridTemplateColumns: '2fr 3fr',
        border: `1px solid ${theme.palette.info.light}`,
        borderRadius: 1,
        backgroundColor: theme.palette.background.paper,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export const ElevatedContainer: React.FC<{ children?: React.ReactNode; sx?: SxProps }> = ({
  children,
  sx,
}) => {
  return (
    <Container
      sx={{
        padding: 2,
        boxShadow: `0px 0px 8px ${theme.palette.info.light}`,
        border: 'none',
        ...sx,
      }}
    >
      {children}
    </Container>
  );
};
