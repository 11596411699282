import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { Box, Button, PaletteColorOptions, PaletteOptions, Paper, Typography } from '@mui/material';

import theme, { Colors } from 'frontend/theme';

type FontAwesomeIconValues = FontAwesomeIconProps['icon'];

export function ActionBanner({
  color = 'primary',
  icon,
  content,
  buttonText,
  actionItems,
  onButtonClick,
}: {
  color?: ExtractTypeKeys<PaletteOptions, PaletteColorOptions> | string;
  icon?: FontAwesomeIconValues;
  content?: React.ReactNode;
  buttonText?: string;
  actionItems?: React.ReactNode;
  onButtonClick?: () => void;
}): JSX.Element {
  const colorHex = color === 'primary' ? theme.palette.primary.main : color;

  return (
    <Paper>
      <Box
        sx={{
          color: colorHex,
          borderRadius: 1,
          padding: 2,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 1,
        }}
      >
        {typeof content === 'string' ? (
          <Typography variant="body1" color="inherit" sx={{ fontWeight: 500 }}>
            {icon && (
              <FontAwesomeIcon size="lg" icon={icon} style={{ marginRight: theme.spacing(1) }} />
            )}
            {content}
          </Typography>
        ) : (
          <>
            {icon && (
              <FontAwesomeIcon size="lg" icon={icon} style={{ marginRight: theme.spacing(1) }} />
            )}
            {content}
          </>
        )}
        {(buttonText || actionItems) && (
          <Box sx={{ marginLeft: 'auto ' }}>
            {buttonText && (
              <Button variant="contained" onClick={onButtonClick}>
                {buttonText}
              </Button>
            )}
            {actionItems}
          </Box>
        )}
      </Box>
    </Paper>
  );
}
