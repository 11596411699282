import { getNumGpt3Tokens } from '@scale/llm-shared/tokenizers/countTokens';
import { ScaleLaunchBaseModelDisplayName, ScaleLaunchBaseModelInternalId } from '../consts';
import { IScaleLaunchBaseModel } from '../types';

export const Dolly2: IScaleLaunchBaseModel = {
  id: ScaleLaunchBaseModelInternalId.Dolly2,
  displayName: ScaleLaunchBaseModelDisplayName[ScaleLaunchBaseModelInternalId.Dolly2],
  minTokensAllowed: 0,
  maxTokensAllowed: 2048,

  minTemperatureAllowed: 0,
  maxTemperatureAllowed: 1,
  externalModelId: '',
  costCentsPerMillionPromptTokens: 200,
  costCentsPerMillionCompletionTokens: 200,

  endpoint_short: 'end_ch5b2rj0f7m003qg1ajg',
  endpoint_streaming: 'end_ci5ajav1122g0336k3sg',

  getNumTokens: (prompt: string) => {
    return getNumGpt3Tokens(prompt);
  },
};
