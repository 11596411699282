import { z } from 'zod';

// Type

export const FinetuneJobMetadataBase = z.object({
  id: z.string(),
});
export const FinetuneJobMetadataScaleTrain = FinetuneJobMetadataBase.extend({
  configUri: z.string(),
  metricsUri: z.string(),
  weightsUri: z.string(),
  shapesUri: z.string(),
});
export const FinetuneJobMetadata = FinetuneJobMetadataScaleTrain;

export type FinetuneJobMetadataBase = z.infer<typeof FinetuneJobMetadataBase>;
export type FinetuneJobMetadataScaleTrain = z.infer<typeof FinetuneJobMetadataScaleTrain>;
export type FinetuneJobMetadata = z.infer<typeof FinetuneJobMetadata>;

// Create
export const FinetuneJobMetadataScaleTrainCreate = FinetuneJobMetadataScaleTrain.omit({ id: true });
export const FinetuneJobMetadataCreate = FinetuneJobMetadataScaleTrainCreate;

export type FinetuneJobMetadataScaleTrainCreate = z.infer<
  typeof FinetuneJobMetadataScaleTrainCreate
>;
export type FinetuneJobMetadataCreate = z.infer<typeof FinetuneJobMetadataCreate>;
