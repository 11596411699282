import { Box, BoxProps, Tooltip } from '@mui/material';

import theme, { Colors } from 'frontend/theme';

export function SettingsEditor(props: BoxProps) {
  return <Box sx={{ minWidth: 140 }} {...props} />;
}

export function SettingsContainer(props: BoxProps) {
  return <Box sx={{ flex: 1, maxWidth: 240 }} {...props} />;
}

export function SettingsHeader({ title, children }: { title: string; children?: React.ReactNode }) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: 14,
        fontWeight: 500,
        color: Colors.CoolGray70,
        marginBottom: theme.spacing(1),
      }}
    >
      <Box>{title}</Box>
      {children}
    </Box>
  );
}
