import {
  OpenAiBaseModelDisplayName,
  OpenAiBaseModelFineTuneEndpoint,
  OpenAiBaseModelInternalId,
} from '@scale/llm-shared/modelProviders/openAi/consts';
import { getNumGpt3Tokens } from '@scale/llm-shared/tokenizers/countTokens';

const CENTS_PER_MILLION_TOKENS = 50; // Single price for now

import type { IOpenAiSingleTurnBaseModel } from '@scale/llm-shared/modelProviders/openAi/types';
export const OpenAiGPT3TextBabbage001: IOpenAiSingleTurnBaseModel = {
  id: OpenAiBaseModelInternalId.GPT3TextBabbage001,
  displayName: OpenAiBaseModelDisplayName[OpenAiBaseModelInternalId.GPT3TextBabbage001],
  minTokensAllowed: 0,
  maxTokensAllowed: 2048,
  minTemperatureAllowed: 0,
  maxTemperatureAllowed: 2,
  externalModelId: 'text-babbage-001',
  costCentsPerMillionPromptTokens: CENTS_PER_MILLION_TOKENS,
  costCentsPerMillionCompletionTokens: CENTS_PER_MILLION_TOKENS,
  finetuneEndpoint: OpenAiBaseModelFineTuneEndpoint[OpenAiBaseModelInternalId.GPT3TextBabbage001],

  getNumTokens: (prompt: string) => {
    return getNumGpt3Tokens(prompt);
  },
};
