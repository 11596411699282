import _ from 'lodash';
import {
  Line,
  LineChart,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
} from 'recharts';

import { Box } from '@mui/material';
import theme, { Colors } from 'frontend/theme';

export interface FinetuningMetrics {
  data: Record<string, any>[];
  dataNames: string[];
  xLabel: string;
  yLabel: string;
  title: string;
}

const COLORS = ['#8884d8', '#82ca9d'];

export default function MetricsChart({ metrics }: { metrics: FinetuningMetrics }) {
  return (
    <Box
      sx={{
        '& .recharts-text': {
          fontSize: 12,
          fontFamily: theme.typography.fontFamily,
          color: Colors.CoolGray70,
        },
        '& .recharts-label': {
          fontSize: 14,
        },
        '& .recharts-default-tooltip': {
          border: 'none',
        },
      }}
    >
      <ResponsiveContainer width="100%" height={240}>
        <LineChart data={metrics.data} margin={{ top: 12, right: 12, bottom: 16, left: 24 }}>
          <XAxis dataKey="name">
            <Label value={metrics.xLabel} position="bottom" />
          </XAxis>
          <YAxis>
            <Label value={metrics.yLabel} position="insideBottomLeft" angle={-90} />
          </YAxis>
          <CartesianGrid stroke={Colors.CoolGray30} />
          {metrics.dataNames.map((name, i) => (
            <Line key={name} type="monotone" dataKey={name} stroke={COLORS[i]} />
          ))}
          <Legend verticalAlign="top" />
          <Tooltip
            labelStyle={{ display: 'none' }}
            wrapperStyle={{
              border: 'none',
              strokeWidth: 0,
              boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.15)',
            }}
            contentStyle={{ border: 'none', color: Colors.CoolGray70, fontSize: 12 }}
            animationDuration={0}
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
}
