import { Chip, Typography } from '@mui/material';
import { Data } from '@scale/llm-shared/interfaces/data';

import FlexBox from 'frontend/components/FlexBox';

export function DataNameChip({ name }: { name: string }) {
  return (
    <FlexBox>
      <Typography variant="subtitle1">Dataset:</Typography>
      <Chip size="small" label={name}></Chip>
    </FlexBox>
  );
}
