import React, { useEffect } from 'react';

import _ from 'lodash';
import { useLocation } from 'react-router-dom';

import { navigationPages } from 'frontend/components/navigation';
import { page, track } from 'frontend/utils/analytics';

const pathNameToTitle = _.fromPairs(
  Object.values(navigationPages).map(val => [val.path, val.title]),
);

/**
 * Tracks when a page gets viewed.
 */
export function useAnalytics() {
  const location = useLocation();

  useEffect(() => {
    const basePath = _.trim(location.pathname, '/');

    const title = pathNameToTitle[basePath];
    if (title) {
      track(`${title} Page Viewed`);
      page(undefined, title);
    }
  }, [location.pathname]);
}
