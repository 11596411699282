import { useCallback } from 'react';

import * as R from 'ramda';

import { App } from '@prisma/client';
import { AppCreate } from '@scale/llm-shared/interfaces/app';
import { useMutation } from '@tanstack/react-query';

import { client } from 'frontend/api/trpc';
import { useLLMNavigation } from 'frontend/hooks/useLLMNavigation';
import { useAppsPageState } from 'frontend/models/useAppsPageState';
import { useAppStore } from 'frontend/stores/AppStore';
import { StoredApp } from 'frontend/stores/types';
import { useUserStore } from 'frontend/stores/UserStore';
import { convertClientAppCreateToPrismaAppUpdate } from 'frontend/utils/transformations';

export default function useApi() {
  const userStore = useUserStore(R.pick(['user', 'setUser']));
  const appStore = useAppStore(R.pick(['createApp', 'updateApp', 'setAppById']));
  const { setShowWizard } = useAppsPageState(R.pick(['setShowWizard']));
  const { goToPromptPage } = useLLMNavigation();

  const agreeToPublicApps = useCallback(() => {
    const user = userStore.user;
    if (!user) {
      return;
    }
    const agreedToPublicAppsAt = new Date();
    userStore.setUser({
      ...user,
      agreedToPublicAppsAt,
    });
    return client.mutation('user.updateAgreedToPublicAppsAt', {
      agreedToPublicAppsAt,
    });
  }, [userStore]);

  const updateOpenAiApiKey = useCallback(
    (openAiApiKey: string) => {
      const user = userStore.user;
      if (!user) {
        return;
      }
      userStore.setUser({
        ...user,
        openAiApiKey,
      });
      return client.mutation('user.updateOpenAiApiKey', {
        openAiApiKey,
      });
    },
    [userStore],
  );

  const createApp = useMutation(
    (app: AppCreate) => {
      return client.mutation('app.create', app) as Promise<StoredApp>;
    },
    {
      onSuccess: (data: StoredApp, _variables: AppCreate, _context: any) => {
        appStore.createApp(data);
        goToPromptPage(undefined, data);
        // Use timeout so that the screen doesn't flash to the apps page
        setTimeout(() => setShowWizard(false), 500);
      },
    },
  );

  const updateApp = useMutation(
    (app: App) => {
      const clientAppObj = convertClientAppCreateToPrismaAppUpdate(app);
      return client.mutation('app.update', {
        data: clientAppObj,
        where: { id: app.id },
      });
    },
    {
      onSuccess: (data: any, _variables: App, _context: any) => {
        appStore.updateApp(data);
      },
    },
  );

  const archiveApp = useMutation(
    (app: App) => {
      // Optimistic delete
      appStore.updateApp({ ...app, isDeleted: true });
      return client.mutation('app.update', {
        data: { isDeleted: true },
        where: { id: app.id },
      });
    },
    {
      onError: (err: any, appVariable: App) => {
        console.error(`Archiving for app ${appVariable.id} failed`);
        appStore.updateApp({ ...appVariable, isDeleted: false });
      },
    },
  );

  const forkApp = useMutation(
    (app: App) => {
      return client.mutation('app.fork', { forkedAppId: app.id });
    },
    {
      onSuccess: (data: any, _variables: App, _context: any) => {
        appStore.createApp(data);
        goToPromptPage(undefined, data);
      },
    },
  );

  const setAppToPublic = useMutation(
    (app: StoredApp) => client.mutation('app.setPublic', { id: app.id }),
    {
      onSuccess: (data: any, _variables: StoredApp, _context: any) => {
        if (data && Object.keys(data).length) {
          appStore.updateApp(data);
        }
      },
    },
  );

  const setAppToPrivate = useMutation(
    (app: StoredApp) => client.mutation('app.setPrivate', { id: app.id }),
    {
      onSuccess: (data: any, _variables: StoredApp, _context: any) => {
        if (data && Object.keys(data).length) {
          appStore.updateApp(data);
        }
      },
    },
  );

  return {
    agreeToPublicApps,
    updateOpenAiApiKey,
    createApp,
    updateApp,
    forkApp,
    archiveApp,
    setAppToPublic,
    setAppToPrivate,
  };
}
