import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Version 2: Remove the V2 modifiers when this is fully launched
import { AppOverviewPage as AppOverviewPageV2 } from 'frontend/pages/v2/AppOverviewPage';
import { ComparePage as ComparePageV2 } from 'frontend/pages/v2/ComparePage';
import { DeploymentsPage as DeploymentsPageV2 } from 'frontend/pages/v2/DeploymentsPage';
import { EvaluationsPage as EvaluationsPageV2 } from 'frontend/pages/v2/EvaluationsPage';
import { FineTuningPage as FineTuningPageV2 } from 'frontend/pages/v2/FineTuningPage';
import { PromptPage as PromptPageV2 } from 'frontend/pages/v2/PromptPage';
import { VariantsPage as VariantsPageV2 } from 'frontend/pages/v2/VariantsPage';

export const EVALUATION_PAGE_TITLE = 'Evaluations';

export const navigationPages = [
  {
    path: '',
    title: 'Overview',
    icon: <FontAwesomeIcon icon="home" />,
    elementV2: <AppOverviewPageV2 />,
    requiresVariant: false,
  },
  {
    path: 'prompt',
    title: 'Prompt',
    icon: <FontAwesomeIcon icon="flask" />,
    elementV2: <PromptPageV2 />,
    requiresVariant: false,
  },
  {
    path: 'fine-tuning',
    title: 'Fine Tuning',
    icon: <FontAwesomeIcon icon="gears" />,
    elementV2: <FineTuningPageV2 />,
    requiresVariant: false,
  },
  {
    path: 'app-variants',
    title: 'App Variants',
    icon: <FontAwesomeIcon icon="cubes" />,
    elementV2: <VariantsPageV2 />,
    requiresVariant: true,
  },
  {
    path: 'app-compare',
    title: 'Compare',
    icon: <FontAwesomeIcon icon="scale-balanced" />,
    elementV2: <ComparePageV2 />,
    requiresVariant: true,
  },
  {
    path: 'evaluations',
    title: EVALUATION_PAGE_TITLE,
    icon: <FontAwesomeIcon icon="book-open-reader" />,
    elementV2: <EvaluationsPageV2 />,
    requiresVariant: true,
  },
  {
    path: 'deployments',
    title: 'Deployments',
    icon: <FontAwesomeIcon icon="upload" />,
    elementV2: <DeploymentsPageV2 />,
    requiresVariant: true,
  },
] as const;

export type NavigationPageName = typeof navigationPages[number]['path'];

export type NavigationPage = typeof navigationPages[number];

export type GeneralPageName = 'apps' | 'data' | 'home' | 'settings' | 'explore';

export type PageName = NavigationPageName | GeneralPageName;
