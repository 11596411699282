import { Link } from 'react-router-dom';

import logo from 'frontend/assets/spellbook-logo.svg';
import { useParamsNavigate } from 'frontend/hooks/useLLMNavigation';

export function SpellbookLogo() {
  const { paramsUrl } = useParamsNavigate();

  return (
    <Link to={paramsUrl('/')}>
      <img src={logo} alt="Spellbook logo" style={{ height: 38 }} />
    </Link>
  );
}
