import { useMemo } from 'react';

import { Link } from 'react-router-dom';

import { Typography } from '@mui/material';
import {
  MAX_ENABLED_DEPLOYMENTS_ERROR_MESSAGE,
  NO_PAYMENT_METHOD_ERROR_MESSAGE,
  NO_PRIVATE_APPS_ERROR_MESSAGE,
} from '@scale/llm-shared/consts/errors';

import { useParamsNavigate } from 'frontend/hooks/useLLMNavigation';
import { useUserStore } from 'frontend/stores/UserStore';
import theme from 'frontend/theme';
import { isError } from 'frontend/utils/errors';

export function ErrorMessage({ error }: { error?: any }): JSX.Element | null {
  const { paramsUrl } = useParamsNavigate();
  const settingsPageUrl = useMemo(() => paramsUrl('/settings'), [paramsUrl]);

  const message = useMemo(() => {
    if (!error || !isError(error)) {
      return;
    }

    switch (error.message) {
      case MAX_ENABLED_DEPLOYMENTS_ERROR_MESSAGE:
        return 'You have reached the max number of enabled deployments. To enable more deployments, upgrade your plan.';
      case NO_PRIVATE_APPS_ERROR_MESSAGE:
        return 'You must be on the Team or Pro plan to create private apps.';
      case NO_PAYMENT_METHOD_ERROR_MESSAGE: {
        if (useUserStore.getState().canVerifyPhoneNumber()) {
          return (
            <span>
              To continue to use Spellbook, verify your account for free credits or add a payment
              method in{' '}
              <Link to={settingsPageUrl} style={{ color: theme.palette.primary.main }}>
                account settings
              </Link>{' '}
              under the Billing tab. Thank you for your support!
            </span>
          );
        }
        return (
          <span>
            To continue to use Spellbook, please add a payment method in{' '}
            <Link to={settingsPageUrl} style={{ color: theme.palette.primary.main }}>
              account settings
            </Link>{' '}
            under the Billing tab. Thank you for your support!
          </span>
        );
      }
      default:
        return error.message;
    }
  }, [error?.message, settingsPageUrl]);

  if (!message) {
    return null;
  }

  return <Typography color="error">{message}</Typography>;
}
