import React, { MouseEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import _ from 'lodash';
import * as R from 'ramda';
import { useDebounce } from 'react-use';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Box, Divider, Link as ExternalLink, Tooltip, Typography } from '@mui/material';
import { Data, DataRow } from '@scale/llm-shared/interfaces/data';
import { ModelParametersCreate } from '@scale/llm-shared/interfaces/modelParameters';
import { PromptCreate } from '@scale/llm-shared/interfaces/prompt';
import { Variant, VariantCreate } from '@scale/llm-shared/interfaces/variant';
import { BaseModelInternalId } from '@scale/llm-shared/modelProviders/types';
import {
  exampleKeywordRe,
  parseExampleVariables,
  validateTemplateVariables,
} from '@scale/llm-shared/templating';
import { logger } from '@scale/llm-shared/utils/logging';

import { Container } from 'frontend/components/Container';
import { MultiInputPreview } from 'frontend/components/evaluation/MultiInputPreview';
import FlexBox from 'frontend/components/FlexBox';
import PageTitle from 'frontend/components/PageTitle';
import { SegmentedButtons } from 'frontend/components/SegmentedButtons';
import { VSpace } from 'frontend/components/Spacer';
import { useLLMNavigation } from 'frontend/hooks/useLLMNavigation';
import usePageError from 'frontend/hooks/usePageError';
import useRandom from 'frontend/hooks/useRandom';
import { usePromptPageState } from 'frontend/models/v2/usePromptPageState';
import LoadingPage from 'frontend/pages/LoadingPage';
import PageContainer from 'frontend/pages/PageContainer/PageContainer';
import { CreateVariantButton } from 'frontend/pages/v2/PromptPage/CreateVariantButton';
import { FewShotExamplesGrid } from 'frontend/pages/v2/PromptPage/FewShotExamplesGrid';
import { useAppStore } from 'frontend/stores/AppStore';
import { useModelStore } from 'frontend/stores/ModelStore';
import { LoadingState, useUserStore } from 'frontend/stores/UserStore';
import { useDataStore } from 'frontend/storesV2/DataStore';
import { useFinetuneStore } from 'frontend/storesV2/FinetuneStore';
import { useSelectionStore } from 'frontend/storesV2/SelectionStore';
import { useVariantStore } from 'frontend/storesV2/VariantStore';
import { Colors } from 'frontend/theme';
import { track } from 'frontend/utils/analytics';
import { padArray } from 'frontend/utils/array';

import { PromptParametersEditor } from './PromptParametersEditor';

const PROMPT_DISABLED_DUE_TO_FINETUNE_MESSAGE = `This prompt cannot be edited, as it was set when the model was finetuned.`;

export function PromptPage() {
  const { goToHomePage } = useLLMNavigation();
  const { appsInitLoaded } = useAppStore(R.pick(['appsInitLoaded']));
  const { loadingState } = useUserStore(R.pick(['loadingState']));

  if (appsInitLoaded) {
    return <PromptPageInner />;
  }

  switch (loadingState) {
    case LoadingState.Error:
    case LoadingState.Unauthorized:
    case LoadingState.Unauthenticated:
      if (process.env.NODE_ENV !== 'production') console.warn('No user, redirecting to home');
      goToHomePage();
      return null;
  }

  return <LoadingPage />;
}

const PromptTab = {
  Editor: 'Editor',
  Examples: 'Few-Shot Examples',
} as const;
const TabLabels = Object.entries(PromptTab).map(([, value]) => ({ text: value, value }));

const minEditorHeight = 100;

function CompletionTextEditor({
  prompt,
  setPrompt,
  editorHeight,
  disabled,
}: {
  prompt: Partial<PromptCreate>;
  setPrompt: (
    state: Partial<PromptCreate> | ((state: Partial<PromptCreate>) => Partial<PromptCreate>),
  ) => void;
  editorHeight: number;
  disabled?: boolean;
}) {
  return (
    <Container
      sx={{
        height: editorHeight,
        width: '100%',
        minWidth: 400,
        flexShrink: 5,
        overflow: 'auto',
        padding: 0,
      }}
    >
      <textarea
        className="monospace"
        style={{
          position: 'relative',
          height: '100%',
          padding: 16,
          lineHeight: 1.4,
          resize: 'none',
          borderRadius: '8px',
          border: 'none',
        }}
        disabled={disabled}
        value={prompt.template}
        onChange={e =>
          setPrompt(prompt => ({
            ...prompt,
            template: e.target.value,
          }))
        }
        placeholder={'Create a new prompt or use an existing prompt template'}
        rows={36}
      />
    </Container>
  );
}

function ChatTextEditor({
  prompt,
  setPrompt,
  editorHeight,
  disabled,
}: {
  prompt: Partial<PromptCreate>;
  setPrompt: (
    state: Partial<PromptCreate> | ((state: Partial<PromptCreate>) => Partial<PromptCreate>),
  ) => void;
  editorHeight: number;
  disabled?: boolean;
}) {
  return (
    <FlexBox sx={{ width: '100%', flexDirection: 'row' }}>
      <Container
        sx={{
          height: editorHeight,
          width: '40%',
          minWidth: 200,
          flexDirection: 'column',
          flexShrink: 5,
          overflow: 'auto',
          padding: 0,
          '&:focus-within': {
            outline: '2px solid blue',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: 2,
          }}
        >
          <Typography variant="h2">System Message</Typography>
          <Tooltip title="The system message provides general guidance to the model on how to respond to user queries. You can leave this blank to exclude a system message.">
            <Box component="span" ml={0.5} color={Colors.CoolGray40}>
              <FontAwesomeIcon icon="circle-info" size="sm" />
            </Box>
          </Tooltip>
        </Box>
        <VSpace s={-3} />
        <textarea
          className="monospace"
          style={{
            position: 'relative',
            height: '100%',
            padding: 16,
            lineHeight: 1.4,
            resize: 'none',
            borderRadius: '8px',
            border: 'none',
            outline: 'none',
          }}
          value={prompt.systemMessage}
          onChange={e =>
            setPrompt(prompt => ({
              ...prompt,
              systemMessage: e.target.value,
            }))
          }
          disabled={disabled}
          placeholder={'Create a system message or leave blank to exclude'}
          rows={36}
        />
      </Container>
      <Container
        sx={{
          height: editorHeight,
          width: '60%',
          minWidth: 400,
          flexShrink: 5,
          overflow: 'auto',
          padding: 0,
          '&:focus-within': {
            outline: '2px solid blue',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: 2,
          }}
        >
          <Typography variant="h2">User</Typography>
          <Tooltip
            title={
              'The user message is the first query made to the model. This is the same as a typical "prompt" for single-turn applications.'
            }
          >
            <Box component="span" ml={0.5} color={Colors.CoolGray40}>
              <FontAwesomeIcon icon="circle-info" size="sm" />
            </Box>
          </Tooltip>
        </Box>
        <VSpace s={-3} />
        <textarea
          className="monospace"
          style={{
            position: 'relative',
            height: '100%',
            padding: 16,
            lineHeight: 1.4,
            resize: 'none',
            borderRadius: '8px',
            border: 'none',
            outline: 'none',
          }}
          value={prompt.template}
          onChange={e =>
            setPrompt(prompt => ({
              ...prompt,
              template: e.target.value,
            }))
          }
          placeholder={'Create a new prompt or use an existing prompt template'}
          rows={36}
        />
      </Container>
    </FlexBox>
  );
}

function TextEditor({
  modelId,
  prompt,
  setPrompt,
  editorHeight,
  disabled,
}: {
  modelId: string | undefined;
  prompt: Partial<PromptCreate>;
  setPrompt: (
    state: Partial<PromptCreate> | ((state: Partial<PromptCreate>) => Partial<PromptCreate>),
  ) => void;
  editorHeight: number;
  disabled?: boolean;
}) {
  switch (modelId) {
    case BaseModelInternalId.GPT3_5Turbo0301:
    case BaseModelInternalId.GPT3_5Turbo16k:
    case BaseModelInternalId.GPT4:
    case BaseModelInternalId.GPT4_32K:
      return (
        <ChatTextEditor
          prompt={prompt}
          setPrompt={setPrompt}
          editorHeight={editorHeight}
          disabled={disabled}
        />
      );
    default:
      return (
        <CompletionTextEditor
          prompt={prompt}
          setPrompt={setPrompt}
          editorHeight={editorHeight}
          disabled={disabled}
        />
      );
  }
}

export function PromptPageInner() {
  const [selectedTab, setSelectedTab] = useState<string>(PromptTab.Editor);
  const [isGrabbing, setIsGrabbing] = useState<boolean>(false);

  const {
    unsavedPrompt: prompt,
    setUnsavedPrompt,
    unsavedModelParameters: modelParameters,
    unsavedVariant: variant,
    setRecentlySetFromTemplate,
  } = usePromptPageState();
  const { variablesSourceDataId } = prompt;

  const { user } = useUserStore(R.pick(['user']));
  const { selectedApp } = useSelectionStore(R.pick(['selectedApp']));
  const { finetuneById } = useFinetuneStore(R.pick(['finetuneById']));

  const { modelById } = useModelStore(R.pick(['modelById']));
  const { goToVariantsPage } = useLLMNavigation();
  const { createVariant } = useVariantStore(R.pick(['createVariant']));

  // When setting the prompt from the interface, make sure to override the dirty bit
  const setPrompt = R.pipe(setUnsavedPrompt, () => setRecentlySetFromTemplate(false));

  // Dataset can be set or unset
  const { dataById } = useDataStore(R.pick(['dataById']));
  const variablesSourceData = useMemo(() => {
    return variablesSourceDataId == null ? variablesSourceDataId : dataById[variablesSourceDataId];
  }, [variablesSourceDataId, dataById]);

  // Drag to adjust height logic -- edits the interal components manually
  const [editorHeight, setEditorHeight] = useState<number>(500);
  const promptEditorRef = useRef<HTMLTextAreaElement>(null);
  const handleDragStart: MouseEventHandler = useCallback(
    e => {
      e.preventDefault();
      const drawerHeightStart = editorHeight;
      const dragStartY = e.clientY;

      const handleDragMove = ((e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        const dy = dragStartY - e.clientY;
        const newHeight = Math.max(drawerHeightStart - dy, minEditorHeight);
        setEditorHeight(newHeight);
      }) as unknown as EventListener; // TODO fix this later
      setIsGrabbing(true);

      const handleDragEnd = () => {
        setIsGrabbing(false);
        window.removeEventListener('mousemove', handleDragMove);
        window.removeEventListener('mouseup', handleDragEnd);
      };
      window.addEventListener('mousemove', handleDragMove);
      window.addEventListener('mouseup', handleDragEnd);
    },
    [editorHeight, promptEditorRef],
  );

  const { showError } = usePageError();

  // If the model is finetuned
  const finetune = useMemo(() => {
    if (!modelParameters.modelId || !finetuneById || Object.keys(finetuneById).length === 0) {
      return undefined;
    }
    const model = modelById[modelParameters.modelId];
    if (!model.dataId) {
      // Semi hack to see if model has a finetune or not
      return undefined;
    }
    const finetune = Object.values(finetuneById).find(
      finetune => finetune.modelId === modelParameters.modelId,
    );

    return finetune;
  }, [modelParameters.modelId, finetuneById]);

  const [promptError, setPromptError] = useState<string | null>(null);
  useDebounce(
    () => {
      let error = validateTemplateVariables(
        finetune ? finetune.finetuneParameters.prompt : prompt,
        variablesSourceData,
      );
      if (finetune && error) {
        error =
          error +
          " Consider switching your dataset to one that has columns matching the finetuned model's prompt variables.";
      }
      setPromptError(error);
    },
    500,
    [prompt, variablesSourceData, finetune],
  );

  const processedTabLabels = useMemo(() => {
    if (!finetune) {
      return TabLabels;
    }
    // Disable the examples tab when it is a finetuned model
    return Object.entries(PromptTab).map(([, value]) => ({
      text: value,
      value,
      disabled: value === PromptTab.Examples,
    }));
  }, [finetune]);

  // The draft variant is the current one we are editing. It is not saved
  // yet, and so we have to construct a draft one based on the current input elements
  const draftVariant: VariantCreate | undefined = useMemo(() => {
    if (!selectedApp || !prompt.template || !prompt.exampleVariables) {
      return;
    }
    return {
      name: 'New Variant',
      appId: selectedApp.id,
      taxonomy: variant.taxonomy,
      // Hacky :(
      prompt: finetune ? finetune.finetuneParameters.prompt : (prompt as PromptCreate),
      modelParameters: modelParameters as ModelParametersCreate,
    };
  }, [selectedApp, variant, prompt, modelParameters, finetune]);

  const buildAndCreateVariant = useCallback(
    async (name: string) => {
      if (!selectedApp) {
        logger.error('No selected app selected');
        return;
      }
      if (!user) {
        logger.error('No user selected');
        return;
      }

      const newVariant: Partial<Variant> = {
        ...variant,
        name,
        appId: selectedApp.id,
      };

      const promptToUse = finetune ? finetune.finetuneParameters.prompt : (prompt as PromptCreate);

      // TODO: need better consolidation of all the chat vs. completion demuxing
      switch (modelParameters.modelId) {
        case BaseModelInternalId.GPT4:
        case BaseModelInternalId.GPT4_32K:
        case BaseModelInternalId.GPT3_5Turbo0301:
          break;
        default:
          prompt.systemMessage = undefined;
      }
      if (
        validVariant(newVariant) &&
        validPrompt(promptToUse, variablesSourceData) &&
        validModelParameters(modelParameters)
      ) {
        const storedVariant = {
          ...newVariant,
          prompt: promptToUse,
          modelParameters,
        };
        return await createVariant(storedVariant);
      } else {
        console.error('Invalid variant inputs');
      }
    },
    [selectedApp, variant, createVariant, prompt, modelParameters, user, finetune],
  );

  const handleCreateVariant = useCallback(
    (name: string) => {
      track('Prompt Variant Created', { name });
      buildAndCreateVariant(name)
        .then(() => goToVariantsPage())
        .catch((error: Error) => showError(error.message));
    },
    [buildAndCreateVariant, goToVariantsPage],
  );

  useEffect(() => {
    if (isGrabbing) {
      document.body.style.cursor = 'grabbing';
    } else {
      document.body.style.cursor = 'default';
    }
  }, [isGrabbing]);

  const { random } = useRandom();

  // Nested map: dataId -> key -> DataRow (key as in example[key].column)
  const [sampleRowsMap, setSampleRowsMap] = useState<{
    [dataId: string]: { [key: string]: DataRow | null };
  }>({});

  const assignExampleVariables = useCallback(
    (variableFullNames: string[]) => {
      // Get all keys (example[key].column) and try resampling rows
      const keys = variableFullNames.map(n => (n.match(exampleKeywordRe) ?? [])[1]);
      if (!keys.length) return;

      if (!variablesSourceData?.rowByIndex) return;
      const sampleRowsByKey = sampleRowsMap[variablesSourceData.id];

      // Exclude rows still in use from the sampling population
      const reusedKeys: string[] = [];
      const newKeys: string[] = [];
      const usedIndices = new Set();
      keys.forEach(k => {
        if (!sampleRowsByKey || !sampleRowsByKey[k]) {
          newKeys.push(k);
          return;
        }
        usedIndices.add(sampleRowsByKey[k]?.index);
        reusedKeys.push(k);
      });
      const unusedRows = Object.values(variablesSourceData.rowByIndex).filter(
        r => !usedIndices.has(r.index),
      );

      // Sample enough new rows to fill new keys
      const newSampleRows = padArray(
        random.sampleSize(unusedRows, newKeys.length),
        null,
        newKeys.length,
      );
      const newSampleRowsByKey = _.fromPairs([
        ...newKeys.map((k, i) => [k, newSampleRows[i]]),
        ...reusedKeys.map(k => [k, sampleRowsByKey[k]]), // overwrite with old value
      ]);

      setSampleRowsMap({
        ...sampleRowsMap,
        [variablesSourceData.id]: newSampleRowsByKey,
      });

      // Assign variables in struct shaped { [variable]: value }
      const exampleVariables: Record<string, string> = {};
      for (const fullName of variableFullNames) {
        const [, key, colName] = fullName.match(exampleKeywordRe) ?? [];

        const row = newSampleRowsByKey[key];
        // These validations should be redundant based on validateTemplateVariables
        if (!row) {
          setPromptError(
            `{{ ${fullName} }} is a template variable reserved for inserting few shot examples` +
              ', but could not be set because there are not enough rows in your dataset.',
          );
          return;
        }
        const value = row.valueByName[colName];
        if (!value) {
          setPromptError(
            `Could not find a column named "${colName}" in your dataset; consider editing {{ ${fullName} }} to contain a valid column name.`,
          );
          return;
        }
        exampleVariables[fullName] = value;
      }

      setPrompt(prompt => ({
        ...prompt,
        exampleVariables,
        variablesSourceDataId: variablesSourceData.id,
      }));
    },
    [random, sampleRowsMap, setPrompt, prompt.template, variablesSourceData],
  );

  // On edit, validate query/example vars and attempt to assign example vars
  useDebounce(
    () => {
      try {
        assignExampleVariables(parseExampleVariables(prompt.template ?? ''));
      } catch (err) {
        // Error will be surfaced to PageError component
      }
    },
    200,
    [prompt.template, variablesSourceData?.id, prompt.variablesSourceDataId],
  );

  return (
    <PageContainer page="prompt" sx={{ paddingBottom: 0 }}>
      <PageTitle
        title="Prompt"
        subtitle={
          <>
            A great prompt can dramatically improve the quality of your application.{' '}
            <ExternalLink
              sx={{ textDecoration: 'none' }}
              href="https://spellbook.readme.io/docs/prompt-engineering"
              target="_blank"
              rel="noopener noreferrer"
            >
              Get tips on writing prompts here
            </ExternalLink>
            .
          </>
        }
      />

      <FlexBox sx={{ justifyContent: 'space-between' }}>
        <SegmentedButtons
          labels={processedTabLabels}
          selectedLabel={selectedTab}
          onClick={setSelectedTab}
        />
        <FlexBox>
          <CreateVariantButton
            disabled={!!promptError}
            onCreateVariant={handleCreateVariant}
            promptError={promptError}
          />
        </FlexBox>
      </FlexBox>
      <VSpace s={1} />
      {promptError && (
        <>
          <Alert severity="error">{promptError}</Alert>
          <VSpace s={1} />
        </>
      )}
      {!!finetune && (
        <>
          <Alert severity="warning">{PROMPT_DISABLED_DUE_TO_FINETUNE_MESSAGE}</Alert>
          <VSpace s={1} />
        </>
      )}
      <FlexBox sx={{ width: '100%' }}>
        {selectedTab === PromptTab.Editor && (
          <TextEditor
            modelId={modelParameters.modelId}
            prompt={finetune ? finetune.finetuneParameters.prompt : prompt}
            setPrompt={setPrompt}
            editorHeight={editorHeight}
            disabled={!!finetune}
          />
        )}

        {selectedTab === PromptTab.Examples && (
          <Container
            sx={{
              height: editorHeight,
              width: '100%',
              minWidth: 400,
              flexShrink: 1,
              overflow: 'auto',
            }}
          >
            <FewShotExamplesGrid dataId={variablesSourceDataId} prompt={prompt} />
          </Container>
        )}
        <Container
          sx={{
            height: editorHeight,
            flexBasis: 350,
            maxWidth: 350,
            minWidth: 260,
            flexShrink: 2,
            overflow: 'auto',
          }}
        >
          <PromptParametersEditor usingFinetunedModel={!!finetune} />
        </Container>
      </FlexBox>
      <Box>
        <Divider sx={{ cursor: isGrabbing ? 'grabbing' : 'grab' }} onMouseDown={handleDragStart}>
          <FontAwesomeIcon icon="grip-lines" color={Colors.CoolGray40} />
        </Divider>
      </Box>
      {draftVariant && (
        <MultiInputPreview variants={[draftVariant]} dataId={variablesSourceDataId} size="small" />
      )}
    </PageContainer>
  );
}

function validVariant(value: any): value is VariantCreate {
  return !R.props(['name', 'taxonomy', 'appId', 'promptId', 'modelParametersId'], value).find(
    v => v == null,
  );
}

function validPrompt(
  value: PromptCreate,
  sourceData: Data | null | undefined,
): value is PromptCreate {
  if (R.props(['template', 'exampleVariables'], value).find(v => v == null)) {
    return false;
  }
  const templateVariableError = validateTemplateVariables(value, sourceData);
  if (templateVariableError) {
    return false;
  }
  return true;
}

function validModelParameters(value: any): value is ModelParametersCreate {
  return !R.props(['modelId', 'modelType'], value).find(v => v == null);
}
