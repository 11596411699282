import { OpenAiBaseModelInternalId } from '@scale/llm-shared/modelProviders/openAi/consts';

import { OpenAiGPT3_5Turbo0301 } from './OpenAiGPT3_5Turbo0301';
import { OpenAiGPT3_5Turbo16k } from './OpenAiGPT3_5Turbo16k';
import { OpenAiGPT3TextAda001 } from './OpenAiGPT3TextAda001';
import { OpenAiGPT3TextBabbage001 } from './OpenAiGPT3TextBabbage001';
import { OpenAiGPT3TextCurie001 } from './OpenAiGPT3TextCurie001';
import { OpenAiGPT3TextDavinci001 } from './OpenAiGPT3TextDavinci001';
import { OpenAiGPT3TextDavinci002 } from './OpenAiGPT3TextDavinci002';
import { OpenAiGPT3TextDavinci003 } from './OpenAiGPT3TextDavinci003';
import { OpenAiGPT4 } from './OpenAiGPT4';
import { OpenAiGPT4_32K } from './OpenAiGPT4_32K';

export const GetOpenAiBaseModelFromInternalId = {
  [OpenAiBaseModelInternalId.GPT3TextAda001]: OpenAiGPT3TextAda001,
  [OpenAiBaseModelInternalId.GPT3TextBabbage001]: OpenAiGPT3TextBabbage001,
  [OpenAiBaseModelInternalId.GPT3TextCurie001]: OpenAiGPT3TextCurie001,
  [OpenAiBaseModelInternalId.GPT3TextDavinci001]: OpenAiGPT3TextDavinci001,
  [OpenAiBaseModelInternalId.GPT3TextDavinci002]: OpenAiGPT3TextDavinci002,
  [OpenAiBaseModelInternalId.GPT3TextDavinci003]: OpenAiGPT3TextDavinci003,
  [OpenAiBaseModelInternalId.GPT3_5Turbo0301]: OpenAiGPT3_5Turbo0301,
  [OpenAiBaseModelInternalId.GPT3_5Turbo16k]: OpenAiGPT3_5Turbo16k,
  [OpenAiBaseModelInternalId.GPT4]: OpenAiGPT4,
  [OpenAiBaseModelInternalId.GPT4_32K]: OpenAiGPT4_32K,
};
