import {
  OpenAiBaseModelDisplayName,
  OpenAiBaseModelFineTuneEndpoint,
  OpenAiBaseModelInternalId,
} from '@scale/llm-shared/modelProviders/openAi/consts';
import { getNumGpt3Tokens } from '@scale/llm-shared/tokenizers/countTokens';
import { ChatMessage } from '@scale/llm-shared/types/openAi';

import type { IOpenAiMultiTurnBaseModel } from '@scale/llm-shared/modelProviders/openAi/types';

const GPT3_5TURBO_TOKENS_PER_MESSAGE = 5;

const CENTS_PER_MILLION_TOKENS = 2000;

export const OpenAiGPT3_5Turbo0301: IOpenAiMultiTurnBaseModel = {
  id: OpenAiBaseModelInternalId.GPT3_5Turbo0301,
  displayName: OpenAiBaseModelDisplayName[OpenAiBaseModelInternalId.GPT3_5Turbo0301],
  minTokensAllowed: 0,
  maxTokensAllowed: 4096,
  minTemperatureAllowed: 0,
  maxTemperatureAllowed: 2,
  externalModelId: 'gpt-3.5-turbo',
  costCentsPerMillionPromptTokens: CENTS_PER_MILLION_TOKENS,
  costCentsPerMillionCompletionTokens: CENTS_PER_MILLION_TOKENS,
  finetuneEndpoint: OpenAiBaseModelFineTuneEndpoint[OpenAiBaseModelInternalId.GPT3_5Turbo0301],

  getNumTokens: (messages: ChatMessage[]) => {
    return (
      getNumGpt3Tokens(messages.map(m => m.content).join('\n')) +
      GPT3_5TURBO_TOKENS_PER_MESSAGE * messages.length
    );
  },
};
