import {
  OpenAiBaseModelDisplayName,
  OpenAiBaseModelFineTuneEndpoint,
  OpenAiBaseModelInternalId,
} from '@scale/llm-shared/modelProviders/openAi/consts';
import { getNumGpt3Tokens } from '@scale/llm-shared/tokenizers/countTokens';
import { ChatMessage } from '@scale/llm-shared/types/openAi';

import type { IOpenAiMultiTurnBaseModel } from '@scale/llm-shared/modelProviders/openAi/types';
const GPT4_32K_TOKENS_PER_MESSAGE = 4;

export const OpenAiGPT4_32K: IOpenAiMultiTurnBaseModel = {
  id: OpenAiBaseModelInternalId.GPT4_32K,
  displayName: OpenAiBaseModelDisplayName[OpenAiBaseModelInternalId.GPT4_32K],
  minTokensAllowed: 0,
  maxTokensAllowed: 32768,
  minTemperatureAllowed: 0,
  maxTemperatureAllowed: 2,
  externalModelId: 'gpt-4-32k',
  costCentsPerMillionPromptTokens: 6000,
  costCentsPerMillionCompletionTokens: 12000,
  finetuneEndpoint: OpenAiBaseModelFineTuneEndpoint[OpenAiBaseModelInternalId.GPT4_32K],

  getNumTokens: (messages: ChatMessage[]) => {
    return (
      getNumGpt3Tokens(messages.map(m => m.content).join('\n')) +
      GPT4_32K_TOKENS_PER_MESSAGE * messages.length
    );
  },
};
