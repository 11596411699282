export const OpenAiSingleTurnBaseModelInternalId = {
  GPT3TextAda001: 'GPT3TextAda001',
  GPT3TextBabbage001: 'GPT3TextBabbage001',
  GPT3TextCurie001: 'GPT3TextCurie001',
  GPT3TextDavinci001: 'GPT3TextDavinci001',
  GPT3TextDavinci002: 'GPT3TextDavinci002',
  GPT3TextDavinci003: 'GPT3TextDavinci003',
} as const;

export type OpenAiSingleTurnBaseModelInternalId =
  typeof OpenAiSingleTurnBaseModelInternalId[keyof typeof OpenAiSingleTurnBaseModelInternalId];

export const OpenAiMultiTurnBaseModelInternalId = {
  GPT3_5Turbo0301: 'GPT3_5Turbo0301',
  GPT3_5Turbo16k: 'GPT3_5Turbo16k',
  GPT4: 'GPT4',
  GPT4_32K: 'GPT4_32K',
  // GPT3_5Turbo: 'GPT3_5Turbo',
  // NOTE: until the next release in 2023-04, this rolling release endpoint is the same as -0301.
  // Using -0301 for now (2023-03-01) to ensure variants remain on the same model endpoint
  // (e.g. we did not migrate variants using davinci-002 to use davinci-003).
  // We will make the rolling release (non -0301) available once it is actually different.
  // See https://platform.openai.com/docs/models/gpt-3-5 for more info.
} as const;

export type OpenAiMultiTurnBaseModelInternalId =
  typeof OpenAiMultiTurnBaseModelInternalId[keyof typeof OpenAiMultiTurnBaseModelInternalId];

export const OpenAiBaseModelInternalId = {
  ...OpenAiSingleTurnBaseModelInternalId,
  ...OpenAiMultiTurnBaseModelInternalId,
} as const;

export const OpenAiBaseModelDisplayName: Record<OpenAiBaseModelInternalId, string> = {
  GPT3TextAda001: 'GPT-3 Text Ada 001',
  GPT3TextBabbage001: 'GPT-3 Text Babbage 001',
  GPT3TextCurie001: 'GPT-3 Text Curie 001',
  GPT3TextDavinci001: 'GPT-3 Text Davinci 001',
  GPT3TextDavinci002: 'GPT-3 Text Davinci 002',
  GPT3TextDavinci003: 'GPT-3 Text Davinci 003',
  GPT3_5Turbo0301: 'GPT-3.5 Turbo', // NOTE: see above note. This is the current model snapshot that will not receive updates. We will change the name back to -0301 once there is a new snapshot.
  GPT3_5Turbo16k: 'GPT-3.5 Turbo 16k',
  GPT4: 'GPT-4',
  GPT4_32K: 'GPT-4 32K',
} as const;

export const OpenAiBaseModelFineTuneEndpoint: Record<
  OpenAiBaseModelInternalId,
  string | undefined
> = {
  GPT3TextAda001: 'ada',
  GPT3TextBabbage001: 'babbage',
  GPT3TextCurie001: 'curie',
  GPT3TextDavinci001: 'davinci',
  GPT3TextDavinci002: undefined,
  GPT3TextDavinci003: undefined,
  GPT3_5Turbo0301: undefined,
  GPT3_5Turbo16k: undefined,
  GPT4: undefined,
  GPT4_32K: undefined,
} as const;

export type OpenAiBaseModelInternalId =
  typeof OpenAiBaseModelInternalId[keyof typeof OpenAiBaseModelInternalId];
export type OpenAiBaseModelDisplayName =
  typeof OpenAiBaseModelDisplayName[keyof typeof OpenAiBaseModelDisplayName];
export type OpenAiBaseModelFineTuneEndpoint =
  typeof OpenAiBaseModelFineTuneEndpoint[keyof typeof OpenAiBaseModelFineTuneEndpoint];

export function isOpenAiBaseModelInternalId(modelId: string): modelId is OpenAiBaseModelInternalId {
  return modelId in OpenAiBaseModelInternalId;
}
