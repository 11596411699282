type Token = {
  type: 'variable' | 'text';
  value: string;
  start: number;
  end: number;
};

const nonSpaceRe = /\S/;
const whiteRe = /[^\S\r\n]*/; // any whitespace except newline
const openingTagRe = /\{\{[^\S\r\n]*/;
const closingTagRe = /[^\S\r\n]*\}\}/;

/**
 * A simple string scanner that is used by the template parser to find
 * tokens in template strings.
 */
class Scanner {
  public string: string;
  public tail: string;
  public pos: number;

  constructor(s: string) {
    this.string = s;
    this.tail = s;
    this.pos = 0;
  }

  /**
   * Returns `true` if the tail is empty (end of string).
   */
  public eos() {
    return this.tail === '';
  }

  /**
   * Tries to match the given regular expression at the current position.
   * Returns the matched text if it can match, the empty string otherwise.
   */
  public scan(re: RegExp) {
    const match = this.tail.match(re);

    if (!match || match.index !== 0) return '';

    const string = match[0];

    this.tail = this.tail.substring(string.length);
    this.pos += string.length;

    return string;
  }

  /**
   * Skips all text until the given regular expression can be matched. Returns
   * the skipped string, which is the entire tail if no match can be made.
   */
  public scanUntil(re: RegExp) {
    let match;
    const index = this.tail.search(re);

    switch (index) {
      case -1:
        match = this.tail;
        this.tail = '';
        break;
      case 0:
        match = '';
        break;
      default:
        match = this.tail.substring(0, index);
        this.tail = this.tail.substring(index);
    }

    this.pos += match.length;

    return match;
  }
}

/**
 * Breaks up the given `template` string into a tree of tokens.
 */
function parseTemplate(template: string) {
  if (!template) return [];
  let tokens: Token[] = []; // Buffer to hold the tokens
  let spaceIndices: number[] = []; // Indices of whitespace tokens on the current line
  let hasTag = false; // Is there a {{tag}} on the current line?
  let nonSpace = false; // Is there a non-space char on the current line?

  // Strips all whitespace tokens array if the line only contains {{tag}} and whitespace.
  function stripSpace() {
    if (hasTag && !nonSpace) {
      spaceIndices.forEach(i => delete tokens[i]);
    } else {
      spaceIndices = [];
    }

    hasTag = false;
    nonSpace = false;
  }

  const scanner = new Scanner(template);

  let start, value, chr;
  while (!scanner.eos()) {
    start = scanner.pos;

    // Match any text between tags.
    value = scanner.scanUntil(openingTagRe);

    if (value) {
      for (let i = 0, valueLength = value.length; i < valueLength; ++i) {
        chr = value.charAt(i);

        if (!nonSpaceRe.test(chr)) {
          spaceIndices.push(tokens.length);
        } else {
          nonSpace = true;
        }

        tokens.push({ type: 'text', value: chr, start, end: start + 1 });
        start += 1;

        // Check for whitespace on the current line.
        if (chr === '\n') {
          stripSpace();
        }
      }
    }

    // Proceed if {{ was matched
    if (!scanner.scan(openingTagRe)) break;

    hasTag = true;
    scanner.scan(whiteRe);
    value = scanner.scanUntil(closingTagRe);

    // Confirm }} is matched on the same line
    if (value.match(/\n/) || !scanner.scan(closingTagRe))
      throw new Error(`There is an unclosed tag {{ ending at position ${scanner.pos}`);

    nonSpace = true;
    tokens.push({ type: 'variable', value, start, end: scanner.pos });
  }

  stripSpace();

  return squashTokens(tokens);
}

/**
 * Combines the values of consecutive text tokens in the given `tokens` array to a single token.
 */
function squashTokens(tokens: Token[]) {
  const squashedTokens = [];

  let token, lastToken;
  for (let i = 0, numTokens = tokens.length; i < numTokens; ++i) {
    token = tokens[i];

    if (token) {
      if (token.type === 'text' && lastToken && lastToken.type === 'text') {
        lastToken.value += token.value;
        lastToken.end = token.end;
      } else {
        squashedTokens.push(token);
        lastToken = token;
      }
    }
  }

  return squashedTokens;
}

// TODO: should be LRU, and should use hash instead of entire template string
const cache: Record<string, Token[]> = {};

/**
 * Parses and caches the given template in the default writer and returns the
 * array of tokens it contains. Doing this ahead of time avoids the need to
 * parse templates on the fly as they are rendered.
 */
export function parse(template: string) {
  const maybeTokens = cache[template];
  if (maybeTokens) {
    return maybeTokens;
  }
  const tokens = parseTemplate(template);
  cache[template] = tokens;
  return tokens;
}

/**
 * Renders the `template` with the given `view`, `partials`, and `config` using the default writer.
 */
export function render(template: string, view: Record<string, string>) {
  const tokens = parse(template);
  return renderTokens(tokens, view);
}

/**
 * Low-level method that renders the given array of `tokens` using the given `context`.
 */
function renderTokens(tokens: Token[], view: Record<string, string>) {
  let buffer = '';
  for (const token of tokens) {
    const { type, value } = token;
    if (type === 'variable' && view[value]) {
      buffer += view[value];
    } else if (type === 'text' && value) {
      buffer += value;
    }
  }
  return buffer;
}
