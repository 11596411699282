import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import logo from 'frontend/assets/spellbook-logo.svg';
import FlexBox from 'frontend/components/FlexBox';
import HeaderContainer from 'frontend/components/playground/HeaderContainer';
import HeaderSubTitle from 'frontend/components/playground/HeaderSubTitle';
import HeaderTitleContainer from 'frontend/components/playground/HeaderTitleContainer';
import SubHeader from 'frontend/components/playground/SubHeader';
import { SpellbookLogo } from 'frontend/components/SpellbookLogo';

interface IPlaygroundPageHeader {
  onClickSettings: () => void;
}

const PlaygroundPageHeader = ({ onClickSettings }: IPlaygroundPageHeader) => {
  return (
    <>
      <Box p={3}>
        <SpellbookLogo />
      </Box>
      <HeaderContainer>
        <FlexBox
          sx={{
            flexDirection: 'column',
            gap: 1,
            alignItems: 'flex-start',
          }}
        >
          <HeaderTitleContainer>Playground</HeaderTitleContainer>
          <SubHeader onClickSettings={onClickSettings} />
        </FlexBox>
      </HeaderContainer>
    </>
  );
};
export default PlaygroundPageHeader;
