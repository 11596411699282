import { useCallback, useEffect, useMemo, useState } from 'react';

import _ from 'lodash';
import Papa from 'papaparse';
import * as R from 'ramda';
import { useDropzone } from 'react-dropzone';

import ClearIcon from '@mui/icons-material/Clear';
import { Box, IconButton, LinearProgress, Typography } from '@mui/material';
import { styled } from '@mui/system';

import { Colors } from 'frontend/theme';

export const StepLinearProgress = styled(LinearProgress)({
  height: 6,
  borderRadius: 3,
});

export function StepProgressBar({
  step,
  maxSteps,
  title,
  onClose,
}: {
  step: number;
  maxSteps: number;
  title: string;
  onClose: () => void;
}) {
  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box>
          <Typography variant="h4" sx={{ textTransform: 'uppercase' }}>
            Step {step} of {maxSteps}
          </Typography>
          <Box my={0.5}>
            <Typography sx={{ fontSize: 20, fontWeight: 700 }}>{title}</Typography>
          </Box>
        </Box>
        <IconButton size="medium" sx={{ backgroundColor: Colors.White }} onClick={onClose}>
          <ClearIcon />
        </IconButton>
      </Box>
      <Box my={1}>
        <StepLinearProgress value={(step / maxSteps) * 100} variant="determinate" />
      </Box>
    </Box>
  );
}
