export function base64Encode(value: string) {
  return Buffer.from(value).toString('base64');
}

const FINAL_STRING_LENGTH = 12;
/**
 * Creates a string from the number that has exactly 12 characters. The beginning characters will be padded with '0's if the integer is less than 12 characters long.
 **/
export function intToStringWithPadding(int: number) {
  return String(int).padStart(FINAL_STRING_LENGTH, '0');
}
