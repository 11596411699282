import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, BoxProps, Tooltip } from '@mui/material';

import theme, { Colors } from 'frontend/theme';

export function SettingsEditor(props: BoxProps) {
  return <Box sx={{ minWidth: 160 }} {...props} />;
}

export function SettingsContainer(props: BoxProps) {
  return <Box sx={{ flex: 1 }} {...props} />;
}

export function SettingsHeader({
  title,
  tooltip,
  children,
}: {
  title: string;
  tooltip?: React.ReactNode;
  children?: React.ReactNode;
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: 14,
        fontWeight: 500,
        color: Colors.CoolGray70,
        marginBottom: theme.spacing(1),
      }}
    >
      <Box>
        {tooltip ? (
          <Tooltip title={tooltip} placement="left">
            <Box>{title}</Box>
          </Tooltip>
        ) : (
          title
        )}
      </Box>
      {children}
    </Box>
  );
}
