export function padArray<S, T extends any[]>(arr: T, fill: S, len: number): (T[number] | S)[] {
  return arr.concat(Array(len).fill(fill)).slice(0, Math.max(len, arr.length));
}

/**
 * Finds matching value or returns first value (or undefined if array is empty)
 */
export function findOrFirst<T, S>(accessor: (val: T) => S, predicateValue: S, values: T[]): S | undefined {
  return values.find(val => accessor(val) === predicateValue) ? predicateValue : values[0] ? accessor(values[0]) : undefined;
}

/**
 * If the underlying objects in original and other are different, return true.
 * Otherwise, return false.
 */
export function diffArray<T extends any[]>(original: T, other: T): boolean {
  if (original.length !== other.length) {
    return true;
  }

  for (let i = 0; i < original.length; i++) {
    // If theres a diff, return other
    if (original[i] !== other[i]) {
      return true;
    }
  }

  // All match, return original
  return false;
}

/**
 * Diff array contents by id
 */
export function diffContents<T extends any[]>(ary: T, map: Record<string, T[number]>): boolean {
  for (let i = 0; i < ary.length; i++) {
    // If theres a diff, return other
    if (ary[i] !== map[ary[i].id]) {
      return true;
    }
  }

  return false;
}
