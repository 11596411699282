import { Box, Chip, styled, Typography } from '@mui/material';

import EvaluationTypeIcon, {
  EvaluationIconName,
} from 'frontend/components/evaluation/EvaluationTypeIcon';
import theme, { Colors } from 'frontend/theme';

const buttonIconSize = (iconName: EvaluationIconName) => {
  switch (iconName) {
    case EvaluationIconName.Binary:
      return 42;
    case EvaluationIconName.Ranking:
    case EvaluationIconName.Classification:
    case EvaluationIconName.Mauve:
    default:
      return 25;
  }
};

type SelectFeedbackTypeButtonProps = {
  handleClick: () => void;
  isSelected: boolean;
  isDisabled: boolean;
  titleText: string;
  bodyText: string;
  iconName: EvaluationIconName;
};

export function SelectFeedbackTypeButton(input: SelectFeedbackTypeButtonProps) {
  const { handleClick, isSelected, isDisabled, titleText, bodyText, iconName } = input;

  return (
    <Box
      sx={{
        display: 'flex',
        border: `1px solid ${isSelected ? Colors.Purple : theme.palette.info.light}`,
        borderRadius: 1,
        ...(!isDisabled ? { cursor: 'pointer' } : { backgroundColor: Colors.RemoGray01 }),
        alignItems: 'center',
        minHeight: 100,
      }}
      onClick={!isSelected && !isDisabled ? handleClick : undefined}
    >
      <Box
        sx={{
          display: 'flex',
          minWidth: '50px',
          height: buttonIconSize(iconName),
          justifyContent: 'center',
          marginInlineStart: '15px',
        }}
      >
        <EvaluationTypeIcon
          iconName={iconName}
          color={isSelected ? theme.palette.primary.main : theme.palette.info.main}
          size={buttonIconSize(iconName)}
        />
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', margin: 2, gap: 0.5 }}>
        <Typography variant="h3">
          {titleText}
          {isDisabled && <Chip size="small" label="Coming Soon" sx={{ ml: 1, fontSize: 10 }} />}
        </Typography>
        <Typography>{bodyText}</Typography>
      </Box>
    </Box>
  );
}
