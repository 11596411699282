import { useCallback, useMemo, useState } from 'react';

import * as R from 'ramda';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  SelectChangeEvent,
  styled,
  TextField,
  ThemeProvider,
  Tooltip,
  Typography,
} from '@mui/material';

import FlexBox from 'frontend/components/FlexBox';
import { useLLMNavigation } from 'frontend/hooks/useLLMNavigation';
import { useAppsPageState } from 'frontend/models/useAppsPageState';
import { useAppStore } from 'frontend/stores/AppStore';
import { useSelectionStore as useSelectionStoreV2 } from 'frontend/storesV2/SelectionStore';
import theme, { Colors } from 'frontend/theme';
import { track } from 'frontend/utils/analytics';
import { TASK_TYPE_ICON } from 'frontend/utils/taskType';

const IconBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.spacing(0.5),
  backgroundColor: Colors.CoolGray20,
  height: 32,
  width: 32,
}));

export default function AppSelectorButton(props: {
  selectedAppName: string | undefined;
  setSelectedAppId: (id: string | undefined) => void;
}) {
  const { appById, listVisibleApps } = useAppStore();
  const { setSelectedAppId, selectedAppId } = useSelectionStoreV2(
    R.pick(['setSelectedAppId', 'selectedAppId']),
  );
  const [searchFilter, setSearchFilter] = useState<string>('');

  const { goToHomePage, goToAppsPage } = useLLMNavigation();

  const handleSelect = useCallback(
    (e: SelectChangeEvent<string>) => {
      const app = appById[e.target.value];
      track('App Selected', { app: e.target.value, app_name: app?.name });
      setSelectedAppId(e.target.value);
      goToHomePage(app);
    },
    [setSelectedAppId, goToHomePage],
  );

  const { setShowWizard } = useAppsPageState();

  const handleClickCreateApp = useCallback(() => {
    setShowWizard(true);
    goToAppsPage();
  }, [setShowWizard, goToAppsPage]);

  const visibleApps = useMemo(() => {
    const apps = listVisibleApps();
    if (searchFilter) {
      return apps.filter(
        a => a.name.toLowerCase().includes(searchFilter.toLowerCase()) || a.id === selectedAppId,
      );
    }
    return apps;
  }, [listVisibleApps, searchFilter, selectedAppId]);

  if (listVisibleApps().length === 0) {
    return (
      <ThemeProvider theme={theme}>
        <Button fullWidth variant="contained" onClick={handleClickCreateApp}>
          Create New App
        </Button>
      </ThemeProvider>
    );
  }

  // TODO: The styling on this is bonkers, maybe we should move it to a ListItemButton?
  return (
    <FormControl fullWidth>
      {!selectedAppId && (
        <InputLabel id="app-select-input" size="small">
          <Box sx={{ color: Colors.CoolGray40 }}>Select Application</Box>
        </InputLabel>
      )}
      <Select
        MenuProps={{
          autoFocus: false,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          sx: {
            '& .MuiList-padding': {
              padding: 0,
            },
            '& .MuiMenu-list .iconbox': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: theme.spacing(0.5),
              backgroundColor: Colors.CoolGray20,
              height: 32,
              width: 32,
            },
          },
        }}
        inputProps={{
          sx: {
            '& .checkmark': {
              display: 'none',
            },
          },
        }}
        fullWidth
        label={selectedAppId ? undefined : 'Select Application'}
        labelId={selectedAppId ? undefined : 'app-select-input'}
        variant="outlined"
        color="info"
        value={selectedAppId || ''}
        size="small"
        onChange={handleSelect}
        sx={{
          borderRadius: 1,
          '& .MuiBox-root .AppNameText': {
            maxWidth: 135,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        }}
      >
        <Box
          p={2}
          py={1.5}
          sx={{
            backgroundColor: Colors.CoolGray05,
            borderBottom: `1px solid ${Colors.CoolGray20}`,
          }}
        >
          <Typography
            sx={{
              fontSize: 12,
              color: Colors.CoolGray50,
              textTransform: 'uppercase',
            }}
          >
            Select App
          </Typography>
        </Box>
        <Box p={1} py={1} sx={{ borderBottom: `1px solid ${Colors.CoolGray20}` }}>
          <OutlinedInput
            fullWidth
            size="small"
            value={searchFilter}
            placeholder="Find an existing app"
            onKeyDown={e => {
              if (e.key !== 'Escape') {
                e.stopPropagation();
              }
            }}
            onChange={e => setSearchFilter(e.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <FontAwesomeIcon icon="search" />
              </InputAdornment>
            }
            autoFocus
          />
        </Box>
        <MenuItem value={''} sx={{ display: 'none' }} />
        {visibleApps.map(app => (
          <MenuItem
            key={app.id}
            value={app.id}
            sx={{
              borderBottom: `1px solid ${Colors.CoolGray20}`,
            }}
          >
            <FlexBox
              sx={{
                flex: 1,
                color: 'info.main',
                justifyContent: 'space-between',
              }}
            >
              <FlexBox>
                <Tooltip title={app.taskType} placement="bottom" disableInteractive>
                  <Box className="iconbox">
                    <FontAwesomeIcon icon={TASK_TYPE_ICON[app.taskType]} />
                  </Box>
                </Tooltip>
                <Typography className="AppNameText" variant="body1">
                  {app.name}
                </Typography>
              </FlexBox>
              {app.id === selectedAppId ? (
                <FontAwesomeIcon className="checkmark" icon="check" />
              ) : (
                <Box className="checkmark" p={1} />
              )}
            </FlexBox>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
