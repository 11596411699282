import Pino from 'pino';

export const basicLogger = Pino({
  formatters: {
    level: label => {
      return { level: label };
    },
  },
});

export let asyncLocalStorage: any = null;
export let logger = basicLogger;

// HACK: In order for this to be compatible with the frontend, we need to only
// import the async_hooks module if we're in a Node environment.
const async_hooks = eval("typeof window === 'undefined' && require('async_hooks')");
const pinoCaller = eval("typeof window === 'undefined' && require('pino-caller')");

if (async_hooks && pinoCaller) {
  asyncLocalStorage = new async_hooks.AsyncLocalStorage();
  // source: https://blog.logrocket.com/logging-with-pino-and-asynclocalstorage-in-node-js/
  logger = pinoCaller(
    new Proxy(basicLogger, {
      get(target, property, receiver) {
        target = (asyncLocalStorage.getStore() as Record<string, any>)?.logger || target;
        return Reflect.get(target, property, receiver);
      },
    }),
  );
}

export const oldEvaluationLogger = logger.child({
  spellbook_service: 'old_evaluation',
});
export const oldInferenceLogger = logger.child({
  spellbook_service: 'old_inference',
});
// We eventually want to have step execution in one repo, but for now it is spread out across a few.
// We export this logger to the relevant repos that use step executions
export const stepExecutionRepoLogger = logger.child({
  spellbook_repo: 'step_execution_repo',
});
