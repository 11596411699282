import React from 'react';

import {
  BinaryWizardStep,
  useBinaryWizardState,
} from 'frontend/models/v2/useEvaluationWizardState';

import BinaryWizardInstructionStep from './BinaryWizardInstructionStep';

export const COST_PER_ITEM = 0.21;
export const HOURS_PER_ITEM = 0.018;

const BinaryWizardContainer: React.FC = () => {
  const { step } = useBinaryWizardState();

  switch (step) {
    case BinaryWizardStep.Instructions:
      return <BinaryWizardInstructionStep />;
    default:
      throw new Error(`Invalid binary wizard step: ${step}`);
  }
};

export default BinaryWizardContainer;
