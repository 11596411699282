export const CohereBaseModelInternalId = {
  CohereGenerateMedium: 'CohereGenerateMedium',
  CohereGenerateXLarge: 'CohereGenerateXLarge',
} as const;

export type CohereBaseModelInternalId =
  typeof CohereBaseModelInternalId[keyof typeof CohereBaseModelInternalId];

export const CohereBaseModelDisplayName: Record<CohereBaseModelInternalId, string> = {
  CohereGenerateMedium: 'Cohere Generate Medium',
  CohereGenerateXLarge: 'Cohere Generate XLarge',
} as const;

export type CohereBaseModelDisplayName =
  typeof CohereBaseModelDisplayName[keyof typeof CohereBaseModelDisplayName];

export function isCohereBaseModelInternalId(modelId: string): modelId is CohereBaseModelInternalId {
  return modelId in CohereBaseModelInternalId;
}
