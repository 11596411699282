import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip, Tooltip } from '@mui/material';

import { Colors } from 'frontend/theme';

type FilenameSizes = 'sm' | 'lg' | '1x';

export default function Filename({
  filename,
  size = 'sm',
}: {
  filename: string;
  size?: FilenameSizes;
}) {
  return (
    <Tooltip title={filename}>
      <Chip
        sx={{
          backgroundColor: Colors.CoolGray10,
          maxWidth: 230,
          px: size === 'sm' ? 0.5 : 1.0,
          py: size === 'sm' ? 0.5 : 1.0,
        }}
        icon={
          <FontAwesomeIcon
            icon="file-csv"
            style={{ color: Colors.RemoGreen, display: 'inline-block' }}
            size={size}
          />
        }
        label={filename}
      />
    </Tooltip>
  );
}
