import React, { useCallback, useEffect, useMemo, useState } from 'react';

import _ from 'lodash';
import * as R from 'ramda';

import { Link, TextField, Typography } from '@mui/material';
import {
  EXAMPLE_CRITERIA,
  generateAIFeedbackPrompt,
} from '@scale/llm-shared/consts/aiFeedbackEvaluation';
import { DataRow } from '@scale/llm-shared/interfaces/data';
import { useQuery } from '@tanstack/react-query';

import {
  EvaluationWizardCell,
  EvaluationWizardGrid,
} from 'frontend/components/evaluation/EvaluationWizardLayout';
import FlexBox from 'frontend/components/FlexBox';
import useRandom from 'frontend/hooks/useRandom';
import {
  useAIFeedbackWizardState,
  useEvaluationWizardState,
} from 'frontend/models/v2/useEvaluationWizardState';
import { useDataStore } from 'frontend/storesV2/DataStore';
import { Colors } from 'frontend/theme';
import { track } from 'frontend/utils/analytics';

import { AIFeedbackWizardPreview } from './AIFeedbackWizardPreview';

const AIFeedbackWizardContainer: React.FC = () => {
  return (
    <EvaluationWizardGrid sx={{ display: 'flex', flexDirection: 'column' }}>
      <AIFeedbackWizardInstructionHeader />
      <AIFeedbackWizardInstructionBody />
    </EvaluationWizardGrid>
  );
};

const AIFeedbackWizardInstructionHeader: React.FC = () => {
  return (
    <>
      <EvaluationWizardCell sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
        <Typography variant="h2"> Define Criteria </Typography>
        <Typography variant="body2">
          Calibrate your evaluation criteria and view sample evaluation performance using GPT-4. See
          our{' '}
          <Link
            href={'https://spellbook.readme.io/docs/ai-feedback-evaluation'}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: Colors.Periwinkle100, textDecoration: 'none' }}
          >
            documentation
          </Link>{' '}
          for more details.
        </Typography>
      </EvaluationWizardCell>
    </>
  );
};

const AIFeedbackWizardInstructionBody: React.FC = () => {
  const { evaluationCriteria, setEvaluationCriteria } = useAIFeedbackWizardState();
  // Options for showing number of example rows
  const numberOfRows = 5;
  // Default message shown to user to understand fixed prompt passed into ChatGPT/LLMs
  const displayedAIFeedbackPrompt = useMemo(
    () =>
      generateAIFeedbackPrompt(
        evaluationCriteria ? ` ${evaluationCriteria} ` : ' No criteria defined ',
        ' Input ',
        ' Output ',
      ),
    [evaluationCriteria],
  );

  const { random } = useRandom();
  const { getData } = useDataStore(R.pick(['getData']));

  const { dataId } = useEvaluationWizardState();

  // Data injection manipulation
  const [inputRowsFull, setInputRows] = useState<DataRow[]>([]);
  const dataRes = useQuery(['getData', dataId], async () => {
    return dataId ? getData(dataId) : null;
  });
  const data = dataRes.data || undefined;
  const getRandomRows = useCallback(() => {
    if (!data) {
      return [];
    }
    return random.sampleSize(Object.values(data.rowByIndex), numberOfRows);
  }, [random, data, numberOfRows]);

  const shuffleInputRows = useCallback(() => {
    track('Prompt Items Shuffled', { view: 'Evaluation' });
    setInputRows(getRandomRows());
  }, [getRandomRows, setInputRows]);

  // tab complete
  const handleCriteriaKeyDown = useMemo(
    () => (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Tab') {
        e.preventDefault();
        if (!evaluationCriteria) {
          setEvaluationCriteria(EXAMPLE_CRITERIA);
        }
      }
    },
    [evaluationCriteria],
  );

  // Shuffle items when data is loaded
  useEffect(() => {
    shuffleInputRows();
  }, [dataRes.data]);

  return (
    <>
      <EvaluationWizardCell>
        <FlexBox sx={{ flexDirection: 'row', marginTop: '8px' }}>
          <Typography variant="body1" sx={{ width: '100%' }}>
            {' '}
            1. What is the main criteria of your app? (required){' '}
          </Typography>
          <Typography variant="body1" sx={{ width: '100%' }}>
            {' '}
            2. Preview your evaluation prompt, including criteria.{' '}
          </Typography>
        </FlexBox>
        <FlexBox sx={{ flexDirection: 'row', paddingTop: '16px' }}>
          <TextField
            value={evaluationCriteria}
            multiline
            fullWidth
            rows={8}
            onChange={e => {
              setEvaluationCriteria(e.target.value);
            }}
            onKeyDown={handleCriteriaKeyDown}
            placeholder={EXAMPLE_CRITERIA}
            InputProps={{
              style: {
                fontFamily: "'IBM Plex Mono', 'Consolas', monospace",
              },
            }}
            sx={{ paddingRight: '8px' }}
          />
          <TextField
            value={displayedAIFeedbackPrompt}
            multiline
            fullWidth
            rows={8}
            disabled
            InputProps={{
              style: {
                fontFamily: "'IBM Plex Mono', 'Consolas', monospace",
              },
            }}
          />
        </FlexBox>
        <AIFeedbackWizardPreview />
      </EvaluationWizardCell>
    </>
  );
};

export default AIFeedbackWizardContainer;
