import { EvaluationType } from '@scale/llm-shared/interfaces/evaluation';
import { FinetuneType } from '@scale/llm-shared/interfaces/finetune';
import { PolledJobType } from '@scale/llm-shared/interfaces/job';

export function isNotUndefinedOrNull<T>(variable: T | null | undefined): variable is T {
  return variable !== null && variable !== undefined;
}

const EVALUATION_TYPES_SET = new Set<string>(Object.values(EvaluationType));

export function isEvaluationJobType(jobType: PolledJobType): boolean {
  return EVALUATION_TYPES_SET.has(jobType);
}

const FINETUNE_TYPES_SET = new Set<string>(Object.values(FinetuneType));

export function isFinetuneJobType(jobType: PolledJobType): boolean {
  return FINETUNE_TYPES_SET.has(jobType);
}
