import { styled, Table } from '@mui/material';

import { Colors } from 'frontend/theme';

const MultiInputTable = styled(Table)(({ theme }) => ({
  borderCollapse: 'separate',
  '& .MuiTableCell-row': {
    border: 'none',
  },
  '& .MuiTableHead-root .MuiTableCell-root': {
    color: Colors.CoolGray50,
    fontFamily: 'inherit',
    fontSize: theme.typography.subtitle2.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    backgroundColor: Colors.CoolGray10,
    borderWidth: 1,
    borderColor: Colors.CoolGray30,
    paddingY: 0.5,
  },
  '& .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root': {
    borderBottom: 'none',
  },
  '& .MuiTableRow-root:hover': {
    backgroundColor: Colors.CoolGray10,
  },
  '& .MuiTableCell-root': {
    color: Colors.CoolGray70,
    fontFamily: 'monospace',
    borderRight: `1px solid ${Colors.CoolGray20}`,
  },
  '& .MuiTableCell-root:last-child': {
    borderRight: 'none',
    borderLeft: 'none',
  },

  '& .multiinput-inner-table .MuiTableCell-root': {
    borderBottom: 'none',
  },
  '& .custom-input-inner-table .MuiTableCell-root': {
    borderBottom: 'none',
  },

  // MultiInput display changes based on the hover and selection state of
  // the dataset row (darken borders, change base color to blue)
  // TODO: probably doable via theming
  '& .MuiTableRow-root.selected .multiinput-inner-table .MuiTableRow-root': {
    backgroundColor: 'transparent',
  },
  '& .MuiTableRow-root:hover .multiinput-inner-table .MuiTableCell-root': {
    borderColor: Colors.CoolGray20,
  },
  '& .MuiTableRow-root.selected .multiinput-inner-table .MuiTableCell-root': {
    borderColor: Colors.CoolBlue10,
  },

  // Selection colors -- change theme to blue and add border
  '& .MuiTableRow-root.selected': {
    backgroundColor: Colors.CoolBlue05,
  },
  '& .MuiTableRow-root.selected:hover': {
    backgroundColor: Colors.CoolBlue10,
  },
  '& .MuiTableCell-root.selected': {
    border: `1px double ${Colors.Blue}`,
  },
  '& .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root.selected': {
    borderBottom: `1px double ${Colors.Blue}`,
  },
}));

export default MultiInputTable;
