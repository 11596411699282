import 'rsuite/dist/rsuite.min.css';
import './index.css';
import '@fortawesome/fontawesome-svg-core/styles.css';

import ReactDOM from 'react-dom/client';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import * as FullStory from '@fullstory/browser';
import { FRONTEND_STRIPE_KEY } from '@scale/llm-shared/consts/stripe';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { navigationPages } from 'frontend/components/navigation';
import GlobalHookComponent from 'frontend/GlobalHookComponent';
import { initializeDatadog } from 'frontend/integrations/datadog';
import { AppsPage } from 'frontend/pages/AppsPage/AppsPage';
import PlaygroundPage from 'frontend/pages/PlaygroundPage';
import SettingsPage from 'frontend/pages/SettingsPage';
import { EvaluationSetsPage } from 'frontend/pages/v2/EvaluationSetsPage';
import { SelectedAppRequired } from 'frontend/SelectedAppRequired';
import theme from 'frontend/theme';
import { track } from 'frontend/utils/analytics';

import reportWebVitals from './reportWebVitals';

library.add(fas, far, fab);
const queryClient = new QueryClient();

FullStory.init({ orgId: '25WP4' });
track('Webpage Loaded');
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || FRONTEND_STRIPE_KEY);

if (process.env.NODE_ENV === 'production') {
  // See Datadog RUM Applications - Spellbook for charts
  initializeDatadog();
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter basename={process.env.PUBLIC_URL || '/'}>
      {/* Disabled React strict mode because incompatible with stripe */}
      {/* <React.StrictMode> */}
      <Elements stripe={stripePromise}>
        <GlobalHookComponent>
          {/* Please make sure that Basename includes the leading slash but NOT the trailing slash e.g. "/spellbook"; not "spellbook", "spellbook/" or "/spellbook/" */}
          <Routes>
            <Route path="" element={<AppsPage />} />
            <Route path="settings" element={<SettingsPage />} />
            <Route path="playground" element={<PlaygroundPage />} />
            <Route path="data" element={<EvaluationSetsPage />} />
            <Route path="app/:shortName">
              {navigationPages.map(page => (
                <Route
                  key={page.path}
                  path={page.path}
                  element={<SelectedAppRequired v2={page.elementV2} />}
                />
              ))}
            </Route>
            {/* Redirects to / if route does not exist */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </GlobalHookComponent>
      </Elements>
      {/* </React.StrictMode> */}
    </BrowserRouter>
  </QueryClientProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Set rsuite theme
document.documentElement.style.setProperty('--rs-picker-value', theme.palette.primary.main);
document.documentElement.style.setProperty('--rs-bg-active', theme.palette.primary.main);
document.documentElement.style.setProperty('--rs-text-active', theme.palette.primary.main);
document.documentElement.style.setProperty('--rs-calendar-range-bg', theme.palette.primary.light);
document.documentElement.style.setProperty('--rs-input-focus-border', theme.palette.primary.main);
document.documentElement.style.setProperty('--rs-btn-link-text', theme.palette.primary.main);
document.documentElement.style.setProperty('--rs-btn-link-hover-text', theme.palette.primary.dark);
document.documentElement.style.setProperty('--rs-btn-primary-bg', theme.palette.primary.main);
document.documentElement.style.setProperty('--rs-btn-primary-hover-bg', theme.palette.primary.dark);
document.documentElement.style.setProperty(
  '--rs-calendar-cell-selected-hover-bg',
  theme.palette.primary.main,
);
document.documentElement.style.setProperty('--rs-state-focus-shadow', 'none');
document.documentElement.style.setProperty('--rs-state-hover-bg', theme.palette.primary.light);
document.documentElement.style.setProperty(
  '--rs-listbox-option-hover-bg',
  theme.palette.primary.light,
);
document.documentElement.style.setProperty(
  '--rs-listbox-option-hover-text',
  theme.palette.primary.main,
);
