import { Box } from '@mui/material';

import theme, { Colors } from 'frontend/theme';

interface SidebarHeaderProps {
  title: string;
  children?: React.ReactNode;
  noMargin?: boolean;
}

const SidebarHeader = (props: SidebarHeaderProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        fontSize: 14,
        fontWeight: 500,
        color: Colors.CoolGray70,
        marginBottom: props.noMargin ? 0 : theme.spacing(1),
      }}
    >
      <Box>{props.title}</Box>
      {props.children}
    </Box>
  );
};

export default SidebarHeader;
