import { Box, styled } from '@mui/material';

import { Colors } from 'frontend/theme';

const Layout = styled(Box)(({ theme }) => ({
  background: Colors.TrueGray50,
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
}));

export default Layout;
