import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, MenuItem, Select } from '@mui/material';

import SettingsContainer from 'frontend/components/playground/SettingsContainer';
import SettingsEditor from 'frontend/components/playground/SettingsEditor';
import SidebarHeader from 'frontend/components/playground/SidebarHeader';
import { IPromptPreset } from 'frontend/consts/playground';
import { Colors } from 'frontend/theme';

interface IPromptTemplateSelectorProps {
  presets: IPromptPreset[];
  selectedPromptPreset: IPromptPreset | undefined;
  onChangeSelectedPromptPreset: (presetTitle: string | undefined) => void;
  onClearPromptTemplate: () => void;
}

const PromptTemplateSelector = ({
  presets,
  selectedPromptPreset,
  onChangeSelectedPromptPreset,
  onClearPromptTemplate,
}: IPromptTemplateSelectorProps) => {
  return (
    <SettingsContainer>
      <SidebarHeader title={'Prompt Template'}>
        {selectedPromptPreset && (
          <Box
            onClick={onClearPromptTemplate}
            sx={{
              color: `${Colors.CoolGray70}`,
              cursor: 'pointer',
            }}
          >
            <FontAwesomeIcon icon="trash-can" style={{ fontSize: 16 }} />
          </Box>
        )}
      </SidebarHeader>
      {!selectedPromptPreset && (
        <Box
          sx={{
            position: 'absolute',
            padding: '9px 13px',
            color: `${Colors.CoolGray70}`,
            fontSize: '15px',
          }}
        >
          Load a preset...
        </Box>
      )}

      <SettingsEditor>
        <Select
          displayEmpty
          fullWidth={true}
          size={'small'}
          value={!selectedPromptPreset ? '' : selectedPromptPreset.title}
          onChange={e => onChangeSelectedPromptPreset(e.target.value)}
        >
          {presets.map(prst => (
            <MenuItem value={prst.title} key={prst.title}>
              {prst.title}
            </MenuItem>
          ))}
        </Select>
      </SettingsEditor>
    </SettingsContainer>
  );
};

export default PromptTemplateSelector;
