import { useMemo, useState } from 'react';

import { sortBy } from 'lodash';
import * as R from 'ramda';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Box, InputAdornment, TextField, Typography } from '@mui/material';

import PageTitle from 'frontend/components/PageTitle';
import { VSpace } from 'frontend/components/Spacer';
import { VariantSummary } from 'frontend/components/v2/variant/VariantSummary';
import useWindowSize from 'frontend/hooks/useWindowSize';
import { useAppVariantsPageState } from 'frontend/models/useAppVariantsPageState';
import PageContainer from 'frontend/pages/PageContainer';
import { useSelectionStore } from 'frontend/storesV2/SelectionStore';
import { useVariantStore } from 'frontend/storesV2/VariantStore';
import { Colors } from 'frontend/theme';

export function VariantsPage() {
  const { filterValue, setFilterValue } = useAppVariantsPageState();

  const { selectedApp } = useSelectionStore(R.pick(['selectedApp']));
  const { variantById } = useVariantStore(R.pick(['variantById']));

  const [innerWidth, unusedInnerHeight] = useWindowSize();
  const summarySize = innerWidth > 1200 ? 'large' : 'small';

  const variants = selectedApp
    ? Object.values(variantById).filter(v => v.appId === selectedApp.id)
    : [];

  const sortedVariants = useMemo(() => sortBy(variants, v => -v.updatedAt), [variants]);
  const displayedVariants = useMemo(
    () => sortedVariants.filter(v => v.name.toLowerCase().includes(filterValue.toLowerCase())),
    [sortedVariants, filterValue],
  );

  return (
    <PageContainer page="app-variants">
      <PageTitle title="App Variants" />
      <TextField
        fullWidth
        size="small"
        placeholder="Filter app variants"
        value={filterValue}
        onChange={e => setFilterValue(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FontAwesomeIcon icon="search" />
            </InputAdornment>
          ),
        }}
        sx={{ backgroundColor: Colors.White }}
      />
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
          [`& .MuiTimelineSeparator-root`]: {
            transform: `translateY(23px)`,
          },
          [`& .MuiTimelineDot-root`]: {
            borderColor: Colors.CoolGray70,
            backgroundColor: Colors.White,
            margin: 0,
          },
        }}
      >
        {displayedVariants.map((variant, i) => (
          <TimelineItem key={variant.id}>
            <TimelineSeparator sx={{ borderColor: 'purple' }}>
              <TimelineDot variant="outlined" />
              {i < displayedVariants.length - 1 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
              <VSpace s={2} />
              {variant.updatedAt && (
                <Box m={1}>
                  <Typography variant="h4">
                    Last updated {variant.updatedAt.toLocaleDateString()} at{' '}
                    {variant.updatedAt.toLocaleTimeString()}
                  </Typography>
                </Box>
              )}
              <VariantSummary variant={variant} defaultExpanded={i === 0} size={summarySize} />
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </PageContainer>
  );
}
