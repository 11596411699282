import { Slider } from '@mui/material';
import SettingsContainer from 'frontend/components/playground/SettingsContainer';
import SettingsEditorWithNoBackground from 'frontend/components/playground/SettingsEditorWithNoBackground';
import SidebarNumberInputHeader from 'frontend/components/playground/SidebarNumberInputHeader';

interface ITemperatureSliderProps {
  temperature: number;
  minTemperature: number;
  maxTemperature: number;
  onChangeTemperature: (e: any, val: any) => void;
}

const TemperatureSlider = ({
  temperature,
  minTemperature,
  maxTemperature,
  onChangeTemperature,
}: ITemperatureSliderProps) => {
  return (
    <SettingsContainer>
      <SidebarNumberInputHeader
        title="Temperature"
        value={temperature}
        onChangeInput={onChangeTemperature}
      />
      <SettingsEditorWithNoBackground>
        <Slider
          value={temperature}
          step={0.01}
          min={minTemperature}
          max={maxTemperature}
          size="medium"
          onChange={onChangeTemperature}
        />
      </SettingsEditorWithNoBackground>
    </SettingsContainer>
  );
};

export default TemperatureSlider;
