import { useEffect, useMemo, useState } from 'react';

import * as R from 'ramda';
import { useDebounce } from 'react-use';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { EvaluationMethod, HumanJobSource } from '@prisma/client';
import { HumanJobSourceToDisplayText } from '@scale/llm-shared/consts';
import { BaseEvaluationTypeV2, ExpertFeedbackType } from '@scale/llm-shared/types';

import FlexBox from 'frontend/components/FlexBox';
import { HSpace, VSpace } from 'frontend/components/Spacer';
import { DataOutputColumnSelector } from 'frontend/components/v2/data/DataColumnNameSelector';
import { DataSelectorAndUploader } from 'frontend/components/v2/data/DataSelectorAndUploader';
import { useModelStore } from 'frontend/stores/ModelStore';
import { useDataStore } from 'frontend/storesV2/DataStore';
import { StoredData, StoredDataColumn, StoredVariant } from 'frontend/storesV2/types';
import { useVariantStore } from 'frontend/storesV2/VariantStore';
import theme, { Colors } from 'frontend/theme';
import { evaluationDollarsCostEstimate } from 'frontend/utils/cost';

import { SelectVariantPanel } from './SelectVariantPanel';

type SelectVariantAndDatasetPanelProps = {
  canPickVariantAndDataset: boolean;
  handleVariantSelect: (variantIds: string[]) => void;
  handleSelectData: (value: string | undefined) => void;
  selectedDataId: string | undefined;
  selectedVariantIds: string[];
  feedbackType?: BaseEvaluationTypeV2;
  selectedHumanJobSource?: HumanJobSource;
  handleHumanJobSourceSelect?: (newSource: HumanJobSource) => void;
  shouldStripWhitespace?: boolean;
  setShouldStripWhitespace?: (value: boolean) => void;
  outputColumnRequired?: boolean;
  selectedOutputColumn?: StoredDataColumn;
  setSelectedOutputColumn?: (newColumn: StoredDataColumn) => void;
  disabledVariants?: { variantId: string | undefined; reason: string }[];
  shouldUseMultiVariantSelect?: boolean;
};

export function SelectVariantAndDatasetPanel(input: SelectVariantAndDatasetPanelProps) {
  const {
    feedbackType,
    canPickVariantAndDataset,
    handleVariantSelect,
    handleSelectData,
    selectedDataId,
    selectedVariantIds,
    selectedHumanJobSource,
    handleHumanJobSourceSelect,
    shouldStripWhitespace,
    setShouldStripWhitespace,
    outputColumnRequired,
    selectedOutputColumn,
    setSelectedOutputColumn,
    disabledVariants,
    shouldUseMultiVariantSelect = false,
  } = input;
  if (!canPickVariantAndDataset) {
    return (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography>Select an evaluation option on the left.</Typography>
      </Box>
    );
  }

  const { dataById, getData } = useDataStore(R.pick(['dataById', 'getData']));
  const dataColumns = useMemo(() => {
    if (!selectedDataId) {
      return undefined;
    }
    const data = dataById[selectedDataId];
    if (!data) {
      // TODO(albert): Probably need to hold temp state here
      getData(selectedDataId);
      return undefined;
    }
    return data.columns;
  }, [selectedDataId, dataById]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ width: '100%' }}>
          <SelectVariantPanel
            handleVariantSelect={handleVariantSelect}
            selectedVariantIds={selectedVariantIds}
            feedbackType={feedbackType}
            disabledVariants={disabledVariants}
            shouldUseMultiVariantSelect={shouldUseMultiVariantSelect}
          />
          <HSpace s={2} />
          <Typography>2. Which dataset would you like to evaluate against?</Typography>
          <VSpace s={0.5} />
          <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <HSpace s={1} />
            <DataSelectorAndUploader dataId={selectedDataId} setDataId={handleSelectData} />
          </Box>
          {outputColumnRequired && dataColumns && setSelectedOutputColumn && (
            <>
              <VSpace s={2} />
              <Typography>
                2a. Which column in the dataset should be used as the expected output?
              </Typography>
              <VSpace s={0.5} />
              <FlexBox sx={{ width: '100%', gap: 0 }}>
                <HSpace s={1} />
                <DataOutputColumnSelector
                  variant="outlined"
                  columns={dataColumns}
                  value={selectedOutputColumn}
                  onChange={setSelectedOutputColumn}
                />
              </FlexBox>
            </>
          )}
        </Box>
        {Object.values<string>(ExpertFeedbackType).includes(feedbackType ?? '') && (
          <>
            <VSpace s={2} />
            <Typography>3. Select human evaluation source</Typography>
            <VSpace s={0.3} />
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <HSpace s={1} />
              <Select
                fullWidth
                displayEmpty
                value={selectedHumanJobSource}
                onChange={e => handleHumanJobSourceSelect?.(e.target.value as HumanJobSource)}
                size={'small'}
                renderValue={
                  !selectedHumanJobSource
                    ? () => (
                        <Typography sx={{ color: Colors.CoolGray40 }}>
                          Select Human Evaluation Source
                        </Typography>
                      )
                    : undefined
                }
              >
                {Object.values(HumanJobSource).map(humanJobSource => (
                  <MenuItem key={humanJobSource} value={humanJobSource}>
                    <span style={{ textTransform: 'none' }}>
                      <FontAwesomeIcon
                        icon="layer-group"
                        style={{ color: Colors.CoolGray50, marginRight: 6 }}
                      />
                      {HumanJobSourceToDisplayText[humanJobSource]}
                    </span>
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </>
        )}
        {feedbackType === EvaluationMethod.Classification && (
          <>
            <VSpace s={2} />
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <HSpace s={1} />
              <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={shouldStripWhitespace}
                      onChange={() => setShouldStripWhitespace?.(!shouldStripWhitespace)}
                    />
                  }
                  label="Strip starting and ending whitespace for both expected outputs and model outputs"
                />
              </FormGroup>
            </Box>
            <VSpace s={2} />
            <EvalEstimatedCost
              variantIds={selectedVariantIds}
              dataId={selectedDataId}
              outputColName={selectedOutputColumn?.name}
            />
          </>
        )}
        {feedbackType === EvaluationMethod.Mauve && (
          <>
            <VSpace s={2} />
            <EvalEstimatedCost
              variantIds={selectedVariantIds}
              dataId={selectedDataId}
              outputColName={selectedOutputColumn?.name}
            />
          </>
        )}
        {feedbackType === EvaluationMethod.AIFeedback && setShouldStripWhitespace && (
          <>
            <VSpace s={2} />
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <HSpace s={1} />
              <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={shouldStripWhitespace}
                      onChange={() => setShouldStripWhitespace(!shouldStripWhitespace)}
                    />
                  }
                  label="Strip starting and ending whitespace for expected outputs. This is highly recommended for higher quality evaluations."
                />
              </FormGroup>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}

function EvalEstimatedCost({
  variantIds,
  dataId,
  outputColName,
}: {
  variantIds: string[];
  dataId?: string;
  outputColName?: string;
}): JSX.Element | null {
  const { getData } = useDataStore(R.pick(['getData']));
  const { getVariant } = useVariantStore(R.pick(['getVariant']));
  const { modelById } = useModelStore(R.pick(['modelById']));

  const [variants, setVariants] = useState<StoredVariant[] | undefined>();
  useEffect(() => {
    setVariants(undefined);
    Promise.all(variantIds.map(getVariant)).then(loadedVariants => {
      setVariants(prevVariants => {
        // Check staleness
        return prevVariants == null ? loadedVariants : prevVariants;
      });
    });
  }, [variantIds]);

  const [data, setData] = useState<StoredData | undefined>();
  useEffect(() => {
    setData(undefined);
    if (dataId) {
      getData(dataId).then(loadedData => {
        setData(prevData => {
          // Check staleness
          return prevData == null ? loadedData : prevData;
        });
      });
    }
  }, [dataId]);

  const [estimatedDollarsCost, setEstimatedDollarsCost] = useState<number | undefined>();
  useDebounce(
    () => {
      if (data == null || variants == null || outputColName == null) {
        setEstimatedDollarsCost(undefined);
      } else {
        setEstimatedDollarsCost(
          evaluationDollarsCostEstimate(data, outputColName, variants, modelById),
        );
      }
    },
    500,
    [variants, data, modelById, outputColName],
  );

  if (estimatedDollarsCost == null) {
    return null;
  }

  return (
    <Typography variant="body2">Estimated cost ~${estimatedDollarsCost.toFixed(2)}</Typography>
  );
}
