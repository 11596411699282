import { useMemo, useState } from 'react';

import { useDebounce } from 'react-use';

export function useDebounceMemo<S>(fn: () => S, deps: any[], debounce: number) {
  // Calculate once
  const initialState = useMemo(() => fn(), []);
  const [state, setState] = useState<S>(initialState);
  useDebounce(() => setState(fn()), debounce, deps);

  return [state, setState] as const;
}
