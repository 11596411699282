import { useCallback, useEffect, useMemo, useState } from 'react';

import * as R from 'ramda';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import FlexBox from 'frontend/components/FlexBox';
import { VSpace } from 'frontend/components/Spacer';
import { useDatasetPageState } from 'frontend/models/v2/useDatasetPageState';
import { useDataStore } from 'frontend/storesV2/DataStore';

export const DatasetRenameModal = () => {
  const { renameModalDataId: dataId, setRenameModalDataId } = useDatasetPageState();
  const open = !!dataId;
  const onClose = useCallback(() => {
    setRenameModalDataId(null);
  }, [setRenameModalDataId]);

  const { dataInfoById, renameData } = useDataStore(R.pick(['dataInfoById', 'renameData']));

  const [newName, setNewName] = useState<string>('');

  const existingDatasetNames = useMemo(
    () => Object.values(dataInfoById || {}).map(d => d.name) || [],
    [dataInfoById],
  );

  const renameDataset = useCallback(() => {
    if (!dataId) {
      return;
    }
    renameData(dataId, newName)
      .then(() => {
        onClose();
        setNewName('');
      })
      .catch(e => console.error(e));
  }, [dataId, newName, renameData, onClose]);

  useEffect(() => {
    const defaultName = (dataId && dataInfoById[dataId]?.name) || '';
    setNewName(defaultName);
  }, [dataInfoById, dataId, setNewName]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Rename Dataset</DialogTitle>
      <DialogContent dividers={true} sx={{ width: 500 }}>
        <Typography>Dataset Name</Typography>
        <VSpace />
        <TextField
          size="small"
          placeholder="Dataset Name"
          value={newName}
          onChange={e => setNewName(e.target.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <FlexBox>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Tooltip
            title={existingDatasetNames.includes(newName) ? 'This name is already in use' : ''}
            placement="top"
            arrow
          >
            <span>
              <Button
                variant="contained"
                onClick={renameDataset}
                disabled={newName === '' || existingDatasetNames.includes(newName)}
              >
                Save
              </Button>
            </span>
          </Tooltip>
        </FlexBox>
      </DialogActions>
    </Dialog>
  );
};
