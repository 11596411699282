import { useState } from 'react';

import { Button, Chip } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { DataType } from '@prisma/client';
import { DataInfo } from '@scale/llm-shared/interfaces';

import GrayDataGrid from 'frontend/components/datagrid/GrayDataGrid';
import FlexBox from 'frontend/components/FlexBox';
import { useLLMNavigation } from 'frontend/hooks/useLLMNavigation';
import { DataActionsButton } from 'frontend/pages/v2/EvaluationSetsPage/DataActionsButton';
import { useDataStore } from 'frontend/storesV2/DataStore';
import { Colors } from 'frontend/theme';

interface DataInfoWithActions extends DataInfo {
  goToPage: () => void;
}

function Variables({ dataColumns }: { dataColumns?: string[] }) {
  const [showAll, setShowAll] = useState<boolean>(false);
  const allVariables: string[] = dataColumns || [];
  const variables = allVariables.filter(dc => dc.trim().length > 0);
  const threshold = 4;
  if (variables.length <= threshold + 1 || showAll) {
    return (
      <FlexBox flexWrap="wrap">
        {variables.map(dataColumn => (
          <Chip
            key={dataColumn}
            variant="filled"
            label={dataColumn}
            size="small"
            sx={{
              color: Colors.CoolGray70,
              borderRadius: 0.5,
              fontFamily: 'monospace',
            }}
          />
        ))}
      </FlexBox>
    );
  } else {
    return (
      <FlexBox>
        {variables.slice(0, threshold).map(dataColumn => (
          <Chip
            key={dataColumn}
            variant="filled"
            label={dataColumn}
            size="small"
            sx={{
              color: Colors.CoolGray70,
              borderRadius: 0.5,
              fontFamily: 'monospace',
            }}
          />
        ))}
        <Chip
          variant="filled"
          label={`+${variables.length - threshold}`}
          size="small"
          onClick={() => setShowAll(true)}
          sx={{
            color: Colors.CoolGray70,
            borderRadius: 0.5,
            fontFamily: 'monospace',
            cursor: 'pointer',
          }}
        />
      </FlexBox>
    );
  }
}

const EVALUATION_SET_COLUMNS: GridColDef<DataInfoWithActions>[] = [
  {
    field: 'name',
    headerName: 'File Name',
    flex: 1,
  },
  {
    field: 'dataColumns',
    headerName: 'Variables',
    sortable: false,
    disableColumnMenu: true,
    flex: 2,
    renderCell: (params: GridRenderCellParams<DataInfoWithActions>) => (
      <Variables dataColumns={params.row.dataColumns} />
    ),
  },
  {
    field: 'numRows',
    headerName: '# Examples',
    flex: 0.5,
  },
  {
    field: 'id',
    headerName: 'Id',
    flex: 1,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    flex: 0.5,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params: GridRenderCellParams<DataInfoWithActions>) => (
      <FlexBox sx={{ width: '100%', justifyContent: 'space-between' }}>
        <Button sx={{ ml: -1 }} onClick={params.row.goToPage}>
          View Dataset
        </Button>
        <DataActionsButton dataId={params.row.id} variant="icon" />
      </FlexBox>
    ),
  },
];

export function EvaluationSetTableView() {
  const { dataInfoById } = useDataStore();
  const { goToDataPage } = useLLMNavigation();
  const rows: DataInfoWithActions[] = Object.values(dataInfoById)
    .filter(d => d.type === DataType.UserUploaded)
    .map(r => ({
      ...r,
      goToPage: () => goToDataPage(r.id),
    }));
  return (
    <GrayDataGrid
      rows={rows}
      columns={EVALUATION_SET_COLUMNS}
      pageSize={10}
      disableSelectionOnClick
      autoHeight
      density="compact"
    />
  );
}
