import { useCallback } from 'react';

import _ from 'lodash';

import { Chip, Tooltip } from '@mui/material';
import { DataInfo } from '@scale/llm-shared/interfaces/data';

import { useLLMNavigation } from 'frontend/hooks/useLLMNavigation';

export function DataChip({
  dataInfo,
  maxLen,
}: {
  dataInfo?: DataInfo;
  maxLen?: number;
}): JSX.Element {
  const { goToDataPage } = useLLMNavigation();
  const handleClick = useCallback(() => goToDataPage(dataInfo?.id), [dataInfo?.id]);

  if (!dataInfo) {
    return <Chip size="small" color="warning" label="No dataset" variant="outlined" />;
  }

  if (maxLen) {
    const label = _.truncate(dataInfo.name, { length: maxLen });
    return (
      <Tooltip title={dataInfo.name}>
        <Chip size="small" label={label} onClick={handleClick} />
      </Tooltip>
    );
  }

  return <Chip size="small" label={dataInfo.name} onClick={handleClick} />;
}
