import { getNumGpt3Tokens } from '@scale/llm-shared/tokenizers/countTokens';
import { Ai21BaseModelDisplayName, Ai21BaseModelInternalId } from '../consts';
import { IAi21BaseModel } from '../types';

export const J1Grande: IAi21BaseModel = {
  id: Ai21BaseModelInternalId.J1Grande,
  displayName: Ai21BaseModelDisplayName[Ai21BaseModelInternalId.J1Grande],
  minTokensAllowed: 0,
  maxTokensAllowed: 2048,

  minTemperatureAllowed: 0,
  maxTemperatureAllowed: 1,
  externalModelId: 'j1-grande',
  costCentsPerMillionPromptTokens: 80,
  costCentsPerMillionCompletionTokens: 80,

  getNumTokens: (prompt: string) => {
    return getNumGpt3Tokens(prompt);
  },
};
