import { z } from 'zod';

import { ModelParametersCreate } from '@scale/llm-shared/interfaces/modelParameters';
import { PromptCreate } from '@scale/llm-shared/interfaces/prompt';

// Type

export const Variant = z.object({
  id: z.string(),
  updatedAt: z.date(),
  name: z.string(),
  taxonomy: z.optional(z.array(z.string())),
  appId: z.string(),
  promptId: z.string(),
  modelParametersId: z.string(),
});

export type Variant = z.infer<typeof Variant>;

// Create

export const VariantCreate = Variant.omit({
  id: true,
  updatedAt: true,
  promptId: true,
  modelParametersId: true,
}).extend({
  prompt: PromptCreate,
  modelParameters: ModelParametersCreate,
});

export type VariantCreate = z.infer<typeof VariantCreate>;

// Run

export const VariantRun = Variant.omit({
  id: true,
  name: true,
  appId: true,
  promptId: true,
  modelParametersId: true,
  updatedAt: true,
});
export type VariantRun = z.infer<typeof VariantRun>;
