import { Box, LinearProgress, Typography } from '@mui/material';

export function ProgressBar({ progress = 0 }: { progress?: number }): JSX.Element {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
      <Box sx={{ width: 280 }}>
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{ height: 8, borderRadius: 6 }}
        />
      </Box>
      <Typography variant="subtitle1">{Math.floor(progress).toFixed(2)}%</Typography>
    </Box>
  );
}
