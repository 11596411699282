import _ from 'lodash';

import { ModelHost } from '@prisma/client';
import { ScaleIdentityServiceMetadata } from '@scale/llm-shared/types/user';

const DEFAULT_USER_ID = 'scale_id';
const DEFAULT_USER_KEY = 'this_is_a_default_key_123';
const DEFAULT_USER_METRONOME_ID = '3bab4ae5-5382-4a17-8fe5-baabbc5fbbfb';

const DEFAULT_BATCH_SIZE_OLD_INFERENCE = 4;
const DEFAULT_BATCH_SIZE_NEW_INFERENCE = 4;
const DEFAULT_CONCURRENCY_NEW_INFERENCE = 4;

// For Scale-created default accessible resources, e.g. base models
const SPELLBOOK_DEFAULT_NAMESPACE = 'spellbook_default';
// For user-created publicly accessible resources, e.g. public apps
const SPELLBOOK_PUBLIC_NAMESPACE = 'spellbook_public';

// TODO: the default user block can be moved to server
// Default user should be a Scale user
const DEFAULT_USER_DATE = new Date('2022-01-23').toISOString();
export const DEFAULT_USER_METADATA: ScaleIdentityServiceMetadata = {
  userIdentity: DEFAULT_USER_ID,
  customerType: 'local',
  customerStatus: 'active',
  team: {
    id: DEFAULT_USER_ID,
    createdDate: DEFAULT_USER_DATE,
    updatedAt: DEFAULT_USER_DATE,
  },
  teamRole: 'admin',
  createdDate: DEFAULT_USER_DATE,
  updatedAt: DEFAULT_USER_DATE,
  email: 'spellbook@scale.com',
};

const DEFAULT_USER = {
  id: DEFAULT_USER_ID,
  name: 'Scale',
  scaleApiKey: '',
  openAiApiKey: '',
  spellbookApiKey: 'secret-spellbook-key',
  metadata: DEFAULT_USER_METADATA,
};

const OpenAiFineTunedModel = {
  CurieAutoComplete: 'CurieAutoComplete',
  DavinciAutoComplete: 'DavinciAutoComplete',
} as const;

const DEFAULT_TOKENIZER_TYPE = 'codex'; // OpenAI seems to encode with codex even for e.g. davinci completions!

const DEFAULT_MODERATION_MODEL_ID = 'text-moderation-latest';

export type TextSplitter = 'character-text-splitter' | 'hugging-face' | 'nltk' | 'spacy';

export enum TextEmbedder {
  SentenceTransformersAllMiniLmL12V2 = 'sentence-transformers/all-MiniLM-L12-v2',
  SentenceTransformersMultiQaDistilbertCosV1 = 'sentence-transformers/multi-qa-distilbert-cos-v1',
  SentenceTransformersParaphraseMultilingualMpnetBaseV2 = 'sentence-transformers/paraphrase-multilingual-mpnet-base-v2',
  OpenAiTextEmbeddingAda002 = 'openai/text-embedding-ada-002',
}

export const TEXT_SPLITTER_MAPPINGS: Record<TextSplitter, string> = {
  ['character-text-splitter']: 'character-text-splitter',
  ['hugging-face']: 'character-text-splitter', // TODO: replace when we support this
  ['nltk']: 'character-text-splitter', // TODO: replace when we support this
  ['spacy']: 'character-text-splitter', // TODO: replace when we support this
};

const DEFAULT_TEXT_SPLITTER = 'nltk';
const DEFAULT_EMBEDDING_MODEL = TextEmbedder.OpenAiTextEmbeddingAda002;

const SPELLBOOK_API_S3_BUCKET = 'scale-spellbook';

const SPELLBOOK_INGESTION_BASE_URL = 'https://spellbook-api.internal.scale.com/egp/v1';

export const AWS_REGION = 'us-west-2';

export {
  DEFAULT_USER,
  DEFAULT_USER_ID,
  DEFAULT_USER_METRONOME_ID,
  DEFAULT_USER_KEY,
  DEFAULT_BATCH_SIZE_OLD_INFERENCE,
  DEFAULT_BATCH_SIZE_NEW_INFERENCE,
  DEFAULT_CONCURRENCY_NEW_INFERENCE,
  SPELLBOOK_DEFAULT_NAMESPACE,
  SPELLBOOK_PUBLIC_NAMESPACE,
  ModelHost,
  DEFAULT_TOKENIZER_TYPE,
  DEFAULT_MODERATION_MODEL_ID,
  DEFAULT_TEXT_SPLITTER,
  DEFAULT_EMBEDDING_MODEL,
  OpenAiFineTunedModel,
  SPELLBOOK_API_S3_BUCKET,
  SPELLBOOK_INGESTION_BASE_URL,
};
