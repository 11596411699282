import React, { useCallback, useEffect } from 'react';

import Bluebird from 'bluebird';
import * as R from 'ramda';
import { useInterval } from 'react-use';

import { ScaleIdentityServiceMetadata, User } from '@scale/llm-shared/types/user';

import {
  hydrateAppStoreFromApi,
  hydrateModelStoreFromApi,
  hydrateUserStoreFromApi,
} from 'frontend/api/hydrateStores';
import {
  hydrateDataStoreFromApi,
  hydrateDeploymentStoreFromApi,
  hydrateEvaluationStoreFromApi,
  hydrateFinetuneStoreFromApi,
  hydrateInferenceBatchStoreFromApi,
  hydrateVariantStoreFromApi,
} from 'frontend/api/hydrateStoresV2';
import { useAsyncAndPollV2 } from 'frontend/api/useAsyncAndPollV2';
import { useAnalytics } from 'frontend/hooks/useAnalytics';
import useRandom from 'frontend/hooks/useRandom';
import { initIntercom, intercom } from 'frontend/integrations/intercom';
import { useAppStore } from 'frontend/stores/AppStore';
import { useModelStore } from 'frontend/stores/ModelStore';
import { useSettingsStore } from 'frontend/stores/SettingsStore';
import { useUserStore } from 'frontend/stores/UserStore';
import { useInferenceBatchStore } from 'frontend/storesV2/InferenceBatchStore';
import { ifOrOnFinishHydration } from 'frontend/utils/store';

export default function GlobalHookComponent(props: { children?: React.ReactNode }) {
  const { settings } = useSettingsStore();
  const { setPseudoRandom, setNativeRandom } = useRandom();
  const { user } = useUserStore(R.pick(['user']));

  const { handleJobPollCycle } = useAsyncAndPollV2();

  // Tracks when a page gets viewed.
  useAnalytics();

  // On mount, get user.
  useEffect(() => {
    hydrateUserStoreFromApi({ init: true });
  }, []);

  // TODO: Remove this once we figure out what's going on.
  const hydrateVariantStoreWithRetries = useCallback(async () => {
    let retries = 0;
    let timeoutMs = 5000;
    while (retries < 3) {
      try {
        const timeoutPromise = new Promise(resolve => {
          setTimeout(resolve, timeoutMs, 'timeout');
        });
        // TODO: we should only hydrate selected app's variants
        const result = await Promise.race([timeoutPromise, hydrateVariantStoreFromApi()]);
        if (result === 'timeout') {
          throw new Error('Variant promise return timed out.');
        }
        return;
      } catch (err) {
        console.error('Error raised while hydrating the variant store:', err);
        console.error('Retrying variant store hydration...');
      }
      retries += 1;
      timeoutMs *= 1.5;
    }
  }, [hydrateVariantStoreFromApi]);

  // On getting user, first hydrate stores from local storage / indexed db. Once
  // those are completed, hydrate the stores from the API.

  // To read more about zustand and store hydration, refer to https://github.com/pmndrs/zustand/blob/main/docs/integrations/persisting-store-data.md#hydration-and-asynchronous-storages
  useEffect(() => {
    if (!user) {
      return;
    }

    // zustand returns a unsubscribe function that should be called after the store hydration has completed
    const hydrateUnsubs = [
      // These hydrations are for V1 objects and objects that are shared between V1 and V2 that use the old zustand store model
      ifOrOnFinishHydration(useAppStore, () => hydrateAppStoreFromApi({ init: true })),
      ifOrOnFinishHydration(useModelStore, () => hydrateModelStoreFromApi({ init: true })),
    ];

    // These hydrations are for V2 objects that use the new zustand store model
    hydrateVariantStoreWithRetries()
      .then(() => hydrateDeploymentStoreFromApi())
      .then(() => hydrateEvaluationStoreFromApi())
      .then(() => hydrateFinetuneStoreFromApi())
      .then(() => hydrateInferenceBatchStoreFromApi())
      // Data store is last because it could take a while
      .then(() => hydrateDataStoreFromApi());

    return () => {
      // calls the unsubscribe functions to unmount the listeners
      hydrateUnsubs.forEach(fn => fn());
    };
  }, [user]);

  useEffect(() => intercom(), []);

  useEffect(() => {
    if (!user) {
      return;
    }
    // TODO: disable for spoof
    initIntercom(user as User<ScaleIdentityServiceMetadata>);
  }, [user]);

  // Initialize randomness
  useEffect(() => {
    if (settings.pseudoRandomActive && settings.pseudoRandomSeed) {
      setPseudoRandom(settings.pseudoRandomSeed);
    } else {
      setNativeRandom();
    }
  }, [settings, setNativeRandom, setPseudoRandom]);

  useInterval(() => {
    if (!user) {
      return;
    }
    handleJobPollCycle();
  }, 30000);

  useInterval(async () => {
    if (!user) {
      return;
    }

    // Update inference batches in the frontend that are in progress
    const inferenceBatches = Object.values(useInferenceBatchStore.getState().inferenceBatchById);
    await Bluebird.map(
      inferenceBatches.filter(ib => ib.status === 'Running' || ib.status === 'Pending'),
      async ib => useInferenceBatchStore.getState().loadInferenceBatch(ib.id),
      { concurrency: 10 },
    );

    // Get inference batches in the frontend that are in progress from the backend
    useInferenceBatchStore.getState().getProcessingInferenceBatches();
  }, 10000);

  return <>{props.children}</>;
}
