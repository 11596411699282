import { useCallback, useMemo, useState } from 'react';

import _ from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ArrowDropDown } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  MenuItem,
  MenuList,
  Popover,
  Select,
  Typography,
} from '@mui/material';
import { ModelParametersCreate } from '@scale/llm-shared/interfaces/modelParameters';
import { Prompt, PromptCreate } from '@scale/llm-shared/interfaces/prompt';

import FlexBox from 'frontend/components/FlexBox';
import { usePromptPageState } from 'frontend/models/v2/usePromptPageState';
import {
  DEFAULT_AUTOCOMPLETION_PROMPT,
  DEFAULT_CLASSIFICATION_PROMPT,
  DEFAULT_EXTRACTION_PROMPT,
  DEFAULT_GENERATION_PROMPT,
  DEFAULT_SUMMARIZATION_PROMPT,
  generateFewShotStr,
} from 'frontend/pages/v2/PromptPage/defaults';
import { useSelectionStore } from 'frontend/storesV2/SelectionStore';
import { StoredData, StoredPrompt, StoredVariant } from 'frontend/storesV2/types';
import { Colors } from 'frontend/theme';
import { insertBefore } from 'frontend/utils/string';

const PROMPT_TEMPLATE_EXAMPLES = {
  'Generic Classification': DEFAULT_CLASSIFICATION_PROMPT,
  Autocomplete: DEFAULT_AUTOCOMPLETION_PROMPT,
  'Named Entity Extraction': DEFAULT_EXTRACTION_PROMPT,
  'One-Sentence Summarization': DEFAULT_SUMMARIZATION_PROMPT,
};

export function PromptPresetSelector({
  dataVariables,
  fullWidth = false,
  disabled = false,
}: {
  dataVariables?: string[];
  fullWidth?: boolean;
  disabled?: boolean;
}) {
  const { selectedAppVariants } = useSelectionStore();

  const [stagedPrompt, setStagedPrompt] = useState<PromptCreate | null>(null);
  const [stagedModelParameters, setStagedModelParameters] = useState<ModelParametersCreate | null>(
    null,
  );
  const [stagedValue, setStagedValue] = useState<string | null>(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<string>('');

  const {
    unsavedPrompt,
    setUnsavedPrompt,
    setUnsavedModelParameters,
    recentlySetFromTemplate,
    setRecentlySetFromTemplate,
  } = usePromptPageState();

  // TODO: Fix the template library on defaults.ts
  const fewShotClassificationPrompt = useMemo(() => {
    if (dataVariables && dataVariables.length >= 2) {
      const inputColumn = dataVariables[0];
      const inputName = _.capitalize(inputColumn);
      const outputColumn = dataVariables[1];
      const outputName = _.capitalize(outputColumn);
      const beforeStr =
        `Classify each input.\n\nUse this format:\n\n${inputName}: <input>\n${outputName}: <class label>\n\nExamples:`.trim();
      const insertStr = generateFewShotStr(3, [inputColumn], outputColumn).trim();
      const afterStr = `Begin:\n\n${inputName}: {{ ${inputColumn} }}\n${outputName}:`;
      return [beforeStr, insertStr, afterStr].join('\n\n');
    } else {
      return DEFAULT_CLASSIFICATION_PROMPT;
    }
  }, [dataVariables]);

  const handleStageVariant = useCallback(
    (value: string, prompt: PromptCreate, modelParameters?: ModelParametersCreate) => {
      // If we just set it from template, then just set it without the dialog
      if (recentlySetFromTemplate) {
        setSelectedTemplate(value);
        setUnsavedPrompt(prompt);
        if (modelParameters) {
          setUnsavedModelParameters(modelParameters);
        }
      } else {
        setStagedValue(value);
        setStagedPrompt(prompt);
        setStagedModelParameters(modelParameters || null);
        setDialogOpen(true);
      }
    },
    [setStagedPrompt, setStagedModelParameters, setStagedValue, recentlySetFromTemplate],
  );

  const handleUnstageVariant = useCallback(() => {
    setStagedPrompt(null);
    setStagedModelParameters(null);
    setDialogOpen(false);
    setStagedValue(null);
  }, [setStagedPrompt, setStagedModelParameters]);

  const handleSubmitStagedVariant = useCallback(() => {
    if (stagedPrompt !== null) {
      setUnsavedPrompt(stagedPrompt);
    }
    if (stagedModelParameters !== null) {
      setUnsavedModelParameters(stagedModelParameters);
    }
    setRecentlySetFromTemplate(true);
    setDialogOpen(false);
    if (stagedValue) {
      setSelectedTemplate(stagedValue);
    }
  }, [
    setDialogOpen,
    setUnsavedPrompt,
    setUnsavedModelParameters,
    stagedPrompt,
    stagedModelParameters,
    setRecentlySetFromTemplate,
    stagedValue,
  ]);

  const showFewShotExamples = dataVariables && dataVariables.length >= 2;

  return (
    <FormControl fullWidth={fullWidth}>
      <Dialog open={dialogOpen} onClose={handleUnstageVariant}>
        <DialogTitle sx={{ color: Colors.CoolGray90 }}>
          Overwrite your existing settings?
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Typography sx={{ maxWidth: 420 }}>
            Selecting this prompt template will overwrite your existing prompt text and model
            parameters. Do you wish to continue?
          </Typography>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button size="small" variant="outlined" onClick={handleUnstageVariant}>
            Cancel
          </Button>
          <Button size="small" variant="contained" onClick={handleSubmitStagedVariant}>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
      <Select
        size="small"
        value={selectedTemplate}
        displayEmpty
        disabled={disabled}
        renderValue={
          !selectedTemplate
            ? () => <Typography sx={{ color: Colors.CoolGray40 }}>Start from Template</Typography>
            : undefined
        }
      >
        <Box px={2} py={1}>
          <Typography variant="overline">Example Prompts</Typography>
        </Box>
        <Divider />
        {Object.entries(PROMPT_TEMPLATE_EXAMPLES).map(([name, template]) => (
          <MenuItem
            value={`generic-${name}`}
            key={name}
            onClick={e =>
              handleStageVariant(`generic-${name}`, {
                ...unsavedPrompt,
                template,
                exampleVariables: {},
              })
            }
          >
            {name}
          </MenuItem>
        ))}
        {showFewShotExamples && [
          <Box px={2} py={1} key="fewshot-title">
            <Typography variant="overline">Few-Shot Examples</Typography>
          </Box>,
          <Divider key="fewshot-divider" />,
          <MenuItem
            key={`fewshot-selection-${dataVariables.join(',')}`}
            value={'fewshot'}
            onClick={() =>
              handleStageVariant('fewshot', {
                ...unsavedPrompt,
                template: fewShotClassificationPrompt,
                exampleVariables: {},
              })
            }
          >
            Few-Shot Classification
          </MenuItem>,
        ]}
        {!!selectedAppVariants?.length && [
          <Box px={2} py={1} key="fork-existing-title">
            <Typography variant="overline">Fork an Existing Variant</Typography>
          </Box>,
          <Divider key="fork-existing-divider" />,
        ]}
        {!!selectedAppVariants?.length &&
          selectedAppVariants?.map(variant => (
            <MenuItem
              value={`variant-${variant.id}`}
              key={`variant-selection-${variant.id}`}
              onClick={() =>
                handleStageVariant(`variant-${variant.id}`, variant.prompt, variant.modelParameters)
              }
            >
              {variant.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
