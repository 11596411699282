import { useMemo } from 'react';

import { useLocation } from 'react-router-dom';

import { useDeploymentStore } from 'frontend/storesV2/DeploymentStore';

export function useSelectedDeploymentFromUri() {
  const { deploymentById } = useDeploymentStore();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const uriParam = params.get('uri');

  const selectedDeploymentFromUri = useMemo(
    () => Object.values(deploymentById).find(d => d.uri === uriParam),
    [deploymentById, uriParam],
  );

  return {
    selectedDeploymentFromUri,
  };
}
