import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip, Tooltip } from '@mui/material';

export function PublicOrPrivateChip({ isPublic }: { isPublic?: boolean }) {
  return <Tooltip
    title={
      isPublic
        ? "This app and its associated data is publicly accessible to anyone with a link."
        : "This app is private and can only be accessed by you and your team."
    }
  >
    <Chip
      sx={{ color: "info.main" }}
      variant="outlined"
      size="small"
      icon={
        <FontAwesomeIcon
          icon={isPublic ?  "lock-open" : "lock"}
          style={{ fontSize: "small", marginLeft: 8 }}
        />
      }
      label={isPublic ? "Public" : "Private"}
    />
  </Tooltip>;
}
