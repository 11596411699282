import React from 'react';

import { boolean } from 'zod';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

import { Colors } from 'frontend/theme';

interface ArchiveAppConfirmationDialogProps {
  open: boolean;
  handleConfirm: (event: React.MouseEvent) => void;
  handleCancel: (event: React.MouseEvent) => void;
}

const ArchiveAppConfirmationDialog: React.FC<ArchiveAppConfirmationDialogProps> = ({
  open,
  handleConfirm,
  handleCancel,
}) => {
  return (
    <Dialog open={open} onClose={handleCancel} maxWidth="md">
      <DialogTitle>
        <Typography variant="h2" color={Colors.Black}>
          Are you sure you want to archive this app?
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ maxWidth: 360 }}>
          Archiving an app will remove it from your apps page. This action cannot be undone.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ArchiveAppConfirmationDialog;
