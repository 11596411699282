import _ from 'lodash';
import * as R from 'ramda';
import create, { StoreApi } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

import { GetPolledJob, PolledJob } from '@scale/llm-shared/interfaces/job';

import { client } from 'frontend/api/trpc';
import { StoredPolledJob } from 'frontend/storesV2/types';
import { singleIndexById } from 'frontend/utils/object';

class JobPollingStore {
  polledJobById: Record<string, StoredPolledJob> = {};
  polledJobPromiseById: Record<string, Promise<StoredPolledJob>> = {};

  constructor(
    public set: StoreApi<JobPollingStore>['setState'],
    public get: StoreApi<JobPollingStore>['getState'],
  ) {}

  setPolledJobById = (byId: Record<string, StoredPolledJob>) => this.set(R.set(indexLens, byId));
  setPolledJobPromiseById = (byId: Record<string, Promise<StoredPolledJob>>) =>
    this.set(R.set(promiseIndexLens, byId));
  appendPolledJobById = (byId: Record<string, StoredPolledJob>) =>
    this.set(R.over(indexLens, R.mergeLeft(byId)));
  appendPolledJobPromiseById = (byId: Record<string, Promise<StoredPolledJob>>) =>
    this.set(R.over(promiseIndexLens, R.mergeLeft(byId)));

  addPolledJob = (job: PolledJob): StoredPolledJob => {
    return R.tap(
      R.pipe(
        // Prepare to be stored
        singleIndexById<PolledJob>,
        // Store in polledJobById
        this.appendPolledJobById,
      ),
    )(job);
  };

  getPolledJob = (get: GetPolledJob): Promise<PolledJob> => {
    const { polledJobById, polledJobPromiseById } = this.get();
    // Get job from cache
    const polledJob = polledJobById[get.id];
    if (polledJob) return Promise.resolve(polledJob);
    // Get job from loading
    const polledJobPromise = polledJobPromiseById[get.id];
    if (polledJobPromise) return polledJobPromise;
    return (
      client
        // Load job from server
        .query('v2.jobPolling.get', get)
        // Construct StoredPolledJb
        .then(JobPollingStore.constructStoredPolledJob)
        .then(
          R.tap(
            R.pipe(
              // Prepare to be stored
              singleIndexById,
              // Store in jobById
              this.appendPolledJobById,
            ),
          ),
        )
    );
  };

  listPolledJobs = () => {
    const { polledJobById } = this.get(); // DOES NOT LOAD
    return R.values(polledJobById);
  };

  static constructStoredPolledJob = (polledJob: PolledJob): StoredPolledJob => {
    return polledJob;
  };
}

const indexKey = 'polledJobById';
const indexLens = R.lensProp<JobPollingStore, typeof indexKey>(indexKey);

const promiseIndexKey = 'polledJobPromiseById';
const promiseIndexLens = R.lensProp<JobPollingStore, typeof promiseIndexKey>(promiseIndexKey);

export const useJobPollingStore = create<JobPollingStore>()(
  subscribeWithSelector((set, get) => new JobPollingStore(set, get)),
);

(window as any).useJobPollingStoreV2 = useJobPollingStore;
