import { z } from 'zod';

import { Prompt, PromptCreate } from '@scale/llm-shared/interfaces/prompt';

// Type
export const FinetuneParameters = z.object({
  id: z.string(),
  trainDataId: z.string(),
  validationDataId: z.string().optional().nullable(),
  expectedDataColumnId: z.string(),
  promptId: z.string(),
  prompt: Prompt,
  userId: z.string(),
  namespaceId: z.string(),
  derivedModelName: z.string(),
  baseModelId: z.string(),
  epochs: z.number(),
  learningRateModifier: z.number(),
  stopSequence: z.string().optional().nullable(),
});
export type FinetuneParameters = z.infer<typeof FinetuneParameters>;

// Create
export const FinetuneParametersCreate = FinetuneParameters.omit({
  id: true,
  promptId: true,
  namespaceId: true,
  prompt: true,
}).extend({
  prompt: PromptCreate,
});
export type FinetuneParametersCreate = z.infer<typeof FinetuneParametersCreate>;
