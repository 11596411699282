import { Box } from '@mui/material';

interface SpacerProps {
  s?: number;
}

export function HSpace(props: SpacerProps) {
  return <Box m={props.s || 1} sx={{ display: 'inline-block' }} />;
}

export function VSpace(props: SpacerProps) {
  return <Box m={props.s || 1} />;
}
