import * as R from 'ramda';
import create from 'zustand';
import { persist } from 'zustand/middleware';

import { LOCAL_STORAGE_STORE_OPTIONS } from 'frontend/clientSideStorage';

export interface LocalSettings {
  pseudoRandomActive?: boolean;
  pseudoRandomSeed?: string;
  showTokenProbabilities: boolean;
  colorblindMode: boolean;
}

interface SettingsStore {
  settings: LocalSettings;
  setSettings: (settings: LocalSettings) => void;
}

export const useSettingsStore = create<SettingsStore>()(
  persist(
    (set, get) => ({
      settings: {
        showTokenProbabilities: false,
        colorblindMode: false,
      },
      setSettings: (settings: LocalSettings) => set(R.set(R.lensProp('settings'), settings)),
    }),
    {
      ...LOCAL_STORAGE_STORE_OPTIONS('settings'),
    },
  ),
);
