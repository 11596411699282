import { useCallback, useMemo, useState } from 'react';

import * as R from 'ramda';
import { useToggle } from 'react-use';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Snackbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { AppVersion, BillingPlan } from '@prisma/client';
import { FrontendFeatureFlag } from '@scale/llm-shared/featureFlags';
import useApi from 'frontend/api/useApi';
import { Container } from 'frontend/components/Container';
import FlexBox from 'frontend/components/FlexBox';
import { useLLMNavigation } from 'frontend/hooks/useLLMNavigation';
import { useUnleashFlags } from 'frontend/hooks/useUnleashFlags';
import { PublicAppTermsCheckboxes } from 'frontend/pages/AppsPage/AppCreationWizard/PublicAppTermsCheckboxes';
import { PublicAppShareModal } from 'frontend/pages/ExplorePage/PublicAppShareModal';
import { useAppStore } from 'frontend/stores/AppStore';
import { StoredApp } from 'frontend/stores/types';
import { useUserStore } from 'frontend/stores/UserStore';
import { useSelectionStore as useSelectionStoreV2 } from 'frontend/storesV2/SelectionStore';
import { track } from 'frontend/utils/analytics';
import { getPublicAppUrl } from 'frontend/utils/explore';

import ArchiveAppConfirmationDialog from './ArchiveAppConfimationDialog';

function MenuItemShareApp({ app }: { app: StoredApp }) {
  const [open, setOpen] = useState<boolean>(false);
  const handleShareApp = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setOpen(true);
    },
    [app],
  );

  const disabled = !app.primaryVariantId;

  if (disabled) {
    return (
      <Tooltip title="App needs a variant before it's sharable!">
        <span>
          <MenuItem onClick={handleShareApp} disabled>
            Share App
          </MenuItem>
        </span>
      </Tooltip>
    );
  }

  return (
    <>
      <PublicAppShareModal open={open} setOpen={setOpen} app={app} />
      <MenuItem onClick={handleShareApp}>Share App</MenuItem>
    </>
  );
}

export function AppEditIconButton({ app }: { app: StoredApp }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { goToAppsPage } = useLLMNavigation();
  const { deleteApp } = useAppStore(R.pick(['deleteApp', 'updateApp']));
  const { archiveApp, setAppToPublic, setAppToPrivate, agreeToPublicApps } = useApi();
  const { user, billingPlan } = useUserStore(R.pick(['user', 'billingPlan']));

  const [isConfirmArchiveDialogOpen, setIsConfirmArchiveDialogOpen] = useState(false);

  const { setSelectedAppId } = useSelectionStoreV2(R.pick(['setSelectedAppId']));

  const isFreePlan = useMemo(() => billingPlan === BillingPlan.Free, [billingPlan]);

  const handleSetPublic = useCallback(() => {
    track('App Set To Public', { app: app.id, app_name: app.name });
    setAppToPublic.mutate(app);
  }, [app, setAppToPublic]);

  const handleSetPrivate = useCallback(() => {
    track('App Set To Private', { app: app.id, app_name: app.name });
    setAppToPrivate.mutate(app);
  }, [app, setAppToPrivate]);

  const handleOpenMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleArchiveAppButtonClick = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setIsConfirmArchiveDialogOpen(true);
  }, []);

  const handleConfirmArchiveApp = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      track('App Archived', { app: app.id, app_name: app.name });
      archiveApp.mutate(app);
      deleteApp(app.id);
      setSelectedAppId(undefined);
      setIsConfirmArchiveDialogOpen(false);
      goToAppsPage();
    },
    [archiveApp],
  );

  const [agreedToShare, setAgreedToShare] = useState<boolean>(!!user?.agreedToPublicAppsAt);
  const [agreedToTerms, setAgreedToTerms] = useState<boolean>(!!user?.agreedToPublicAppsAt);
  const agreedToAll = useMemo(
    () => !!user?.agreedToPublicAppsAt || (agreedToShare && agreedToTerms),
    [user?.agreedToPublicAppsAt, agreedToShare, agreedToTerms],
  );
  const [isTermsModalOpen, setIsTermsModalOpen] = useState<boolean>(false);

  const handleAgreeClick = useCallback(async () => {
    if (!agreedToAll) {
      return;
    }
    await agreeToPublicApps();
    handleSetPublic();
    setIsTermsModalOpen(false);
  }, [agreedToAll]);

  const { checkUnleashFlag } = useUnleashFlags();
  const publicAppsEnabled = checkUnleashFlag(FrontendFeatureFlag.SpellbookPublicAppsEnabled);

  function SetToPublicOption(): JSX.Element {
    return (
      <Tooltip title={isFreePlan ? 'Warning: this action cannot be reversed!' : ''}>
        <span>
          <MenuItem
            onClick={() => {
              if (!agreedToAll) {
                setIsTermsModalOpen(true);
                return;
              }
              handleSetPublic();
            }}
          >
            Make App Public
          </MenuItem>
        </span>
      </Tooltip>
    );
  }

  // Rare case where Pro user -> Free but still has private apps
  function SetToPrivateOption(): JSX.Element {
    return (
      <>
        <Tooltip title={isFreePlan ? 'You must upgrade your plan to use private apps!' : ''}>
          <span>
            <MenuItem onClick={handleSetPrivate} disabled={isFreePlan}>
              Make App Private
            </MenuItem>
          </span>
        </Tooltip>
      </>
    );
  }

  return (
    <>
      <Dialog
        open={isTermsModalOpen}
        onClose={() => {
          setAgreedToShare(false);
          setAgreedToTerms(false);
          setIsTermsModalOpen(false);
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Container sx={{ border: 0 }}>
            <PublicAppTermsCheckboxes
              agreedToShare={agreedToShare}
              setAgreedToShare={setAgreedToShare}
              agreedToTerms={agreedToTerms}
              setAgreedToTerms={setAgreedToTerms}
              isFreePlan={isFreePlan}
            />
            <FlexBox sx={{ justifyContent: 'flex-end', gap: 1 }}>
              <Button
                variant="outlined"
                onClick={() => {
                  setAgreedToShare(false);
                  setAgreedToTerms(false);
                  setIsTermsModalOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                variant="contained"
                disabled={!agreedToAll}
                onClick={handleAgreeClick}
              >
                I Agree
              </Button>
            </FlexBox>
          </Container>
        </DialogContent>
      </Dialog>
      <ArchiveAppConfirmationDialog
        open={isConfirmArchiveDialogOpen}
        handleConfirm={handleConfirmArchiveApp}
        handleCancel={(event: React.MouseEvent) => {
          event.stopPropagation();
          setIsConfirmArchiveDialogOpen(false);
          setAnchorEl(null);
        }}
      />
      <IconButton
        className={open ? 'extra-actions-button-open' : 'extra-actions-button'}
        sx={{
          marginRight: -1,
          width: 38,
        }}
        onClick={handleOpenMenuClick}
      >
        <FontAwesomeIcon size="xs" icon="ellipsis-vertical" />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClick={handleClose} onClose={handleClose}>
        <MenuList disablePadding>
          <MenuItem onClick={handleArchiveAppButtonClick}>Archive App</MenuItem>
          {publicAppsEnabled && (app.isPublic ? <SetToPrivateOption /> : <SetToPublicOption />)}
          {publicAppsEnabled && <MenuItemShareApp app={app} />}
        </MenuList>
      </Menu>
    </>
  );
}
