import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MOBILE_WIDTH_THRESHOLD, RESPONSIVE_WIDTH_THRESHOLD } from 'frontend/consts/playground';

const PromptFieldLayout = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 1px',
  minHeight: '100%',
  maxHeight: '100%',
  padding: '18px',
  transition: 'visibility 1s, opacity 0.5s linear',
  [`@media (max-width:${RESPONSIVE_WIDTH_THRESHOLD}px)`]: {
    padding: '18px',
  },
  [`@media (max-width:${MOBILE_WIDTH_THRESHOLD}px)`]: {
    padding: '18px 0px',
  },
}));

export default PromptFieldLayout;
