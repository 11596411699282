import * as R from 'ramda';
import create from 'zustand';

import { BillingPlan } from '@prisma/client';
import { Namespace } from '@scale/llm-shared/types/namespace';
import { FrontendFeatureFlag, FrontendVariantFeatureFlag } from '@scale/llm-shared/featureFlags';
import { getPrimaryNamespace, ScaleNamespaceMetadata, User } from '@scale/llm-shared/types/user';

export enum LoadingState {
  Loading = 'Loading',
  Error = 'Error',
  Unauthorized = 'Unauthorized',
  Success = 'Success',
  Unauthenticated = 'Unauthenticated',
}

export type UserBooleanFeatureFlags = Partial<Record<FrontendFeatureFlag, boolean>>;
export type UserStringFeatureFlags = Partial<Record<FrontendVariantFeatureFlag, string>>;

interface UserStore {
  user: User | undefined;
  namespaces: Namespace[] | undefined;
  setUser: (user: User) => void;
  booleanFeatureFlags: UserBooleanFeatureFlags;
  setNamespaces: (namespaces: Namespace[]) => void;
  setBooleanFeatureFlags: (booleanFeatureFlags: UserBooleanFeatureFlags) => void;
  stringFeatureFlags: UserStringFeatureFlags; // These should just be strings
  setStringFeatureFlags: (stringFeatureFlags: UserStringFeatureFlags) => void;
  billingPlan: BillingPlan;
  setBillingPlan: (billingPlan: BillingPlan) => void;
  loadingState: LoadingState;
  setLoadingState: (state: LoadingState) => void;
  deploymentKey: string | undefined;
  setDeploymentKey: (deploymentKey: string) => void;
  getPrimaryNamespace: () => Namespace | undefined;
  // Sets primary namespace phone number verified to be true
  setPrimaryNamespaceVerified: () => void;
  canVerifyPhoneNumber: () => boolean;
}

export const useUserStore = create<UserStore>()((set, get) => ({
  user: undefined,
  namespaces: undefined,
  booleanFeatureFlags: {},
  stringFeatureFlags: {},
  billingPlan: BillingPlan.Free, // TODO: need a better workflow for enterprise onboarding whose new users should start as Pro
  loadingState: LoadingState.Loading,
  setUser: (user: User) => set(R.set(R.lensProp('user'), user)),
  setNamespaces: (namespaces: Namespace[]) => set(R.set(R.lensProp('namespaces'), namespaces)),
  setBooleanFeatureFlags: (flags: UserBooleanFeatureFlags) =>
    set(R.set(R.lensProp('booleanFeatureFlags'), flags)),
  setStringFeatureFlags: (flags: UserStringFeatureFlags) =>
    set(R.set(R.lensProp('stringFeatureFlags'), flags)),
  setBillingPlan: (billingPlan: BillingPlan) => set(R.set(R.lensProp('billingPlan'), billingPlan)),
  setLoadingState: (state: LoadingState) => set(R.set(R.lensProp('loadingState'), state)),
  deploymentKey: undefined,
  setDeploymentKey: (deploymentKey: string) =>
    set(R.set(R.lensProp('deploymentKey'), deploymentKey)),
  getPrimaryNamespace: () => {
    const { namespaces, user } = get();
    return user && namespaces && getPrimaryNamespace(user, namespaces);
  },
  setPrimaryNamespaceVerified: () => {
    const { getPrimaryNamespace, namespaces, setNamespaces } = get();
    const namespace = getPrimaryNamespace();
    if (!namespaces || !namespace) {
      return;
    }

    setNamespaces(
      namespaces.map(n => {
        if (n.id !== namespace.id) {
          return n;
        }
        return {
          ...namespace,
          metadata: {
            ...(namespace.metadata as Record<string, any>),
            phoneNumberVerified: true,
          },
        };
      }),
    );
    return;
  },
  canVerifyPhoneNumber: () => {
    const { getPrimaryNamespace } = get();
    const metadata = getPrimaryNamespace()?.metadata as ScaleNamespaceMetadata | undefined;
    if (!metadata) {
      return false;
    }
    return !metadata.phoneNumberVerified && !metadata.creditGrantedPhoneNum;
  },
}));

(window as any).useUserStore = useUserStore;
