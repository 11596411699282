import * as R from 'ramda';
import create from 'zustand';

interface PageErrorStore {
  pageError: string | null;
  setPageError: (error: string | null) => void;
  hideOpenAiBanner: boolean;
  setHideOpenAiBanner: (hide: boolean) => void;
  hideBanner: boolean;
  setHideBanner: (hide: boolean) => void;
}

export const usePageErrorStore = create<PageErrorStore>()((set, get) => ({
  pageError: null,
  setPageError: (pageError: string | null) => set(R.set(R.lensProp('pageError'), pageError)),
  hideOpenAiBanner: false,
  setHideOpenAiBanner: (hide: boolean) => set(R.set(R.lensProp('hideOpenAiBanner'), hide)),
  hideBanner: false,
  setHideBanner: (hide: boolean) => set(R.set(R.lensProp('hideBanner'), hide)),
}));
