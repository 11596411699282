import _ from 'lodash';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

import { client } from 'frontend/api/trpc';
import { useDataStore } from 'frontend/storesV2/DataStore';
import { useDeploymentStore } from 'frontend/storesV2/DeploymentStore';
import { useEvaluationStore } from 'frontend/storesV2/EvaluationStore';
import { useFinetuneStore } from 'frontend/storesV2/FinetuneStore';
import { useInferenceBatchStore } from 'frontend/storesV2/InferenceBatchStore';
import { useJobPollingStore } from 'frontend/storesV2/JobPollingStore';
import { useVariantStore } from 'frontend/storesV2/VariantStore';
import { HasId } from 'frontend/types/types';

export function hydrateDataStoreFromApi() {
  const { findAllDataInfos, setDataIsHydrated } = useDataStore.getState();
  // Need to flip hydration bit
  // since there is no differentiation between unhydrated and empty data
  return findAllDataInfos().then(() => setDataIsHydrated(true));
}

export function hydrateDeploymentStoreFromApi() {
  const { findAllDeployments } = useDeploymentStore.getState();
  return findAllDeployments();
}

export function hydrateVariantStoreFromApi() {
  const { findAllVariants } = useVariantStore.getState();
  return findAllVariants();
}

export function hydrateEvaluationStoreFromApi() {
  const { findAllEvaluations } = useEvaluationStore.getState();
  return findAllEvaluations();
}

export function hydrateFinetuneStoreFromApi() {
  const { findAllFinetunes } = useFinetuneStore.getState();
  return findAllFinetunes();
}

export function hydrateInferenceBatchStoreFromApi() {
  const { getInferenceBatches } = useInferenceBatchStore.getState();
  return getInferenceBatches();
}
