import _ from 'lodash';
import * as R from 'ramda';

import { DataType } from '@prisma/client';

import { SELECTABLE_TYPES } from 'frontend/components/v2/data/DataSelector';
import { useDataStore } from 'frontend/storesV2/DataStore';
import { useSelectionStore } from 'frontend/storesV2/SelectionStore';
import { createFromStates, state } from 'frontend/utils/store';

const states = [
  state('isModalOpen', false as boolean),
  state('showIdentical', true as boolean),
  state('dataId', undefined as string | undefined),
  state('variantLeftId', undefined as string | undefined),
  state('variantRightId', undefined as string | undefined),
];

type Store = UnionToIntersection<ReturnType<typeof states[number]>>;

export const useComparePageState = createFromStates<Store>(states);

useSelectionStore.subscribe(R.prop('selectedAppId'), selectedAppId => {
  const { setDataId } = useComparePageState.getState();
  setDataId(undefined);
});

useDataStore.subscribe(R.prop('dataById'), dataById => {
  const { dataId, setDataId } = useComparePageState.getState();
  if (!dataId || !_.get(dataById, dataId)) {
    if (_.values(dataById).length) {
      const dataId = _.find(_.values(dataById), ad => SELECTABLE_TYPES.includes(ad.type))?.id;
      setDataId(dataId);
    } else {
      setDataId(undefined);
    }
  }
});
