import _ from 'lodash';
import * as R from 'ramda';

import { App } from '@prisma/client';
import { ModelParametersCreate } from '@scale/llm-shared/interfaces/modelParameters';
import { Prompt, PromptCreate } from '@scale/llm-shared/interfaces/prompt';
import { VariantCreate } from '@scale/llm-shared/interfaces/variant';

import {
  createDefaultModelParameters,
  createDefaultPrompt,
  createDefaultVariant,
} from 'frontend/pages/v2/PromptPage/defaults';
import { useDataStore } from 'frontend/storesV2/DataStore';
import { useSelectionStore } from 'frontend/storesV2/SelectionStore';
import { createFromStates, state } from 'frontend/utils/store';

const states = [
  state('unsavedModelParameters', createDefaultModelParameters() as Partial<ModelParametersCreate>),
  state('unsavedPrompt', createDefaultPrompt() as Partial<PromptCreate>),
  state('unsavedVariant', createDefaultVariant() as Partial<VariantCreate>),

  state('recentlySetFromTemplate', true as boolean),
  state('templateVarsOpen', false as boolean),
];

type Store = UnionToIntersection<ReturnType<typeof states[number]>>;

export const usePromptPageState = createFromStates<Store>(states);

const omitId = R.omit(['id']);

function resetPromptPageState(state: Store) {
  const {
    setUnsavedModelParameters,
    setUnsavedPrompt,
    setUnsavedVariant,
    setRecentlySetFromTemplate,
  } = state;
  setUnsavedModelParameters(createDefaultModelParameters());
  setUnsavedPrompt(createDefaultPrompt());
  setUnsavedVariant(createDefaultVariant());
  setRecentlySetFromTemplate(true);
}

function setPromptPageState(state: Store, selectedApp: App) {
  const { setUnsavedModelParameters, setUnsavedPrompt, setUnsavedVariant } = state;

  setUnsavedModelParameters(createDefaultModelParameters(selectedApp.taskType));
  setUnsavedPrompt(createDefaultPrompt({ taskType: selectedApp.taskType }));
  setUnsavedVariant(createDefaultVariant());
}

// When selected app changes, reset unsaved values and set prompt page to default
useSelectionStore.subscribe(R.prop('selectedApp'), selectedApp => {
  const promptPageState = usePromptPageState.getState();

  if (!selectedApp) {
    resetPromptPageState(promptPageState);
    return;
  }

  setPromptPageState(promptPageState, selectedApp);
});

// When selected variant changes, reflect it in prompt page
useSelectionStore.subscribe(R.prop('selectedVariant'), selectedVariant => {
  const promptPageState = usePromptPageState.getState();
  const { setUnsavedModelParameters, setUnsavedPrompt, setUnsavedVariant } = promptPageState;
  const { selectedApp } = useSelectionStore.getState();

  if (!selectedApp) {
    resetPromptPageState(promptPageState);
    return;
  }

  if (!selectedVariant) {
    setPromptPageState(promptPageState, selectedApp);
    return;
  }

  setUnsavedModelParameters(omitId(selectedVariant.modelParameters));
  setUnsavedPrompt(
    omitId({
      ...selectedVariant.prompt,
      systemMessage: selectedVariant.prompt.systemMessage ?? '', // HACK: ensure empty prompts are preserved
    }),
  );
  setUnsavedVariant(omitId(selectedVariant));
});

// When selected app data changes, set prompt data id
useDataStore.subscribe(R.prop('dataById'), dataById => {
  const { unsavedPrompt, setUnsavedPrompt } = usePromptPageState.getState();

  // If datasets null or empty
  if (!_.values(dataById).length) {
    setUnsavedPrompt(R.set(R.lensProp<Partial<Prompt>>('variablesSourceDataId'), undefined));
    return;
  }

  // If prompt dataset unset
  if (!unsavedPrompt.variablesSourceDataId) {
    setUnsavedPrompt(
      R.set(R.lensProp<Partial<Prompt>>('variablesSourceDataId'), _.values(dataById)[0].id),
    );
    return;
  }

  // If prompt dataset set but not matching app datasets
  if (!_.values(dataById).find(d => d.id === unsavedPrompt.variablesSourceDataId)) {
    setUnsavedPrompt(
      R.set(R.lensProp<Partial<Prompt>>('variablesSourceDataId'), _.values(dataById)[0].id),
    );
    return;
  }
});
