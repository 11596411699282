import Prism from 'prismjs';

import theme from 'frontend/theme';

Prism.highlightAll();

// Defined in frontend/craco.config.js:babel
const LANGUAGES = ['sh', 'python', 'javascript', 'json'];

export function Code({
  value,
  language,
  useDiv = false,
  style = {},
}: {
  value: string;
  language: typeof LANGUAGES[number];
  useDiv?: boolean;
  style?: React.CSSProperties;
}): JSX.Element {
  const htmlStr = Prism.highlight(value, Prism.languages[language], language);
  if (useDiv) {
    return (
      <div
        dangerouslySetInnerHTML={{ __html: htmlStr }}
        style={{
          lineHeight: theme.typography.body1.lineHeight,
          fontSize: theme.typography.body1.fontSize,
          whiteSpace: 'pre',
          fontFamily: 'monospace',
          overflowX: 'scroll',
          display: 'inline-block',
          ...style,
        }}
      />
    );
  } else {
    return (
      <code
        dangerouslySetInnerHTML={{ __html: htmlStr }}
        style={{
          lineHeight: theme.typography.body1.lineHeight,
          fontSize: theme.typography.body1.fontSize,
          whiteSpace: 'pre',
          fontFamily: 'monospace',
          overflow: 'scroll',
          display: 'inline-block',
          ...style,
        }}
      />
    );
  }
}
