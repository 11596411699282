import { useEffect, useMemo } from 'react';

import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';

import { AppVersion } from '@prisma/client';

import LoadingPage from 'frontend/pages/LoadingPage';
import { useAppStore } from 'frontend/stores/AppStore';
import { LoadingState, useUserStore } from 'frontend/stores/UserStore';
import { useSelectionStore } from 'frontend/storesV2/SelectionStore';

// Wrapper for any component that requires the selected app to be set.
// Will parse the app shortname from the URL context on initial page load.
export function SelectedAppRequired(props: { v2: JSX.Element }) {
  const { loadingState } = useUserStore();
  const { listApps, appsInitLoaded } = useAppStore();
  const { selectedApp, setSelectedAppId } = useSelectionStore();
  const { shortName } = useParams();
  const navigate = useNavigate();

  const allStores = [appsInitLoaded];
  const allStoresLoaded = useMemo(() => _.every(allStores), allStores);

  // When the apps are first loaded, make sure that the selectedApp is
  // set to the one matching the shortName URL
  useEffect(() => {
    if (
      loadingState === LoadingState.Unauthenticated ||
      loadingState === LoadingState.Unauthorized
    ) {
      navigate('/');
    }
    if (!allStoresLoaded) {
      return;
    }
    const apps = listApps();
    const matchingApp = _.find(apps, { shortName });
    if (matchingApp) {
      setSelectedAppId(matchingApp.id);
    } else {
      navigate('/');
    }
  }, [loadingState, shortName, listApps, setSelectedAppId, allStoresLoaded]);

  if (!selectedApp) {
    return <LoadingPage />;
  }
  return props.v2;
}
