import { Typography } from '@mui/material';
import { EvaluationType } from '@scale/llm-shared/interfaces/evaluation';

import FlexBox from 'frontend/components/FlexBox';

const EVALUATION_TYPE_DISPLAY: { [type: string]: string } = {
  [EvaluationType.ClassificationEvaluation]: 'Classification',
  [EvaluationType.MauveEvaluation]: 'MAUVE',
  [EvaluationType.HumanEvaluation]: 'Human Evaluation',
};

export function EvaluationTypeChip({ type }: { type: EvaluationType }) {
  const typeDisplay = EVALUATION_TYPE_DISPLAY[type] || type;
  return (
    <FlexBox>
      <Typography variant="subtitle1">Method:</Typography>
      <Typography variant="body1">{typeDisplay}</Typography>
    </FlexBox>
  );
}
