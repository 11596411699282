import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const HeaderTitleContainer = styled(Box)(() => ({
  fontSize: '22px',
  lineHeight: '24px',
  color: '#202123',
  fontWeight: '1000',
}));

export default HeaderTitleContainer;
