import { useEffect, useState } from 'react';

import EventEmitter from 'events';

export enum ApiState {
  Connected = 'Connected',
  Retrying = 'Retrying',
  Fatal = 'Fatal',
  Unauthorized = 'Unauthorized',
}

class ApiConnectionStatus extends EventEmitter {
  public state = ApiState.Connected;
}

export const apiConnection = new ApiConnectionStatus();

export function useApiConnectError() {
  const [connectionState, setConnectionState] = useState<ApiState>(apiConnection.state);

  function handleFatal() {
    apiConnection.state = ApiState.Fatal;
    setConnectionState(ApiState.Fatal);
  }

  function handleRetrying() {
    apiConnection.state = ApiState.Retrying;
    setConnectionState(ApiState.Retrying);
  }

  function handleConnectSuccess() {
    apiConnection.state = ApiState.Connected;
    setConnectionState(ApiState.Connected);
  }

  function handleUnauthorized() {
    apiConnection.state = ApiState.Unauthorized;
    setConnectionState(ApiState.Unauthorized);
  }

  useEffect(() => {
    apiConnection.addListener('fatal', handleFatal);
    apiConnection.addListener('error', handleRetrying);
    apiConnection.addListener('unauthorized', handleUnauthorized);
    apiConnection.addListener('connect', handleConnectSuccess);
    return () => {
      apiConnection.removeListener('fatal', handleFatal);
      apiConnection.removeListener('error', handleRetrying);
      apiConnection.removeListener('unauthorized', handleUnauthorized);
      apiConnection.removeListener('connect', handleConnectSuccess);
      return;
    };
  });

  return {
    connectionState,
  };
}
