import React from 'react';

import {
  RankingWizardStep,
  useRankingWizardState,
} from 'frontend/models/v2/useEvaluationWizardState';

import RankingWizardInstructionStep from './RankingWizardInstructionStep';

export const COST_PER_ITEM = 0.25;
export const HOURS_PER_ITEM = 0.02;

const RankingWizardContainer: React.FC = () => {
  const { step } = useRankingWizardState();

  switch (step) {
    case RankingWizardStep.Instructions:
      return <RankingWizardInstructionStep />;
    default:
      throw new Error(`Invalid binary wizard step: ${step}`);
  }
};

export default RankingWizardContainer;
