import _ from 'lodash';
import * as R from 'ramda';
import create, { StoreApi } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

import { JobStatus } from '@prisma/client';
import { DataRow } from '@scale/llm-shared/interfaces/data';
import {
  Evaluation,
  EvaluationCreate,
  EvaluationCreateResult,
  EvaluationType,
  GetEvaluation,
} from '@scale/llm-shared/interfaces/evaluation';
import { PendingJob } from '@scale/llm-shared/interfaces/job';

import { client } from 'frontend/api/trpc';
import { useJobPollingStore } from 'frontend/storesV2/JobPollingStore';
import { StoredEvaluation } from 'frontend/storesV2/types';
import { singleIndexById } from 'frontend/utils/object';

class EvaluationStore {
  evaluationById: Record<string, StoredEvaluation> = {};
  evaluationPromiseById: Record<string, Promise<StoredEvaluation>> = {};

  constructor(
    public set: StoreApi<EvaluationStore>['setState'],
    public get: StoreApi<EvaluationStore>['getState'],
  ) {}

  setEvaluationById = (byId: Record<string, StoredEvaluation>) => this.set(R.set(indexLens, byId));
  setEvaluationPromiseById = (byId: Record<string, Promise<StoredEvaluation>>) =>
    this.set(R.set(promiseIndexLens, byId));
  appendEvaluationById = (byId: Record<string, StoredEvaluation>) =>
    this.set(R.over(indexLens, R.mergeLeft(byId)));
  appendEvaluationPromiseById = (byId: Record<string, Promise<StoredEvaluation>>) =>
    this.set(R.over(promiseIndexLens, R.mergeLeft(byId)));

  // Create evaluation from server
  createEvaluation = (evaluationCreate: EvaluationCreate) => {
    return (
      client
        // Create Variant
        .mutation('v2.evaluation.create', evaluationCreate)
        // Construct StoredEvaluation
        .then((res: EvaluationCreateResult) => {
          if (res.success) {
            const storedEvaluation = EvaluationStore.constructStoredEvaluation(res.evaluation);
            // Store evaluation
            R.tap(this.storeEvaluation)(storedEvaluation);
            // Watch evaluation job
            R.tap(this.watchEvaluation)(storedEvaluation);
            return { success: true, evaluation: storedEvaluation };
          } else {
            return { success: false, message: res.message };
          }
        })
    );
  };

  // Add to job to be watched by polling
  watchEvaluation: (evaluation: StoredEvaluation) => void = evaluation => {
    const polledJob: PendingJob = {
      id: evaluation.id,
      type: evaluation.type,
      progress: 0,
      status: JobStatus.Pending,
    };
    useJobPollingStore.getState().addPolledJob(polledJob);
  };

  // Add evaluation to store
  storeEvaluation: (evaluation: StoredEvaluation) => void = R.pipe(
    // Prepare to be stored
    singleIndexById,
    // Store in evaluationById
    this.appendEvaluationById,
  );

  getEvaluation = (get: GetEvaluation): Promise<StoredEvaluation> => {
    const { evaluationById, evaluationPromiseById } = this.get();
    // Get evaluation from cache
    const evaluation = evaluationById[get.id];
    if (evaluation) return Promise.resolve(evaluation);
    // Get evaluation from loading
    const evaluationPromise = evaluationPromiseById[get.id];
    if (evaluationPromise) return evaluationPromise;
    return (
      client
        // Load evaluation from server
        .query('v2.evaluation.get', get)
        // Construct StoredEvaluation
        .then(EvaluationStore.constructStoredEvaluation)
        // Store evaluation
        .then(R.tap(this.storeEvaluation))
    );
  };

  findAllEvaluations = () => {
    return (
      client
        // Load deployment from server
        .query('v2.evaluation.findAll')
        .then(R.map(EvaluationStore.constructStoredEvaluation))
        .then(evaluations => {
          const pendingAndRunningEvaluations = evaluations.filter(
            evaluation =>
              evaluation.status === JobStatus.Pending || evaluation.status === JobStatus.Running,
          );
          pendingAndRunningEvaluations.forEach(evaluation => this.watchEvaluation(evaluation));
          R.tap(
            R.pipe(
              // Prepare to be stored
              R.indexBy(R.prop('id')),
              // Store in deploymentById
              this.appendEvaluationById,
            ),
          )(evaluations);
        })
    );
  };

  listEvaluations = () => {
    const { evaluationById } = this.get(); // DOES NOT LOAD
    return R.values(evaluationById);
  };

  // Only supported for some evaluations!
  runEvaluation = (type: EvaluationType, inputs: DataRow[]) => {
    return client.mutation('v2.evaluation.run', { inputs, type });
  };

  static constructStoredEvaluation = (evaluation: Evaluation): StoredEvaluation => {
    return evaluation;
  };
}
const indexKey = 'evaluationById';
const indexLens = R.lensProp<EvaluationStore, typeof indexKey>(indexKey);

const promiseIndexKey = 'evaluationPromiseById';
const promiseIndexLens = R.lensProp<EvaluationStore, typeof promiseIndexKey>(promiseIndexKey);

export const useEvaluationStore = create<EvaluationStore>()(
  subscribeWithSelector((set, get) => new EvaluationStore(set, get)),
);

(window as any).useEvaluationStoreV2 = useEvaluationStore;
