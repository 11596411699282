import { Box, Button, Tooltip } from '@mui/material';

type EvaluationWizardNavigationProps = {
  shouldDisplayBackButton: boolean;
  shouldDisplaySubmitButton: boolean; // Display submit instead of continue
  handleClickNextButton: () => void;
  canClickNextButton: boolean;
  handleClickBackButton?: () => void;
  tooltipText: string;
};
export function EvaluationWizardNavigation(input: EvaluationWizardNavigationProps) {
  const {
    shouldDisplayBackButton,
    shouldDisplaySubmitButton,
    handleClickBackButton,
    canClickNextButton,
    handleClickNextButton,
    tooltipText,
  } = input;
  return (
    <Box>
      <Box>
        {shouldDisplayBackButton && handleClickBackButton && (
          <Button onClick={handleClickBackButton}>Back</Button>
        )}
        <Tooltip title={tooltipText} arrow>
          <span>
            <Button
              variant="contained"
              disabled={!canClickNextButton}
              onClick={handleClickNextButton}
            >
              {shouldDisplaySubmitButton ? 'Start Evaluation' : 'Continue'}
            </Button>
          </span>
        </Tooltip>
      </Box>
    </Box>
  );
}
