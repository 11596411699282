import { TextField } from '@mui/material';
import SettingsContainer from 'frontend/components/playground/SettingsContainer';
import SettingsEditor from 'frontend/components/playground/SettingsEditor';
import SidebarHeader from 'frontend/components/playground/SidebarHeader';

interface IStopSequenceFieldProps {
  stopSequence: string;
  onChangeStopSequence: (val: string) => void;
}

const StopSequenceField = ({ stopSequence, onChangeStopSequence }: IStopSequenceFieldProps) => {
  return (
    <SettingsContainer>
      <SidebarHeader title="Stop sequence"></SidebarHeader>
      <SettingsEditor>
        <TextField
          value={stopSequence}
          size={'small'}
          style={{ width: 220 }}
          onChange={e => onChangeStopSequence(e.target.value)}
          onKeyDown={e => {
            if (e.code === 'Enter') {
              onChangeStopSequence(stopSequence + '⏎');
            }
          }}
        />
      </SettingsEditor>
    </SettingsContainer>
  );
};

export default StopSequenceField;
