import { useCallback, useEffect, useMemo } from 'react';

import _ from 'lodash';
import * as R from 'ramda';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { DataType } from '@prisma/client';

import { VSpace } from 'frontend/components/Spacer';
import { StoredDataset } from 'frontend/stores/types';
import { useDataStore } from 'frontend/storesV2/DataStore';
import { Colors } from 'frontend/theme';

const NO_DATASETS: StoredDataset[] = [];
const NO_DATASET_VALUE = '';
export const SELECTABLE_TYPES: DataType[] = [DataType.UserUploaded];

export const DataSelector = ({
  dataId,
  setDataId,
  setIsUploading,
  allowNoDataset,
}: {
  dataId: string | undefined;
  setDataId: (value: string | undefined) => void;
  setIsUploading: React.Dispatch<React.SetStateAction<boolean>>;
  allowNoDataset: boolean;
}) => {
  const { dataInfoById } = useDataStore(R.pick(['dataInfoById']));
  const dataInfo = useMemo(
    () => (dataId ? dataInfoById[dataId] : undefined),
    [dataId, dataInfoById],
  );

  const selectableDatasets = useMemo(
    () =>
      Object.values(dataInfoById)?.filter(ds => SELECTABLE_TYPES.includes(ds.type)) ?? NO_DATASETS,
    [dataInfoById],
  );

  const handleSelect = useCallback(
    (e: SelectChangeEvent<string | undefined>) => setDataId(e.target.value),
    [setDataId],
  );

  if (selectableDatasets.length === 0) {
    return (
      <Button onClick={() => setIsUploading(true)} size="medium" variant="outlined" fullWidth>
        <FontAwesomeIcon icon="upload" style={{ marginRight: '1ex' }} />
        Upload Dataset
      </Button>
    );
  }

  return (
    <Select
      fullWidth
      size="small"
      labelId="selected-dataset-label"
      value={dataInfo?.id || NO_DATASET_VALUE}
      displayEmpty
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        sx: {
          '& .MuiList-padding': {
            padding: 0,
          },
        },
      }}
      onChange={handleSelect}
      renderValue={
        !dataInfo
          ? () => <Typography sx={{ color: Colors.CoolGray40 }}>Select Dataset</Typography>
          : undefined
      }
    >
      <Box
        sx={{
          px: 2,
          pb: 1.5,
          pt: 2.5,
          backgroundColor: Colors.CoolGray05,
          borderBottom: `1px solid ${Colors.CoolGray20}`,
        }}
      >
        <Typography variant="overline">Your Datasets</Typography>
      </Box>
      {allowNoDataset && <MenuItem value={NO_DATASET_VALUE}>None</MenuItem>}
      {selectableDatasets.map(ds => (
        <MenuItem key={ds.id} value={ds.id}>
          <FontAwesomeIcon
            icon="database"
            style={{ color: Colors.CoolGray50, marginRight: '1ex' }}
          />
          {ds.name}
        </MenuItem>
      ))}
      <VSpace s={0.5} />
      <Box p={1}>
        <Button onClick={() => setIsUploading(true)} size="small" variant="outlined" fullWidth>
          <FontAwesomeIcon icon="upload" style={{ marginRight: '1ex' }} />
          Upload New Dataset
        </Button>
      </Box>
    </Select>
  );
};
