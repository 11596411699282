import { getNumGpt3Tokens } from '@scale/llm-shared/tokenizers/countTokens';
import { ScaleLaunchBaseModelDisplayName, ScaleLaunchBaseModelInternalId } from '../consts';
import { IScaleLaunchBaseModel } from '../types';

export const FlanXXL: IScaleLaunchBaseModel = {
  id: ScaleLaunchBaseModelInternalId.Flan_XXL,
  displayName: ScaleLaunchBaseModelDisplayName[ScaleLaunchBaseModelInternalId.Flan_XXL],
  minTokensAllowed: 0,
  maxTokensAllowed: 512,

  minTemperatureAllowed: 0,
  maxTemperatureAllowed: 1,
  externalModelId: '',
  costCentsPerMillionPromptTokens: 200,
  costCentsPerMillionCompletionTokens: 200,

  endpoint_short: 'end_ch054fe5qieg03id0ldg',
  endpoint_streaming: 'end_ci5aa3bk0d3003t7u3og',

  getNumTokens: (prompt: string) => {
    return getNumGpt3Tokens(prompt);
  },
};
