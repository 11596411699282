import _ from 'lodash';
import * as R from 'ramda';
import create, { StoreApi } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

import {
  Data,
  DataColumn,
  DataCreate,
  DataInfo,
  DataRow,
  DataValue,
} from '@scale/llm-shared/interfaces/data';

import { client } from 'frontend/api/trpc';
import { StoredData } from 'frontend/storesV2/types';
import { singleIndexById } from 'frontend/utils/object';

class DataStore {
  dataIsHydrated = false;
  dataById: Record<string, StoredData> = {};
  dataPromiseById: Record<string, Promise<StoredData>> = {};
  dataInfoById: Record<string, DataInfo> = {};

  constructor(
    public set: StoreApi<DataStore>['setState'],
    public get: StoreApi<DataStore>['getState'],
  ) {}

  setDataIsHydrated = (hydrated: boolean) => this.set(R.set(hydratedLens, hydrated));
  setDataById = (byId: Record<string, StoredData>) => this.set(R.set(indexLens, byId));
  setDataPromiseById = (byId: Record<string, Promise<StoredData>>) =>
    this.set(R.set(promiseIndexLens, byId));
  setDataInfoById = (byId: Record<string, DataInfo>) => this.set(R.set(infoIndexLens, byId));
  appendDataById = (byId: Record<string, StoredData>) => {
    this.set(R.over(indexLens, R.mergeLeft(byId)));
  };
  appendDataPromiseById = (byId: Record<string, Promise<StoredData>>) =>
    this.set(R.over(promiseIndexLens, R.mergeLeft(byId)));
  omitDataById = (id: string) => this.set(R.over(indexLens, R.omit([id])));
  omitDataInfoById = (id: string) => this.set(R.over(infoIndexLens, R.omit([id])));

  createData = (dataCreate: DataCreate) =>
    client
      // Create Data
      .mutation('v2.data.create', dataCreate)
      // Get DataColumns and DataValues
      .then(data =>
        Promise.all([
          data,
          client.query('v2.data.column.findMany', { dataId: data.id }),
          client.query('v2.data.value.findMany', { dataId: data.id }),
        ]),
      )
      // Construct StoredData
      .then(DataStore.constructStoredData)
      .then(
        R.tap(
          R.pipe(
            // Prepare to be stored
            singleIndexById<StoredData>,
            // Store in dataById
            this.appendDataById,
            // Refetch data info for new dataset
            this.findAllDataInfos,
          ),
        ),
      );

  // TODO: paginate loading
  getData = (id: string) => {
    const { dataById, dataPromiseById } = this.get();
    // Get data from cache
    const data = dataById[id];
    if (data) return Promise.resolve(data);
    // Get data from loading
    const dataPromise = dataPromiseById[id];
    if (dataPromise) return dataPromise;
    return (
      // Load data from server
      Promise.all([
        client.query('v2.data.findUnique', { dataId: id }, { context: { skipBatch: true } }),
        client.query('v2.data.column.findMany', { dataId: id }, { context: { skipBatch: true } }),
        client.query('v2.data.value.findMany', { dataId: id }, { context: { skipBatch: true } }),
      ])
        // Construct StoredData
        .then(DataStore.constructStoredData)
        .then(
          R.tap(
            R.pipe(
              // Prepare to be stored
              singleIndexById,
              // Store in dataById
              this.appendDataById,
            ),
          ),
        )
    );
  };

  static constructStoredData = ([data, dataColumns, dataValues]: [
    Data,
    DataColumn[],
    DataValue[],
  ]) => {
    const columnById = R.indexBy(c => c.id, dataColumns);
    const valuesByColumnId = R.groupBy(v => v.dataColumnId, dataValues);

    const columns = dataColumns.map(c => ({ ...c, values: valuesByColumnId[c.id] }));
    const valuesByColumn = _.mapKeys(valuesByColumnId, (_, columnId) => columnById[columnId].name);

    const rowByIndex: { [index: string]: DataRow } = {};
    for (const v of dataValues) {
      const { row: index, dataColumnId, value } = v;
      const colName = columnById[dataColumnId].name;
      if (!rowByIndex[index]) {
        rowByIndex[index] = { index, valueByName: {} };
      }
      rowByIndex[index].valueByName[colName] = value;
    }

    return { ...data, columns, valuesByColumn, rowByIndex };
  };

  findAllDataInfos = () => {
    return client.query('v2.data.info.findAll').then(
      R.tap(
        R.pipe(
          // Prepare to be stored
          R.indexBy(R.prop('id')),
          // Store in dataInfoById
          this.setDataInfoById,
        ),
      ),
    );
  };

  renameData = (id: string, newName: string) =>
    client
      .mutation('v2.data.updateName', { dataId: id, newName })
      .then(
        R.tap(dataInfo =>
          this.set(R.set(R.lensPath([indexKey, dataInfo.id, 'name']), dataInfo.name)),
        ),
      )
      .then(
        R.tap(dataInfo =>
          this.set(R.set(R.lensPath([infoIndexKey, dataInfo.id, 'name']), dataInfo.name)),
        ),
      );

  archiveData = (id: string) =>
    client
      .mutation('v2.data.archive', { dataId: id })
      .then(R.tap(R.pipe(R.prop('id'), this.omitDataById)))
      .then(R.tap(R.pipe(R.prop('id'), this.omitDataInfoById)));
}

const indexKey = 'dataById';
const indexLens = R.lensProp<DataStore, typeof indexKey>(indexKey);

const promiseIndexKey = 'dataPromiseById';
const promiseIndexLens = R.lensProp<DataStore, typeof promiseIndexKey>(promiseIndexKey);

const infoIndexKey = 'dataInfoById';
const infoIndexLens = R.lensProp<DataStore, typeof infoIndexKey>(infoIndexKey);

const hydratedKey = 'dataIsHydrated';
const hydratedLens = R.lensProp<DataStore, typeof hydratedKey>(hydratedKey);

export const useDataStore = create<DataStore>()(
  subscribeWithSelector((set, get) => new DataStore(set, get)),
);

(window as any).useDataStore = useDataStore;
