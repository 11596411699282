import { useMemo } from 'react';

import { Box, Typography } from '@mui/material';
import { BaseEvaluationTypeV2 } from '@scale/llm-shared/types';

import { HSpace, VSpace } from 'frontend/components/Spacer';
import { SingleVariantSelector } from 'frontend/components/v2/variant/SingleVariantSelector';
import MultiVariantSelector from 'frontend/components/v2/variant/MultiVariantSelector';

export function SelectVariantPanel({
  handleVariantSelect,
  selectedVariantIds,
  disabledVariants,
  shouldUseMultiVariantSelect,
}: {
  handleVariantSelect: (variantIds: string[]) => void;
  selectedVariantIds: string[];
  feedbackType: BaseEvaluationTypeV2 | undefined;
  disabledVariants?: { variantId: string | undefined; reason: string }[];
  shouldUseMultiVariantSelect: boolean;
}) {
  return (
    <Box sx={{ width: '100%' }}>
      <Typography>1. Which variant(s) would you like to evaluate?</Typography>
      <VSpace s={0.3} />
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <HSpace s={1} />
        {shouldUseMultiVariantSelect ? (
          <MultiVariantSelector
            value={selectedVariantIds}
            onChange={handleVariantSelect}
            disabledVariants={disabledVariants}
            fullWidth
            size="small"
          />
        ) : (
          <SingleVariantSelector
            value={selectedVariantIds.length ? selectedVariantIds[0] : ''}
            onChange={(variantId: string) => handleVariantSelect([variantId])}
            disabledVariants={disabledVariants}
            fullWidth
            size="small"
          />
        )}
      </Box>
    </Box>
  );
}
