import { MenuItem, Select } from '@mui/material';
import { BaseModelInternalId } from '@scale/llm-shared/modelProviders/types';

import SettingsContainer from 'frontend/components/playground/SettingsContainer';
import SettingsEditor from 'frontend/components/playground/SettingsEditor';
import SidebarHeader from 'frontend/components/playground/SidebarHeader';

interface IModelSelectorProps {
  models: BaseModelInternalId[];
  baseModel: BaseModelInternalId;
  onChangeModel: (e: any) => void;
}

const ModelSelector = ({ models, baseModel, onChangeModel }: IModelSelectorProps) => {
  return (
    <SettingsContainer>
      <SidebarHeader title="Base Model" />
      <SettingsEditor>
        <Select
          displayEmpty
          fullWidth={true}
          size={'small'}
          value={baseModel}
          onChange={onChangeModel}
        >
          {models.map(model => (
            <MenuItem value={model} key={model}>
              {model}
            </MenuItem>
          ))}
        </Select>
      </SettingsEditor>
    </SettingsContainer>
  );
};

export default ModelSelector;
