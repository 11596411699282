import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@mui/material';

import { Container } from 'frontend/components/Container';
import theme from 'frontend/theme';

// This is only for display purposes, right now it doesn't actually have
// drag and drop re-ordering functionality

interface RankingDragAndDropProps {
  values: string[];
}

const RankingDragAndDrop: React.FC<RankingDragAndDropProps> = ({ values }) => {
  return (
    <>
      {values.map((value, idx) => (
        <Box key={value} sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <Typography> {idx + 1} </Typography>
          <Container
            sx={{
              paddingBlock: 1,
              paddingInline: 2,
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Typography> {value} </Typography>
            <FontAwesomeIcon
              icon="grip-vertical"
              color={theme.palette.primary.main}
              style={{ cursor: 'pointer' }}
            />
          </Container>
        </Box>
      ))}
    </>
  );
};

export default RankingDragAndDrop;
