import { forwardRef } from 'react';

import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

interface ISettingsUpdatedSnackbarProps {
  isOpen: boolean;
  onClose: () => void;
}

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SettingsUpdatedSnackbar = ({ isOpen, onClose }: ISettingsUpdatedSnackbarProps) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={isOpen}
      autoHideDuration={3000}
      onClose={onClose}
    >
      <Alert onClose={onClose} severity="success">
        Settings updated!
      </Alert>
    </Snackbar>
  );
};

export default SettingsUpdatedSnackbar;
