import { Chip, Tooltip, Typography } from '@mui/material';
import { JobStatus } from '@prisma/client';

import FlexBox from 'frontend/components/FlexBox';
import { JobStatusChip, StatusColor } from 'frontend/components/JobStatusChip';

export function EvaluationStatus({
  inferenceStatus,
  evaluationStatus,
  tooltip,
}: {
  inferenceStatus?: JobStatus;
  evaluationStatus?: JobStatus;
  tooltip?: string;
}) {
  let label: StatusColor;
  // Inference Status vs. Evaluation Status
  // Inferences are almost always run before the specific code for evaluations
  // start. This means that inferences will be in a "Complete" state, while the
  // code that runs evaluations (human evaluation, mauve evaluation) is churning.
  // For backfilled jobs, just use the completed chip
  if (evaluationStatus === JobStatus.Backfilled) {
    label = 'Completed';
  } else if (!inferenceStatus || !evaluationStatus) {
    label = inferenceStatus || evaluationStatus || 'No Status';
  }
  // In the case where inferences are complete but evaluation is not, then
  // use the 'Postprocessing' catch-all title;
  else if (
    inferenceStatus === JobStatus.Completed &&
    (evaluationStatus === JobStatus.Running || evaluationStatus === JobStatus.Pending)
  ) {
    label = 'Postprocessing';
  }
  // Otherwise, just fall back to using the evaluationStatus
  // This means that canceled or errored jobs might be slightly stale until the
  // result propagates to the evaluation object for a few seconds.
  else {
    label = evaluationStatus;
  }
  return (
    <FlexBox>
      <Typography variant="subtitle1">Status:</Typography>
      {tooltip ? (
        <Tooltip title={tooltip}>
          <JobStatusChip status={label} />
        </Tooltip>
      ) : (
        <JobStatusChip status={label} />
      )}
    </FlexBox>
  );
}
