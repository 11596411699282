import { useMemo } from 'react';

import * as R from 'ramda';
import shallow from 'zustand/shallow';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemButton,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import { FrontendFeatureFlag } from '@scale/llm-shared/featureFlags';

import { navigationPages } from 'frontend/components/navigation';
import { SpellbookLogo } from 'frontend/components/SpellbookLogo';
import { LOGOUT_REDIRECT_URL } from 'frontend/consts/authRedirectUrls';
import { useLLMNavigation } from 'frontend/hooks/useLLMNavigation';
import { useUnleashFlags } from 'frontend/hooks/useUnleashFlags';
import AppSelectorButton from 'frontend/pages/PageContainer/AppSelectorButton';
import { useAppStore } from 'frontend/stores/AppStore';
import { useSelectionStore } from 'frontend/storesV2/SelectionStore';
import { useVariantStore } from 'frontend/storesV2/VariantStore';
import theme, { Colors } from 'frontend/theme';
import { track } from 'frontend/utils/analytics';

const ToggleButton = styled('div', {
  shouldForwardProp: prop => prop !== 'open' && prop !== 'drawerWidth',
})<{
  open?: boolean;
  drawerWidth: number;
}>(({ theme, open, drawerWidth }) => ({
  flexGrow: 1,
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  top: 60,
  height: 40,
  width: 16,
  color: Colors.CoolGray70,
  border: `1px solid ${Colors.CoolGray30}`,
  borderTopRightRadius: 4,
  borderBottomRightRadius: 4,
  backgroundColor: Colors.White,
  cursor: 'pointer',
  transition: theme.transitions.create('left', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  left: 0,
  ...(open && {
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    left: `${drawerWidth}px`,
  }),
}));

const PaddedListItem = styled(ListItem, { shouldForwardProp: () => true })(({ theme }) => ({
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
}));

const PaddedListItemButton = styled(ListItemButton, { shouldForwardProp: () => true })(
  ({ theme }) => ({
    padding: theme.spacing(0.5),
    height: 40,
    borderRadius: 8,
    gap: 4,
  }),
);

function openDocumentation() {
  track('Documentation Opened');
  window.open('https://spellbook.readme.io/docs', '_blank');
}

export default function PageNavigationDrawer({
  currentPage,
  drawerWidth,
  open,
  hidden,
  setDrawerOpen,
}: {
  currentPage: string;
  drawerWidth: number;
  open: boolean;
  hidden?: boolean;
  setDrawerOpen: (open: boolean) => void;
}) {
  const { goToAppsPage, goToDataPage, goToExplorePage, goToSettingsPage, goToDynamicPage } =
    useLLMNavigation();

  const { selectedAppId, setSelectedAppId } = useSelectionStore(
    R.pick(['selectedAppId', 'setSelectedAppId', 'selectedVariantId']),
  );
  const { checkUnleashFlag } = useUnleashFlags();

  const { appById } = useAppStore(
    R.pick(['appById', 'selectedAppId', 'setSelectedAppId']),
    shallow,
  );
  const selectedApp = useMemo(
    () => (selectedAppId ? appById[selectedAppId] : undefined),
    [selectedAppId, appById],
  );

  const { getVariantsByAppId, variantById } = useVariantStore(
    R.pick(['getVariantsByAppId', 'variantById']),
  );
  const variants = useMemo(() => {
    return selectedAppId ? getVariantsByAppId(selectedAppId) : [];
  }, [selectedAppId, variantById]);
  const hasVariants = variants.length > 0;

  const explorePageEnabled = checkUnleashFlag(FrontendFeatureFlag.SpellbookPublicAppsEnabled);

  const navigationPagesToRender = useMemo(() => {
    if (!selectedApp) {
      return [];
    }
    return navigationPages;
  }, [selectedApp]);

  return (
    <div style={{ position: 'relative', display: hidden ? 'none' : 'block' }}>
      <ToggleButton open={open} drawerWidth={drawerWidth} onClick={() => setDrawerOpen(!open)}>
        <FontAwesomeIcon size="sm" icon={open ? 'caret-left' : 'caret-right'} />
      </ToggleButton>
      <Drawer variant="persistent" anchor="left" open={open}>
        <List sx={{ width: drawerWidth }}>
          <PaddedListItem>
            <Box p={1}>
              <SpellbookLogo />
            </Box>
          </PaddedListItem>
          <PaddedListItem>
            <PaddedListItemButton
              sx={currentPage === 'apps' ? HIGHLIGHTED_COLOR : DEFAULT_COLOR}
              onClick={() => goToAppsPage()}
            >
              <Box sx={{ mx: theme.spacing(1), width: 16, color: 'inherit' }}>
                <FontAwesomeIcon icon="th-large" />
              </Box>
              <Typography variant="body1" sx={{ color: 'inherit', my: 0.5 }}>
                Apps
              </Typography>
            </PaddedListItemButton>
          </PaddedListItem>
          {explorePageEnabled && (
            <PaddedListItem>
              <PaddedListItemButton
                sx={currentPage === 'explore' ? HIGHLIGHTED_COLOR : DEFAULT_COLOR}
                onClick={() => goToExplorePage()}
              >
                <Box sx={{ mx: theme.spacing(1), width: 16, color: 'inherit' }}>
                  <FontAwesomeIcon icon="compass" />
                </Box>
                <Typography variant="body1" sx={{ color: 'inherit', my: 0.5 }}>
                  Explore
                </Typography>
              </PaddedListItemButton>
            </PaddedListItem>
          )}
          <PaddedListItem>
            <PaddedListItemButton
              sx={currentPage === 'settings' ? HIGHLIGHTED_COLOR : DEFAULT_COLOR}
              onClick={() => goToSettingsPage()}
            >
              <Box sx={{ mx: theme.spacing(1), width: 16, color: 'inherit' }}>
                <FontAwesomeIcon icon="gear" />
              </Box>
              <Typography variant="body1" sx={{ color: 'inherit', my: 0.5 }}>
                Settings
              </Typography>
            </PaddedListItemButton>
          </PaddedListItem>
          <PaddedListItem>
            <PaddedListItemButton
              sx={currentPage === 'data' ? HIGHLIGHTED_COLOR : DEFAULT_COLOR}
              onClick={() => goToDataPage()}
            >
              <Box sx={{ mx: theme.spacing(1), width: 16, color: 'inherit' }}>
                <FontAwesomeIcon icon="database" />
              </Box>
              <Typography variant="body1" sx={{ color: 'inherit', my: 0.5 }}>
                Evaluation Sets
              </Typography>
            </PaddedListItemButton>
          </PaddedListItem>
          <PaddedListItem>
            <PaddedListItemButton sx={DEFAULT_COLOR} onClick={openDocumentation}>
              <Box sx={{ mx: theme.spacing(1), width: 16, color: 'inherit' }}>
                <FontAwesomeIcon icon="book" />
              </Box>
              <Typography variant="body1" sx={{ color: 'inherit', my: 0.5 }}>
                Documentation
                <FontAwesomeIcon
                  icon="up-right-from-square"
                  size="sm"
                  style={{
                    transform: 'translateY(1px)',
                    marginLeft: '1ex',
                    color: Colors.CoolGray50,
                  }}
                />
              </Typography>
            </PaddedListItemButton>
          </PaddedListItem>
          <Box p={1}>
            <Divider />
          </Box>
          <ListItem sx={{ py: 1, px: 1.5 }}>
            <Box sx={{ width: '100%' }}>
              <AppSelectorButton
                selectedAppName={selectedApp?.name}
                setSelectedAppId={(nextAppId: string | undefined) => {
                  setSelectedAppId(nextAppId);
                }}
              />
            </Box>
          </ListItem>

          {navigationPagesToRender.map(page => {
            const disabled = !hasVariants && page.requiresVariant;
            return (
              <PaddedListItem key={page.title}>
                <Tooltip
                  placement="right"
                  title={disabled ? 'Create a variant in the Prompt page before proceeding.' : ''}
                >
                  <Grid container>
                    <PaddedListItemButton
                      disabled={disabled}
                      onClick={() => {
                        goToDynamicPage(page.path);
                      }}
                      sx={currentPage === page.path ? HIGHLIGHTED_COLOR : DEFAULT_COLOR}
                    >
                      <Box sx={{ mx: theme.spacing(1), width: 16, color: 'inherit' }}>
                        {page.icon}
                      </Box>
                      <Typography
                        variant="body1"
                        sx={{ color: 'inherit', my: 0.5, whiteSpace: 'nowrap' }}
                      >
                        {page.title}
                      </Typography>
                    </PaddedListItemButton>
                    {disabled && (
                      <Box sx={{ mt: 1, mr: 1.5 }}>
                        <FontAwesomeIcon
                          icon="lock"
                          size="xs"
                          style={{ color: Colors.CoolGray30 }}
                        />
                      </Box>
                    )}
                  </Grid>
                </Tooltip>
              </PaddedListItem>
            );
          })}
        </List>

        <List style={{ marginTop: `auto` }}>
          <PaddedListItem>
            <PaddedListItemButton
              sx={DEFAULT_COLOR}
              onClick={() => {
                window.location.href = LOGOUT_REDIRECT_URL;
              }}
            >
              <Box sx={{ mx: theme.spacing(1), width: 16, color: 'inherit' }}>
                <FontAwesomeIcon icon="sign-out" />
              </Box>
              <Typography variant="body1" sx={{ color: 'inherit', my: 0.5 }}>
                Log Out
              </Typography>
            </PaddedListItemButton>
          </PaddedListItem>
        </List>
      </Drawer>
    </div>
  );
}

const HIGHLIGHTED_COLOR = {
  color: 'primary.main',
  bgcolor: 'primary.light',
};

const DEFAULT_COLOR = {
  color: 'info.main',
};
