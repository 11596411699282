import React, { useCallback, useEffect, useMemo, useState } from 'react';

import _ from 'lodash';
import * as R from 'ramda';
import validUrl from 'valid-url';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  Box,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  styled,
  Switch,
  TextField,
  Tooltip,
} from '@mui/material';
import { DEPLOYMENT_ROUTE_V2 } from '@scale/llm-shared/consts';
import { useMutation } from '@tanstack/react-query';

import { absoluteApiHref } from 'frontend/api/trpc';
import { Container } from 'frontend/components/Container';
import { ErrorMessage } from 'frontend/components/ErrorMessage';
import { SettingsContainer, SettingsEditor, SettingsHeader } from 'frontend/components/Settings';
import { SingleVariantSelector } from 'frontend/components/v2/variant/SingleVariantSelector';
import { useDebounceMemo } from 'frontend/hooks/useDebounceMemo';
import { SpellbookApiKeyField } from 'frontend/pages/SettingsPage/SettingsPage';
import { useDeploymentStore } from 'frontend/storesV2/DeploymentStore';
import { useSelectionStore } from 'frontend/storesV2/SelectionStore';
import { StoredDeployment } from 'frontend/storesV2/types';
import { useVariantStore } from 'frontend/storesV2/VariantStore';

const DEPLOYMENT_URL = absoluteApiHref + '/' + DEPLOYMENT_ROUTE_V2;

export function DeploymentSummary({ deployment }: { deployment: StoredDeployment }): JSX.Element {
  const [currentDeployment, setCurrentDeployment] = useState<StoredDeployment>(deployment);
  useEffect(() => setCurrentDeployment(deployment), [deployment]);
  const { uri, disabled, variantId } = currentDeployment;
  const deploymentUri = DEPLOYMENT_URL + '/' + uri;
  // Debounce updates to avoid flickering
  const [isDeploymentEdited, setIsDeploymentEdited] = useDebounceMemo<boolean>(
    () => isDeploymentEqual(deployment, currentDeployment),
    [currentDeployment, deployment],
    300,
  );
  const [showDeploymentWarning] = useDebounceMemo<boolean>(
    () => isDeploymentBreaking(deployment, currentDeployment),
    [currentDeployment, deployment],
    300,
  );
  const [editErrorByName, setEditErrorByName] = useState<Record<string, EditError>>({});

  const { updateDeployment } = useDeploymentStore(R.pick(['updateDeployment']));
  const updateDeploymentMutation = useMutation(updateDeployment);

  const { getVariant } = useVariantStore(R.pick(['getVariant']));
  const variant = getVariant(variantId);

  const [showCopied, setShowCopied] = useState<boolean>(false);
  const copyUrlToClipboard = useCallback(
    (e: React.MouseEvent) => {
      navigator.clipboard.writeText(deploymentUri);
      setShowCopied(true);
      setTimeout(() => setShowCopied(false), 2000);
      e.stopPropagation();
    },
    [deploymentUri, setShowCopied],
  );

  const handleConfirmChanges = useCallback(() => {
    if (!isDeploymentEdited) {
      return;
    }
    updateDeploymentMutation.mutateAsync(currentDeployment);
    setIsDeploymentEdited(false);
  }, [isDeploymentEdited, currentDeployment]);

  const handleUriChange = useHandleEventTarget(setCurrentDeployment, 'uri');

  if (!variant) {
    return <></>;
  }

  // TODO: https://app.shortcut.com/scaleai/story/682327
  const urlEditor = (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', minWidth: 360 }}>
      {validUrl.isWebUri(uri) ? (
        <TextField size="small" value={uri} onChange={handleUriChange} />
      ) : (
        <OutlinedInput
          fullWidth
          endAdornment={
            <InputAdornment position="end">
              <Tooltip title={showCopied ? 'Copied!' : 'Copy URL to clipboard'} placement="top">
                <IconButton size="small" onClick={copyUrlToClipboard}>
                  <FontAwesomeIcon size="sm" icon={['far', 'copy']} />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          }
          size="small"
          value={DEPLOYMENT_URL + '/' + uri}
          onChange={handleUriChange}
          inputProps={{ readOnly: true }} // TODO: remove
        />
      )}
    </Box>
  );

  return (
    <Container sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', alignItems: 'top', gap: 2, flexWrap: 'wrap' }}>
        <SettingsContainer>
          <SettingsHeader title="Name" />
          <SettingsEditor sx={{ minWidth: 200 }}>
            <DeploymentNameInput
              deploymentName={deployment.name}
              currentDeploymentName={currentDeployment.name}
              setCurrentDeploymentName={name => {
                setCurrentDeployment(R.set(R.lensProp('name'), name));
              }}
              setError={setEditErrorByName}
            />
          </SettingsEditor>
        </SettingsContainer>

        <SettingsContainer>
          <SettingsHeader title="Deployed Variant" />
          <SettingsEditor>
            <SingleVariantSelector
              fullWidth
              value={currentDeployment.variantId}
              onChange={(variantId: string) => {
                setCurrentDeployment(
                  R.set<StoredDeployment, string>(R.lensProp('variantId'), variantId),
                );
              }}
            />
          </SettingsEditor>
        </SettingsContainer>

        <SettingsContainer sx={{ maxWidth: 600 }}>
          <SettingsHeader title="URL" />
          <SettingsEditor>{urlEditor}</SettingsEditor>
        </SettingsContainer>

        <SettingsContainer>
          <SettingsHeader title="API Key" />
          <SettingsEditor>
            <SpellbookApiKeyField />
          </SettingsEditor>
        </SettingsContainer>

        <SettingsContainer>
          <SettingsHeader title="Active" />
          <SettingsEditor sx={{ minWidth: 'unset' }}>
            <PrimarySwitch
              id="active"
              checked={!disabled}
              color="primary"
              onChange={(__, checked) => {
                setCurrentDeployment(
                  R.set<StoredDeployment, boolean>(R.lensProp('disabled'), !checked),
                );
              }}
            />
          </SettingsEditor>
        </SettingsContainer>
      </Box>
      {isDeploymentEdited &&
        (_.size(editErrorByName) ? (
          <Alert
            severity="error"
            action={
              <Button variant="contained" disabled>
                Apply
              </Button>
            }
          >
            Error: {R.head(R.values(editErrorByName))?.message}
          </Alert>
        ) : showDeploymentWarning ? (
          <Alert
            severity="warning"
            action={
              <Button variant="contained" onClick={handleConfirmChanges}>
                Apply
              </Button>
            }
          >
            Warning: Applying your changes to this deployment will affect live users!
          </Alert>
        ) : (
          <Alert
            severity="success"
            action={
              <Button variant="contained" onClick={handleConfirmChanges}>
                Apply
              </Button>
            }
          >
            Apply changes
          </Alert>
        ))}
      <ErrorMessage error={updateDeploymentMutation.error} />
    </Container>
  );
}

function useHandleEventTarget<T>(setFn: (set: (val: T) => T) => void, key: keyof T) {
  return useCallback(
    (e: { target: { value: string } }) => {
      setFn(val => ({ ...val, [key]: e.target.value }));
    },
    [setFn, key],
  );
}

const PrimarySwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    color: theme.palette.info.main,
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: theme.palette.primary.main,
  },
  '& .MuiSwitch-track': {
    backgroundColor: theme.palette.info.main,
  },
}));

export const DeploymentNameInput = React.forwardRef(function DeploymentNameInput({
  deploymentName,
  currentDeploymentName,
  setCurrentDeploymentName,
  setError,
}: {
  deploymentName: string;
  currentDeploymentName: string;
  setCurrentDeploymentName: (name: string) => void;
  setError: React.Dispatch<React.SetStateAction<Record<string, EditError>>>;
}) {
  const { selectedAppDeployments } = useSelectionStore(R.pick(['selectedAppDeployments']));
  const deploymentNamesSet = useMemo(
    () => new Set(selectedAppDeployments?.map(e => e.name)),
    [selectedAppDeployments],
  );

  // Reset when deployment id changes
  useEffect(() => {
    setError(R.omit(['TakenName', 'InvalidName']));
  }, [deploymentName]);

  const handleUpdateName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.target.value;
      setCurrentDeploymentName(name);

      setError(R.omit(['TakenName', 'InvalidName']));

      // No error if name did not change
      if (name === deploymentName) {
        return;
      }

      // Set errors
      if (!name) {
        setError(R.set(R.lensProp('InvalidName'), createInvalidNameError()));
      }
      if (deploymentNamesSet.has(name)) {
        setError(R.set(R.lensProp('TakenName'), createTakenNameError()));
      }
    },
    [deploymentName],
  );

  return (
    <Box>
      <TextField
        size="small"
        fullWidth
        value={currentDeploymentName}
        onChange={handleUpdateName}
        onFocus={e => e.target.select()}
      />
    </Box>
  );
});

function isDeploymentEqual(d1: StoredDeployment, d2: StoredDeployment): boolean {
  return (
    d1.uri !== d2.uri ||
    d1.disabled !== d2.disabled ||
    d1.variantId !== d2.variantId ||
    d1.name !== d2.name
  );
}

// Checks if theres a breaking change
function isDeploymentBreaking(d1: StoredDeployment, d2: StoredDeployment): boolean {
  return d1.uri !== d2.uri || d1.disabled !== d2.disabled || d1.variantId !== d2.variantId;
}

function createTakenNameError() {
  return {
    name: 'TakenName',
    message: 'Deployment name already exists.',
  } as const;
}
function createInvalidNameError() {
  return {
    name: 'InvalidName',
    message: 'Invalid deployment name.',
  } as const;
}
type EditError = {
  name: string;
  message: string;
};
