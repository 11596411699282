import { z } from 'zod';

export const SCALE_PRODUCT_CODE = 'srn:scale:product:spellbook';
export const SCALE_USER_SRN = 'srn:scale:identity:user:scale';
export const SRN_DOMAIN_MONEY = 'money';
export const QUEUE_URL =
  'https://sqs.us-west-2.amazonaws.com/307185671274/counting-service-submitter-input-queue';

export const PrettyFinancialEvent: Record<SpellbookFinancialEventType, string> = {
  BaseModelQuerying: 'Model querying',
  FineTuningModelTraining: 'Fine tune model training',
  FineTuningModelQuerying: 'Fine tune model querying',
  EmbeddingModelQuerying: 'Embedding model querying',
};

// Financial event types for Spellbook
export const SPELLBOOK_FINANCIAL_EVENT_TYPES = [
  z.literal('BaseModelQuerying'),
  z.literal('FineTuningModelTraining'),
  z.literal('FineTuningModelQuerying'),
  z.literal('EmbeddingModelQuerying'),
] as const;
export const SpellbookFinancialEventType = z.union(SPELLBOOK_FINANCIAL_EVENT_TYPES);
export type SpellbookFinancialEventType = z.infer<typeof SpellbookFinancialEventType>;

// Financial event subTypes for Spellbook
export const SPELLBOOK_FINANCIAL_EVENT_SUBTYPES = [
  z.literal('GPT3TextAda001'),
  z.literal('GPT3TextBabbage001'),
  z.literal('GPT3TextCurie001'),
  z.literal('GPT3TextDavinci001'),
  z.literal('GPT3TextDavinci002'),
  z.literal('GPT3TextDavinci003'),
  z.literal('GPT3_5Turbo0301'),
  z.literal('GPT3_5Turbo16k'),
  z.literal('GPT3_5Turbo16k_Prompt'),
  z.literal('GPT3_5Turbo16k_Completion'),
  z.literal('GPT4'),
  z.literal('GPT4_32K'),
  z.literal('Flan_XXL'),
  z.literal('Mpt7B'),
  z.literal('Mpt7BInstruct'),
  z.literal('Dolly2'),
  z.literal('J1Large'),
  z.literal('J1Grande'),
  z.literal('J1Jumbo'),
  z.literal('J2Large'),
  z.literal('J2Grande'),
  z.literal('J2Jumbo'),
  z.literal('J2GrandeInstruct'),
  z.literal('J2JumboInstruct'),
  z.literal('CohereGenerateMedium'),
  z.literal('CohereGenerateXLarge'),
  z.literal('GPT4_Prompt'),
  z.literal('GPT4_Completion'),
  z.literal('GPT4_32K_Prompt'),
  z.literal('GPT4_32K_Completion'),
  z.literal('ClaudeInstantV1_prompt'),
  z.literal('ClaudeInstantV1_completion'),
  z.literal('ClaudeV1_prompt'),
  z.literal('ClaudeV1_completion'),
] as const;
export const SpellbookFinancialEventSubType = z.union(SPELLBOOK_FINANCIAL_EVENT_SUBTYPES);
export type SpellbookFinancialEventSubType = z.infer<typeof SpellbookFinancialEventSubType>;

// Financial event tags for Spellbook
export const SpellbookFinancialEventTags = z.object({
  model_id: z.string().optional(),
  variant_id: z.string().optional(),
  deployment_id: z.string().optional(),
  user_id: z.string().optional(),
  dataset_id: z.string().optional(),
  data_id: z.string().optional(),
  fine_tune_id: z.string().optional(),
});
export type SpellbookFinancialEventTags = z.infer<typeof SpellbookFinancialEventTags>;

// Dollars per million units
export const FINANCIAL_EVENT_PRICE: Record<
  SpellbookFinancialEventType,
  Record<SpellbookFinancialEventSubType, number>
> = {
  BaseModelQuerying: {
    GPT3TextAda001: 0.4,
    GPT3TextBabbage001: 0.5,
    GPT3TextCurie001: 2,
    GPT3TextDavinci001: 20,
    GPT3TextDavinci002: 20,
    GPT3TextDavinci003: 20,
    GPT3_5Turbo0301: 2,
    GPT3_5Turbo16k: NaN, // Used for record keeping, not real event
    GPT3_5Turbo16k_Prompt: 3,
    GPT3_5Turbo16k_Completion: 6,
    GPT4: NaN, // Used for record keeping, not real event
    GPT4_32K: NaN, // Used for record keeping, not real event
    GPT4_Prompt: 30,
    GPT4_Completion: 60,
    GPT4_32K_Prompt: 60,
    GPT4_32K_Completion: 120,
    Flan_XXL: 2,
    Dolly2: 2,
    Mpt7B: 2,
    Mpt7BInstruct: 2,
    J1Large: 30,
    J1Grande: 80,
    J1Jumbo: 250,
    J2Large: 3,
    J2Grande: 10,
    J2Jumbo: 15,
    J2GrandeInstruct: 10,
    J2JumboInstruct: 15,
    CohereGenerateMedium: 15,
    CohereGenerateXLarge: 15,
    ClaudeInstantV1_prompt: 1.63,
    ClaudeInstantV1_completion: 5.51,
    ClaudeV1_prompt: 11.02,
    ClaudeV1_completion: 32.68,
  },
  FineTuningModelTraining: {
    GPT3TextAda001: 0.4,
    GPT3TextBabbage001: 0.6,
    GPT3TextCurie001: 3,
    GPT3TextDavinci001: 20,
    GPT3TextDavinci002: NaN,
    GPT3TextDavinci003: NaN,
    GPT3_5Turbo0301: NaN,
    GPT3_5Turbo16k: NaN,
    GPT3_5Turbo16k_Prompt: NaN,
    GPT3_5Turbo16k_Completion: NaN,
    GPT4: NaN,
    GPT4_32K: NaN,
    GPT4_Prompt: NaN,
    GPT4_Completion: NaN,
    GPT4_32K_Prompt: NaN,
    GPT4_32K_Completion: NaN,
    Flan_XXL: 3,
    Dolly2: NaN,
    Mpt7B: NaN,
    Mpt7BInstruct: NaN,
    J1Large: NaN,
    J1Grande: NaN,
    J1Jumbo: NaN,
    J2Large: NaN,
    J2Grande: NaN,
    J2Jumbo: NaN,
    J2GrandeInstruct: NaN,
    J2JumboInstruct: NaN,
    CohereGenerateMedium: NaN,
    CohereGenerateXLarge: NaN,
    ClaudeInstantV1_prompt: NaN,
    ClaudeInstantV1_completion: NaN,
    ClaudeV1_prompt: NaN,
    ClaudeV1_completion: NaN,
  },
  FineTuningModelQuerying: {
    GPT3TextAda001: 1.6,
    GPT3TextBabbage001: 2.4,
    GPT3TextCurie001: 12,
    GPT3TextDavinci001: 20,
    GPT3TextDavinci002: NaN,
    GPT3TextDavinci003: NaN,
    GPT3_5Turbo0301: NaN,
    GPT3_5Turbo16k: NaN,
    GPT3_5Turbo16k_Prompt: NaN,
    GPT3_5Turbo16k_Completion: NaN,
    GPT4: NaN,
    GPT4_32K: NaN,
    GPT4_Prompt: NaN,
    GPT4_Completion: NaN,
    GPT4_32K_Prompt: NaN,
    GPT4_32K_Completion: NaN,
    Flan_XXL: 6,
    Dolly2: NaN,
    Mpt7B: NaN,
    Mpt7BInstruct: NaN,
    J1Large: NaN,
    J1Grande: NaN,
    J1Jumbo: NaN,
    J2Large: NaN,
    J2Grande: NaN,
    J2Jumbo: NaN,
    J2GrandeInstruct: NaN,
    J2JumboInstruct: NaN,
    CohereGenerateMedium: NaN,
    CohereGenerateXLarge: NaN,
    ClaudeInstantV1_prompt: NaN,
    ClaudeInstantV1_completion: NaN,
    ClaudeV1_prompt: NaN,
    ClaudeV1_completion: NaN,
  },
  EmbeddingModelQuerying: {
    GPT3TextAda001: 0.4,
    GPT3TextBabbage001: 0.5,
    GPT3TextCurie001: 2,
    GPT3TextDavinci001: 20,
    GPT3TextDavinci002: NaN,
    GPT3TextDavinci003: NaN,
    GPT3_5Turbo0301: NaN,
    GPT3_5Turbo16k: NaN,
    GPT3_5Turbo16k_Prompt: NaN,
    GPT3_5Turbo16k_Completion: NaN,
    GPT4: NaN,
    GPT4_32K: NaN,
    GPT4_Prompt: NaN,
    GPT4_Completion: NaN,
    GPT4_32K_Prompt: NaN,
    GPT4_32K_Completion: NaN,
    Flan_XXL: NaN,
    Dolly2: NaN,
    Mpt7B: NaN,
    Mpt7BInstruct: NaN,
    J1Large: NaN,
    J1Grande: NaN,
    J1Jumbo: NaN,
    J2Large: NaN,
    J2Grande: NaN,
    J2Jumbo: NaN,
    J2GrandeInstruct: NaN,
    J2JumboInstruct: NaN,
    CohereGenerateMedium: NaN,
    CohereGenerateXLarge: NaN,
    ClaudeInstantV1_prompt: NaN,
    ClaudeInstantV1_completion: NaN,
    ClaudeV1_prompt: NaN,
    ClaudeV1_completion: NaN,
  },
};

/**
 * Type can be found here
 * https://github.com/scaleapi/scaleapi/blob/master/packages/financial-events/src/submitter.ts#L6
 *
 * This type is specific to Spellbook
 */
export const BaseFinancialEvent = z.object({
  // https://github.com/scaleapi/scaleapi/blob/master/packages/financial-events/src/index.ts#L19
  product: z.literal(SCALE_PRODUCT_CODE),
  type: SpellbookFinancialEventType,
  subType: SpellbookFinancialEventSubType,
  amount: z.number().int(),
  idempotencyKey: z.string(),
  reference: z.object({
    referenceId: z.string(),
    referenceType: z.string(),
  }),

  tags: SpellbookFinancialEventTags.optional(),
});
export type BaseFinancialEvent = z.infer<typeof BaseFinancialEvent>;

export const FinancialEvent = z.intersection(
  BaseFinancialEvent,
  z.object({
    payee: z.string(),
    payor: z.string(),
    recordTime: z.string(),
    recognizeTime: z.string().optional(),
  }),
);
export type FinancialEvent = z.infer<typeof FinancialEvent>;

export const BillingEvent = z.intersection(BaseFinancialEvent, z.object({ payor: z.string() }));
export type BillingEvent = z.infer<typeof BillingEvent>;

// ====================== I HOPE I GOT THIS RIGHT ======================

// A singular charge
export const FinancialLineItem = z.object({
  type: SpellbookFinancialEventType,
  subType: SpellbookFinancialEventSubType,
  amount: z.number(),
  chargedAmount: z.number(),
  createdAt: z.string(), // iso date
});
export type FinancialLineItem = z.infer<typeof FinancialLineItem>;

// An invoice of charges starting from some period
export const FinancialInvoice = z.object({
  startDate: z.string(), // iso date
  lineItems: z.array(FinancialLineItem),
});
export type FinancialInvoice = z.infer<typeof FinancialInvoice>;

// A group of invoices
export const FinancialStatement = z.object({
  periodSize: z.union([z.literal('day'), z.literal('week'), z.literal('month')]),
  invoices: z.array(FinancialInvoice),
});
export type FinancialStatement = z.infer<typeof FinancialStatement>;
