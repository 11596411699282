import { useCallback, useEffect, useMemo, useState } from 'react';

import * as R from 'ramda';
import { useDebounce } from 'react-use';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  IconButton,
  LinearProgress,
  Link,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { JobStatus } from '@prisma/client';
import { DataRow } from '@scale/llm-shared/interfaces/data';
import { FinetuneCreate, FinetuneType } from '@scale/llm-shared/interfaces/finetune';
import { isScaleLaunchBaseModelInternalId } from '@scale/llm-shared/modelProviders/scaleLaunch';
import { BaseModelInternalId } from '@scale/llm-shared/modelProviders/types';
import { parseQueryVariables, validateTemplateVariables } from '@scale/llm-shared/templating';
import { ScaleTrainMetrics } from '@scale/llm-shared/types/scaleTrain';
import { useQuery } from '@tanstack/react-query';

import { client } from 'frontend/api/trpc';
import { Container } from 'frontend/components/Container';
import { MultiInputValues } from 'frontend/components/evaluation/MultiInputPreview';
import { RenderResult } from 'frontend/components/evaluation/utils';
import Filename from 'frontend/components/Filename';
import FlexBox from 'frontend/components/FlexBox';
import ModelSelector from 'frontend/components/model/ModelSelector';
import PageTitle from 'frontend/components/PageTitle';
import { SegmentedButtons } from 'frontend/components/SegmentedButtons';
import { SettingsContainer, SettingsEditor, SettingsHeader } from 'frontend/components/Settings';
import { VSpace } from 'frontend/components/Spacer';
import { DataSelectorAndUploader } from 'frontend/components/v2/data/DataSelectorAndUploader';
import VariantName from 'frontend/components/v2/variant/VariantName';
import usePageError from 'frontend/hooks/usePageError';
import useRandom from 'frontend/hooks/useRandom';
import { Tab, TABS, useFineTuningPageState } from 'frontend/models/useFineTuningPageState';
import { useMultiInputEvaluationState } from 'frontend/models/v2/useMultiInputEvaluationState';
import MetricsChart from 'frontend/pages/FineTuningPage/MetricsChart';
import PageContainer from 'frontend/pages/PageContainer';
import PageActionsDrawer from 'frontend/pages/PageContainer/PageActionsDrawer';
import FineTuningPageSettings from 'frontend/pages/v2/FineTuningPage/FineTuningPageSettings';
import PromptErrorMessage from 'frontend/pages/v2/PromptPage/PromptErrorMessage';
import { useModelStore } from 'frontend/stores/ModelStore';
import { useUserStore } from 'frontend/stores/UserStore';
import { useDataStore } from 'frontend/storesV2/DataStore';
import { useFinetuneStore } from 'frontend/storesV2/FinetuneStore';
import { useSelectionStore } from 'frontend/storesV2/SelectionStore';
import { StoredFinetune } from 'frontend/storesV2/types';
import theme, { Colors } from 'frontend/theme';
import { PairedRowStatus } from 'frontend/types/types';
import { track } from 'frontend/utils/analytics';
import {
  finetuneRenderPromptWithQuery,
  fineTuningDollarsCostEstimateV2,
} from 'frontend/utils/cost';
import { HIDDEN_V2_MODEL_IDS } from 'frontend/consts/defaults';

function FineTuningSummary({ finetune }: { finetune: StoredFinetune }) {
  const { dataById } = useDataStore(R.pick(['dataById']));
  const { modelById } = useModelStore(R.pick(['modelById']));

  const [metricsExpanded, setMetricsExpanded] = useState<boolean>(false);
  const [metrics, setMetrics] = useState<ScaleTrainMetrics | undefined>(undefined);

  const dataset = dataById[finetune.finetuneParameters.trainDataId];
  const createdAtString = `${finetune.createdAt.toLocaleDateString()} ${finetune.createdAt.toLocaleTimeString()}`;
  const progress = finetune.status === JobStatus.Completed ? 100 : finetune.progress * 100;

  // TODO: Probably need to set these directly on the finetuning object
  const finetunedModelName = finetune.finetuneParameters.derivedModelName;
  const baseModelId = finetune.finetuneParameters.baseModelId;
  const baseModelName = modelById[baseModelId]?.name;

  // Sample loss data
  useEffect(() => {
    if (
      metricsExpanded &&
      finetune.type === FinetuneType.ScaleTrainFinetune &&
      finetune.finetuneJobMetadata
    ) {
      client
        .query('v2.finetune.getScaleModelMetrics', {
          metricsUri: finetune.finetuneJobMetadata.metricsUri,
        })
        .then(res => {
          setMetrics(res);
        });
    }
  }, [metricsExpanded, finetune.type, finetune.finetuneJobMetadata]);

  // TODO: move this the hell outta here
  const lossMetricsData = useMemo(() => {
    if (!metrics) {
      return undefined;
    }
    const metricsData = {
      data: metrics.train_losses.map(
        (loss, i) =>
          ({ name: i, train_loss: loss } as {
            name: number;
            train_loss: number;
            val_loss: number | undefined;
          }),
      ),
      xLabel: 'Step',
      yLabel: 'Loss',
      title: 'Losses',
      dataNames: ['train_loss'],
    };
    // Downsample the data and add in validation losses
    const downsampledData = [];
    const frequency =
      metrics.val_losses && metrics.val_losses.length > 0
        ? Math.round(metrics.train_losses.length / metrics.val_losses.length)
        : Math.round(metricsData.data.length / 10);
    if (metrics.val_losses && metrics.val_losses.length > 0) {
      metricsData.dataNames.push('val_loss');
    }
    for (let i = frequency - 1; i < metricsData.data.length; i += frequency) {
      if (metrics.val_losses && metrics.val_losses.length > 0) {
        metricsData.data[i].val_loss = metrics.val_losses[(i + 1) / frequency - 1];
      }
      let averageLoss = 0;
      for (let j = i - frequency + 1; j <= i; j += 1) {
        averageLoss += metricsData.data[j].train_loss;
      }
      averageLoss /= frequency;
      metricsData.data[i].train_loss = averageLoss;
      downsampledData.push(metricsData.data[i]);
    }
    metricsData.data = downsampledData;
    return metricsData;
  }, [metrics]);

  const statusRender = useMemo(() => {
    switch (finetune.status) {
      case JobStatus.Errored:
        return <Chip color="error" size="small" label="Error" />;
      case JobStatus.Completed:
        return <Chip color="success" size="small" label="Complete" />;
      case JobStatus.Cancelled:
        return <Chip color="info" size="small" label="Cancelled" />;
      case JobStatus.Pending:
        return <Chip color="secondary" size="small" label="Pending" />;
      case JobStatus.Running:
        return <Chip color="secondary" size="small" label="Running" />;
    }
  }, [finetune.status]);

  return (
    <Container>
      <FlexBox sx={{ justifyContent: 'space-between' }}>
        <Typography variant="h2">
          <VariantName name={baseModelName} />
          {finetunedModelName && (
            <>
              <Box mx={1} sx={{ display: 'inline-block' }}>
                <FontAwesomeIcon icon="arrow-right" size="xs" />
              </Box>
              <VariantName name={finetunedModelName} />
            </>
          )}
        </Typography>
        <Typography variant="subtitle1">Created At: {createdAtString}</Typography>
      </FlexBox>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <FlexBox sx={{ gap: 6 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant="h3">Job Type</Typography>
            <Typography variant="body2">Fine-Tune</Typography>
          </Box>
          {dataset && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Typography variant="h3">Dataset</Typography>
              <Filename filename={dataset.name} />
            </Box>
          )}
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant="h3">Status</Typography>
            {statusRender}
          </Box>
        </FlexBox>
        {finetune.status === JobStatus.Completed &&
          finetune.type === FinetuneType.ScaleTrainFinetune && (
            <Accordion
              variant="outlined"
              defaultExpanded={false}
              onChange={(_, expanded) => setMetricsExpanded(expanded)}
            >
              <AccordionSummary expandIcon={<FontAwesomeIcon icon="caret-down" />}>
                <Typography variant="h3">Metrics</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {lossMetricsData === undefined ? (
                  <CircularProgress size="1.5rem" />
                ) : (
                  <MetricsChart metrics={lossMetricsData} />
                )}
              </AccordionDetails>
            </Accordion>
          )}
        {
          // Hide progress bar until progres is actually tracked for openai
        }
        {finetune.type === FinetuneType.ScaleTrainFinetune && (
          <FlexBox sx={{ width: '100%' }}>
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{ height: 8, borderRadius: 6, width: '-webkit-fill-available' }}
            />
            <Typography variant="h4" sx={{ width: '5ex' }}>
              {progress.toFixed(0)}%
            </Typography>
          </FlexBox>
        )}
      </Box>
    </Container>
  );
}

// TODO: Migrate after
function ColumnNameSelector({
  columns,
  value,
  onChange,
}: {
  columns?: string[];
  value: string;
  onChange: (newValue: string) => void;
}) {
  if (!columns) {
    return null;
  }
  return (
    <Select
      variant="standard"
      size="small"
      fullWidth
      value={value}
      onChange={e => onChange(e.target.value)}
      label="Expected Output"
      sx={{
        padding: 0,
        '& .MuiInputBase-input': {
          padding: 0,
          border: 0,
        },
      }}
    >
      {columns.map(column => (
        <MenuItem value={column} key={column}>
          <Typography
            sx={{
              margin: 0,
              color: Colors.CoolGray50,
              fontFamily: 'monospace',
              fontSize: theme.typography.body1.fontSize,
              fontWeight: theme.typography.fontWeightBold,
            }}
          >
            {column}
          </Typography>
        </MenuItem>
      ))}
    </Select>
  );
}

export function FineTuningPage() {
  const { createFinetune } = useFinetuneStore(R.pick(['createFinetune']));

  const { selectedAppId, selectedApp, selectedAppFinetunes } = useSelectionStore(
    R.pick(['selectedApp', 'selectedAppId', 'selectedAppFinetunes']),
  );

  const { user } = useUserStore(R.pick(['user']));
  const {
    selectedTab,
    setSelectedTab,
    numEpochs,
    setNumEpochs,
    modelName,
    setModelName,
    model,
    setModel,
    prompt,
    setPrompt,
    scaleFinetuningMethod,
    learningRateModifier,
    setLearningRateModifier,
    trainDatasetId,
    setTrainDatasetId,
    validationDatasetId,
    setValidationDatasetId,
    outputColumn,
    setOutputColumn,
    stopSequence,
    setStopSequence,
  } = useFineTuningPageState();

  const { getData, dataById } = useDataStore(R.pick(['getData', 'dataById']));
  const { showError } = usePageError();

  const { modelById } = useModelStore(R.pick(['modelById']));

  const trainDataRes = useQuery(['getData', trainDatasetId], async () => {
    return trainDatasetId ? getData(trainDatasetId) : undefined;
  });
  const trainDataset = trainDataRes.data || undefined;

  const validationDataRes = useQuery(['getData', validationDatasetId], async () => {
    return validationDatasetId ? getData(validationDatasetId) : undefined;
  });
  const validationDataset = validationDataRes.data || undefined;

  const [estimatedDollarsCost, setEstimatedDollarsCost] = useState<number | undefined>();
  useDebounce(
    () => {
      const validationCost = fineTuningDollarsCostEstimateV2(
        validationDataset,
        model,
        prompt,
        outputColumn,
      );
      const trainCost = fineTuningDollarsCostEstimateV2(trainDataset, model, prompt, outputColumn);
      // If both are undefined, return undefined
      if (validationCost == null && trainCost == null) {
        setEstimatedDollarsCost(undefined);
      } else {
        setEstimatedDollarsCost(((validationCost || 0) + (trainCost || 0)) * numEpochs);
      }
    },
    500,
    [trainDataset, validationDataset, outputColumn, model, prompt, numEpochs],
  );

  const [requestSent, setRequestSent] = useState<boolean>(false);

  const isScaleModel = useMemo(() => model && isScaleLaunchBaseModelInternalId(model), [model]);

  const sourceTrainData = useMemo(
    () => (trainDatasetId ? dataById[trainDatasetId] : undefined),
    [trainDatasetId, dataById],
  );

  const sourceValidationData = useMemo(
    () => (validationDatasetId ? dataById[validationDatasetId] : undefined),
    [validationDatasetId, dataById],
  );

  const promptError = useMemo(() => {
    if (sourceTrainData) {
      const trainError = validateTemplateVariables({ template: prompt }, sourceTrainData);
      if (trainError) {
        return 'Training dataset: ' + trainError;
      }
      // Outputcolumn is based on training dataset so no need to check for errors
    }
    if (isScaleModel && sourceValidationData) {
      const validationError = validateTemplateVariables({ template: prompt }, sourceValidationData);
      if (validationError) {
        return 'Validation dataset: ' + validationError;
      }
      if (outputColumn) {
        if (!sourceValidationData.columns.find(column => column.name === outputColumn.name)) {
          return `Validation dataset missing output column ${outputColumn}`;
        }
      }
    }
  }, [
    validateTemplateVariables,
    prompt,
    sourceTrainData,
    sourceValidationData,
    isScaleModel,
    outputColumn,
  ]);

  useEffect(() => {
    setModelName(
      `${modelById[model as string]?.name}${trainDataset ? ` - ${trainDataset.name}` : ''}`,
    );
  }, [modelName, trainDataset, modelById, setModelName, model]);

  useEffect(() => {
    setTrainDatasetId(undefined);
    setModel(BaseModelInternalId.GPT3TextDavinci001);
  }, [selectedAppId]);

  const handleCreateFineTuning = useCallback(() => {
    if (!trainDataset || !trainDataset.id || !selectedAppId || !selectedApp || promptError) {
      return;
    }
    if (numEpochs <= 0 || numEpochs > 100) {
      showError('Please set the number of finetuning epochs between 1 and 100!');
      return;
    }
    if (!outputColumn) {
      showError('Please set an output column in the prompt sidebar!');
      return;
    }

    setRequestSent(true);
    track('Fine Tuning Training Requested');
    const finetuneCreate: FinetuneCreate = {
      appId: selectedAppId,
      finetuneParameters: {
        trainDataId: trainDataset.id,
        validationDataId: validationDatasetId ?? undefined,
        expectedDataColumnId: outputColumn.id,
        prompt: {
          template: prompt,
          exampleVariables: {},
          variablesSourceDataId: undefined,
        },
        userId: selectedApp.userId,
        derivedModelName: modelName,
        baseModelId: model,
        epochs: numEpochs,
        learningRateModifier,
        stopSequence,
      },
      progress: 0,
      type: isScaleModel ? FinetuneType.ScaleTrainFinetune : FinetuneType.OpenAIFinetune,
    };
    client.query('v2.finetune.checkCanStartFinetuning', finetuneCreate).then(errorMsg => {
      if (errorMsg) {
        showError(errorMsg);
        setRequestSent(false);
        return;
      }
      return createFinetune(finetuneCreate)
        .then(returnedFineTuning => {
          if (returnedFineTuning.success) {
            setSelectedTab(Tab.View);
          } else {
            throw new Error('Unsuccessful finetune!');
          }
          setRequestSent(false);
        })
        .catch(() => {
          showError('Internal Server Error');
          setRequestSent(false);
        });
    });
  }, [
    trainDataset,
    validationDataset,
    modelName,
    numEpochs,
    user,
    scaleFinetuningMethod,
    learningRateModifier,
    model,
    outputColumn,
    prompt,
    selectedAppId,
    selectedApp,
    setSelectedTab,
    showError,
  ]);

  // Table preview
  const dataHasColumns = !!trainDataset?.columns;
  const { showExpectedColumn, expectedOutputColumn, setExpectedOutputColumn } =
    useMultiInputEvaluationState();

  // Options for showing number of example rows
  const numberOfRows = 5;

  const { random } = useRandom();

  const handleExpectedOutputColumnChange = useCallback(
    (newValue: string) => {
      setExpectedOutputColumn(newValue);
    },
    [setExpectedOutputColumn],
  );

  // Data injection manipulation
  const [inputRowsFull, setInputRows] = useState<DataRow[]>([]);
  const getRandomRows = useCallback(() => {
    if (!trainDataset) {
      return [];
    }
    return random.sampleSize(Object.values(trainDataset.rowByIndex), numberOfRows);
  }, [random, trainDataset, numberOfRows]);

  const inputRows = inputRowsFull.slice(0, numberOfRows);

  const shuffleInputRows = useCallback(() => {
    track('Prompt Items Shuffled', { view: 'Evaluation' });
    setInputRows(getRandomRows());
  }, [getRandomRows, setInputRows]);

  // Shuffle items when data is loaded

  useEffect(() => {
    shuffleInputRows();
  }, [trainDataRes.data]);

  const generatedInput = useMemo(() => {
    if (!promptError) {
      return inputRows.map(row => finetuneRenderPromptWithQuery(prompt, row.valueByName));
    }
    return [];
  }, [promptError, prompt, inputRows]);

  // Parsed input column names from prompt
  const [inputNames, setInputNames] = useState<string[]>([]);
  useEffect(() => {
    try {
      const promptInputNames = parseQueryVariables(prompt);
      setInputNames(promptInputNames);
    } catch (err) {
      setInputNames([] as string[]);
    }
  }, [prompt]);

  // Selection state
  const [selectedRow, setSelectedRow] = useState<number>(-1);
  const cellSelectionProps = useCallback(
    (row: number, col: number) => ({
      onMouseDown: (e: React.MouseEvent<HTMLTableCellElement>) => {
        setSelectedRow(row != selectedRow ? row : -1);
        e.stopPropagation();
      },
      className: row === selectedRow ? 'selected' : '',
    }),
    [selectedRow, setSelectedRow],
  );

  return (
    <PageContainer page="fine-tuning">
      <PageTitle
        title="Fine Tuning"
        subtitle={
          <Typography variant="body1">
            {'See our '}
            <Link
              href={'https://spellbook.readme.io/docs/finetuning'}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: Colors.Periwinkle100, textDecoration: 'none' }}
            >
              documentation on fine-tuning
            </Link>
            {' for more details on how fine-tuning can improve your variants.'}
          </Typography>
        }
      ></PageTitle>
      <FlexBox sx={{ justifyContent: 'space-between' }}>
        <SegmentedButtons labels={TABS} selectedLabel={selectedTab} onClick={setSelectedTab} />
        {selectedTab === Tab.Create && (
          <>
            <Tooltip
              followCursor
              title={
                requestSent
                  ? 'Kicking off finetuning can sometimes take some time, depending on how large your dataset is.'
                  : trainDataset
                  ? ''
                  : 'You must select a dataset first!'
              }
            >
              <span>
                <LoadingButton
                  loading={requestSent}
                  variant="contained"
                  onClick={handleCreateFineTuning}
                  disabled={requestSent || !trainDataset || !outputColumn || !!promptError}
                >
                  Create fine tune
                </LoadingButton>
              </span>
            </Tooltip>
          </>
        )}
      </FlexBox>
      <VSpace s={1} />
      {selectedTab === Tab.Create && (
        <>
          <Container sx={{ flexDirection: 'row', gap: 3 }}>
            <SettingsContainer>
              <SettingsHeader title="Fine Tuned Model Name" />
              <SettingsEditor>
                <TextField
                  fullWidth
                  size="small"
                  value={modelName}
                  onChange={e => setModelName(e.target.value)}
                />
              </SettingsEditor>
            </SettingsContainer>
            <SettingsContainer>
              <SettingsHeader title="Base Model" />
              <SettingsEditor>
                <ModelSelector
                  selectedModelId={model}
                  onChange={setModel}
                  baseModelsOnly
                  hiddenModelIds={HIDDEN_V2_MODEL_IDS}
                />
              </SettingsEditor>
            </SettingsContainer>
            <SettingsContainer>
              <SettingsHeader title="Train Dataset" />
              <SettingsEditor>
                <DataSelectorAndUploader dataId={trainDatasetId} setDataId={setTrainDatasetId} />
              </SettingsEditor>
            </SettingsContainer>
            {
              // TODO(685693): Enable for openai models
              isScaleModel && (
                <SettingsContainer>
                  <Tooltip
                    title={
                      'Dataset used to validate model performance. Should not overlap at all with the training dataset. ' +
                      'If not provided, 20% of the training dataset will automatically be used as the validation dataset.'
                    }
                  >
                    <div>
                      <SettingsHeader title="Validation Dataset" />
                    </div>
                  </Tooltip>
                  <SettingsEditor>
                    <DataSelectorAndUploader
                      allowNoDataset
                      dataId={validationDatasetId}
                      setDataId={setValidationDatasetId}
                    />
                  </SettingsEditor>
                </SettingsContainer>
              )
            }
          </Container>
          {promptError ? (
            <>
              <PromptErrorMessage promptError={promptError} />
              <VSpace s={0.5} />
            </>
          ) : (
            <VSpace s={1} />
          )}
          <FineTuningPageSettings
            isScaleModel={isScaleModel}
            numEpochs={numEpochs}
            setNumEpochs={setNumEpochs}
            prompt={prompt}
            setPrompt={setPrompt}
            learningRateModifier={learningRateModifier}
            setLearningRateModifier={setLearningRateModifier}
            trainDataset={trainDataset || null}
            outputColumn={outputColumn}
            setOutputColumn={setOutputColumn}
            stopSequence={stopSequence}
            setStopSequence={setStopSequence}
          />
          <TableContainer component={Paper} variant="outlined">
            <Table
              sx={{
                borderCollapse: 'separate',
                '& .MuiTableCell-row': {
                  border: 'none',
                },
                '& .MuiTableHead-root .MuiTableCell-root': {
                  color: Colors.CoolGray50,
                  fontFamily: 'inherit',
                  fontSize: theme.typography.subtitle2.fontSize,
                  fontWeight: theme.typography.fontWeightBold,
                  backgroundColor: Colors.CoolGray10,
                  borderWidth: 1,
                  borderColor: Colors.CoolGray30,
                  paddingY: 0.5,
                },
                '& .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root': {
                  borderBottom: 'none',
                },
                '& .MuiTableRow-root:hover': {
                  backgroundColor: Colors.CoolGray10,
                },
                '& .MuiTableCell-root': {
                  color: Colors.CoolGray70,
                  fontFamily: 'monospace',
                  borderRight: `1px solid ${Colors.CoolGray20}`,
                },
                '& .MuiTableCell-root:last-child': {
                  borderRight: 'none',
                  borderLeft: 'none',
                },

                '& .multiinput-inner-table .MuiTableCell-root': {
                  borderBottom: 'none',
                },
                '& .custom-input-inner-table .MuiTableCell-root': {
                  borderBottom: 'none',
                },

                // MultiInput display changes based on the hover and selection state of
                // the dataset row (darken borders, change base color to blue)
                // TODO: probably doable via theming
                '& .MuiTableRow-root.selected .multiinput-inner-table .MuiTableRow-root': {
                  backgroundColor: 'transparent',
                },
                '& .MuiTableRow-root:hover .multiinput-inner-table .MuiTableCell-root': {
                  borderColor: Colors.CoolGray20,
                },
                '& .MuiTableRow-root.selected .multiinput-inner-table .MuiTableCell-root': {
                  borderColor: Colors.CoolBlue10,
                },

                // Selection colors -- change theme to blue and add border
                '& .MuiTableRow-root.selected': {
                  backgroundColor: Colors.CoolBlue05,
                },
                '& .MuiTableRow-root.selected:hover': {
                  backgroundColor: Colors.CoolBlue10,
                },
                '& .MuiTableCell-root.selected': {
                  border: `1px double ${Colors.Blue}`,
                },
                '& .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root.selected': {
                  borderBottom: `1px double ${Colors.Blue}`,
                },
              }}
            >
              <TableHead sx={{ display: 'auto' }}>
                <TableRow>
                  {!!inputNames.length && trainDataset?.columns ? (
                    <TableCell width={200}>
                      Input
                      <Tooltip title="Randomize items used for testing" placement="top">
                        <IconButton onClick={shuffleInputRows}>
                          <FontAwesomeIcon size="xs" icon="shuffle" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  ) : (
                    <TableCell>Input</TableCell>
                  )}
                  {dataHasColumns && showExpectedColumn && (
                    <TableCell width={200}>
                      <ColumnNameSelector
                        columns={trainDataset?.columns.map(column => column.name)}
                        value={expectedOutputColumn}
                        onChange={handleExpectedOutputColumnChange}
                      />
                    </TableCell>
                  )}
                  <TableCell width={200}>
                    <VariantName name={'Input Prompt'} />
                  </TableCell>
                  <TableCell width={200}>
                    <VariantName name={'Target Completion'} />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataHasColumns && showExpectedColumn && <TableCell />}
                {inputRows.map((inputRow, row) => (
                  <TableRow
                    key={inputRow.index}
                    className={selectedRow === row + 1 ? 'selected' : ''}
                  >
                    <TableCell {...cellSelectionProps(row + 1, 0)} sx={{ padding: 0 }}>
                      <MultiInputValues
                        valueByName={inputRow.valueByName}
                        columnNames={inputNames}
                      />
                    </TableCell>
                    <TableCell>
                      <RenderResult
                        row={{
                          id: 'generated_input' + row,
                          status: PairedRowStatus.COMPLETE,
                          input: '',
                          expectedOutput: '',
                          actualOutput: generatedInput[row] || '',
                          tokenProbs: null,
                          error: null,
                          finishReason: null,
                        }}
                        inProgressElem={
                          <Box>
                            <Skeleton width={120} />
                            <Skeleton width={150} />
                          </Box>
                        }
                      />
                    </TableCell>
                    {!outputColumn ? (
                      <TableCell>
                        <Chip
                          variant="outlined"
                          size="small"
                          label="Empty Output"
                          sx={{ fontSize: 10 }}
                        />
                      </TableCell>
                    ) : (
                      <TableCell>{inputRow.valueByName[outputColumn.name]}</TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      {selectedTab === Tab.View && (
        <Paper variant="outlined">
          <Box p={2}>
            <Box mb={2}>
              <Typography variant="h2">Fine-tuned Models</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {selectedAppFinetunes?.map(finetune => (
                <FineTuningSummary key={finetune.id} finetune={finetune} />
              ))}
              {!selectedAppFinetunes?.length && (
                <Typography>You currently have no fine-tunings for this app.</Typography>
              )}
            </Box>
          </Box>
        </Paper>
      )}
      <PageActionsDrawer open={selectedTab === Tab.Create}>
        {trainDataset && estimatedDollarsCost != null && (
          <Typography variant="body2">
            Estimated cost ~${estimatedDollarsCost.toFixed(2)}
          </Typography>
        )}
      </PageActionsDrawer>
    </PageContainer>
  );
}
