import * as R from 'ramda';

import { setUserVars } from '@fullstory/browser';
import { BillingPlan, Model } from '@prisma/client';
import { ScaleIdentityServiceMetadata, User } from '@scale/llm-shared/types/user';

import { absoluteApiHref, client } from 'frontend/api/trpc';
import { loadBooleanFeatureFlags } from 'frontend/consts/featureFlags';
import { useAppStore } from 'frontend/stores/AppStore';
import { useModelStore } from 'frontend/stores/ModelStore';
import {
  LoadingState,
  UserBooleanFeatureFlags,
  UserStringFeatureFlags,
  useUserStore,
} from 'frontend/stores/UserStore';
import { HasId } from 'frontend/types/types';
import { identify } from 'frontend/utils/analytics';

/**
 * Curried function that sets an indexed list to a callback.
 */
function setIndexedData<T>(setter: (indexedData: Record<string, T & HasId>) => unknown) {
  return (data: (T & HasId)[]): Record<string, T & HasId> => {
    const indexedData = R.indexBy(R.prop('id'), data);
    setter(indexedData);
    return indexedData;
  };
}

export async function hydrateUserStoreFromApi({ init }: { init?: boolean }) {
  const search = window.location.search;
  const loggedInUserUrl = `${absoluteApiHref}/loggedInUser${search}`;
  try {
    const response = await fetch(loggedInUserUrl, { credentials: 'include' });
    const result = (await response.json()) as any;
    if (response.status === 401) {
      useUserStore.getState().setLoadingState(LoadingState.Unauthenticated);
    } else if (response.status === 403) {
      useUserStore.getState().setLoadingState(LoadingState.Unauthorized);
      window.location.href = 'https://scale.com/spellbook';
    } else if (!result) {
      useUserStore.getState().setLoadingState(LoadingState.Error);
    } else if (result.user) {
      const user = result.user as User;

      // Feature flags
      const featureFlags = result.features;
      if (featureFlags) {
        useUserStore
          .getState()
          .setBooleanFeatureFlags(featureFlags.booleanFlags as UserBooleanFeatureFlags);
        loadBooleanFeatureFlags(featureFlags.booleanFlags);
        useUserStore
          .getState()
          .setStringFeatureFlags(featureFlags.stringFlags as UserStringFeatureFlags);
      }

      // Billing plan
      const billingPlan = await client.query('billing.getBillingPlan');
      if (billingPlan) {
        useUserStore.getState().setBillingPlan(billingPlan as BillingPlan);
      }

      // Analytics
      try {
        const metadata = user.metadata as ScaleIdentityServiceMetadata;
        const scaleId = metadata.userIdentity || 'unknown';
        const email = metadata.email;
        const team = metadata.team?.id;
        setUserVars({
          id: user.id,
          scaleId,
        });
        identify(scaleId, { email, team_id: team });
      } catch (err) {
        console.warn('Unable to identify user for analytics');
      }

      // Deployment key
      const deploymentKey = await client.query('user.getDeploymentKey');
      if (deploymentKey) {
        useUserStore.getState().setDeploymentKey(deploymentKey);
      }

      const namespaces = await client.query('user.getNamespaces');
      if (namespaces) {
        useUserStore.getState().setNamespaces(namespaces);
      }

      useUserStore.getState().setUser(user);
      useUserStore.getState().setLoadingState(LoadingState.Success);
    }
  } catch (err) {
    useUserStore.getState().setLoadingState(LoadingState.Error);
  }
}

export function hydrateAppStoreFromApi({ init }: { init?: boolean }) {
  init && useAppStore.getState().setAppByIdInitLoaded(false);
  return client
    .query('app.findVisible', undefined, { context: { skipBatch: true } })
    .then(setIndexedData(useAppStore.getState().setAppById))
    .then(R.tap(() => init && useAppStore.getState().setAppByIdInitLoaded(true)));
}

export function hydrateModelStoreFromApi({
  init,
  ids,
}: { init?: boolean; ids?: string[] } = {}): Promise<Record<string, Model & HasId>> {
  const { setModelByIdInitLoaded, setModelById, appendModelById } = useModelStore.getState();
  init && setModelByIdInitLoaded(false);
  // If has ids but no length
  if (ids && !ids.length) {
    return Promise.resolve({});
  }
  const modelPromise = ids
    ? client.query('model.findByIds', { ids })
    : client.query('model.findAll');

  return modelPromise
    .then(setIndexedData(init ? setModelById : appendModelById))
    .then(R.tap(() => init && setModelByIdInitLoaded(true)));
}
