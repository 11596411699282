import { styled } from '@mui/material';

import { Colors } from 'frontend/theme';

const AddedText = styled('span')({
  backgroundColor: Colors.LightSkyBlue,
  marginLeft: '-0.5ex',
  marginRight: '-0.5ex',
  paddingLeft: '0.5ex',
  paddingRight: '0.5ex',
  borderRadius: '2px',
});

export default AddedText;
