import { useCallback, useEffect, useMemo, useState } from 'react';

import _ from 'lodash';

export function FineTuningPromptTextField({
  value,
  onChange,
  readOnly = false,
  debounce = 0,
  disabled = false,
}: {
  value: string;
  onChange?: (value: string) => void;
  readOnly?: boolean;
  debounce?: number;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  label?: string;
}) {
  const [currentValue, setCurrentValue] = useState(value);
  useEffect(() => setCurrentValue(value), [value]);

  const debouncedOnChange = useMemo(
    () => (debounce ? onChange && _.debounce(onChange, debounce) : onChange),
    [onChange, debounce],
  );
  const handleChange = useCallback(
    (e: any) => {
      setCurrentValue(e.target.value);
      debouncedOnChange?.(e.target.value);
    },
    [debouncedOnChange],
  );

  return (
    <textarea
      className="monospace"
      style={{
        position: 'relative',
        height: '100%',
        width: '100%',
        padding: 16,
        lineHeight: 1.4,
        resize: 'none',
        borderRadius: '8px',
        border: 'none',
      }}
      disabled={disabled}
      value={currentValue}
      onChange={readOnly ? undefined : handleChange}
      placeholder={'Create a new prompt or use an existing prompt template'}
      rows={36}
    />
  );
}
