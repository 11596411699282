import { useCallback, useMemo, useState } from 'react';

import _ from 'lodash';
import * as R from 'ramda';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { logger } from '@scale/llm-shared/utils/logging';

import { useDeploymentStore } from 'frontend/storesV2/DeploymentStore';
import { useSelectionStore } from 'frontend/storesV2/SelectionStore';
import { StoredDeployment, StoredVariant } from 'frontend/storesV2/types';
import { Colors } from 'frontend/theme';

const NO_DEPLOYMENT_VALUE = '';

export const DeploymentSelector = ({
  deployment,
  setDeployment,
  variant,
  allowCreateDeployment = true,
  size,
}: {
  deployment: StoredDeployment | undefined;
  setDeployment: (value: StoredDeployment | undefined) => void;
  variant?: StoredVariant | undefined;
  allowCreateDeployment?: boolean;
  size?: 'small' | 'medium' | undefined;
}) => {
  const { selectedAppDeployments } = useSelectionStore(R.pick(['selectedAppDeployments']));
  const { deploymentById } = useDeploymentStore(R.pick(['deploymentById']));

  const [open, setOpen] = useState<boolean>(false);

  const deploymentIdsForVariant = useMemo(() => {
    return Object.values(deploymentById)
      .filter(deployment => deployment.variantId === variant?.id)
      .map(deployment => deployment.id);
  }, [deploymentById, variant?.id]);

  const handleSelect = useCallback(
    (e: SelectChangeEvent<string>) => {
      const deploymentId = e.target.value;
      if (deploymentId === NO_DEPLOYMENT_VALUE) {
        setDeployment(undefined);
        return;
      }
      const selectedDeployment = _.find(selectedAppDeployments, { id: deploymentId });
      if (!selectedDeployment) {
        logger.error('Selected non-existent deployment id');
        return;
      }
      setDeployment(selectedDeployment as StoredDeployment);
    },
    [selectedAppDeployments, setDeployment],
  );

  return (
    <Select
      fullWidth
      size={size}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      value={deployment?.id || NO_DEPLOYMENT_VALUE}
      labelId="selected-deployment-label"
      displayEmpty
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        sx: {
          '& .MuiList-padding': {
            padding: 0,
          },
        },
      }}
      onChange={handleSelect}
      renderValue={
        !deployment
          ? () => <Typography sx={{ color: Colors.CoolGray40 }}>Select Deployment</Typography>
          : undefined
      }
    >
      <Box
        sx={{
          px: 2,
          pb: 1.5,
          pt: 2.5,
          backgroundColor: Colors.CoolGray05,
          borderBottom: `1px solid ${Colors.CoolGray20}`,
        }}
      >
        <Typography variant="overline">Your Existing Deployments</Typography>
      </Box>
      {selectedAppDeployments?.map(deployment => {
        return (
          <MenuItem
            key={deployment.id}
            value={deployment.id}
            disabled={deploymentIdsForVariant.includes(deployment.id)}
          >
            <FontAwesomeIcon
              icon="upload"
              style={{ color: Colors.CoolGray50, marginRight: '1ex' }}
            />
            {deployment.name}
          </MenuItem>
        );
      })}
      {allowCreateDeployment && (
        <Box sx={{ padding: 1, paddingTop: 1.5 }}>
          <Button
            variant="outlined"
            size="small"
            fullWidth
            onClick={() => {
              setDeployment(undefined);
              setOpen(false);
            }}
          >
            <FontAwesomeIcon icon="plus" style={{ marginRight: '1ex' }} />
            Create New Deployment
          </Button>
        </Box>
      )}
    </Select>
  );
};
