import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Chip, Popper, styled, Tooltip, TooltipProps } from '@mui/material';

import FlexBox from 'frontend/components/FlexBox';
import theme, { Colors } from 'frontend/theme';

const AddButton = styled(Button)({
  padding: 0,
  fontSize: 10,
  textDecoration: 'underline',
  color: Colors.CoolGray30,
  '&:hover': {
    textDecoration: 'underline',
  },
});

function Variable({ name }: { name: string }) {
  return <Box sx={{ display: 'inline-block', fontFamily: 'monospace' }}>{'{{' + name + '}}'}</Box>;
}

export interface FineTuningPromptVariableChipProps {
  name: string;
  isDataVariable: boolean;
  isPromptVariable: boolean;
}

export function FineTuningPromptVariableChip({
  name,
  isDataVariable,
  isPromptVariable,
  isLinkRequired,
}: FineTuningPromptVariableChipProps & {
  isLinkRequired: boolean;
}) {
  const tooltipProps: Partial<TooltipProps> = {
    placement: 'left',
    leaveDelay: 350,
  };

  if (isPromptVariable && isDataVariable) {
    return (
      <Chip
        variant="filled"
        label={
          <Tooltip
            {...tooltipProps}
            title={
              <FlexBox sx={{ gap: 2 }}>
                <FontAwesomeIcon icon="info-circle" />
                <Box>
                  The text <Variable name={name} /> in your prompt is linked to your dataset. It
                  will be replaced with the value from the matching column &ldquo;{name}&rdquo; when
                  running this prompt.
                </Box>
              </FlexBox>
            }
          >
            <Box>
              <FontAwesomeIcon icon="database" size="sm" style={{ marginRight: '1ex' }} />
              {name}
            </Box>
          </Tooltip>
        }
        size="small"
        sx={{
          color: Colors.CoolGray70,
          borderRadius: 0.5,
          fontFamily: 'monospace',
        }}
      />
    );
  }
  if (isPromptVariable && !isDataVariable) {
    return (
      <Chip
        variant="filled"
        label={
          <Tooltip
            {...tooltipProps}
            title={
              isLinkRequired ? (
                <FlexBox sx={{ gap: 2 }}>
                  <FontAwesomeIcon icon="info-circle" />
                  <Box>
                    The variable <Variable name={name} /> is not linked to any of the columns in the
                    dataset. You can still use it with custom inputs.
                  </Box>
                </FlexBox>
              ) : (
                <FlexBox sx={{ gap: 2 }}>
                  <FontAwesomeIcon icon="info-circle" />
                  <Box>
                    The text <Variable name={name} /> will be replaced with an input value when
                    running the prompt.
                  </Box>
                </FlexBox>
              )
            }
          >
            <Box>{name}</Box>
          </Tooltip>
        }
        size="small"
        sx={{
          color: Colors.CoolGray70,
          borderRadius: 0.5,
          fontFamily: 'monospace',
        }}
      />
    );
  }
  if (isDataVariable && !isPromptVariable) {
    return (
      <Chip
        variant="outlined"
        label={
          <Tooltip
            {...tooltipProps}
            title={
              <FlexBox sx={{ gap: 2 }}>
                <FontAwesomeIcon icon="circle-info" />
                <Box>
                  <Box>
                    Add <Variable name={name} /> to your prompt to link it to the data column
                    &ldquo;
                    {name}&rdquo;.
                  </Box>
                </Box>
              </FlexBox>
            }
          >
            <Box>
              <FontAwesomeIcon icon="database" size="sm" style={{ marginRight: '1ex' }} />
              {name}
            </Box>
          </Tooltip>
        }
        size="small"
        sx={{
          color: Colors.CoolGray40,
          borderColor: Colors.CoolGray40,
          borderStyle: 'dashed',
          borderRadius: 0.5,
          fontFamily: 'monospace',
        }}
      />
    );
  }
  console.error('Variable must be at least one of data variable or prompt variable');
  return null;
}
