import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  addMonths,
  differenceInDays,
  endOfMonth,
  format,
  formatISO,
  isSameDay,
  startOfDay,
  startOfMonth,
  subDays,
} from 'date-fns';
import _ from 'lodash';
import millify from 'millify';
import * as R from 'ramda';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import DateRangePicker from 'rsuite/DateRangePicker';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Skeleton,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { VERIFIED_PHONE_CREDIT_STR } from '@scale/llm-shared/consts/financial';
import { FrontendFeatureFlag } from '@scale/llm-shared/featureFlags';
import { BillingEventDisplayName } from '@scale/llm-shared/modelProviders/getDisplayName';
import { isAdminUser, userMetadataFromScaleIdentityService } from '@scale/llm-shared/types/user';
import { useQuery } from '@tanstack/react-query';

import { client } from 'frontend/api/trpc';
import useApi from 'frontend/api/useApi';
import { ActionBanner } from 'frontend/components/ActionBanner';
import { Container } from 'frontend/components/Container';
import FlexBox from 'frontend/components/FlexBox';
import PageTitle from 'frontend/components/PageTitle';
import { SegmentedButtons } from 'frontend/components/SegmentedButtons';
import { VSpace } from 'frontend/components/Spacer';
import { FEATURE_FLAG, FEATURE_FLAGS } from 'frontend/consts/featureFlags';
import { CreditCardInput } from 'frontend/containers/CreditCardInput';
import { useCodePassed } from 'frontend/hooks/useCodePassed';
import { useForceUpdate } from 'frontend/hooks/useForceUpdate';
import { useParamsNavigate } from 'frontend/hooks/useLLMNavigation';
import { useUnleashFlags } from 'frontend/hooks/useUnleashFlags';
import PageContainer from 'frontend/pages/PageContainer';
import { VerifyPhoneNumberButton } from 'frontend/pages/SettingsPage/PhoneVerificationModal';
import { LocalSettings, useSettingsStore } from 'frontend/stores/SettingsStore';
import { useUserStore } from 'frontend/stores/UserStore';
import theme, { Colors } from 'frontend/theme';

function OpenAiApiKeySetting() {
  const { user } = useUserStore(R.pick(['user']));
  const [draftKey, setDraftKey] = useState<string | undefined>(user?.openAiApiKey || undefined);
  const [showOpenAiApiKey, setShowOpenAiApiKey] = useState<boolean>(false);
  const { updateOpenAiApiKey } = useApi();
  const [updatedOpen, setUpdatedOpen] = useState<boolean>(false);

  const handleSave = useCallback(async () => {
    if (!user) {
      console.warn('User object is not loaded, please check preload hydration');
      return;
    }
    if (draftKey === undefined) {
      return;
    }
    await updateOpenAiApiKey(draftKey);
    setUpdatedOpen(true);
  }, [draftKey, user]);

  useEffect(() => {
    if (draftKey === undefined && user?.openAiApiKey) {
      setDraftKey(user.openAiApiKey);
    }
  }, [draftKey, user]);

  const handleToggleShowOpenAiApiKey = useCallback(() => {
    setShowOpenAiApiKey(show => !show);
  }, [setShowOpenAiApiKey]);

  return (
    <Box>
      <Snackbar
        open={updatedOpen}
        autoHideDuration={6000}
        onClose={() => setUpdatedOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setUpdatedOpen(false)} severity="success" sx={{ width: '100%' }}>
          OpenAI API key updated!
        </Alert>
      </Snackbar>
      <Typography variant="h3">OpenAI API Key</Typography>
      <Box my={1}>
        <Typography>Enter your OpenAI API key to connect with OpenAI models like GPT-3.</Typography>
      </Box>
      <FlexBox>
        <form style={{ width: '100%' }}>
          <OutlinedInput
            fullWidth
            size="small"
            value={draftKey || ''}
            onChange={e => setDraftKey(e.target.value)}
            type={showOpenAiApiKey ? 'text' : 'password'}
            autoComplete="off"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle api key visibility"
                  onClick={handleToggleShowOpenAiApiKey}
                  edge="end"
                  size="small"
                >
                  {showOpenAiApiKey ? (
                    <FontAwesomeIcon icon="eye-slash" size="sm" style={{ width: 24 }} />
                  ) : (
                    <FontAwesomeIcon icon="eye" size="sm" style={{ width: 24 }} />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
        </form>
        <Button onClick={handleSave} variant="outlined" disabled={draftKey === user?.openAiApiKey}>
          Update
        </Button>
      </FlexBox>
    </Box>
  );
}

function ShowTokenProbabilitiesSetting() {
  const { settings, setSettings } = useSettingsStore();

  const handleCheckboxChange = useCallback(
    (checked: boolean) => {
      setSettings({
        ...settings,
        showTokenProbabilities: checked,
      });
    },
    [settings, setSettings],
  );

  return (
    <Box>
      <Typography variant="h3">Show Token Probabilities</Typography>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={settings.showTokenProbabilities || false}
              onChange={e => handleCheckboxChange(e.target.checked)}
            />
          }
          label="Show token probabilities in model output when available"
        />
      </FormGroup>
    </Box>
  );
}

function ColorblindModeSetting() {
  const { settings, setSettings } = useSettingsStore();

  const handleCheckboxChange = useCallback(
    (checked: boolean) => {
      setSettings({
        ...settings,
        colorblindMode: checked,
      });
    },
    [settings, setSettings],
  );

  return (
    <Box>
      <Typography variant="h3">Colorblind Mode</Typography>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={settings.colorblindMode || false}
              onChange={e => handleCheckboxChange(e.target.checked)}
            />
          }
          label="Enable high contrast colors for colorblind users"
        />
      </FormGroup>
    </Box>
  );
}

function RandomnessSetting() {
  const { settings, setSettings } = useSettingsStore();
  const [draftSettings, setDraftSettings] = useState<LocalSettings>(settings);

  const handleSave = useCallback(() => {
    setSettings(draftSettings);
  }, [draftSettings, setSettings]);

  const handleCheckboxChange = useCallback(
    (checked: boolean) => {
      setDraftSettings(s => ({
        ...s,
        pseudoRandomActive: checked,
      }));
    },
    [setDraftSettings],
  );

  const handleSeedChange = useCallback(
    (seed: string) => {
      setDraftSettings(s => ({
        ...s,
        pseudoRandomSeed: seed,
      }));
    },
    [setDraftSettings],
  );

  const hasChanged = useMemo(
    () =>
      draftSettings.pseudoRandomActive !== settings.pseudoRandomActive ||
      draftSettings.pseudoRandomSeed !== settings.pseudoRandomSeed,
    [draftSettings, settings],
  );

  const randomizeSeed = useCallback(() => {
    handleSeedChange(_.random(1000000, 9999999).toString());
  }, [handleSeedChange]);

  return (
    <Box>
      <Typography variant="h3">Random Numbers</Typography>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={draftSettings.pseudoRandomActive || false}
              onChange={e => handleCheckboxChange(e.target.checked)}
            />
          }
          label="Use Fixed Seed"
        />
        <FlexBox>
          <OutlinedInput
            placeholder="Random Seed"
            fullWidth
            disabled={!draftSettings.pseudoRandomActive}
            value={draftSettings.pseudoRandomSeed}
            onChange={e => handleSeedChange(e.target.value)}
            size="small"
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="randomize seed value" onClick={randomizeSeed} edge="end">
                  <FontAwesomeIcon icon="shuffle" size="xs" style={{ width: 24 }} />
                </IconButton>
              </InputAdornment>
            }
          />
          <Button
            onClick={handleSave}
            variant="outlined"
            disabled={
              (draftSettings.pseudoRandomActive && !draftSettings.pseudoRandomSeed) || !hasChanged
            }
          >
            Update
          </Button>
        </FlexBox>
      </FormGroup>
    </Box>
  );
}

// TODO: move this out. we use it in deployment page too
const BlurOutlinedInput = styled(OutlinedInput)({
  '&.blurText input': {
    filter: 'blur(4px)',
  },
});
export function SpellbookApiKeyField() {
  const { deploymentKey: apiKey } = useUserStore(R.pick(['deploymentKey']));
  const [showApiKey, setShowApiKey] = useState(false);

  const handleToggleVisibility = () => {
    setShowApiKey(!showApiKey);
  };

  const handleCopyToClipboard = () => {
    if (!apiKey) {
      return;
    }
    navigator.clipboard.writeText(apiKey);
  };

  if (!apiKey) {
    // NOTE: this should never happen
    return <Alert severity="error">You do not have a Spellbook API key!</Alert>;
  }

  return (
    <BlurOutlinedInput
      className={showApiKey ? '' : 'blurText'}
      type="text"
      value={apiKey}
      size="small"
      sx={{ width: '25%' }}
      onClick={handleCopyToClipboard}
      endAdornment={
        <InputAdornment position="end">
          <IconButton size="small" onClick={handleToggleVisibility}>
            {showApiKey ? (
              <FontAwesomeIcon icon="eye-slash" size="xs" style={{ width: 24 }} />
            ) : (
              <FontAwesomeIcon icon="eye" size="xs" style={{ width: 24 }} />
            )}
          </IconButton>
          <IconButton size="small" onClick={handleCopyToClipboard}>
            <FontAwesomeIcon size="sm" icon={['far', 'copy']} />
          </IconButton>
        </InputAdornment>
      }
    />
  );
}

function SpellbookApiKeySetting() {
  return (
    <Box>
      <Box mb={2}>
        <Typography>
          View your Spellbook API key. Learn how to use your API Key in{' '}
          <a
            href="https://spellbook.readme.io/reference/authentication"
            target="_blank"
            rel="noreferrer"
            style={{ color: theme.palette.primary.main }}
          >
            our docs.
          </a>
        </Typography>
      </Box>
      <SpellbookApiKeyField />
    </Box>
  );
}

const SECRET_CODE = 'rkaplan';

const TABS = [
  { text: 'General', value: 'general' },
  { text: 'Billing', value: 'billing' },
] as const;
type Tab = typeof TABS[number]['value'];

type AggregatedDaySpendByType = {
  createdAtDay: Date;
  'Model querying': number;
  'Fine tune model training': number;
  'Fine tune model querying': number;
  'Embedding model querying': number;
};

type AggregatedSubTypeSpend = {
  modelName: typeof BillingEventDisplayName[keyof typeof BillingEventDisplayName];
  units: number;
  cost: number;
};

const isDevelopment = process.env.NODE_ENV === 'development';

export default function SettingsPage() {
  const {
    user,
    namespaces,
    getPrimaryNamespace,
    setPrimaryNamespaceVerified,
    canVerifyPhoneNumber,
  } = useUserStore(
    R.pick([
      'user',
      'namespaces',
      'getPrimaryNamespace',
      'setPrimaryNamespaceVerified',
      'canVerifyPhoneNumber',
    ]),
  );
  const { paramsUrl } = useParamsNavigate();
  const dashboardUrl = useMemo(
    () => paramsUrl('https://dashboard.scale.com/settings/team?useLegacyDashboard=1'),
    [paramsUrl],
  );
  const forceUpdate = useForceUpdate();
  const showFeatureFlags = useCodePassed('feature-flags', SECRET_CODE) || isDevelopment;
  const [tab, setTab] = useState<Tab>('general');
  const { checkUnleashFlag } = useUnleashFlags();
  const { billingPlan } = useUserStore(R.pick(['billingPlan']));

  const [hoveredPlan, setHoveredPlan] = useState<string | null>(null);
  const showOpenAiSetting = checkUnleashFlag(FrontendFeatureFlag.AllowCustomOpenAiKey);

  const [dateRange, setDateRange] = useState<[Date, Date]>([
    startOfDay(subDays(new Date(), 21)),
    startOfDay(new Date()),
  ]);
  const [startDate, endDate] = dateRange;

  const lineItemsRes = useQuery(
    ['lineItemsRes', startDate, endDate],
    () => {
      return client.query(
        'billing.getLineItems',
        { startDate, endDate },
        {
          context: {
            skipBatch: true,
          },
        },
      );
    },
    { enabled: tab === 'billing', staleTime: 60000, retry: false },
  );

  const paddingByDayByType: Record<string, AggregatedDaySpendByType> = useMemo(() => {
    // +1 to be inclusive on both sides
    const startDateAgo = differenceInDays(endDate, startDate) + 1;
    const paddingByDayByType = R.map(daysAfter => {
      return {
        createdAtDay: startOfDay(subDays(endDate, startDateAgo - daysAfter - 1)),
        'Model querying': 0,
        'Fine tune model training': 0,
        'Fine tune model querying': 0,
        'Embedding model querying': 0,
      };
    }, R.range(0, startDateAgo));
    return R.indexBy(day => day.createdAtDay.toISOString(), paddingByDayByType);
  }, [endDate, startDate]);

  const enrichedLineItems = useMemo(() => {
    if (!lineItemsRes.data) return [];
    return lineItemsRes.data.map(item => ({
      ...item,
      createdAtDay: startOfDay(new Date(item.createdAt)),
      prettyType: PrettyFinancialEvent[item.type],
      modelName: BillingEventDisplayName[item.subType] || item.subType,
    }));
  }, [lineItemsRes.data]);

  const aggDaySpendByTypeList = useMemo(() => {
    // Group by day
    const byDay = R.groupBy(item => item.createdAtDay.toISOString(), enrichedLineItems);
    // Group by day by financial event type
    const byDayByAggregatedType = R.mapObjIndexed((dayItems, dayString) => {
      return R.mergeRight(
        {
          createdAtDay: new Date(dayString),
          'Model querying': 0,
          'Fine tune model training': 0,
          'Fine tune model querying': 0,
          'Embedding model querying': 0,
        },
        R.mapObjIndexed(
          (items: typeof dayItems) =>
            R.reduce<number, number>(R.add, 0, R.map(R.prop('chargedAmount'), items)),
          R.groupBy(R.prop('prettyType'), dayItems),
        ),
      );
    }, byDay);
    return R.values(R.mergeLeft(byDayByAggregatedType, paddingByDayByType));
  }, [enrichedLineItems]);

  const aggSubTypeSpendList: AggregatedSubTypeSpend[] = useMemo(() => {
    const bySubType = R.groupBy(R.prop('modelName'), enrichedLineItems);
    const bySubTypeAggregated = R.mapObjIndexed(
      (items, modelName) =>
        R.reduce(
          (acc, item) => {
            acc.units += item.amount;
            acc.cost += item.chargedAmount;
            return acc;
          },
          { units: 0, cost: 0, modelName },
          items,
        ),
      bySubType,
    );
    return R.values(bySubTypeAggregated);
  }, [enrichedLineItems]);

  const showVerifyPhoneNumber = useMemo(() => {
    return canVerifyPhoneNumber();
  }, [user, namespaces]);

  function renderBody() {
    switch (tab) {
      case 'general':
        return (
          <>
            {user && userMetadataFromScaleIdentityService(user) && isAdminUser(user) && (
              <>
                <Container sx={{ minWidth: 640 }}>
                  <Typography variant="h2">Team Settings</Typography>
                  <Typography variant="body1">
                    Add team members through the{' '}
                    <a
                      href={dashboardUrl}
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: theme.palette.primary.main }}
                    >
                      Scale dashboard
                    </a>
                    .
                  </Typography>
                </Container>
                <VSpace s={1} />
              </>
            )}
            <Container sx={{ minWidth: 640 }}>
              <Typography variant="h2">Spellbook API Key</Typography>
              <SpellbookApiKeySetting />
              {showOpenAiSetting && <OpenAiApiKeySetting />}
            </Container>
            <VSpace s={1} />
            <Container sx={{ minWidth: 640 }}>
              <Typography variant="h2">Display Settings</Typography>
              <ShowTokenProbabilitiesSetting />
              <ColorblindModeSetting />
            </Container>
            <VSpace s={1} />
            <Container sx={{ minWidth: 640 }}>
              <Typography variant="h2">Developer Tools</Typography>
              <RandomnessSetting />
            </Container>
            <VSpace s={1} />
            {showFeatureFlags && (
              <Container sx={{ minWidth: 640 }}>
                <Typography variant="h2">Feature Flags</Typography>
                {FEATURE_FLAGS.map(ff => {
                  if (ff.hidden) {
                    return;
                  }
                  return (
                    <Box key={ff.name}>
                      <Typography variant="h3">{ff.name}</Typography>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={ff.enabled}
                            onChange={e => {
                              ff.enabled = !ff.enabled;
                              forceUpdate();
                            }}
                          />
                        }
                        label={ff.description}
                      />
                    </Box>
                  );
                })}
              </Container>
            )}
          </>
        );
      case 'billing':
        return (
          <>
            <Box
              sx={{
                minWidth: 640,
                marginBottom: 2,
                display: showVerifyPhoneNumber ? 'block' : 'none',
              }}
            >
              <ActionBanner
                icon="money-bill"
                color="black"
                content={
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h3">
                      {VERIFIED_PHONE_CREDIT_STR} in free credits when you verify your account!
                    </Typography>
                    <Typography variant="body1">
                      Simply add and verify your phone number to unlock the credits.
                    </Typography>
                  </Box>
                }
                actionItems={<VerifyPhoneNumberButton onVerify={setPrimaryNamespaceVerified} />}
              />
            </Box>
            <Container sx={{ minWidth: 640, marginBottom: 2 }}>
              <CreditCardInput title="Payment Method" />
            </Container>
            <Container sx={{ minWidth: 640 }}>
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <Typography variant="h2">Usage from</Typography>
                  <DateRangePicker
                    value={dateRange}
                    disabledDate={DateRangePicker.combine?.(
                      DateRangePicker.before?.(FIRST_DATE),
                      DateRangePicker.afterToday?.(),
                    )}
                    character="  to  "
                    cleanable={false}
                    ranges={PREDEFINED_RANGES}
                    onChange={dateRange => {
                      if (dateRange) {
                        setDateRange(dateRange);
                      }
                    }}
                    renderValue={([startDate, endDate]) => (
                      <FlexBox sx={{ alignItems: 'flex-end' }}>
                        <Typography variant="body1">
                          {formatISO(startDate, { representation: 'date' })}
                        </Typography>
                        to
                        <Typography variant="body1">
                          {formatISO(endDate, { representation: 'date' })}
                        </Typography>
                      </FlexBox>
                    )}
                  />
                </Box>
                <Typography variant="subtitle2">
                  Recorded usage is delayed and will only show up after an hour.
                </Typography>
              </Box>
              <Container>
                <Typography variant="h3">Token usage</Typography>
                <TableContainer
                  sx={{
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderRadius: 1,
                    borderColor: theme.palette.info.light,
                  }}
                >
                  <Table
                    size="small"
                    sx={{
                      '& tbody td': {
                        border: 0,
                        borderBottomWidth: 1,
                        borderRightWidth: 1,
                        borderStyle: 'solid',
                        borderColor: theme.palette.info.light,
                      },
                      '& tbody td:last-child': {
                        borderRightWidth: 0,
                      },
                      '& tbody tr:last-child td, & tbody tr:last-child th': { borderBottom: 0 },
                    }}
                  >
                    <TableHead
                      sx={{ bgcolor: theme.palette.info.light, color: theme.palette.info.dark }}
                    >
                      <TableRow>
                        <TableCell>
                          <Typography variant="overline">Model</Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="overline">Tokens</Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="overline">Cost</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {lineItemsRes.isLoading ? (
                        <TableRow>
                          <TableCell sx={{ px: 2, py: 1 }}>
                            <Skeleton
                              variant="text"
                              width={200}
                              sx={{ bgcolor: theme.palette.info.light }}
                            />
                          </TableCell>
                          <TableCell align="right" sx={{ px: 2, py: 1 }}>
                            <Skeleton
                              variant="text"
                              width={160}
                              sx={{ bgcolor: theme.palette.info.light }}
                            />
                          </TableCell>
                          <TableCell align="right" sx={{ px: 2, py: 1 }}>
                            <Skeleton
                              variant="text"
                              width={160}
                              sx={{ bgcolor: theme.palette.info.light }}
                            />
                          </TableCell>
                        </TableRow>
                      ) : aggSubTypeSpendList.length === 0 ? (
                        <TableCell sx={{ px: 2, py: 1 }}>
                          <Typography variant="body1">No usage recorded!</Typography>
                        </TableCell>
                      ) : (
                        aggSubTypeSpendList.map(row => (
                          <TableRow key={row.modelName}>
                            <TableCell sx={{ px: 2, py: 1 }}>
                              <Typography variant="body1">{row.modelName}</Typography>
                            </TableCell>
                            <TableCell align="right" sx={{ px: 2, py: 1 }}>
                              <Typography variant="body1">
                                {millify(row.units, { precision: 2 })}
                              </Typography>
                            </TableCell>
                            <TableCell align="right" sx={{ px: 2, py: 1 }}>
                              <Typography variant="body1">${row.cost.toFixed(2)}</Typography>
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Container>
              <Container>
                <Typography variant="h3">Daily usage</Typography>
                {lineItemsRes.isLoading ? (
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height={300}
                    sx={{
                      display: 'grid',
                      placeItems: 'center',
                      bgcolor: theme.palette.info.light,
                      '&>*': { visibility: 'visible' },
                    }}
                  >
                    <Typography variant="body1">
                      Crunching the billing data just for you. This should only take a minute.
                    </Typography>
                  </Skeleton>
                ) : (
                  <Box
                    sx={{
                      '& .recharts-text': {
                        fontSize: 12,
                        fontFamily: theme.typography.fontFamily,
                        color: Colors.CoolGray70,
                      },
                      '& .recharts-label': {
                        fontSize: 14,
                      },
                      '& .recharts-default-tooltip': {
                        border: 'none',
                      },
                    }}
                  >
                    <ResponsiveContainer width="100%" height={300}>
                      <BarChart
                        data={aggDaySpendByTypeList}
                        margin={{ top: 8, right: 16, bottom: 4, left: -4 }}
                      >
                        <XAxis
                          dataKey="createdAtDay"
                          tickFormatter={date =>
                            format(date !== 'auto' ? date : new Date(), 'dd MMM')
                          }
                        ></XAxis>
                        <YAxis
                          type="number"
                          tickFormatter={num => `$${num}`}
                          domain={[
                            0,
                            (dataMax: number) => (dataMax < 0.8 ? 1 : Math.ceil(dataMax * 1.2)),
                          ]}
                        ></YAxis>
                        <CartesianGrid stroke={Colors.CoolGray30} />
                        <Tooltip<number, string>
                          labelStyle={{ display: 'none' }}
                          wrapperStyle={{
                            border: 'none',
                            strokeWidth: 0,
                            boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.15)',
                          }}
                          content={props =>
                            lineItemsRes.isLoading ? null : props.active &&
                              props.label !== 'auto' ? (
                              <Container>
                                <Typography variant="h3">
                                  {format(props.label, 'dd MMM')}
                                </Typography>
                                <Box
                                  sx={{
                                    display: 'grid',
                                    gridTemplateColumns: '1fr auto',
                                    gap: 0.5,
                                  }}
                                >
                                  {R.reverse(props.payload || []).map(data => (
                                    <React.Fragment key={data.dataKey}>
                                      <Typography variant="body1" color={data.color}>
                                        {data.dataKey}:
                                      </Typography>
                                      <Typography variant="body1">
                                        ${data.value?.toFixed(2)}
                                      </Typography>
                                    </React.Fragment>
                                  ))}
                                  <Typography variant="body1">Total:</Typography>
                                  <Typography variant="body1">
                                    $
                                    {props.payload
                                      ?.reduce((acc, data) => acc + (data.value || 0), 0)
                                      .toFixed(2)}
                                  </Typography>
                                </Box>
                              </Container>
                            ) : null
                          }
                          animationDuration={0}
                        />
                        <Bar
                          dataKey="Model querying"
                          stackId="1"
                          fill={Colors.Periwinkle100}
                          animationDuration={0}
                        />
                        <Bar
                          dataKey="Fine tune model training"
                          stackId="1"
                          fill={Colors.Periwinkle75}
                          animationDuration={0}
                        />
                        <Bar
                          dataKey="Fine tune model querying"
                          stackId="1"
                          fill={Colors.Periwinkle50}
                          animationDuration={0}
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  </Box>
                )}
              </Container>
            </Container>
          </>
        );
    }
  }

  return (
    <>
      <PageContainer page="settings" noRequireApp>
        <PageTitle title="Settings" />
        <SegmentedButtons labels={TABS} selectedLabel={tab} onClick={setTab} />
        <VSpace s={1} />
        {renderBody()}
      </PageContainer>
    </>
  );
}

const PrettyFinancialEvent = {
  BaseModelQuerying: 'Model querying',
  FineTuningModelTraining: 'Fine tune model training',
  FineTuningModelQuerying: 'Fine tune model querying',
  EmbeddingModelQuerying: 'Embedding model querying',
} as const;

const PREDEFINED_RANGES = [
  {
    label: 'Last 7 days',
    value: [subDays(new Date(), 6), new Date()] as [Date, Date],
  },
  {
    label: 'Last 30 days',
    value: [subDays(new Date(), 30), new Date()] as [Date, Date],
  },
  {
    label: 'This month',
    value: [startOfMonth(new Date()), new Date()] as [Date, Date],
  },
  {
    label: 'Last month',
    value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))] as [
      Date,
      Date,
    ],
  },
];

const FIRST_DATE = new Date('2022-10-10');
