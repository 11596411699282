import { createFromStates, state } from 'frontend/utils/store';

const states = [
  state('showCloseDialog', false as boolean),
  state('showWizard', false as boolean),
];

type Store = UnionToIntersection<ReturnType<typeof states[number]>>;

export const useAppsPageState = createFromStates<Store>(states);
