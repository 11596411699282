import { ScaleIdentityServiceMetadata, User } from '@scale/llm-shared/types/user';

export const APP_ID = 'i6he7ru4';

export function intercom() {
  const w: any = window;
  const ic = w.Intercom;
  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', w.intercomSettings);
  } else {
    const d = document;
    const i: any = function () {
      i.c(arguments); // eslint-disable-line prefer-rest-params
    };
    i.q = [];
    i.c = function (args: any) {
      i.q.push(args);
    };
    w.Intercom = i;
    const l = function () {
      const s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://widget.intercom.io/widget/' + APP_ID;
      const x = d.getElementsByTagName('script')[0];
      x.parentNode?.insertBefore(s, x);
    };
    if (document.readyState === 'complete') {
      l();
    } else if (w.attachEvent) {
      w.attachEvent('onload', l);
    } else {
      w.addEventListener('load', l, false);
    }
  }
}

export function initIntercom(user: User<ScaleIdentityServiceMetadata>) {
  const w: any = window;
  if (!w.Intercom) {
    return;
  }
  w.Intercom('boot', {
    app_id: APP_ID,
    email: user?.metadata.email,
    user_id: user?.metadata.userIdentity,
  });
  w.Intercom('update');
}
