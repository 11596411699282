import React, { useCallback, useEffect, useState } from 'react';

import * as R from 'ramda';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  TextField,
  Typography,
} from '@mui/material';

import useApi from 'frontend/api/useApi';
import ArchiveAppConfirmationDialog from 'frontend/components/app/ArchiveAppConfimationDialog';
import FlexBox from 'frontend/components/FlexBox';
import { useLLMNavigation } from 'frontend/hooks/useLLMNavigation';
import { useAppStore } from 'frontend/stores/AppStore';
import { StoredApp } from 'frontend/storesV2/types';
import theme from 'frontend/theme';
import { track } from 'frontend/utils/analytics';

const SettingsContainer = styled(Box)({});
const SettingsTitle = styled(Typography)({
  marginBottom: theme.spacing(0.5),
});

export function AppEditDialog({
  app,
  open,
  setOpen,
}: {
  app: StoredApp;
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const { archiveApp, updateApp } = useApi();
  const { deleteApp } = useAppStore(R.pick(['deleteApp', 'updateApp']));
  const { goToAppsPage } = useLLMNavigation();

  const [isConfirmArchiveDialogOpen, setIsConfirmArchiveDialogOpen] = useState(false);
  const [draftApp, setDraftApp] = useState<StoredApp>({ ...app });

  const cancelEditApp = useCallback(() => {
    setDraftApp({ ...app });
    setOpen(false);
  }, [app, setDraftApp, setOpen]);

  const saveDraftApp = useCallback(async () => {
    await updateApp.mutateAsync(draftApp);
    setOpen(false);
  }, [draftApp, setDraftApp, setOpen]);

  const updateDraftPropertySetter = useCallback(
    (property: string) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      return setDraftApp(draft => ({
        ...draft,
        [property]: e.target.value,
      }));
    },
    [setDraftApp],
  );

  const handleStartArchiveApp = useCallback(() => {
    setOpen(false);
    setIsConfirmArchiveDialogOpen(true);
  }, [setOpen, setIsConfirmArchiveDialogOpen]);

  const handleConfirmArchiveApp = useCallback(() => {
    track('App Archived', { app: app.id, app_name: app.name });
    archiveApp.mutate(app);
    deleteApp(app.id);
    setIsConfirmArchiveDialogOpen(false);
    goToAppsPage();
  }, [archiveApp]);

  return (
    <>
      <ArchiveAppConfirmationDialog
        open={isConfirmArchiveDialogOpen}
        handleConfirm={handleConfirmArchiveApp}
        handleCancel={() => {
          setIsConfirmArchiveDialogOpen(false);
        }}
      />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Update App Settings</DialogTitle>
        <DialogContent dividers sx={{ minWidth: 480 }}>
          <FlexBox sx={{ flexDirection: 'column', alignItems: 'stretch', gap: 2 }}>
            <SettingsContainer>
              <SettingsTitle>Name</SettingsTitle>
              <TextField
                fullWidth
                size="small"
                placeholder="Name or title for your app"
                value={draftApp.name}
                onChange={updateDraftPropertySetter('name')}
              />
            </SettingsContainer>
            <SettingsContainer>
              <SettingsTitle>Description</SettingsTitle>
              <TextField
                fullWidth
                multiline
                rows={3}
                autoFocus
                placeholder="Provide a description for your app"
                size="small"
                value={draftApp.description}
                onChange={updateDraftPropertySetter('description')}
              />
            </SettingsContainer>
            <SettingsContainer>
              <SettingsTitle>Other Actions</SettingsTitle>
              <Button size="small" variant="outlined" color="error" onClick={handleStartArchiveApp}>
                Archive App
              </Button>
            </SettingsContainer>
          </FlexBox>
        </DialogContent>
        <DialogActions>
          <FlexBox>
            <Button variant="outlined" onClick={cancelEditApp}>
              Cancel
            </Button>
            <Button variant="contained" onClick={saveDraftApp} disabled={updateApp.isLoading}>
              Save
            </Button>
          </FlexBox>
        </DialogActions>
      </Dialog>
    </>
  );
}
