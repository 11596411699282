// Frontend-only
export const EXAMPLE_CRITERIA = `
The output should be helpful, relevant, and accurate.`.trim();

// Keep in shared
export const AI_FEEDBACK_DATA_INPUT_COLUMN_NAME = 'input';
export const AI_FEEDBACK_DATA_OUTPUT_COLUMN_NAME = 'output';
export const AI_FEEDBACK_DATA_CRITERIA_COLUMN_NAME = 'criteria';

export function generateAIFeedbackPrompt(criteria: string, input: string, output: string) {
  return `
[Question]
{{${input}}}

[The Start of Assistant 1's Answer]
{{${output}}}

[The End of Assistant 1's Answer]

[Criteria]
{{${criteria}}}

[System]
We would like to request your feedback on the performance of an AI assistant in response to the user question displayed above.
Please rate their response according to the criteria defined above. Do not consider any other criteria. The assistant receives an overall score on a scale of 1 to 10, where a higher score indicates better adherence to the criteria.
Please first output a single line containing only one value indicating the scores for the assistant. In the subsequent line, please provide a comprehensive explanation of your evaluation, avoiding any potential bias."
`.trim();
}

// Move to server
export const AI_FEEDBACK_SYSTEM_MESSAGE =
  'You are a helpful and precise assistant for checking the quality of the answer.';

// what we store as template in the prompt object
export const AI_FEEDBACK_PROMPT_TEMPLATE = generateAIFeedbackPrompt(
  AI_FEEDBACK_DATA_CRITERIA_COLUMN_NAME,
  AI_FEEDBACK_DATA_INPUT_COLUMN_NAME,
  AI_FEEDBACK_DATA_OUTPUT_COLUMN_NAME,
);

export enum AI_FEEDBACK_OPTIONS {
  Yes = 'yes',
  No = 'no',
}
