import { SpellbookFinancialEventSubType } from '@scale/llm-shared/services/billing/billingTypes';
import { Ai21BaseModelDisplayName } from './ai21';
import { AnthropicBaseModelDisplayName } from './anthropic';
import { CohereBaseModelDisplayName } from './cohere';
import { OpenAiBaseModelDisplayName } from './openAi';
import { ScaleLaunchBaseModelDisplayName } from './scaleLaunch';

export const BillingEventDisplayName: Record<SpellbookFinancialEventSubType, string> = {
  ...OpenAiBaseModelDisplayName,
  ...AnthropicBaseModelDisplayName,
  ...Ai21BaseModelDisplayName,
  ...CohereBaseModelDisplayName,
  ...ScaleLaunchBaseModelDisplayName,
  // Only used for billing
  GPT4_Prompt: 'GPT4 Prompts',
  GPT4_32K_Prompt: 'GPT4 32K Context Window Prompts',
  GPT4_Completion: 'GPT4 Completions',
  GPT4_32K_Completion: 'GPT4 32K Context Window Completions',
  GPT3_5Turbo16k_Prompt: 'GPT3.5 16K Completions',
  GPT3_5Turbo16k_Completion: 'GPT3.5 16K Completions',
  ClaudeInstantV1_prompt: 'Claude Instant V1 Prompts',
  ClaudeInstantV1_completion: 'Claude Instant V1 Completions',
  ClaudeV1_prompt: 'Claude V1 Prompts',
  ClaudeV1_completion: 'Claude V1 Completions',
} as const;
