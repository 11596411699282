import _ from 'lodash';
import * as R from 'ramda';
import create, { StoreApi } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

import { JobStatus } from '@prisma/client';
import {
  Finetune,
  FinetuneCreate,
  FinetuneCreateResult,
  GetFinetune,
} from '@scale/llm-shared/interfaces/finetune';
import { PendingJob } from '@scale/llm-shared/interfaces/job';

import { client } from 'frontend/api/trpc';
import { useJobPollingStore } from 'frontend/storesV2/JobPollingStore';
import { StoredFinetune } from 'frontend/storesV2/types';
import { singleIndexById } from 'frontend/utils/object';

class FinetuneStore {
  finetuneById: Record<string, StoredFinetune> = {};
  finetunePromiseById: Record<string, Promise<StoredFinetune>> = {};

  constructor(
    public set: StoreApi<FinetuneStore>['setState'],
    public get: StoreApi<FinetuneStore>['getState'],
  ) {}

  setFinetuneById = (byId: Record<string, StoredFinetune>) => this.set(R.set(indexLens, byId));
  setFinetunePromiseById = (byId: Record<string, Promise<StoredFinetune>>) =>
    this.set(R.set(promiseIndexLens, byId));
  appendFinetuneById = (byId: Record<string, StoredFinetune>) =>
    this.set(R.over(indexLens, R.mergeLeft(byId)));
  appendFinetunePromiseById = (byId: Record<string, Promise<StoredFinetune>>) =>
    this.set(R.over(promiseIndexLens, R.mergeLeft(byId)));

  // Create finetune from server
  createFinetune = (finetuneCreate: FinetuneCreate) => {
    return (
      client
        // Create Variant
        .mutation('v2.finetune.create', finetuneCreate)
        // Construct StoredFinetune
        .then((res: FinetuneCreateResult) => {
          if (res.success) {
            const storedFinetune = FinetuneStore.constructStoredFinetune(res.finetune);
            // Store finetune
            R.tap(this.storeFinetune)(storedFinetune);
            // Watch finetune job
            R.tap(this.watchFinetune)(storedFinetune);
            return { success: true, finetune: storedFinetune };
          } else {
            return { success: false, message: res.message };
          }
        })
    );
  };

  // Add to job to be watched by polling
  watchFinetune: (finetune: StoredFinetune) => void = finetune => {
    const polledJob: PendingJob = {
      id: finetune.id,
      type: finetune.type,
      progress: 0,
      status: JobStatus.Pending,
    };
    useJobPollingStore.getState().addPolledJob(polledJob);
  };

  // Add finetune to store
  storeFinetune: (finetune: StoredFinetune) => void = R.pipe(
    // Prepare to be stored
    singleIndexById,
    // Store in finetuneById
    this.appendFinetuneById,
  );

  getFinetune = (get: GetFinetune): Promise<StoredFinetune> => {
    const { finetuneById, finetunePromiseById } = this.get();
    // Get finetune from cache
    const finetune = finetuneById[get.id];
    if (finetune) return Promise.resolve(finetune);
    // Get finetune from loading
    const finetunePromise = finetunePromiseById[get.id];
    if (finetunePromise) return finetunePromise;
    return (
      client
        // Load finetune from server
        .query('v2.finetune.get', get)
        // Construct StoredFinetune
        .then(FinetuneStore.constructStoredFinetune)
        // Store finetune
        .then(R.tap(this.storeFinetune))
    );
  };

  getFinetunesByAppId = (appId: string): StoredFinetune[] => {
    const { finetuneById } = this.get(); // DOES NOT LOAD
    const sortedFinetunes = Object.values(finetuneById)
      .filter(finetune => finetune.appId === appId)
      .sort(
        (finetune1, finetune2) =>
          new Date(finetune1.createdAt).getTime() - new Date(finetune2.createdAt).getTime(),
      );
    return sortedFinetunes;
  };

  findAllFinetunes = () => {
    return (
      client
        // Load deployment from server
        .query('v2.finetune.findAll')
        .then(R.map(FinetuneStore.constructStoredFinetune))
        .then(finetunes => {
          const pendingAndRunningFinetunes = finetunes.filter(
            finetune =>
              finetune.status === JobStatus.Pending || finetune.status === JobStatus.Running,
          );
          pendingAndRunningFinetunes.forEach(finetune => this.watchFinetune(finetune));
          R.tap(
            R.pipe(
              // Prepare to be stored
              R.indexBy(R.prop('id')),
              // Store in deploymentById
              this.appendFinetuneById,
            ),
          )(finetunes);
        })
    );
  };

  listFinetunes = () => {
    const { finetuneById } = this.get(); // DOES NOT LOAD
    return R.values(finetuneById);
  };

  static constructStoredFinetune = (finetune: Finetune): StoredFinetune => {
    // Already taken care of when retrieving in finetunerepo
    return finetune;
  };
}

const indexKey = 'finetuneById';
const indexLens = R.lensProp<FinetuneStore, typeof indexKey>(indexKey);

const promiseIndexKey = 'finetunePromiseById';
const promiseIndexLens = R.lensProp<FinetuneStore, typeof promiseIndexKey>(promiseIndexKey);

export const useFinetuneStore = create<FinetuneStore>()(
  subscribeWithSelector((set, get) => new FinetuneStore(set, get)),
);

(window as any).useFinetuneStoreV2 = useFinetuneStore;
