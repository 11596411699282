import {
  MODEL_OUTPUT_SUCCESS_ID,
  MODEL_OUTPUT_ERROR_ID,
  NEWLINE_HTML,
  QUERY_INPUT_PLACEHOLDER,
  QUERY_INPUT_ID,
} from 'frontend/consts/playground';

export const stripHTML = (str: string) => {
  const elem = document.createElement(`div`);
  elem.innerHTML = str.replace(/<br\s*\/?>/, '\n');
  return elem.textContent || elem.innerText;
};

export const stripHighlightedOutputStyle = (str: string) => {
  return str.replace(/<\/?span[^>]*>/g, '');
};

export const insertHighlightStyle = (str: string, isSuccess = true) => {
  return `<span> <span id="${
    isSuccess ? MODEL_OUTPUT_SUCCESS_ID : MODEL_OUTPUT_ERROR_ID
  }">${str}</span></span>`;
};

export const insertNewlineBeforeTextHTML = (str: string) => {
  return `<div>${NEWLINE_HTML + str}</div>`;
};

export const getQueryInputHTML = () => {
  return `<span id="${QUERY_INPUT_ID}">${QUERY_INPUT_PLACEHOLDER}</span>`;
};
