import { AppVersion, Hyperparameters, Model, Prisma, Prompt } from '@prisma/client';
import { AppCreate } from '@scale/llm-shared/interfaces/app';

import { DatasetCreate } from 'frontend/stores/types';

export function convertClientAppCreateToPrismaAppUpdate(
  app: Pick<AppCreate, 'name' | 'description' | 'readme'>,
): Prisma.AppUpdateInput {
  const clientAppInput: Prisma.AppUpdateInput = {
    name: app.name,
    description: app.description,
    readme: app.readme,
  };
  return clientAppInput;
}
