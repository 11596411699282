import {
  CustomHumanEvaluationWizardStep,
  useCustomHumanEvaluationWizardState,
} from 'frontend/models/v2/useEvaluationWizardState';
import { CustomHumanEvaluationWizardRunVariantSpecStep } from './CustomHumanEvaluationWizardRunVariantSpecStep';

export function CustomHumanEvaluationWizardContainer() {
  const { step } = useCustomHumanEvaluationWizardState();

  switch (step) {
    case CustomHumanEvaluationWizardStep.RunVariantSpec:
      return <CustomHumanEvaluationWizardRunVariantSpecStep />;
    default:
      throw new Error(`Invalid custom human evaluation wizard step: ${step}`);
  }
}
