import { useMemo } from 'react';

import _ from 'lodash';
import * as R from 'ramda';

import { Box, Typography } from '@mui/material';

import { InferenceBatchCard } from 'frontend/components/v2/inferenceBatch/InferenceBatchCard';
import { useInferenceBatchStore } from 'frontend/storesV2/InferenceBatchStore';

export function InferenceBatchList(): JSX.Element {
  const { inferenceBatchById } = useInferenceBatchStore(R.pick(['inferenceBatchById']));
  const inferenceBatches = useMemo(() => {
    return _.sortBy(Object.values(inferenceBatchById), ib => -ib.createdAt.getTime());
  }, [inferenceBatchById]);

  if (!inferenceBatches.length) {
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          paddingBlock: 30,
        }}
      >
        <Typography variant="h3" color="info">
          No bulk inferences yet! Launch a bulk inference to get started!
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      {inferenceBatches.map(ib => (
        <InferenceBatchCard key={ib.id} inferenceBatch={ib} />
      ))}
    </Box>
  );
}
