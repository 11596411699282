import React, { useMemo, useState } from 'react';

import _ from 'lodash';

import { Box, TextField, Typography } from '@mui/material';

import {
  ElevatedContainer,
  EvaluationWizardCell,
  EvaluationWizardGrid,
} from 'frontend/components/evaluation/EvaluationWizardLayout';
import FlexBox from 'frontend/components/FlexBox';
import { VSpace } from 'frontend/components/Spacer';
import {
  useEvaluationWizardState,
  useRankingWizardState,
} from 'frontend/models/v2/useEvaluationWizardState';
import { useDataStore } from 'frontend/storesV2/DataStore';
import { useVariantStore } from 'frontend/storesV2/VariantStore';
import theme from 'frontend/theme';

import InstructionsPanel from '../InstructionsPanel';
import PricingEstimateTable from '../PricingEstimateTable';
import RankingDragAndDrop from './RankingDragAndDrop';
import { COST_PER_ITEM, HOURS_PER_ITEM } from './RankingWizardContainer';

const RankingWizardInstructionStep: React.FC = () => {
  return (
    <EvaluationWizardGrid>
      <RankingWizardInstructionHeader />
      <RankingWizardInstructionBody />
    </EvaluationWizardGrid>
  );
};

const RankingWizardInstructionHeader: React.FC = () => {
  return (
    <>
      <EvaluationWizardCell sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography variant="h2"> Define Instructions (Ranking) </Typography>
        <Typography variant="body2"> Input tasks and good / bad parameters </Typography>
      </EvaluationWizardCell>
      <EvaluationWizardCell sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography variant="h2"> Evaluation Preview </Typography>
        <Typography variant="body2">
          Based on the fields on the left, this is what the expert will see
        </Typography>
      </EvaluationWizardCell>
    </>
  );
};

const RankingWizardInstructionBody: React.FC = () => {
  const {
    taskDescription,
    setTaskDescription,
    goodInputExample,
    setGoodInputExample,
    badInputExample,
    setBadInputExample,
  } = useRankingWizardState();

  const { variantIds, dataId } = useEvaluationWizardState();
  const { variantById } = useVariantStore();
  const { dataById } = useDataStore();

  const variants = useMemo(() => {
    return variantIds.map(variantId => variantById[variantId]);
  }, [variantIds, variantById]);

  const dataSize = useMemo(() => {
    if (!dataId) {
      return 0;
    }
    return _.size(dataById[dataId]?.rowByIndex);
  }, [dataId, dataById]);

  return (
    <>
      <EvaluationWizardCell>
        <Typography variant="body1"> 1. What is the main task of your app? (required) </Typography>
        <VSpace s={1} />
        <TextField
          value={taskDescription}
          multiline
          fullWidth
          rows={5}
          onChange={e => {
            setTaskDescription(e.target.value);
          }}
          placeholder="Insert task here, 500 character limit"
          InputProps={{
            style: {
              fontFamily: "'IBM Plex Mono', 'Consolas', monospace",
            },
          }}
        />
        <VSpace s={4} />
        <Typography variant="body1"> 2. What would you consider to be a good output? </Typography>
        <VSpace s={1} />
        <TextField
          value={goodInputExample}
          multiline
          fullWidth
          rows={5}
          onChange={e => {
            setGoodInputExample(e.target.value);
          }}
          placeholder={`* is true, *and* \n* is logical, *and* \n* accurately responds to the task provided to the computer`}
          InputProps={{
            style: {
              fontFamily: "'IBM Plex Mono', 'Consolas', monospace",
            },
          }}
        />
        <VSpace s={4} />
        <Typography variant="body1"> 2. What would you consider to be a bad output? </Typography>
        <VSpace s={1} />
        <TextField
          value={badInputExample}
          multiline
          fullWidth
          rows={5}
          onChange={e => {
            setBadInputExample(e.target.value);
          }}
          placeholder={`* is incorrect, *or* \n* is gibberish, *or* \n* contains profanity *or* \n* inaccurately responds to the task provided to the computer`}
          InputProps={{
            style: {
              fontFamily: "'IBM Plex Mono', 'Consolas', monospace",
            },
          }}
        />
      </EvaluationWizardCell>
      <EvaluationWizardCell>
        <InstructionsPanel
          task={taskDescription}
          goodInputExample={goodInputExample}
          badInputExample={badInputExample}
        />
        <VSpace s={4} />
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 1 }}>
          {variants
            .filter(variant => variant !== undefined)
            .map(variant => (
              <Box key={variant.id}>
                <Typography>{variant.name}</Typography>
                <VSpace s={1} />
                <Box
                  sx={{
                    backgroundColor: theme.palette.info.light,
                    border: `1px dashed ${theme.palette.info.main}`,
                    borderRadius: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '100px',
                  }}
                >
                  <Typography> Generated output here </Typography>
                </Box>
              </Box>
            ))}
        </Box>
        <VSpace s={4} />
        <ElevatedContainer>
          <Typography variant="h3">
            Based on the instructions provided, please rank the outputs shown from best to worst
          </Typography>
          <Typography> Drag items from this section into the ranking spots below </Typography>
          <RankingDragAndDrop
            values={variants.filter(x => x !== undefined).map(variant => variant!.name)}
          />
        </ElevatedContainer>
        <VSpace s={4} />
        <PricingEstimateTable
          ratePerItem={COST_PER_ITEM}
          timePerItem={HOURS_PER_ITEM}
          evaluationItems={dataSize ?? 0}
          totalVariants={variantIds.length}
        />
      </EvaluationWizardCell>
    </>
  );
};

export default RankingWizardInstructionStep;
