import { CohereGenerateMedium } from '@scale/llm-shared/modelProviders/cohere/models/CohereGenerateMedium';
import { CohereGenerateXLarge } from '@scale/llm-shared/modelProviders/cohere/models/CohereGenerateXLarge';
import { ICohereBaseModel } from '@scale/llm-shared/modelProviders/cohere/types';

import { CohereBaseModelInternalId } from '../consts';

export const GetCohereBaseModelFromInternalId: Record<CohereBaseModelInternalId, ICohereBaseModel> =
  {
    [CohereBaseModelInternalId.CohereGenerateMedium]: CohereGenerateMedium,
    [CohereBaseModelInternalId.CohereGenerateXLarge]: CohereGenerateXLarge,
  };
