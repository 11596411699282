import { Box, styled } from '@mui/material';

/**
 * Container a styled Box component that styles "containers" in the app.
 */
export const Container = styled(Box, { shouldForwardProp: () => true })(({ theme }) => ({
  borderWidth: 1,
  borderStyle: 'solid',
  borderRadius: theme.shape.borderRadius,
  borderColor: theme.palette.info.light,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
}));
