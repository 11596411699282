import _ from 'lodash';

const HOUR_MS = 3600 * 1000;

export function truncateHour(date: Date) {
  const d = new Date(date);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setMilliseconds(0);
  return d;
}

export function dateToHourString(date: Date) {
  const d = truncateHour(date);
  return d.toISOString();
}

export function rangePreviousHours(date: Date, hoursPrior: number) {
  const t = date.getTime();
  return _.reverse(
    _.range(0, hoursPrior).map(dt => {
      const prior = new Date(t - dt * HOUR_MS);
      const priorHour = truncateHour(prior);
      return priorHour;
    }),
  );
}