import _ from 'lodash';
import * as R from 'ramda';

import { useSelectionStore } from 'frontend/storesV2/SelectionStore';
import { createPersistFromStates, state } from 'frontend/utils/store';

const states = [
  state('showExpectedColumn', false as boolean),
  state('expectedOutputColumn', '' as string),
  state('showWhitespace', false as boolean),
];

type Store = UnionToIntersection<ReturnType<typeof states[number]>>;

export const useMultiInputEvaluationState = createPersistFromStates<Store>(states);

useSelectionStore.subscribe(R.prop('selectedAppId'), selectedAppId => {
  const { setExpectedOutputColumn } = useMultiInputEvaluationState.getState();
  setExpectedOutputColumn('');
});
