import { GetAnthropicBaseModelFromInternalId } from '@scale/llm-shared/modelProviders/anthropic/models/map';
import { GetOpenAiBaseModelFromInternalId } from '@scale/llm-shared/modelProviders/openAi/models/map';
import { BaseModelInternalId, IBaseModel } from '@scale/llm-shared/modelProviders/types';
import { GetScaleLaunchBaseModelFromInternalId } from '@scale/llm-shared/modelProviders/scaleLaunch/models/map';
import { GetCohereBaseModelFromInternalId } from '@scale/llm-shared/modelProviders/cohere/models/map';
import { GetAi21BaseModelFromInternalId } from '@scale/llm-shared/modelProviders/ai21/models/map';

export const GetBaseModelFromInternalId: Record<BaseModelInternalId, IBaseModel> = {
  ...GetAnthropicBaseModelFromInternalId,
  ...GetOpenAiBaseModelFromInternalId,
  ...GetScaleLaunchBaseModelFromInternalId,
  ...GetCohereBaseModelFromInternalId,
  ...GetAi21BaseModelFromInternalId,
};
