import * as R from 'ramda';

import { EvaluationType, HumanJobSource } from '@prisma/client';
import { BaseEvaluationTypeV2 } from '@scale/llm-shared/types';

import { useSelectionStore } from 'frontend/storesV2/SelectionStore';
import { StoredDataColumn } from 'frontend/storesV2/types';
import { createFromStates, state } from 'frontend/utils/store';

// State for the overall wizard container

export const EvaluationWizardStep = {
  Setup: 'Evaluation Setup',
  PostSetup: 'Evaluation Instructions',
};

export type EvaluationWizardStep = typeof EvaluationWizardStep[keyof typeof EvaluationWizardStep];

export const EVALUATION_WIZARD_STEPS = Object.entries(EvaluationWizardStep).map(([, value]) => ({
  text: value,
  value,
}));

const states = [
  state('wizardStep', EvaluationWizardStep.Setup as EvaluationWizardStep),
  state('feedbackType', undefined as BaseEvaluationTypeV2 | undefined),
  state('variantIds', [] as string[]),
  state('dataId', undefined as string | undefined),
  state('humanJobSource', HumanJobSource.ScaleRapid as HumanJobSource | undefined),
  state('shouldShowSubmitEvalWithoutDeduplicationCheckButton', false as boolean),
  state('shouldStripWhitespace', true as boolean),
  state('selectedOutputColumn', undefined as StoredDataColumn | undefined),
];

type Store = UnionToIntersection<ReturnType<typeof states[number]>>;

export const useEvaluationWizardState = createFromStates<Store>(states);

useSelectionStore.subscribe(R.prop('selectedAppId'), selectedApp => {
  const {
    setWizardStep,
    setFeedbackType,
    setVariantIds,
    setDataId,
    setHumanJobSource,
    setShouldShowSubmitEvalWithoutDeduplicationCheckButton,
    setShouldStripWhitespace,
    setSelectedOutputColumn,
  } = useEvaluationWizardState.getState();
  setWizardStep(EvaluationWizardStep.Setup);
  setFeedbackType(undefined);
  setVariantIds([]);
  setDataId(undefined);
  setHumanJobSource(HumanJobSource.ScaleRapid);
  setShouldShowSubmitEvalWithoutDeduplicationCheckButton(false);
  setShouldStripWhitespace(true);
  setSelectedOutputColumn(undefined);
});

// Binary Wizard

export const BinaryWizardStep = {
  Instructions: 'Binary Evaluation Instructions',
};

export type BinaryWizardStep = typeof BinaryWizardStep[keyof typeof BinaryWizardStep];

export const BINARY_WIZARD_STEPS = Object.entries(BinaryWizardStep).map(([, value]) => ({
  text: value,
  value,
}));

const binaryWizardStates = [
  state('step', BinaryWizardStep.Instructions as BinaryWizardStep),
  state('taskDescription', ''),
  state('goodInputExample', ''),
  state('badInputExample', ''),
];

type BinaryWizardStore = UnionToIntersection<ReturnType<typeof binaryWizardStates[number]>>;

export const useBinaryWizardState = createFromStates<BinaryWizardStore>(binaryWizardStates);

useSelectionStore.subscribe(R.prop('selectedAppId'), selectedApp => {
  const { setStep, setTaskDescription, setGoodInputExample, setBadInputExample } =
    useBinaryWizardState.getState();
  setStep(BinaryWizardStep.Instructions as BinaryWizardStep);
  setTaskDescription('');
  setGoodInputExample('');
  setBadInputExample('');
});

// AI Feedback Wizard

export const AIFeedbackWizardStep = {
  Instructions: 'AI Feedback Evaluation Instructions',
};

export type AIFeedbackWizardStep = typeof AIFeedbackWizardStep[keyof typeof AIFeedbackWizardStep];

export const AIFEEDBACK_WIZARD_STEPS = Object.entries(AIFeedbackWizardStep).map(([, value]) => ({
  text: value,
  value,
}));

const aiFeedbackWizardStates = [
  state('step', AIFeedbackWizardStep.Instructions as AIFeedbackWizardStep),
  state('evaluationCriteria', ''),
];

type AIFeedbackWizardStore = UnionToIntersection<ReturnType<typeof aiFeedbackWizardStates[number]>>;

export const useAIFeedbackWizardState =
  createFromStates<AIFeedbackWizardStore>(aiFeedbackWizardStates);

useSelectionStore.subscribe(R.prop('selectedAppId'), selectedApp => {
  const { setStep, setEvaluationCriteria } = useAIFeedbackWizardState.getState();
  setStep(AIFeedbackWizardStep.Instructions as AIFeedbackWizardStep);
  setEvaluationCriteria('');
});

// Ranking Wizard

export const RankingWizardStep = {
  Instructions: 'Ranking Evaluation Instructions',
};

export type RankingWizardStep = typeof RankingWizardStep[keyof typeof RankingWizardStep];

export const RANKING_WIZARD_STEPS = Object.entries(RankingWizardStep).map(([, value]) => ({
  text: value,
  value,
}));

const rankingWizardStates = [
  state('step', RankingWizardStep.Instructions as RankingWizardStep),
  state('taskDescription', ''),
  state('goodInputExample', ''),
  state('badInputExample', ''),
];

type RankingWizardStore = UnionToIntersection<ReturnType<typeof rankingWizardStates[number]>>;

export const useRankingWizardState = createFromStates<RankingWizardStore>(rankingWizardStates);

useSelectionStore.subscribe(R.prop('selectedAppId'), selectedApp => {
  const { setStep, setTaskDescription, setGoodInputExample, setBadInputExample } =
    useRankingWizardState.getState();
  setStep(RankingWizardStep.Instructions as RankingWizardStep);
  setTaskDescription('');
  setGoodInputExample('');
  setBadInputExample('');
});

// Custom Human Evaluation

export const CustomHumanEvaluationWizardStep = {
  RunVariantSpec: 'Custom Human Evaluation Run Variant Spec',
};

export type CustomHumanEvaluationWizardStep =
  typeof CustomHumanEvaluationWizardStep[keyof typeof CustomHumanEvaluationWizardStep];

export const CUSTOM_HUMAN_WIZARD_STEPS = Object.entries(CustomHumanEvaluationWizardStep).map(
  ([, value]) => ({
    text: value,
    value,
  }),
);

const customHumanEvaluationWizardStates = [
  state('step', CustomHumanEvaluationWizardStep.RunVariantSpec as CustomHumanEvaluationWizardStep),
  state('runVariantSpec', {} as { [variantId: string]: number }),
];

type CustomHumanEvaluationWizardStore = UnionToIntersection<
  ReturnType<typeof customHumanEvaluationWizardStates[number]>
>;

export const useCustomHumanEvaluationWizardState =
  createFromStates<CustomHumanEvaluationWizardStore>(customHumanEvaluationWizardStates);

useSelectionStore.subscribe(R.prop('selectedAppId'), selectedApp => {
  const { setStep, setRunVariantSpec } = useCustomHumanEvaluationWizardState.getState();
  setStep(CustomHumanEvaluationWizardStep.RunVariantSpec as CustomHumanEvaluationWizardStep);
  setRunVariantSpec({});
});
