import { HumanJobSource } from '@prisma/client';

// Only needed by frontend
export const HumanJobSourceToDisplayText = {
  [HumanJobSource.ScaleRapid]: "Use Scale's workforce (Scale Rapid)",
  [HumanJobSource.ScaleStudio]: 'Use your own workforce (Scale Studio)',
};

// Should remain in shared
export enum ExpertFeedbackErrors {
  EXPERT_FEEDBACK_JOB_HAS_EXISTING_VARIANT_DATASET_COMBO = 'There is an existing expert feedback job with the specified variant and dataset',
}

// Should go to server
export enum BinaryHumanEvaluationResult {
  GOOD = 'good',
  BAD = 'bad',
}
