import { useCallback, useEffect, useMemo, useState } from 'react';

import cuid from 'cuid';
import * as R from 'ramda';
import { useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Divider, Paper, TextField, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';

import { Container } from 'frontend/components/Container';
import { ErrorMessage } from 'frontend/components/ErrorMessage';
import FlexBox from 'frontend/components/FlexBox';
import PageTitle from 'frontend/components/PageTitle';
import { VSpace } from 'frontend/components/Spacer';
import { DeploymentLatencyChart } from 'frontend/components/v2/deployment/DeploymentLatencyChart';
import { DeploymentLogsTable } from 'frontend/components/v2/deployment/DeploymentLogsTable';
import { DeploymentMultiInputApiCodeSample } from 'frontend/components/v2/deployment/DeploymentMultiInputApiCodeSample';
import { DeploymentSelector } from 'frontend/components/v2/deployment/DeploymentSelector';
import { DeploymentSummary } from 'frontend/components/v2/deployment/DeploymentSummary';
import { DeploymentUsageChart } from 'frontend/components/v2/deployment/DeploymentUsageChart';
import { SingleVariantSelector } from 'frontend/components/v2/variant/SingleVariantSelector';
import { useLLMNavigation } from 'frontend/hooks/useLLMNavigation';
import { useSelectedDeploymentFromUri } from 'frontend/hooks/useSelectedDeploymentFromUri';
import PageContainer from 'frontend/pages/PageContainer/PageContainer';
import { useDeploymentStore } from 'frontend/storesV2/DeploymentStore';
import { useSelectionStore } from 'frontend/storesV2/SelectionStore';
import theme from 'frontend/theme';
import { track } from 'frontend/utils/analytics';

export function DeploymentsPage() {
  const { selectedApp } = useSelectionStore();
  const { selectedDeploymentFromUri: selectedDeployment } = useSelectedDeploymentFromUri();
  const { goToDeploymentPage } = useLLMNavigation();
  return (
    <PageContainer page="deployments">
      <PageTitle title="Deployments">
        <Box sx={{ maxWidth: 500 }}>
          <DeploymentSelector
            deployment={selectedDeployment}
            setDeployment={deployment => goToDeploymentPage({ app: selectedApp, deployment })}
            size="small"
          />
        </Box>
      </PageTitle>
      {selectedDeployment ? (
        <FlexBox sx={{ flexDirection: 'column' }}>
          <DeploymentSummary deployment={selectedDeployment} />
          <DeploymentMultiInputApiCodeSample deployment={selectedDeployment} />
          <FlexBox sx={{ flexDirection: 'row', width: '100%' }}>
            <Container sx={{ width: '50%' }}>
              <DeploymentLatencyChart deployment={selectedDeployment} />
            </Container>
            <Container sx={{ width: '50%' }}>
              <DeploymentUsageChart deployment={selectedDeployment} />
            </Container>
          </FlexBox>
          <Box sx={{ height: '100%', width: '100%' }}>
            <DeploymentLogsTable deployment={selectedDeployment} />
          </Box>
        </FlexBox>
      ) : (
        <CreateDeployment />
      )}
      <VSpace s={2} />
    </PageContainer>
  );
}

function CreateDeployment(): JSX.Element {
  const { selectedApp, selectedVariant, selectedVariantId, setSelectedVariantId } =
    useSelectionStore(
      R.pick([
        'selectedApp',
        'selectedVariant',
        'selectedVariantId',
        'setSelectedVariantId',
        'setSelectedDeploymentId',
      ]),
    );
  const { createDeployment } = useDeploymentStore(R.pick(['createDeployment']));
  const createDeploymentMutation = useMutation(createDeployment);
  const { goToDeploymentPage } = useLLMNavigation();

  const [uri] = useState<string>(cuid.slug());
  const [name, setName] = useState<string>('Deployment ' + uri);

  const handleDeploy = useCallback(() => {
    if (createDeploymentMutation.isLoading) {
      console.warn('Deployment is being created.');
      return;
    }
    if (!selectedVariant) {
      console.warn('No selected variant, possible race condition.');
      return;
    }
    const deployment = {
      name,
      uri,
      variantId: selectedVariant.id,
    };
    createDeploymentMutation.mutateAsync(deployment).then(createdDeployment => {
      track('Deployment Created', {
        deployment: createdDeployment.id,
        variant: selectedVariant.id,
      });
      goToDeploymentPage({ app: selectedApp, deployment: createdDeployment });
    });
  }, [
    createDeploymentMutation.mutateAsync,
    createDeploymentMutation.isLoading,
    selectedVariant,
    name,
    uri,
  ]);

  return (
    <Paper variant="outlined">
      <Box p={2}>
        <FlexBox m={2} sx={{ justifyContent: 'center' }}>
          <FlexBox
            p={2}
            sx={{ flexDirection: 'column', gap: 2, textAlign: 'center', maxWidth: 600 }}
          >
            <Box m={2}>
              <FontAwesomeIcon
                icon="computer"
                style={{ fontSize: 120, color: theme.palette.primary.main }}
              />
            </Box>
            <Typography>
              Variants can be deployed as flexible API endpoints. You can access each endpoint
              programmatically from your Python, JavaScript, or other HTTP-enabled client.
            </Typography>
            <Divider sx={{ marginTop: 1, width: 1 }} />
            <Container
              sx={{ flexDirection: 'column', alignItems: 'center', border: 0, width: 300 }}
            >
              <Typography>Select variant to deploy:</Typography>
              <SingleVariantSelector
                value={selectedVariantId}
                onChange={setSelectedVariantId}
                size="medium"
              />
              <Typography>Name your new deployment:</Typography>
              <TextField
                fullWidth
                value={name}
                onChange={e => setName(e.target.value)}
                onFocus={e => e.target.select()}
                helperText="You can change this later."
                FormHelperTextProps={{ sx: theme.typography.subtitle2 }}
              />
              <Button
                variant="contained"
                disabled={createDeploymentMutation.isLoading}
                onClick={handleDeploy}
                sx={{ marginTop: 1 }}
              >
                <FontAwesomeIcon icon="upload" style={{ marginRight: '1ex' }} />
                Deploy a new API Endpoint
              </Button>
              <ErrorMessage error={createDeploymentMutation.error} />
            </Container>
          </FlexBox>
        </FlexBox>
      </Box>
    </Paper>
  );
}
