import { BillingPlan } from '@prisma/client';
import { RESTRICTIONS_BY_PLAN } from './plans';

type AccountProperty = { [plan in BillingPlan as string]: any };

export const MaxDatasetRows: AccountProperty = {
  [BillingPlan.Free]: RESTRICTIONS_BY_PLAN.Free.dataRowLimit,
  [BillingPlan.Pilot]: RESTRICTIONS_BY_PLAN.Pilot.dataRowLimit,
  [BillingPlan.Enterprise]: RESTRICTIONS_BY_PLAN.Enterprise.dataRowLimit,
  [BillingPlan.Pro]: RESTRICTIONS_BY_PLAN.Pro.dataRowLimit,
  [BillingPlan.Team]: RESTRICTIONS_BY_PLAN.Team.dataRowLimit,
};
