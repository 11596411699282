import superjson from 'superjson';

import { datadogRum } from '@datadog/browser-rum';
import { createTRPCClient } from '@trpc/client';
import { httpBatchLink } from '@trpc/client/links/httpBatchLink';
import { httpLink } from '@trpc/client/links/httpLink';
import { splitLink } from '@trpc/client/links/splitLink';

import { apiConnection } from 'frontend/hooks/useApiConnectError';
import { track } from 'frontend/utils/analytics';
import { isLocalhost } from 'frontend/utils/string';

import type { AppRouter } from '@scale/llm-server/trpc';
const defaultServerRoute = new URL(window.location.origin);
if (isLocalhost(location.hostname)) {
  const port = process.env.REACT_APP_BACKEND_PORT?.toString() || '3000';
  defaultServerRoute.port = port;
}

// Absolute reference to the server URL
// REACT_APP_SERVER_URL is used on devboxes to reference the API route (firstname-lastname-api.*)
// instead of using the localhost route for development
export const serverOrigin = process.env.REACT_APP_SERVER_URL || defaultServerRoute.origin;
console.info('Connecting to server origin:', serverOrigin);

// Note that process.env.REACT_APP_SERVER_SUBDIRECTORY already contains the leading forward slash
const serverPath = process.env.REACT_APP_SERVER_SUBDIRECTORY ?? '';
console.info('Server subpath:', serverPath);

export const applicationHref = new URL(location.origin).href;

// Absolute reference to the API
export const absoluteApiHref = new URL(`${serverPath}/api`, serverOrigin).href;

export const trpcUrl = absoluteApiHref + '/trpc';

async function fetchWithRetries(input: RequestInfo | URL, init?: RequestInit) {
  const maxRetries = 3;
  let retries = 0;
  let backoffMs = 200;
  if (init?.method !== 'GET' && init?.method !== 'OPTIONS') {
    throw new Error('Only GET or OPTIONS requests are supported for fetchWithRetries');
  }
  while (retries < maxRetries) {
    try {
      return await fetch(input, { ...init, credentials: 'include' });
    } catch (err) {
      retries++;
      if (retries === maxRetries) {
        throw err;
      }
      // Do the same error tracking as below, but don't reraise the error
      const inputUrlObj = new URL((input as Request)?.url ?? input);
      const inputUrlStr = `${inputUrlObj.hostname}${inputUrlObj.pathname}`;
      const method = (input as Request)?.method ?? init?.method ?? 'Unknown';
      datadogRum.addError(
        {
          source: 'browser',
          message: 'Fetch GET request failed and retried, ignored by client',
        },
        { apiUrl: inputUrlStr, method, retries },
      );
      await new Promise(resolve => setTimeout(resolve, backoffMs));
      backoffMs *= 2;
    }
  }
  throw new Error('Unreachable state reached');
}

async function safeFetch(input: RequestInfo | URL, init?: RequestInit) {
  try {
    const canRetry = ['GET', 'OPTIONS'].includes(init?.method ?? '');
    const fetchFn = canRetry ? fetchWithRetries : fetch;
    const result = await fetchFn(input, { ...init, credentials: 'include' });
    if (result.status === 401) {
      apiConnection.emit('unauthorized');
      throw new Error('Unauthorized');
    } else {
      apiConnection.emit('connect');
    }
    return result;
  } catch (err) {
    if (err instanceof TypeError && err.message === 'Failed to fetch') {
      apiConnection.emit('error');
    }
    const inputUrlObj = new URL((input as Request)?.url ?? input);
    const inputUrlStr = `${inputUrlObj.hostname}${inputUrlObj.pathname}`;
    const method = (input as Request)?.method ?? init?.method ?? 'Unknown';
    datadogRum.addError(err, { apiUrl: inputUrlStr, method });
    throw err;
  }
}

export const client = createTRPCClient<AppRouter>({
  url: trpcUrl,
  transformer: superjson,
  fetch: safeFetch,
  headers() {
    return {
      query: window.location.search,
    };
  },
  links: [
    splitLink({
      condition(op) {
        // check for context property `skipBatch`
        return op.context.skipBatch === true;
      },
      // when condition is true, use normal request
      true: httpLink({
        url: trpcUrl,
      }),
      // when condition is false, use batching
      false: httpBatchLink({
        url: trpcUrl,
        maxBatchSize: 20,
      }),
    }),
  ],
});
