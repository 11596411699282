import { useCallback, useState } from 'react';

import _, { forEach } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { DataColumn } from '@scale/llm-shared/interfaces/data';
import { EvaluationType } from '@scale/llm-shared/interfaces/evaluation';

import { client } from 'frontend/api/trpc';
import { track } from 'frontend/utils/analytics';
import { downloadAsCsv } from 'frontend/utils/downloadAsCsv';

function combineAndConvertOutputsToCsv(
  inputColumns: DataColumn[],
  outputColumnsByVariantName: { [variantName: string]: DataColumn },
  expectedOutputColumn: DataColumn,
) {
  const columns: DataColumn[] = [
    ...inputColumns,
    expectedOutputColumn,
    ...Object.values(outputColumnsByVariantName),
  ];
  const columnValues = columns.map(column => column.values.map(v => v.value));
  const rows = _.zip(...columnValues);
  const headers = [
    ...inputColumns.map(column => `Input: ${column.name}`),
    `Expected Output: ${expectedOutputColumn.name}`,
    ...Object.keys(outputColumnsByVariantName).map(variantName => `${variantName} Output`),
  ];
  return [headers, ...rows];
}

export default function DownloadComparisonResultsButton({
  evaluationIds,
}: {
  evaluationIds: string[];
}) {
  const [downloading, setDownloading] = useState<boolean>(false);

  const downloadLogsAsCsv = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation(); // prevents button from triggering detailed results expand/collapse
      setDownloading(true);
      try {
        let allInputColumns: DataColumn[] = [];
        let allExpectedOutputColumn: DataColumn | undefined = undefined;
        const allOutputColumnsByVariantName: { [variantName: string]: DataColumn } = {};
        for (const evaluationId of evaluationIds) {
          const {
            inputColumns,
            inferenceOutputColumns: outputColumns,
            expectedOutputColumn,
            variant,
          } = await client.query('v2.evaluation.getEvaluationAllInputOutputs', {
            id: evaluationId,
            type: EvaluationType.ClassificationEvaluation,
          });

          // TODO: can maybe remove these checks and assume the ComparePage will have already validated
          if (!allInputColumns.length) {
            allInputColumns = inputColumns;
          } else {
            const isMatchingAllColumns =
              _.intersection(
                allInputColumns.map(c => c.name),
                inputColumns.map(c => c.name),
              ).length === allInputColumns.length;
            if (!isMatchingAllColumns) {
              throw Error(
                'Failed to generate comparison! ' +
                  `Evaluation for ${variant.name} does not use the same inputs as others in the comparison.`,
              );
            }
          }
          if (!allExpectedOutputColumn) {
            allExpectedOutputColumn = expectedOutputColumn;
          } else {
            if (allExpectedOutputColumn.name !== expectedOutputColumn?.name) {
              throw Error(
                'Failed to generate comparison! ' +
                  `Evaluation for ${variant.name} does not use the same expected output column as others in the comparison.`,
              );
            }
          }

          allOutputColumnsByVariantName[variant.name] = outputColumns[0]; // only one output column
        }

        if (!allExpectedOutputColumn) {
          throw Error('Failed to generate comparison! Could not find an expected output column.');
        }

        const data = combineAndConvertOutputsToCsv(
          allInputColumns,
          allOutputColumnsByVariantName,
          allExpectedOutputColumn,
        );
        const filename = `comparison-results-${evaluationIds.join('-')}.csv`; // TODO: figure out a more scalable filename
        track('Comparison Results Downloaded');
        downloadAsCsv(data, filename);
      } finally {
        setDownloading(false);
      }
    },
    [evaluationIds],
  );

  return (
    <Button size="small" variant="outlined" disabled={downloading} onClick={downloadLogsAsCsv}>
      <FontAwesomeIcon size="sm" icon="download" style={{ marginRight: '1ex' }} />
      Download Full Results
    </Button>
  );
}
