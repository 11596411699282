import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { BaseModelInternalId } from '@scale/llm-shared/modelProviders/types';

import Sidebar from 'frontend/components/playground/Sidebar';
import { IPromptPreset } from 'frontend/consts/playground';
import { Colors } from 'frontend/theme';

interface ISettingsDialogProps {
  isOpen: boolean;
  baseModel: BaseModelInternalId;
  models: BaseModelInternalId[];
  presets: IPromptPreset[];
  selectedPromptPreset: IPromptPreset | undefined;
  temperature: number;
  minTemperature: number;
  maxTemperature: number;
  maxTokens: number;
  minMaxTokens: number;
  maxMaxTokens: number;
  stopSequence: string;
  onChangeModel: (e: any) => void;
  onChangeSelectedPromptPreset: (presetTitle: string | undefined) => void;
  onChangeTemperature: (e: any, val: any) => void;
  onChangeMaxTokens: (e: any, val: any) => void;
  onChangeStopSequence: (e: any) => void;
  onClearPromptTemplate: () => void;
  onClose: () => void;
}

const SettingsDialog = ({
  isOpen,
  baseModel,
  models,
  presets,
  selectedPromptPreset,
  temperature,
  minTemperature,
  maxTemperature,
  maxTokens,
  minMaxTokens,
  maxMaxTokens,
  stopSequence,
  onChangeSelectedPromptPreset,
  onChangeModel,
  onChangeTemperature,
  onChangeMaxTokens,
  onChangeStopSequence,
  onClearPromptTemplate,
  onClose,
}: ISettingsDialogProps) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          position: 'absolute',
          top: 0,
          right: 0,
          margin: '0 !important',
          width: 262,
          bottom: 0,
          borderRadius: '0 !important',
          maxHeight: 'inherit',
        },
      }}
    >
      <DialogTitle>
        <Box sx={{ float: 'right', cursor: 'pointer' }} onClick={onClose}>
          <FontAwesomeIcon icon="xmark" style={{ color: Colors.CoolGray60, fontSize: 20 }} />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Sidebar
          models={models}
          presets={presets}
          selectedPromptPreset={selectedPromptPreset}
          baseModel={baseModel}
          temperature={temperature}
          minTemperature={minTemperature}
          maxTemperature={maxTemperature}
          maxTokens={maxTokens}
          minMaxTokens={minMaxTokens}
          maxMaxTokens={maxMaxTokens}
          stopSequence={stopSequence}
          isDialog={true}
          onChangeSelectedPromptPreset={onChangeSelectedPromptPreset}
          onChangeModel={onChangeModel}
          onChangeTemperature={onChangeTemperature}
          onChangeMaxTokens={onChangeMaxTokens}
          onChangeStopSequence={onChangeStopSequence}
          onClearPromptTemplate={onClearPromptTemplate}
        />
      </DialogContent>
    </Dialog>
  );
};

export default SettingsDialog;
