import color from 'color';
import cuid from 'cuid';
import _ from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Chip, Skeleton, Tooltip, Typography } from '@mui/material';
import { DatasetType } from '@prisma/client';
import { FinishReasonError } from '@scale/llm-shared/consts/inferenceRequest';
import { DEFAULT_USER_ID } from '@scale/llm-shared/types';
import { TokenProbs } from '@scale/llm-shared/types/requests';

import FlexBox from 'frontend/components/FlexBox';
import { useMultiInputEvaluationState } from 'frontend/models/v2/useMultiInputEvaluationState';
import { useSettingsStore } from 'frontend/stores/SettingsStore';
import theme, { Colors } from 'frontend/theme';
import { InferenceResultRow, PairedRowStatus } from 'frontend/types/types';

import { StoredDataset } from '../../stores/types';

// Don't show these in the frontend
// const filteredTokens = [' ', '\n', '  ', '\n\n'];
// Actually, could be important to show these. let's make it a no-op for now.
const filteredTokens: string[] = [];

type TopProbabilityDisplayProps = {
  topTokenProbs: TokenProbs['top_probs'][0];
  selectedToken: string;
};

// Removing trailing and leading whitespace/newline tokens
function stripTokenProbs(tokenProbs: TokenProbs) {
  function shouldStrip(token: string) {
    return filteredTokens.includes(token);
  }
  let start = 0;
  while (start < tokenProbs.tokens.length && shouldStrip(tokenProbs.tokens[start])) {
    start += 1;
  }
  let end = tokenProbs.tokens.length - 1;
  while (end >= 0 && shouldStrip(tokenProbs.tokens[end])) {
    end -= 1;
  }
  if (end < start) {
    // Effectively return nothing
    start = 0;
    end = 0;
  }

  return {
    token_probs: tokenProbs.token_probs.slice(start, end + 1),
    tokens: tokenProbs.tokens.slice(start, end + 1),
    top_probs: tokenProbs.top_probs.slice(start, end + 1),
  };
}

// Generate rgb given a probability. Red = 0 Green = 1
function getColorFromProbability(p: number, colorblindMode: boolean) {
  const lowProb = colorblindMode ? color(Colors.Blue) : color(Colors.Red);
  const highProb = colorblindMode ? color(Colors.Yellow) : color(Colors.Green);
  return lowProb.mix(highProb, p).toString();
}

function getFontColorFromProbability(p: number, colorblindMode: boolean) {
  const bgColor = getColorFromProbability(p, colorblindMode);
  return color(bgColor).darken(0.4).toString();
}

function FinishReason({ reason }: { reason?: string | null }) {
  const { settings } = useSettingsStore();
  const { colorblindMode } = settings;
  if (reason === 'length') {
    const onPromptPage = false;
    return (
      <Tooltip
        placement="bottom"
        title={
          <>
            <Box>
              This output is truncated due to reaching the maximum token limit. You can adjust your
              token limit in the prompt settings.
            </Box>
            {onPromptPage && (
              <Button
                sx={{
                  padding: 0,
                  fontSize: 10,
                  textDecoration: 'underline',
                  color: Colors.CoolGray30,
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                Increase maximum tokens
              </Button>
            )}
          </>
        }
      >
        <Box sx={{ display: 'inline-block', pl: '0.5ex' }}>
          <FontAwesomeIcon
            icon="scissors"
            size="sm"
            color={colorblindMode ? Colors.Blue : Colors.Red20}
            style={{ transform: 'rotate(180deg)' }}
          />
        </Box>
      </Tooltip>
    );
  }
  return null;
}

function TextWithWhitespace({ text }: { text: string }) {
  // ⏎
  // ␄ ⊗
  // →
  // • ‧
  const whitespaceColor = Colors.CoolGray40;
  const tokens = _.compact(text.split(/( +)|(\t)|(\n)/));
  return (
    <Box component="span">
      {tokens.map((token, i) => {
        if (token[0] === ' ') {
          return (
            <Box
              key={`token-${i}`}
              component="span"
              sx={{
                position: 'relative',
                '&::before': {
                  content: `"${_.repeat('‧', token.length)}"`,
                  color: whitespaceColor,
                  position: 'absolute',
                  top: 0,
                  left: 0,
                },
              }}
            >
              {token}
            </Box>
          );
        } else if (token[0] === '\t') {
          return (
            <Box
              key={`token-${i}`}
              component="span"
              sx={{
                position: 'relative',
                '&::before': {
                  content: `"${_.repeat('→', token.length)}"`,
                  color: whitespaceColor,
                  position: 'absolute',
                  top: 0,
                  left: 0,
                },
              }}
            >
              {token}
            </Box>
          );
        } else if (token[0] === '\n') {
          return (
            <Box
              key={`token-${i}`}
              component="span"
              sx={{
                '&::before': {
                  content: `"${_.repeat('⏎', token.length)}"`,
                  color: whitespaceColor,
                },
              }}
            >
              {token}
            </Box>
          );
        } else {
          return token;
        }
      })}
    </Box>
  );
}

function TopProbabilityDisplay(props: TopProbabilityDisplayProps) {
  const { topTokenProbs, selectedToken } = props;
  const { settings } = useSettingsStore();
  const { colorblindMode } = settings;

  const sortedProbs = Object.keys(topTokenProbs)
    .map(token => ({
      token,
      prob: topTokenProbs[token],
    }))
    .filter(prob => !filteredTokens.includes(prob.token));
  sortedProbs.sort((a, b) => b.prob - a.prob);
  return (
    <FlexBox sx={{ flexDirection: 'column' }}>
      {sortedProbs.map(({ token, prob }, i) => {
        if (token !== selectedToken && Number(prob.toFixed(4)) === 0) {
          // This would effectively be p=0, so we don't show.
          return null;
        }
        const color =
          token === selectedToken
            ? getFontColorFromProbability(prob, colorblindMode)
            : token === 'UNKNOWN'
            ? Colors.CoolGray40
            : undefined;
        return (
          <FlexBox
            key={`token-tooltip-${token}-${i}`}
            sx={{
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Typography sx={{ color }}>
              <TextWithWhitespace text={token} />
            </Typography>
            <Typography sx={{ color }}>{(100 * prob).toFixed(2)}%</Typography>
          </FlexBox>
        );
      })}
    </FlexBox>
  );
}

interface getRenderResultProps {
  row: InferenceResultRow;
  inProgressElem?: JSX.Element;
}

export function RenderResult(props: getRenderResultProps) {
  const { row, inProgressElem } = props;
  const { settings } = useSettingsStore();
  const { showWhitespace } = useMultiInputEvaluationState();
  const { showTokenProbabilities, colorblindMode } = settings;

  if (row.status === PairedRowStatus.IN_PROGRESS) {
    return inProgressElem ?? <Skeleton width={80} />;
  } else if (row.status === PairedRowStatus.FAILED) {
    return (
      <Tooltip title={row.error || 'There was an error connecting to the API'} placement="top">
        <FontAwesomeIcon color={theme.palette.error.main} icon="circle-exclamation" />
      </Tooltip>
    );
  } else if (row.finishReason === FinishReasonError) {
    // Individual row failed but the rest of the batch is fine
    return (
      <Tooltip
        title={row.error || 'There was an error executing the prompt on this input.'}
        placement="top"
      >
        <FontAwesomeIcon color={theme.palette.error.main} icon="circle-exclamation" />
      </Tooltip>
    );
  } else if (row.status === PairedRowStatus.NOT_STARTED) {
    return null;
  } else if (row.actualOutput === '') {
    return <Chip variant="outlined" size="small" label="Empty Output" sx={{ fontSize: 10 }} />;
  } else {
    if (showTokenProbabilities && row.tokenProbs) {
      const tokenProbs = stripTokenProbs(row.tokenProbs);
      return (
        <span style={{ whiteSpace: 'pre-wrap' }}>
          {tokenProbs.tokens.map((token, i) => (
            <Tooltip
              key={`token-${token}-${i}`}
              title={
                <TopProbabilityDisplay
                  topTokenProbs={tokenProbs.top_probs[i]}
                  selectedToken={token}
                />
              }
              TransitionProps={{ timeout: 0 }}
              componentsProps={{
                popper: {
                  sx: {
                    '& .MuiTooltip-tooltip': {
                      backgroundColor: Colors.White,
                      boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.15)',
                      padding: 2,
                    },
                    backgroundColor: 'transparent',
                  },
                },
              }}
            >
              <Box
                component="span"
                sx={{
                  borderBottom: `2px solid ${getColorFromProbability(
                    tokenProbs.token_probs[i],
                    colorblindMode,
                  )}`,
                  boxSizing: 'border-box',
                  whiteSpace: 'pre-wrap',
                  '&:hover': {
                    backgroundColor: color(
                      getColorFromProbability(tokenProbs.token_probs[i], colorblindMode),
                    )
                      .lighten(0.5)
                      .toString(),
                  },
                }}
              >
                {showWhitespace ? <TextWithWhitespace text={token} /> : token}
              </Box>
            </Tooltip>
          ))}
          <FinishReason reason={row.finishReason} />
        </span>
      );
    } else {
      return (
        <span style={{ whiteSpace: 'pre-wrap' }}>
          {showWhitespace ? <TextWithWhitespace text={row.actualOutput} /> : row.actualOutput}
          <FinishReason reason={row.finishReason} />
        </span>
      );
    }
  }
}

export function createEmptyDataset(appId: string) {
  const datasetId = cuid();
  const createdAt = new Date();
  const dataset: StoredDataset = {
    name: 'Emphemeral Dataset Empty ' + datasetId,
    appId,
    userId: DEFAULT_USER_ID,
    id: datasetId,
    type: DatasetType.Validation,
    createdAt,
    inputColName: 'input',
    outputColName: null,
    datumById: {},
    ephemeral: true,
    taxonomy: [],
    taxonomyLowercase: true,
    taxonomyStripWhitespace: true,
  };
  return dataset;
}
