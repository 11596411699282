import * as R from 'ramda';

export function maybeMergeLeft<T extends Record<string, any>>(a?: T) {
  return function (b?: T) {
    return a ? (b ? R.mergeLeft(a, b) : a) : undefined;
  };
}

export function singleIndexById<T extends { id: string }>(obj: T): { [id: string]: T } {
  return { [obj.id]: obj };
}
