import { useMemo } from 'react';

import { format } from 'date-fns';
import _ from 'lodash';
import * as R from 'ramda';
import { useToggle } from 'react-use';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Chip, Tooltip, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { EvaluationMethod, JobStatus } from '@prisma/client';
import {
  CompletedAIFeedbackEvaluation,
  CompletedClassificationEvaluation,
  CompletedMauveEvaluation,
  EvaluationType,
} from '@scale/llm-shared/interfaces/evaluation';
import { isNotUndefinedOrNull } from '@scale/llm-shared/utils';

import { Container } from 'frontend/components/Container';
import GrayDataGrid from 'frontend/components/datagrid/GrayDataGrid';
import FlexBox from 'frontend/components/FlexBox';
import { HSpace } from 'frontend/components/Spacer';
import DownloadEvaluationResultsButton from 'frontend/components/v2/evaluation/DownloadEvaluationResultsButton';
import VariantName from 'frontend/components/v2/variant/VariantName';
import { useDataStore } from 'frontend/storesV2/DataStore';
import { StoredEvaluation } from 'frontend/storesV2/types';
import { useVariantStore } from 'frontend/storesV2/VariantStore';
import { round, toPercent } from 'frontend/utils';

import { DataNameChip } from './DataNameChip';
import { EvaluationStatus } from './EvaluationStatus';
import { EvaluationTypeChip } from './EvaluationTypeChip';

const EVALUATION_ACCURACY_DESCRIPTION_TEXT: Record<EvaluationType, string> = {
  [EvaluationType.ClassificationEvaluation]: 'Classification',
  [EvaluationType.MauveEvaluation]: 'MAUVE Evaluation',
  [EvaluationType.HumanEvaluation]: 'Human Evaluation',
  [EvaluationType.AIFeedback]: 'AI Feedback Evaluation',
};

const statGridCols: GridColDef[] = [
  {
    field: 'category',
    headerName: 'Category',
    flex: 1,
  },
  {
    field: 'accuracy',
    headerName: 'Accuracy',
    maxWidth: 120,
  },
  {
    field: 'f1score',
    headerName: 'F1 Score',
    maxWidth: 120,
  },
];

function InferenceErrorRate({ errorRate }: { errorRate: number }) {
  if (errorRate === 0) {
    return null;
  }
  return (
    <Tooltip title="The inference error rate is the percentage of rows in this evaluation that failed to complete.">
      <FlexBox sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Typography variant="subtitle1">Inference Error Rate:</Typography>
        <Typography variant="body1">{toPercent(errorRate)}</Typography>
      </FlexBox>
    </Tooltip>
  );
}

function ClassificationEvaluationStats({
  categories,
  stats,
}: {
  categories: string[];
  stats: any;
}) {
  const accuracyByClass = stats.accuracyByClass;
  const f1ScoreByClass = stats.f1ScoreByClass;
  const rows = categories.map(category => {
    const categoryAccuracy = accuracyByClass?.[category];
    const accuracy = isNotUndefinedOrNull(categoryAccuracy)
      ? toPercent(categoryAccuracy, 2)
      : 'Unknown';

    const categoryF1Score = f1ScoreByClass?.[category];
    const f1score = isNotUndefinedOrNull(categoryF1Score) ? round(categoryF1Score, 2) : 'Unknown';

    return {
      id: category,
      category,
      accuracy,
      f1score,
    };
  });

  return (
    <div onClick={e => e.stopPropagation()}>
      <GrayDataGrid
        pageSize={5}
        density="compact"
        columns={statGridCols}
        rows={rows}
        hideFooter={rows.length <= 5}
      />
    </div>
  );
}

export function ClassificationEvaluationResultsCard({
  evaluation,
}: {
  evaluation: CompletedClassificationEvaluation;
}) {
  const [showDetailedResults, toggleShowDetailedResults] = useToggle(false);

  const { variantById } = useVariantStore(R.pick(['variantById']));
  const { dataInfoById } = useDataStore(R.pick(['dataInfoById']));

  const variantName = variantById[evaluation.variantId].name;
  const dataName = dataInfoById[evaluation.inputDataId]?.name ?? 'Unknown Dataset';

  const stats = evaluation.stats;
  const createdAtString = format(evaluation.createdAt, 'M/d/yyyy h:mm:ss a');

  // TODO: scope this properly
  const evaluationAccuracyDescriptorText = EVALUATION_ACCURACY_DESCRIPTION_TEXT[evaluation.type];

  const maybeEvaluationAccuracy = useMemo(() => {
    const overallAccuracy = stats?.microAccuracy;
    if (!isNotUndefinedOrNull(overallAccuracy)) return 'Unknown';

    switch (evaluation.type) {
      case EvaluationType.ClassificationEvaluation:
        return toPercent(overallAccuracy, 2);
      default:
        console.error(`Unknown evaluation method ${evaluation.type}`);
    }
  }, [evaluation.type, stats]);

  const validationCategories = useMemo(() => {
    return _.keys(stats.accuracyByClass);
  }, [stats.accuracyByClass]);

  return (
    <>
      <Container onClick={toggleShowDetailedResults} sx={{ minWidth: '1000px' }}>
        <Box sx={{ width: '100%', padding: '8px' }}>
          <FlexBox sx={{ justifyContent: 'space-between', marginBottom: '16px' }}>
            <FlexBox>
              <Box sx={{ width: '16px' }}>
                <FontAwesomeIcon icon={showDetailedResults ? 'caret-down' : 'caret-right'} />
              </Box>
              <Typography sx={{ fontWeight: 600 }}>
                <VariantName name={variantName} />
              </Typography>
              <Typography variant="h4">Created At: {createdAtString}</Typography>
            </FlexBox>
          </FlexBox>
          <FlexBox sx={{ gap: 3 }}>
            <EvaluationStatus evaluationStatus={evaluation.status} />
            <EvaluationTypeChip type={evaluation.type} />
            <DataNameChip name={dataName} />
            <FlexBox sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography variant="subtitle1">{evaluationAccuracyDescriptorText}:</Typography>
              <Typography variant="body1">{maybeEvaluationAccuracy}</Typography>
            </FlexBox>
            <InferenceErrorRate errorRate={evaluation.errorRate} />
            <HSpace />
            {evaluation.status === JobStatus.Completed && (
              <FlexBox sx={{ marginLeft: 'auto' }}>
                <DownloadEvaluationResultsButton
                  evaluationId={evaluation.id}
                  evaluationType={evaluation.type}
                />
              </FlexBox>
            )}
            {evaluation.status === JobStatus.Backfilled && (
              <Tooltip title="Full evaluation results are unavailble for legacy evaluations.">
                <FlexBox sx={{ marginLeft: 'auto' }}>
                  <DownloadEvaluationResultsButton
                    evaluationId={evaluation.id}
                    evaluationType={evaluation.type}
                    disabled={true}
                  />
                </FlexBox>
              </Tooltip>
            )}
          </FlexBox>
          {showDetailedResults && validationCategories && (
            <Box sx={{ marginTop: '16px' }}>
              <ClassificationEvaluationStats categories={validationCategories} stats={stats} />
            </Box>
          )}
        </Box>
      </Container>
    </>
  );
}

export function MauveEvaluationResultsCard({
  evaluation,
}: {
  evaluation: CompletedMauveEvaluation;
}) {
  const { variantById } = useVariantStore(R.pick(['variantById']));
  const { dataInfoById } = useDataStore(R.pick(['dataInfoById']));

  const variantName = variantById[evaluation.variantId].name;
  const dataName = dataInfoById[evaluation.inputDataId]?.name ?? 'Unknown Dataset';
  const { score } = evaluation.stats;
  const createdAtString = format(evaluation.createdAt, 'M/d/yyyy h:mm:ss a');

  return (
    <>
      <Container sx={{ minWidth: '1000px' }}>
        <Box sx={{ width: '100%', padding: '8px' }}>
          <FlexBox sx={{ justifyContent: 'space-between', marginBottom: '16px' }}>
            <FlexBox>
              <Typography sx={{ fontWeight: 600 }}>
                <VariantName name={variantName} />
              </Typography>
              <Typography variant="h4">Created At: {createdAtString}</Typography>
            </FlexBox>
          </FlexBox>
          <FlexBox sx={{ gap: 1 }}>
            <EvaluationStatus evaluationStatus={evaluation.status} />
            <EvaluationTypeChip type={evaluation.type} />
            <FlexBox>
              <Typography variant="subtitle1">Dataset:</Typography>
              <Chip size="small" label={dataName}></Chip>
            </FlexBox>
            <FlexBox sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography variant="subtitle1">MAUVE Score:</Typography>
              <Typography variant="body1">{score.toFixed(4)}</Typography>
            </FlexBox>
            <InferenceErrorRate errorRate={evaluation.errorRate} />
            {evaluation.status === JobStatus.Completed && (
              <FlexBox sx={{ marginLeft: 'auto' }}>
                <DownloadEvaluationResultsButton
                  evaluationId={evaluation.id}
                  evaluationType={evaluation.type}
                />
              </FlexBox>
            )}
            {evaluation.status === JobStatus.Backfilled && (
              <Tooltip title="Full evaluation results are unavailble for legacy evaluations.">
                <FlexBox sx={{ marginLeft: 'auto' }}>
                  <DownloadEvaluationResultsButton
                    evaluationId={evaluation.id}
                    evaluationType={evaluation.type}
                    disabled={true}
                  />
                </FlexBox>
              </Tooltip>
            )}
          </FlexBox>
        </Box>
      </Container>
    </>
  );
}

export function AIFeedbackEvaluationResultsCard({
  evaluation,
}: {
  evaluation: CompletedAIFeedbackEvaluation;
}) {
  const { variantById } = useVariantStore(R.pick(['variantById']));
  const { dataInfoById } = useDataStore(R.pick(['dataInfoById']));

  const variantName = variantById[evaluation.variantId].name;
  const dataName = dataInfoById[evaluation.inputDataId]?.name ?? 'Unknown Dataset';
  const { score } = evaluation.stats;
  const createdAtString = format(evaluation.createdAt, 'M/d/yyyy h:mm:ss a');
  const inferenceErrorRate = evaluation.errorRate;

  return (
    <>
      <Container sx={{ minWidth: '1000px' }}>
        <Box sx={{ width: '100%', padding: '8px' }}>
          <FlexBox sx={{ justifyContent: 'space-between', marginBottom: '16px' }}>
            <FlexBox>
              <Typography sx={{ fontWeight: 600 }}>
                <VariantName name={variantName} />
              </Typography>
              <Typography variant="h4">Created At: {createdAtString}</Typography>
            </FlexBox>
          </FlexBox>
          <FlexBox sx={{ gap: 1 }}>
            <EvaluationStatus evaluationStatus={evaluation.status} />
            <EvaluationTypeChip type={evaluation.type} />
            <FlexBox>
              <Typography variant="subtitle1">Dataset:</Typography>
              <Chip size="small" label={dataName}></Chip>
            </FlexBox>
            <FlexBox sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography variant="subtitle1">AI Feedback Score:</Typography>
              <Typography variant="body1">{`${score.toFixed(2)}/10`}</Typography>
            </FlexBox>
            <InferenceErrorRate errorRate={evaluation.errorRate} />
            <FlexBox sx={{ marginLeft: 'auto' }}>
              <DownloadEvaluationResultsButton
                evaluationId={evaluation.id}
                evaluationType={evaluation.type}
              />
            </FlexBox>
          </FlexBox>
        </Box>
      </Container>
    </>
  );
}

export function GenericEvaluationResultsCard({ evaluation }: { evaluation: StoredEvaluation }) {
  const { variantById } = useVariantStore(R.pick(['variantById']));
  const { dataInfoById } = useDataStore(R.pick(['dataInfoById']));

  const variantName = variantById[evaluation.variantId].name;
  const dataName = dataInfoById[evaluation.inputDataId]?.name ?? 'Unknown Dataset';
  const createdAtString = format(evaluation.createdAt, 'M/d/yyyy h:mm:ss a');

  return (
    <>
      <Container sx={{ minWidth: '1000px' }}>
        <Box sx={{ width: '100%', padding: '8px' }}>
          <FlexBox sx={{ justifyContent: 'space-between', marginBottom: '16px' }}>
            <FlexBox>
              <Typography sx={{ fontWeight: 600 }}>
                <VariantName name={variantName} />
              </Typography>
              <Typography variant="h4">Created At: {createdAtString}</Typography>
            </FlexBox>
          </FlexBox>
          <FlexBox sx={{ gap: 1 }}>
            <EvaluationStatus evaluationStatus={evaluation.status} />
            <EvaluationTypeChip type={evaluation.type} />
            <DataNameChip name={dataName} />
          </FlexBox>
        </Box>
      </Container>
    </>
  );
}

export function ProgrammaticEvaluationResultsCard({
  evaluation,
}: {
  evaluation: StoredEvaluation;
}) {
  if (evaluation.status === JobStatus.Completed || evaluation.status === JobStatus.Backfilled) {
    if (evaluation.type === EvaluationType.ClassificationEvaluation) {
      return (
        <ClassificationEvaluationResultsCard
          evaluation={evaluation as CompletedClassificationEvaluation}
        />
      );
    } else if (evaluation.type === EvaluationType.MauveEvaluation) {
      return <MauveEvaluationResultsCard evaluation={evaluation as CompletedMauveEvaluation} />;
    } else if (evaluation.type === EvaluationType.AIFeedback) {
      return (
        <AIFeedbackEvaluationResultsCard evaluation={evaluation as CompletedAIFeedbackEvaluation} />
      );
    }
  } else {
    return <GenericEvaluationResultsCard evaluation={evaluation} />;
  }
  console.error('Attempting to render programmatic unsupported evaluation card', evaluation);
  return null;
}
