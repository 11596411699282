import { useCallback, useEffect, useMemo, useState } from 'react';

import _ from 'lodash';
import * as R from 'ramda';
import { useList } from 'react-use';

import { Box, Button, Typography } from '@mui/material';
import {
  InferenceBatch,
  InferenceBatchCreateVariant,
} from '@scale/llm-shared/interfaces/inferenceBatch';

import { client } from 'frontend/api/trpc';
import { Container } from 'frontend/components/Container';
import { ErrorMessage } from 'frontend/components/ErrorMessage';
import { SelectVariantAndDatasetPanel } from 'frontend/pages/v2/EvaluationsPage/Wizard/Steps/SetupStep/SelectVariantAndDatasetPanel';
import { StoredInferenceBatch } from 'frontend/storesV2/types';

export function InferenceBatchCreateForm({ onClose }: { onClose: () => void }): JSX.Element {
  const [selectedVariantIds, setSelectedVariantIds] = useState<string[]>([]);
  const [selectedDataId, setSelectedDataId] = useState<string | undefined>();
  const [responses, setResponses] = useState<PromiseSettledResult<InferenceBatch>[]>([]);

  const canSubmit = useMemo(() => {
    return Boolean(_.head(selectedVariantIds) && selectedDataId);
  }, [selectedVariantIds, selectedDataId]);

  const handleSubmit = useCallback(() => {
    setResponses([]);
    if (!selectedVariantIds.length || !selectedDataId) {
      return;
    }
    Promise.allSettled(
      selectedVariantIds.map(async variantId => {
        return client.mutation('v2.inference.batch.create', {
          variantId,
          inputDataId: selectedDataId,
        });
      }),
    ).then(promises => {
      // If no rejections, close
      if (!promises.some(p => p.status === 'rejected')) {
        onClose();
        return;
      }
      // Else, show errors
      setResponses(promises);
    });
  }, [onClose, selectedVariantIds, selectedDataId]);

  return (
    <Container>
      <Typography variant="h2">Launch Inference Batch</Typography>
      <form onSubmit={handleSubmit}>
        <SelectVariantAndDatasetPanel
          canPickVariantAndDataset
          handleVariantSelect={setSelectedVariantIds}
          handleSelectData={setSelectedDataId}
          selectedDataId={selectedDataId}
          selectedVariantIds={selectedVariantIds}
          shouldUseMultiVariantSelect
        />
      </form>
      {responses.length ? (
        <Box>
          {responses.map((res, i) => {
            if (res.status === 'rejected') {
              return <ErrorMessage key={i} error={res.reason} />;
            }
            return (
              <Typography key={i} variant="subtitle1" color="success">
                Successfully started variant: {res.value.variantId}
              </Typography>
            );
          })}
        </Box>
      ) : undefined}
      <Box sx={{ display: 'flex', justifyContent: 'end', gap: 2 }}>
        <Button color="infoSecondary" variant="contained" onClick={onClose}>
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={handleSubmit} disabled={!canSubmit}>
          Evaluate
        </Button>
      </Box>
    </Container>
  );
}
