import { useCallback, useMemo, useState } from 'react';

import _ from 'lodash';
import Papa from 'papaparse';
import * as R from 'ramda';
import { useDropzone } from 'react-dropzone';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from '@mui/material';
import { DataType } from '@prisma/client';
import { MaxDatasetRows } from '@scale/llm-shared/consts/account';
import { DataColumnCreate, DataCreate } from '@scale/llm-shared/interfaces/data';
import { intToStringWithPadding } from '@scale/llm-shared/utils';
import { logger } from '@scale/llm-shared/utils/logging';

import DocumentsEmptyState from 'frontend/assets/documents-empty-state.svg';
import { Container } from 'frontend/components/Container';
import FlexBox from 'frontend/components/FlexBox';
import { VSpace } from 'frontend/components/Spacer';
import { AcceptedFileExt } from 'frontend/pages/AppsPage/AppCreationWizard/AppCreationWizardTypes';
import DatasetFullGridView from 'frontend/pages/v2/EvaluationSetsPage/DatasetFullGridView';
import { useUserStore } from 'frontend/stores/UserStore';
import { useDataStore } from 'frontend/storesV2/DataStore';
import { Colors } from 'frontend/theme';
import { track } from 'frontend/utils/analytics';

import HoverableInfoDialog from '../../HoverableInfoDialog';

enum UploadStep {
  Import = 'Import',
  Preview = 'Preview',
  Finalize = 'Finalize',
}

export const DataUploadModal = ({
  open,
  setOpen,
  setDataId,
}: {
  open: boolean;
  setOpen: (value: boolean) => void;
  setDataId?: (value: string | undefined) => void;
}) => {
  const { createData, dataInfoById } = useDataStore(R.pick(['createData', 'dataInfoById']));
  const { user, billingPlan } = useUserStore();
  const existingDatasetNames = useMemo(
    () => Object.values(dataInfoById || {}).map(d => d.name) || [],
    [dataInfoById],
  );

  const [uploadStep, setUploadStep] = useState<UploadStep>(UploadStep.Import);
  const [fileName, setFileName] = useState<string>('');
  const [dataRows, setDataRows] = useState<Record<string, string>[]>([]);
  const [colNames, setColNames] = useState<string[]>([]);
  const [isTruncated, setIsTruncated] = useState<boolean>(false);
  const [colNameTrimmed, setColNameTrimmed] = useState<boolean>(false);
  const [collidedName, setCollidedName] = useState<string>('');

  // TODO: maybe need to set output column on datasets? or it needs to be selected with the taxonomy
  // const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  // const taxonomy = useMemo(
  //   () => _.uniq(_.compact(dataRows.map(row => outputColName && row[outputColName]))),
  //   [dataRows, outputColName],
  // );

  const maxDatasetRows = MaxDatasetRows[billingPlan];

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      // Only use the first file
      setCollidedName('');
      const firstFile = R.head(acceptedFiles);

      if (!firstFile) {
        return;
      }
      if (existingDatasetNames.includes(firstFile.name)) {
        setCollidedName(firstFile.name);
        return;
      }

      const fileExt = firstFile.name.slice(firstFile.name.lastIndexOf('.')) as AcceptedFileExt;
      track('Data Uploaded From Dataset Upload Modal', {
        file_extension: fileExt,
        file: firstFile.name,
      });

      switch (fileExt) {
        case '.csv':
          Papa.parse<Record<string, string>>(firstFile, {
            preview: maxDatasetRows,
            complete: results => {
              // Headers
              if (!results.meta.fields) {
                logger.warn('Skipping csv since results.meta.fields is undefined');
                return;
              }
              setFileName(firstFile.name);
              setColNames(results.meta.fields.map(col => col.trim()));
              setColNameTrimmed(false);
              setDataRows(
                results.data.map(obj =>
                  _.fromPairs(
                    _.entries(obj).map(entry => {
                      const [col, value] = entry;
                      if (col !== col.trim()) {
                        setColNameTrimmed(true);
                      }
                      return [col.trim(), value];
                    }),
                  ),
                ),
              );
              setIsTruncated(results.meta.truncated);
              if (results.data.length > 0) {
                setUploadStep(UploadStep.Preview);
              }
            },
            header: true,
            skipEmptyLines: true,
          });
          break;
        case '.jsonl':
          firstFile.text().then(fileText => {
            const rows: Record<string, string>[] = [];
            for (const [rowIndex, text] of fileText.split('\n').entries()) {
              try {
                // Skip empty lines
                if (!text) {
                  continue;
                }
                const row: Record<string, string> = JSON.parse(text);
                setColNameTrimmed(false);
                const columnWhitespaceTrimmed = _.fromPairs(
                  _.entries(row).map(entry => {
                    const [col, value] = entry;
                    if (col !== col.trim()) {
                      setColNameTrimmed(true);
                    }
                    return [col.trim(), value];
                  }),
                );
                rows.push(columnWhitespaceTrimmed);
              } catch (err) {
                logger.error(
                  `There was an error parsing line ${rowIndex} of JSONL file ${firstFile.name}: ${err}`,
                );
                return;
              }
            }
            const colNames = Array.from(
              rows.reduce((acc, row) => {
                Object.keys(row).forEach(key => acc.add(key));
                return acc;
              }, new Set<string>()),
            );
            setFileName(firstFile.name);
            setColNames(colNames);
            setDataRows(rows.length > maxDatasetRows ? rows.slice(0, maxDatasetRows) : rows);
            setIsTruncated(rows.length > maxDatasetRows);
            if (rows.length > 0) {
              setUploadStep(UploadStep.Preview);
            }
          });
          break;
        default:
          console.warn(`Unsupported format for file ${firstFile.name}`);
      }
    },
    [existingDatasetNames],
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: { 'text/*': ['.csv', '.jsonl'] },
  });

  const handleClose = useCallback(() => {
    setCollidedName('');
    setOpen(false);
  }, [setOpen]);

  const handleFinalize = useCallback(async () => {
    if (dataRows.length > 0) {
      setUploadStep(UploadStep.Finalize);
      // TODO: clean this up...
      const columns: any = {};
      dataRows.forEach((row, i) => {
        colNames.forEach(col => {
          columns[col] ??= { values: [] };
          columns[col].name = col;
          columns[col].values.push({ row: intToStringWithPadding(i), value: row[col] });
        });
      });
      const dataCreate: DataCreate = {
        name: fileName,
        type: DataType.UserUploaded,
        columns: Object.values(columns) as DataColumnCreate[],
      };

      // TODO: reimplement token length check with multi inputs
      // const maxOutputTokenLengthByModel = findMaxTokenLengthForEachModel(
      //   dataset.rows.map(r => r.output).filter(output => !!output),
      // );
      // const maxOutputStrippedTokenLengthByModel = findMaxTokenLengthForEachModel(
      //   dataset.rows
      //     .map(r => r.output)
      //     .filter(output => !!output)
      //     .map(output => output.trim()),
      // );
      // const maxInputTokenLengthByModel = findMaxTokenLengthForEachModel(
      //   dataset.rows.map(r => r.input),
      // );
      // Object.keys(maxInputTokenLengthByModel).forEach(modelId => {
      //   dataset['maxTokenLengthByModel'][modelId] = {
      //     inputs: maxInputTokenLengthByModel[modelId],
      //     outputs: maxOutputTokenLengthByModel[modelId],
      //     outputsStripped: maxOutputStrippedTokenLengthByModel[modelId],
      //   };
      // });
      // logger.info(
      //   `Found max token length for each model: ${JSON.stringify(
      //     dataset['maxTokenLengthByModel'],
      //   )}`,
      // );

      createData(dataCreate)
        .then(data => {
          setOpen(false);
          setUploadStep(UploadStep.Import);
          // TODO: update with selection store v2
          setDataId?.(data.id);
        })
        .catch(e => console.error(e));
    }
  }, [dataRows, fileName, setOpen, user?.id]);

  if (!user) {
    logger.error('No user');
    return <></>;
  }

  const importStep = (
    <FlexBox sx={{ flexDirection: 'column' }}>
      {/* TODO: allow renaming on the fly, or perhaps silently append a number to dupe filenames */}
      {collidedName && (
        <>
          <VSpace s={-1} />
          <Alert variant="outlined" severity="error" sx={{ marginBottom: 0 }}>
            A dataset named {`'${collidedName}'`} has already been uploaded to Spellbook.
          </Alert>
        </>
      )}
      <input {...getInputProps()} />
      <FlexBox sx={{ justifyContent: 'center', minHeight: 440, minWidth: 440 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: isDragActive ? 420 : 400,
            width: isDragActive ? 420 : 400,
            backgroundColor: isDragActive ? Colors.CoolBlue10 : Colors.CoolGray05,
            borderRadius: 100,
            transition: 'all 0.2s ease-in',
          }}
        >
          <Box
            sx={{
              color: 'info.light',
              marginBottom: 2,
            }}
          >
            <img src={DocumentsEmptyState} height={134} width={150} />
          </Box>
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            Drag and drop a CSV or JSONL file here
          </Typography>
          <Box m={0.5}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              or
            </Typography>
          </Box>
          <Button
            color="secondary"
            variant="contained"
            onClick={getRootProps().onClick}
            disableElevation
          >
            <FontAwesomeIcon size="lg" icon="file-arrow-up" />
            <Box m={0.5} />
            Import Data
          </Button>
        </Box>
      </FlexBox>
    </FlexBox>
  );

  const previewStep = (
    <>
      {/* TODO: don't use hardcoded width */}
      <Box my={2} sx={{ minWidth: 1200 }}>
        {isTruncated && (
          <Alert variant="outlined" severity="warning" sx={{ marginBottom: 1 }}>
            Dataset was truncated to the first {maxDatasetRows.toLocaleString()} rows.
          </Alert>
        )}
        {colNameTrimmed && (
          <Alert variant="outlined" severity="warning" sx={{ marginBottom: 1 }}>
            Starting and ending whitespace on column names was trimmed.
          </Alert>
        )}
        <VSpace s={-2} />
        <DatasetFullGridView highlightSelected={true} rows={dataRows} columnNames={colNames} />
      </Box>
    </>
  );

  const finalizeStep = (
    <>
      <Box
        my={2}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          // width: 100,
          height: 100,
        }}
      >
        <CircularProgress />
      </Box>
    </>
  );

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xl">
      <DialogTitle
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '16px 16px 0px',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography>Upload New Dataset</Typography>
          <HoverableInfoDialog iconSize="1x">
            <Typography>
              {'See '}
              <Link
                href={'https://spellbook.readme.io/docs/dataset-guide'}
                target="_blank"
                rel="noopener noreferrer"
                variant="body1"
              >
                examples
              </Link>
              {' of data input formats'}
            </Typography>
          </HoverableInfoDialog>
        </Box>
        {/* {selectedApp.taskType === TaskType.Classification && uploadStep === UploadStep.Preview && ( */}
        {/*   <Button color="primary" variant="text" onClick={() => setIsModalOpen(!isModalOpen)}> */}
        {/*     See Taxonomy */}
        {/*   </Button> */}
        {/* )} */}
      </DialogTitle>
      <DialogContent>
        <Container {...getRootProps()} onClick={undefined} sx={{ border: 0 }}>
          {uploadStep === UploadStep.Import && importStep}
          {uploadStep === UploadStep.Preview && previewStep}
          {uploadStep === UploadStep.Finalize && finalizeStep}
        </Container>
      </DialogContent>
      <DialogActions>
        {uploadStep === UploadStep.Import && (
          <Button variant="outlined" style={{ margin: '16px' }} onClick={handleClose}>
            Cancel
          </Button>
        )}
        {uploadStep === UploadStep.Preview && (
          <Box sx={{ margin: '0px 32px 16px' }}>
            <Button
              variant="outlined"
              style={{ marginRight: '8px' }}
              onClick={() => setUploadStep(UploadStep.Import)}
            >
              Back
            </Button>
            <Button variant="contained" onClick={handleFinalize}>
              Upload
            </Button>
          </Box>
        )}
      </DialogActions>
      {/* <TaxonomyModal taxonomy={taxonomy} open={isModalOpen} setOpen={setIsModalOpen} /> */}
    </Dialog>
  );
};
