export enum FrontendFeatureFlag {
  SpellbookPublicAppsEnabled = 'spellbook-public-apps-enabled',
  AllowCustomOpenAiKey = 'spellbook-allow-custom-openai-key',
  AllowScaleModelFinetuning = 'spellbook-allow-scale-model-finetuning',
  CanUseSpellbookAIFeedback = 'can-use-spellbook-ai-feedback',
  CanUseGPT4Eval = 'can-use-spellbook-gpt-4-eval',
  CanUseAnthropic = 'can-use-spellbook-anthropic',
}

// For features that we want all internal scale users to be able to use, even if they're not avaiable externally yet
export const FrontendFeatureFlagAllScaleUsersCanUse: Record<FrontendFeatureFlag, boolean> = {
  [FrontendFeatureFlag.SpellbookPublicAppsEnabled]: false,
  [FrontendFeatureFlag.AllowCustomOpenAiKey]: false,
  [FrontendFeatureFlag.AllowScaleModelFinetuning]: true,
  [FrontendFeatureFlag.CanUseSpellbookAIFeedback]: true,
  [FrontendFeatureFlag.CanUseGPT4Eval]: true,
  [FrontendFeatureFlag.CanUseAnthropic]: true,
};

export const DEFAULT_FRONTEND_FEATURE_FLAG_VALUE: Record<FrontendFeatureFlag, boolean> = {
  [FrontendFeatureFlag.AllowCustomOpenAiKey]: false,
  [FrontendFeatureFlag.SpellbookPublicAppsEnabled]: false,
  [FrontendFeatureFlag.AllowScaleModelFinetuning]: false,
  [FrontendFeatureFlag.CanUseSpellbookAIFeedback]: false,
  [FrontendFeatureFlag.CanUseGPT4Eval]: false,
  [FrontendFeatureFlag.CanUseAnthropic]: false,
};
//
// For displaying a string
export enum FrontendVariantFeatureFlag {
  SpellbookBannerMessage = 'spellbook-banner',
}

const FrontendFeatureFlagsSet = new Set(Object.values(FrontendFeatureFlag));

export function isFrontendFeatureFlag(flag: string): flag is FrontendFeatureFlag {
  return FrontendFeatureFlagsSet.has(flag as FrontendFeatureFlag);
}
