import _ from 'lodash';
import * as R from 'ramda';

import { MenuItem, Select, Tooltip, Typography } from '@mui/material';

import { useSelectionStore } from 'frontend/storesV2/SelectionStore';
import { Colors } from 'frontend/theme';
import { VariantSelectorItem } from './VariantSelectorItem';

export function SingleVariantSelector(props: {
  value?: string;
  onChange: (variantId: string) => void;
  disabledVariants?: { variantId?: string; reason?: string }[];
  size?: 'small' | 'medium' | undefined;
  fullWidth?: boolean;
}) {
  const { selectedAppVariants } = useSelectionStore(R.pick(['selectedAppVariants']));
  const { value, onChange, disabledVariants, size = 'small', fullWidth } = props;

  return (
    <Select
      fullWidth={fullWidth}
      displayEmpty
      value={value}
      onChange={e => onChange(e.target.value)}
      size={size}
      renderValue={
        !value
          ? () => <Typography sx={{ color: Colors.CoolGray40 }}>Select Variant</Typography>
          : undefined
      }
    >
      {selectedAppVariants?.map(variant => {
        const disabledVariant = disabledVariants?.find(
          disabled => disabled.variantId === variant.id,
        );
        // Call the function specifically rather than create a component since it seems MUI does not interact well with an intermediate component between the Select and MenuItem components
        return VariantSelectorItem({ variant, disabledVariant });
      })}
    </Select>
  );
}
