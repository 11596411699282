import { User as PrismaUser } from '@prisma/client';
import { Namespace } from '@scale/llm-shared/types/namespace';

// Needed by the frontend
export type User<T extends Record<string, any> = Record<string, unknown>> = Omit<
  PrismaUser,
  'metadata'
> & { metadata: T };

// Spellbook user metadata for Users created from Scale authn service.
export type ScaleIdentityServiceMetadata = ScaleIdentityCustomer & {
  email: string;
};

/**
 * Identifies users as Scale API user type.
 * TODO Add 'type' property. Should use introspection and not structure check.
 */
export function userMetadataFromScaleIdentityService(
  user: User,
): user is User<ScaleIdentityServiceMetadata> {
  return !!(user as User<ScaleIdentityServiceMetadata>).metadata?.userIdentity;
}

// Spellbook namespace metadata for Namespaces created for Scale authn service.
export type ScaleNamespaceMetadata = ScaleIdentityCustomer & {
  email: string;
  phoneNumberVerified?: boolean;
  creditGrantedPhoneNum?: boolean; // Flag to check phone number credit has been granted
};

/**
 * Returns if the user is an admin on their team, as defined by the Scale Identity Service
 */
export function isAdminUser(user: User<ScaleIdentityServiceMetadata>) {
  return user.metadata.userIdentity === user.metadata.team.id;
}

/**
 * Get namespace directly associated where the User is the admin.
 */
export function getPrimaryNamespace(user: User, namespaces: Namespace[]): Namespace | undefined {
  if (!user || !namespaces) {
    return;
  }

  if (userMetadataFromScaleIdentityService(user)) {
    // If namespaceId is same as userIdentity, then the user "owns" the Scale account
    // that is associated with the namespace.
    return namespaces.find(n => n.namespaceId === user.metadata.userIdentity);
  }

  return;
}

// TODO: below here should be moved to server

/**
 * Scale Identity CustomerProfileAndTeamRO (INCOMPLETE)
 * This is the CUSTOMER object returned by the Scale identity service
 * Currently the identity service only supports resolving a userKey (jwt token or API key) to a ScaleCustomer object
 * There are plans for the identity service to merge the customer and user objects
 */
export type ScaleIdentityCustomer = {
  userIdentity: string; // This is equal to a Scale user identity object's id
  customerType: string;
  customerStatus: string;
  team: {
    createdDate: string;
    updatedAt: string;
    id: string; // This is the id of the Scale user who is the team admin
    // teamTokens: String[]; // Something up with prisma and arrays in jsons
  };
  teamRole: string;
  createdDate: string;
  updatedAt: string;
};

/**
 * Scale Identity UserIdentityRO (INCOMPLETE)
 * This is the USER object returned by the Scale identity service
 * There are plans for the identity service to merge the customer and user objects
 */
export type ScaleIdentityUser = {
  id: string; // This is equal to some Scale customer object's userIdentity field
  email: string;
  createdDate: string;
  updatedAt: string;
  accountType?: 'user' | 'worker' | 'workquester' | 'bot';
  isCorporateUser?: boolean;
  customerType: 'self_service' | 'enterprise' | 'enterprise_pilot';
  customerStatus?: 'active' | 'disabled' | 'locked';
  teamRole?: 'admin' | 'manager' | 'member' | 'labeler' | 'disabled';
  team: string;
  workerStatus?: 'default' | 'pending' | 'disabled' | 'banned' | 'locked';
  phoneNumberVerified?: boolean;
};

/**
 * Checks if string is a Scale internal email
 */
export function isScaleEmail(email?: string) {
  if (typeof email !== 'string') {
    return false;
  }
  return ['scale.com', 'scaleapi.com', 'scalegov.com'].some(
    s => email.endsWith(`@${s}`) || email.endsWith(`@contractors.${s}`),
  );
}
