export const AnthropicBaseModelInternalId = {
  ClaudeInstantV1: 'ClaudeInstantV1',
  ClaudeV1: 'ClaudeV1',
} as const;

export type AnthropicBaseModelInternalId =
  typeof AnthropicBaseModelInternalId[keyof typeof AnthropicBaseModelInternalId];

export const AnthropicBaseModelDisplayName: Record<AnthropicBaseModelInternalId, string> = {
  ClaudeInstantV1: 'Anthropic Claude Instant V1',
  ClaudeV1: 'Anthropic Claude V1',
} as const;

export type AnthropicBaseModelDisplayName =
  typeof AnthropicBaseModelDisplayName[keyof typeof AnthropicBaseModelDisplayName];

export function isAnthropicBaseModelInternalId(
  modelId: string,
): modelId is AnthropicBaseModelInternalId {
  return modelId in AnthropicBaseModelInternalId;
}
