import { useState } from 'react';

import { DataSelector } from 'frontend/components/v2/data/DataSelector';
import { DataUploadModal } from 'frontend/components/v2/data/DataUploadModal';

export function DataSelectorAndUploader({
  dataId,
  setDataId,
  allowNoDataset = false,
}: {
  dataId: string | undefined;
  setDataId: (value: string | undefined) => void;
  allowNoDataset?: boolean;
}) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <>
      <DataSelector
        dataId={dataId}
        setDataId={setDataId}
        allowNoDataset={allowNoDataset}
        setIsUploading={setIsModalOpen}
      />

      <DataUploadModal open={isModalOpen} setOpen={setIsModalOpen} setDataId={setDataId} />
    </>
  );
}
