import { JobStatus } from '@prisma/client';

// TODO: is there a better way to do this?
export const IncompleteJobStatus: Omit<typeof JobStatus, 'Completed' | 'Backfilled'> = {
  Pending: JobStatus.Pending,
  Running: JobStatus.Running,
  Errored: JobStatus.Errored,
  Cancelled: JobStatus.Cancelled,
};

export const RunningOrCompletedJobStatus: Omit<
  typeof JobStatus,
  'Pending' | 'Errored' | 'Cancelled'
> = {
  Running: JobStatus.Running,
  Completed: JobStatus.Completed,
  Backfilled: JobStatus.Backfilled,
};

export const ErroredOrCancelledJobStatus: Omit<
  typeof JobStatus,
  'Pending' | 'Running' | 'Completed' | 'Backfilled'
> = {
  Errored: JobStatus.Errored,
  Cancelled: JobStatus.Cancelled,
};

export const TerminalJobStatus: Omit<typeof JobStatus, 'Pending' | 'Running'> = {
  Errored: JobStatus.Errored,
  Cancelled: JobStatus.Cancelled,
  Completed: JobStatus.Completed,
  Backfilled: JobStatus.Backfilled,
};

export type IncompleteJobStatus = typeof IncompleteJobStatus[keyof typeof IncompleteJobStatus];

export type RunningOrCompletedJobStatus =
  typeof RunningOrCompletedJobStatus[keyof typeof RunningOrCompletedJobStatus];

export type ErroredOrCancelledJobStatus =
  typeof ErroredOrCancelledJobStatus[keyof typeof ErroredOrCancelledJobStatus];

export type TerminalJobStatus = typeof TerminalJobStatus[keyof typeof TerminalJobStatus];

export function isErroredOrCancelled(status: JobStatus): status is ErroredOrCancelledJobStatus {
  return (
    status === ErroredOrCancelledJobStatus.Cancelled ||
    status === ErroredOrCancelledJobStatus.Errored
  );
}

export function isRunningOrCompleted(status: JobStatus): status is RunningOrCompletedJobStatus {
  return (
    status === RunningOrCompletedJobStatus.Running ||
    status === RunningOrCompletedJobStatus.Completed
  );
}

export function isTerminalStatus(status: JobStatus): status is TerminalJobStatus {
  return (
    status === ErroredOrCancelledJobStatus.Cancelled ||
    status === ErroredOrCancelledJobStatus.Errored ||
    status === RunningOrCompletedJobStatus.Completed
  );
}
