import _ from 'lodash';
import * as R from 'ramda';

import { useAppStore } from 'frontend/stores/AppStore';
import { useDeploymentStore } from 'frontend/storesV2/DeploymentStore';
import { useFinetuneStore } from 'frontend/storesV2/FinetuneStore';
import { StoredDeployment, StoredFinetune, StoredVariant } from 'frontend/storesV2/types';
import { useVariantStore } from 'frontend/storesV2/VariantStore';
import { diffArray } from 'frontend/utils/array';
import { createFromStates, state } from 'frontend/utils/store';

import type { App } from '@prisma/client';
// State dependency tree topographically ordered
const states = [
  state('selectedAppId', undefined as Maybe<string>),

  state('selectedApp', undefined as Maybe<App>),

  state('selectedAppVariants', undefined as Maybe<StoredVariant[]>),
  state('selectedAppDeployments', undefined as Maybe<StoredDeployment[]>),
  state('selectedAppFinetunes', undefined as Maybe<StoredFinetune[]>),
  state('selectedVariantId', undefined as Maybe<string>),

  state('selectedVariant', undefined as Maybe<StoredVariant>),

  state('selectedVariantDeployments', undefined as Maybe<StoredDeployment[]>),
  state('selectedDeploymentId', undefined as Maybe<string>),
  state('selectedVariantFineTunings', undefined as Maybe<any[]>),
];

type Store = UnionToIntersection<ReturnType<typeof states[number]>>;

export const useSelectionStore = createFromStates<Store>(states);

(window as any).useSelectionStoreV2 = useSelectionStore;

// Internal store effects

function resetSelectionStoreState(state: Store) {
  const { setSelectedAppVariants, setSelectedAppDeployments, setSelectedAppFinetunes } = state;

  setSelectedAppVariants(undefined);
  setSelectedAppDeployments(undefined);
  setSelectedAppFinetunes(undefined);
}

/**
 * When selectedAppId changes, update selectedApp
 */
useSelectionStore.subscribe(R.prop('selectedAppId'), selectedAppId => {
  const { setSelectedApp } = useSelectionStore.getState();
  const { getApp } = useAppStore.getState();

  setSelectedApp(getApp(selectedAppId));
});

/**
 * If the selected app changes in the store, update selectedApp
 */
useAppStore.subscribe(R.prop('appById'), appById => {
  const { selectedAppId, setSelectedApp } = useSelectionStore.getState();
  const { getApp } = useAppStore.getState();
  setSelectedApp(getApp(selectedAppId));
});

/**
 * When selectedApp changes, change:
 * - selectedAppVariants
 * - selectedAppDeployments
 * - selectedAppFineTunings
 * - selectedVariantId
 */
useSelectionStore.subscribe(R.prop('selectedApp'), (selectedApp, prevSelectedApp) => {
  const selectionState = useSelectionStore.getState();
  const { setSelectedAppVariants, setSelectedVariantId, setSelectedAppFinetunes } = selectionState;

  const { getVariantsByAppId } = useVariantStore.getState();
  const { getFinetunesByAppId } = useFinetuneStore.getState();

  // If no selected app, reset all derived state
  if (!selectedApp) {
    resetSelectionStoreState(selectionState);
    return;
  }

  if (prevSelectedApp && selectedApp.id === prevSelectedApp.id) {
    return;
  }

  // Get app variants
  const selectedAppVariants = getVariantsByAppId(selectedApp.id);
  setSelectedAppVariants(selectedAppVariants);

  // Set selected variant to last variant (default sorted by updatedAt)
  setSelectedVariantId(_.last(selectedAppVariants)?.id);

  // Get app finetunes
  const selectedAppFinetunes = getFinetunesByAppId(selectedApp.id);
  setSelectedAppFinetunes(selectedAppFinetunes);
});

useSelectionStore.subscribe(R.prop('selectedAppVariants'), selectedAppVariants => {
  const selectionState = useSelectionStore.getState();
  const { setSelectedAppDeployments } = selectionState;
  const { deploymentById } = useDeploymentStore.getState();

  if (!selectedAppVariants) {
    return;
  }

  // Get app deployments
  const selectedAppVariantById = R.indexBy(R.prop('id'), selectedAppVariants);
  const selectedAppDeployments = Object.values(deploymentById).filter(
    d => selectedAppVariantById[d.variantId],
  );
  setSelectedAppDeployments(selectedAppDeployments);

  // Set selected deployment to first deployment
  // Turned off because it flashes the deployment screen
  // setSelectedDeploymentId(selectedAppDeployments[0]?.id);
});

/**
 * When selectedVariantId changes, update selectedVariant
 */
useSelectionStore.subscribe(R.prop('selectedVariantId'), selectedVariantId => {
  const { setSelectedVariant } = useSelectionStore.getState();
  const { getVariant } = useVariantStore.getState();
  if (!selectedVariantId) {
    setSelectedVariant(undefined);
    return;
  }
  getVariant(selectedVariantId).then(setSelectedVariant);
});

/**
 * When selectedVariant changes, change:
 * - selectedVariantDeployments
 * - selectedDeploymentId
 * - selectedVariantFineTunings
 */
useSelectionStore.subscribe(R.prop('selectedVariant'), selectedVariant => {
  const { setSelectedVariantDeployments, setSelectedDeploymentId, setSelectedVariantFineTunings } =
    useSelectionStore.getState();
  const { deploymentById } = useDeploymentStore.getState();

  if (!selectedVariant) {
    setSelectedVariantDeployments(undefined);
    setSelectedDeploymentId(undefined);
    setSelectedVariantFineTunings(undefined);
    return;
  }

  const selectedVariantDeployments = Object.values(deploymentById).filter(
    d => d.variantId === selectedVariant.id,
  );
  setSelectedVariantDeployments(selectedVariantDeployments);
  setSelectedDeploymentId(selectedVariantDeployments[0]?.id);
});

// External store change effects

/**
 * Update selectedAppVariants when varaintById changes.
 */
useVariantStore.subscribe(R.prop('variantById'), variantById => {
  const {
    selectedApp,
    selectedAppVariants,
    setSelectedAppVariants,
    selectedVariantId,
    setSelectedVariantId,
  } = useSelectionStore.getState();
  const { getVariantsByAppId } = useVariantStore.getState();

  if (!selectedApp) {
    return;
  }

  const nextAppVariants = getVariantsByAppId(selectedApp.id);

  if (!selectedAppVariants || diffArray(selectedAppVariants, nextAppVariants)) {
    setSelectedAppVariants(nextAppVariants);
    return;
  }

  if (!selectedVariantId) {
    setSelectedVariantId(_.last(nextAppVariants)?.id);
  }
});

/**
 * Update selectedAppDeployments when deploymentById changes.
 */
useDeploymentStore.subscribe(R.prop('deploymentById'), deploymentById => {
  const {
    selectedApp,
    selectedAppVariants,
    selectedAppDeployments,
    setSelectedAppDeployments,
    selectedDeploymentId,
  } = useSelectionStore.getState();

  if (!selectedApp) {
    return;
  }
  if (!selectedAppVariants) {
    return;
  }
  const allDeployments = Object.values(deploymentById);
  const selectedAppVariantById = R.indexBy(R.prop('id'), selectedAppVariants);
  const nextAppDeployments = allDeployments.filter(d => selectedAppVariantById[d.variantId]);

  if (!selectedAppDeployments || diffArray(selectedAppDeployments, nextAppDeployments)) {
    setSelectedAppDeployments(nextAppDeployments);
  }
  if (!selectedDeploymentId && selectedAppDeployments) {
    // setSelectedDeploymentId(selectedAppDeployments[0]?.id);
  }
});

/**
 * Update selectedFineTunings when fineTuningById changes.
 */
useFinetuneStore.subscribe(R.prop('finetuneById'), () => {
  const { selectedAppId, setSelectedAppFinetunes } = useSelectionStore.getState();
  const { getFinetunesByAppId } = useFinetuneStore.getState();
  if (!selectedAppId) {
    setSelectedAppFinetunes([]);
  } else {
    const selectedAppFinetunes = getFinetunesByAppId(selectedAppId);
    setSelectedAppFinetunes(selectedAppFinetunes);
  }
});
