import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import create from 'zustand';
import { persist, subscribeWithSelector } from 'zustand/middleware';

import { client } from 'frontend/api/trpc';
import { DEFAULT_STORE_OPTIONS } from 'frontend/clientSideStorage';
import { StoredApp } from 'frontend/stores/types';
import { HasId } from 'frontend/types/types';
import { maybeMergeLeft } from 'frontend/utils/object';

interface AppStore {
  // Data
  appById: Record<string, StoredApp | undefined>;
  setAppById: (appById: Record<string, StoredApp>) => void;
  appendAppById: (appById: Record<string, StoredApp>) => void;

  featuredAppIds: string[];
  setFeaturedAppIds: (featuredAppIds: string[]) => void;

  // CRUD
  getApp: (id?: string) => StoredApp | undefined;
  loadApp: (id: string) => Promise<StoredApp | undefined>;
  listApps: () => StoredApp[];
  listVisibleApps: () => StoredApp[];
  createApp: (app: StoredApp) => void;
  updateApp: (app: Partial<StoredApp> & HasId) => void;
  deleteApp: (id: string) => void;

  // Frontend state
  appsInitLoaded: boolean; // Flag representing if the initial load of apps finished
  setAppByIdInitLoaded: (loaded: boolean) => void;

  featuredAppsInitLoaded: boolean; // Flag representing if public apps have been loaded
  setFeaturedAppsInitLoaded: (loaded: boolean) => void;
}

const indexKey = 'appById';
const indexLens = R.lensProp<AppStore, typeof indexKey>(indexKey);

export const useAppStore = create<AppStore>()(
  subscribeWithSelector(
    persist(
      (set, get) => ({
        appById: {},
        setAppById: (appById: Record<string, StoredApp>) => set({ appById }),
        appendAppById: (appById: Record<string, StoredApp>) =>
          set(R.over(indexLens, R.mergeRight(appById))),
        appsInitLoaded: false as boolean,
        setAppByIdInitLoaded: (loaded: boolean) => set({ appsInitLoaded: loaded }),
        getApp: (id = '') => R.view(indexLens, get())[id],
        loadApp: (id: string) =>
          Promise.resolve(
            R.view(indexLens, get())[id] ||
              client
                .query('app.findUnique', { where: { id } })
                .then(app => app || undefined)
                .then(R.tap(app => app && get().appendAppById({ [app.id]: app }))),
          ),
        listApps: () => RA.compact(R.values(R.view(indexLens, get()))),
        listVisibleApps: () =>
          RA.compact(R.values(R.view(indexLens, get()))).filter(app => !app.isDeleted),
        createApp: (app: StoredApp) => set(R.set(R.lensPath([indexKey, app.id]), app)),
        updateApp: (app: Partial<StoredApp> & HasId) =>
          set(R.over(R.lensPath([indexKey, app.id]) as any, maybeMergeLeft(app))),
        deleteApp: (id: string) => set(R.over(indexLens, R.omit([id]))),

        // Featured apps
        featuredAppIds: [] as string[],
        setFeaturedAppIds: (featuredAppIds: string[]) => set({ featuredAppIds }),
        featuredAppsInitLoaded: false as boolean,
        setFeaturedAppsInitLoaded: (loaded: boolean) => set({ featuredAppsInitLoaded: true }),
      }),
      {
        ...DEFAULT_STORE_OPTIONS('app-store'),
      },
    ),
  ),
);

(window as any).useAppStore = useAppStore;
