import { useCallback, useMemo, useState } from 'react';

import { Box, Button, Typography } from '@mui/material';

import PageTitle from 'frontend/components/PageTitle';
import { SegmentedButtons } from 'frontend/components/SegmentedButtons';
import { VSpace } from 'frontend/components/Spacer';
import { InferenceBatchCreateForm } from 'frontend/components/v2/inferenceBatch/InferenceBatchCreateForm';
import { TABS, useEvaluationsPageState } from 'frontend/models/useEvaluationsPageState';
import PageContainer from 'frontend/pages/PageContainer';
import { InferenceBatchList } from 'frontend/pages/v2/EvaluationsPage/InferenceBatchList';
import { track } from 'frontend/utils/analytics';

import EvaluationResultsModal from './EvaluationResultsModal';
import { EvaluationWizardContainer } from './Wizard/EvaluationWizardContainer';

export function EvaluationsPage() {
  const {
    selectedTab,
    setSelectedTab,
    isCreateEvaluationWizardOpen,
    setIsCreateEvaluationWizardOpen,
    isCreateInferenceBatchOpen,
    setIsCreateInferenceBatchOpen,
  } = useEvaluationsPageState();

  const handleCloseCreateInferenceBatch = useCallback(() => {
    setIsCreateInferenceBatchOpen(false);
  }, [setIsCreateInferenceBatchOpen]);

  const renderContent = () => {
    if (isCreateEvaluationWizardOpen) {
      return (
        <EvaluationWizardContainer handleClose={() => setIsCreateEvaluationWizardOpen(false)} />
      );
    }

    if (isCreateInferenceBatchOpen) {
      return <InferenceBatchCreateForm onClose={handleCloseCreateInferenceBatch} />;
    }

    switch (selectedTab) {
      case 'Evaluation Results':
        return (
          <Box>
            <PageTitle title="Evaluations" />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <SegmentedButtons
                labels={TABS}
                selectedLabel={selectedTab}
                onClick={setSelectedTab}
              />
              <Button
                variant="contained"
                onClick={() => {
                  track('Evaluation Wizard Dialog Opened');
                  setIsCreateEvaluationWizardOpen(true);
                }}
              >
                Create Evaluation
              </Button>
            </Box>
            <VSpace s={1} />
            <EvaluationResultsModal />
          </Box>
        );

      case 'Bulk Inferences':
        return (
          <Box>
            <PageTitle title="Evaluations" />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <SegmentedButtons
                labels={TABS}
                selectedLabel={selectedTab}
                onClick={setSelectedTab}
              />
              <Button
                variant="contained"
                onClick={() => {
                  track('Inference Batch Wizard Opened');
                  setIsCreateInferenceBatchOpen(true);
                }}
              >
                New Bulk Inference
              </Button>
            </Box>
            <VSpace s={2} />
            <InferenceBatchList />
          </Box>
        );
    }
  };

  return <PageContainer page="evaluations">{renderContent()}</PageContainer>;
}
