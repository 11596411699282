import { useEffect, useMemo, useState } from 'react';

import _ from 'lodash';

import { useAppStore } from 'frontend/stores/AppStore';
import { LoadingState, useUserStore } from 'frontend/stores/UserStore';

// This zustand store tracks if the necessary state for the web application to load has been fetched
export default function useAppHydrated() {
  const storesToHydrate = [useAppStore];
  const { loadingState: userLoadingState, user } = useUserStore();

  // isUserLoaded tracks whether the user object has been loaded
  const isUserLoaded = userLoadingState !== LoadingState.Loading;

  // areStoresForInitLoadHydrated tracks whether the data that is not the user object has been loaded
  const [areStoresForInitLoadHydrated, setAreStoresForInitLoadHydrated] = useState<boolean>(
    _.every(storesToHydrate.map(s => s.persist.hasHydrated())),
  );

  useEffect(() => {
    for (const store of storesToHydrate) {
      store.persist.onFinishHydration(() => {
        const storesHydrated = storesToHydrate.map(s => s.persist.hasHydrated());
        setAreStoresForInitLoadHydrated(prevHydrated => prevHydrated || _.every(storesHydrated));
      });
    }
  }, []);

  const canLoadSpellbook = useMemo(
    () => areStoresForInitLoadHydrated && isUserLoaded,
    [areStoresForInitLoadHydrated, isUserLoaded],
  );

  return { canLoadSpellbook, user };
}
