
/**
 * Checks if the object is an Error and checks if the objects message matches.
 */
export function isError(error: unknown, message?: string): error is Error {
  if (error instanceof Error) {
    if (message) {
      return error.message === message;
    }
    return true;
  }
  return false;
}
