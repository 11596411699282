import React, { useCallback, useMemo } from 'react';

import _ from 'lodash';
import * as R from 'ramda';

import { Box } from '@mui/material';
import { EvaluationMethod } from '@prisma/client';
import { ExpertFeedbackErrors } from '@scale/llm-shared/consts';
import {
  EvaluationCreateHumanEvaluation,
  EvaluationType,
} from '@scale/llm-shared/interfaces/evaluation';
import { BaseEvaluationTypeV2, ExpertFeedbackType } from '@scale/llm-shared/types';

import useApi from 'frontend/api/useApi';
import { showGlobalSnackbarError } from 'frontend/components/GlobalSnackbar';
import { VSpace } from 'frontend/components/Spacer';
import { StepProgressBar } from 'frontend/components/StepProgressBar';
import usePageError from 'frontend/hooks/usePageError';
import {
  BinaryWizardStep,
  CustomHumanEvaluationWizardStep,
  EvaluationWizardStep,
  RankingWizardStep,
  useAIFeedbackWizardState,
  useBinaryWizardState,
  useCustomHumanEvaluationWizardState,
  useEvaluationWizardState,
  useRankingWizardState,
} from 'frontend/models/v2/useEvaluationWizardState';
import { useDataStore } from 'frontend/storesV2/DataStore';
import { useEvaluationStore } from 'frontend/storesV2/EvaluationStore';
import { track } from 'frontend/utils/analytics';

import AIFeedbackWizardContainer from './AIFeedbackWizard/AIFeedbackWizardContainer';
import BinaryWizardContainer from './BinaryWizard/BinaryWizardContainer';
import { EvaluationWizardInitJobSkipDeduplicationPopup } from './EvaluationWizardInitJobSkipDeduplicationPopup';
import { EvaluationWizardNavigation } from './EvaluationWizardNavigation';
import RankingWizardContainer from './RankingWizard/RankingWizardContainer';
import { EvaluationWizardSetupStep } from './Steps/SetupStep/EvaluationWizardSetupStep';
import { CustomHumanEvaluationWizardContainer } from './CustomHumanEvaluationWizard/CustomHumanEvaluationWizardContainer';

interface EvaluationWizardContainerProps {
  handleClose: () => void;
}

function getProgrammaticEvaluationType(feedbackType: BaseEvaluationTypeV2) {
  if (feedbackType === EvaluationMethod.Classification) {
    return EvaluationType.ClassificationEvaluation;
  } else if (feedbackType === EvaluationMethod.Mauve) {
    return EvaluationType.MauveEvaluation;
  } else if (feedbackType === EvaluationMethod.AIFeedback) {
    return EvaluationType.AIFeedback;
  } else {
    throw new Error(`Attempting to create non-programmatic evaluation type: ${feedbackType}`);
  }
}

export const EvaluationWizardContainer: React.FC<EvaluationWizardContainerProps> = ({
  handleClose,
}) => {
  const {
    wizardStep,
    setWizardStep,
    feedbackType,
    variantIds,
    dataId,
    humanJobSource,
    shouldShowSubmitEvalWithoutDeduplicationCheckButton,
    setShouldShowSubmitEvalWithoutDeduplicationCheckButton,
    shouldStripWhitespace,
    selectedOutputColumn,
  } = useEvaluationWizardState();

  const { showError } = usePageError();
  const { createEvaluation } = useEvaluationStore();
  const {
    taskDescription: binaryTaskDescription,
    goodInputExample: binaryGoodInput,
    badInputExample: binaryBadInput,
    step: binaryWizardStep,
  } = useBinaryWizardState();
  const { step: customHumanEvaluationWizardStep } = useCustomHumanEvaluationWizardState();
  const { taskDescription: rankingTaskDescription, step: rankingWizardStep } =
    useRankingWizardState();
  const { evaluationCriteria: aiFeedbackTaskDescription } = useAIFeedbackWizardState();
  const { runVariantSpec } = useCustomHumanEvaluationWizardState();

  const { dataById } = useDataStore(R.pick(['dataById']));
  const data = dataId ? dataById[dataId] : undefined;

  const bodyRender = useMemo(() => {
    if (wizardStep === EvaluationWizardStep.Setup) {
      return <EvaluationWizardSetupStep />;
    }

    // Otherwise, we return the correct component for the particular feedback type
    switch (feedbackType) {
      case ExpertFeedbackType.BINARY:
        return <BinaryWizardContainer />;
      case ExpertFeedbackType.RANKING:
        return <RankingWizardContainer />;
      case ExpertFeedbackType.CUSTOM_HUMAN_EVAL:
        return <CustomHumanEvaluationWizardContainer />;
      case EvaluationMethod.AIFeedback:
        return <AIFeedbackWizardContainer />;
      default:
        throw new Error(`Invalid evaluation feedback type: ${feedbackType}`);
    }
  }, [wizardStep, feedbackType]);

  const shouldDisplayBackButton = useMemo(() => {
    return wizardStep !== EvaluationWizardStep.Setup;
  }, [wizardStep]);

  const shouldDisplaySubmitButton = useMemo(() => {
    switch (feedbackType) {
      case ExpertFeedbackType.BINARY:
        return (
          wizardStep !== EvaluationWizardStep.Setup &&
          binaryWizardStep === BinaryWizardStep.Instructions
        );
      case ExpertFeedbackType.RANKING:
        return (
          wizardStep !== EvaluationWizardStep.Setup &&
          rankingWizardStep === RankingWizardStep.Instructions
        );
      case ExpertFeedbackType.CUSTOM_HUMAN_EVAL:
        return (
          wizardStep !== EvaluationWizardStep.Setup &&
          customHumanEvaluationWizardStep === CustomHumanEvaluationWizardStep.RunVariantSpec
        );
      case EvaluationMethod.AIFeedback:
        return wizardStep !== EvaluationWizardStep.Setup;
      case EvaluationMethod.Classification:
      case EvaluationMethod.Mauve:
        return true;
      default:
        return false;
    }
  }, [wizardStep, binaryWizardStep, rankingWizardStep, feedbackType]);

  const stepNumber = useMemo(() => {
    if (wizardStep === EvaluationWizardStep.Setup) return 1;

    switch (feedbackType) {
      case ExpertFeedbackType.BINARY:
        return Object.values(BinaryWizardStep).indexOf(binaryWizardStep) + 2; // 0 index + setup
      case ExpertFeedbackType.RANKING:
        return Object.values(RankingWizardStep).indexOf(rankingWizardStep) + 2;
      case ExpertFeedbackType.CUSTOM_HUMAN_EVAL:
        return (
          Object.values(CustomHumanEvaluationWizardStep).indexOf(customHumanEvaluationWizardStep) +
          2
        );
      case EvaluationMethod.AIFeedback:
        return 2;
      default:
        return 1;
    }
  }, [wizardStep, feedbackType, binaryWizardStep, rankingWizardStep]);

  const handleClickBackButton = useCallback(() => {
    if (wizardStep === EvaluationWizardStep.PostSetup) {
      setWizardStep(EvaluationWizardStep.Setup);
      return;
    }

    // If there are more complex steps, will have to add more logic here
    switch (feedbackType) {
      case ExpertFeedbackType.BINARY:
        setWizardStep(EvaluationWizardStep.Setup);
        return;
      case ExpertFeedbackType.RANKING:
        setWizardStep(EvaluationWizardStep.Setup);
        return;
      case ExpertFeedbackType.CUSTOM_HUMAN_EVAL:
        setWizardStep(EvaluationWizardStep.Setup);
        return;
      default:
        return;
    }
  }, [wizardStep, feedbackType]);

  // Cleanup logic necessary every time we call close
  const onClose = useCallback(() => {
    setWizardStep(EvaluationWizardStep.Setup);
    setShouldShowSubmitEvalWithoutDeduplicationCheckButton(false);
    handleClose();
  }, [setWizardStep, setShouldShowSubmitEvalWithoutDeduplicationCheckButton, handleClose]);

  const createExpertFeedbackJob = useCallback(
    async (skipDeduplication: boolean) => {
      if (
        !variantIds.length ||
        !dataId ||
        !feedbackType ||
        !humanJobSource ||
        !binaryTaskDescription ||
        !binaryGoodInput ||
        !binaryBadInput
      ) {
        console.warn('Improper inputs');
        return;
      }
      if (feedbackType === ExpertFeedbackType.BINARY) {
        const taskCreationResults = await Promise.all(
          variantIds.map(variantId => {
            const evaluationCreate: EvaluationCreateHumanEvaluation = {
              variantId,
              type: EvaluationType.HumanEvaluation,
              inputDataId: dataId,
              humanJobSource,
              humanEvaluationInstruction: {
                taskDescription: binaryTaskDescription,
                goodInput: binaryGoodInput,
                badInput: binaryBadInput,
              },
              skipDeduplication,
            };
            return createEvaluation(evaluationCreate).catch(err => {
              showGlobalSnackbarError(err);
              return { success: false, message: err?.message };
            });
          }),
        );

        if (taskCreationResults.every(result => result.success)) {
          onClose();
        } else {
          if (
            taskCreationResults.some(
              result =>
                !result.success &&
                result.message ===
                  ExpertFeedbackErrors.EXPERT_FEEDBACK_JOB_HAS_EXISTING_VARIANT_DATASET_COMBO,
            )
          ) {
            setShouldShowSubmitEvalWithoutDeduplicationCheckButton(true);
          }
        }
      }
    },
    [
      variantIds,
      dataId,
      feedbackType,
      humanJobSource,
      binaryTaskDescription,
      binaryGoodInput,
      binaryBadInput,
      createEvaluation,
      onClose,
      setShouldShowSubmitEvalWithoutDeduplicationCheckButton,
      showError,
    ],
  );

  const createCustomHumanEvaluationJob = useCallback(async () => {
    const someVariantIdHasNoRunSpec = _.some(variantIds, variantId => !runVariantSpec[variantId]);
    if (!variantIds.length || !dataId || !humanJobSource || someVariantIdHasNoRunSpec) {
      console.warn('Improper inputs');
      return;
    }

    const scopedRunVariantSpecs: Record<string, number> = {};
    variantIds.forEach(variantId => {
      scopedRunVariantSpecs[variantId] = runVariantSpec[variantId];
    });
  }, [variantIds, dataId, runVariantSpec, humanJobSource]);

  const handleClickNextButton = useCallback(async () => {
    if (
      feedbackType !== EvaluationMethod.AIFeedback &&
      Object.values<string>(EvaluationMethod).includes(feedbackType ?? '')
    ) {
      if (!dataId) {
        console.error('No dataset selected, button should be disabled.');
        return;
      }
      if (!selectedOutputColumn) {
        console.error('No output column selected, button should be disabled');
        return;
      }
      for (const variantId of variantIds) {
        if (!feedbackType) {
          console.error('Feedback type was unset in page store.');
          return;
        }
        await createEvaluation({
          type: getProgrammaticEvaluationType(feedbackType) as any,
          variantId,
          inputDataId: dataId,
          expectedDataColumnId: selectedOutputColumn.id,
          trimWhitespace: shouldStripWhitespace,
        })
          .then(() => {
            onClose();
          })
          .catch(err => {
            track('Evaluation Creation Errored', { type: feedbackType });
            showGlobalSnackbarError(err);
          });
      }
      track('Evaluation Created', { type: feedbackType });
      return;
    }
    if (wizardStep === EvaluationWizardStep.Setup) {
      setWizardStep(EvaluationWizardStep.PostSetup);
      return;
    }

    const skipDeduplication = false;
    if (feedbackType === ExpertFeedbackType.BINARY) {
      switch (binaryWizardStep) {
        case BinaryWizardStep.Instructions:
          createExpertFeedbackJob(skipDeduplication);
          track('Evaluation Created', { type: feedbackType });
          return;
        default:
          return;
      }
    }

    if (feedbackType === ExpertFeedbackType.RANKING) {
      switch (rankingWizardStep) {
        case RankingWizardStep.Instructions:
          createExpertFeedbackJob(skipDeduplication);
          track('Evaluation Created', { type: feedbackType });
          return;
        default:
          return;
      }
    }

    if (feedbackType === ExpertFeedbackType.CUSTOM_HUMAN_EVAL) {
      switch (customHumanEvaluationWizardStep) {
        case CustomHumanEvaluationWizardStep.RunVariantSpec:
          createCustomHumanEvaluationJob();
          track('Evaluation Created', { type: feedbackType });
          return;
        default:
          return;
      }
    }

    // TODO (george): add backend here
    if (feedbackType === EvaluationMethod.AIFeedback) {
      if (!dataId) {
        console.error('No dataset selected, button should be disabled.');
        return;
      }
      if (!aiFeedbackTaskDescription) {
        console.error('No AI Feedback evaluation set, button should be disabled');
      }
      for (const variantId of variantIds) {
        await createEvaluation({
          type: EvaluationType.AIFeedback,
          variantId,
          inputDataId: dataId,
          criteria: aiFeedbackTaskDescription,
          trimWhitespace: shouldStripWhitespace,
        })
          .then(() => {
            onClose();
          })
          .catch(err => {
            track('Evaluation Creation Errored', { type: feedbackType });
            showGlobalSnackbarError(err);
          });
        return;
      }
    }
    return;
  }, [
    feedbackType,
    wizardStep,
    dataId,
    selectedOutputColumn,
    variantIds,
    onClose,
    createEvaluation,
    shouldStripWhitespace,
    showError,
    setWizardStep,
    binaryWizardStep,
    createExpertFeedbackJob,
    rankingWizardStep,
  ]);

  const canClickNextButton = useMemo(() => {
    if (wizardStep === EvaluationWizardStep.Setup) {
      const requiredForExpertFeedback = !!dataId && !!humanJobSource;
      const requiredForProgrammaticEvaluation =
        !!dataId && !!data && !!selectedOutputColumn && variantIds.length > 0;
      const requiredForAIFeedbackEvaluation = !!dataId && !!data && variantIds.length == 1;
      switch (feedbackType) {
        case ExpertFeedbackType.BINARY:
          return requiredForExpertFeedback && variantIds.length > 0;
        case ExpertFeedbackType.RANKING:
          return requiredForExpertFeedback && variantIds.length > 1;
        case ExpertFeedbackType.CUSTOM_HUMAN_EVAL:
          return variantIds.length > 0;
        case EvaluationMethod.Classification:
          return requiredForProgrammaticEvaluation;
        case EvaluationMethod.Mauve:
          return requiredForProgrammaticEvaluation && _.size(data.rowByIndex) >= 50;
        case EvaluationMethod.AIFeedback:
          return requiredForAIFeedbackEvaluation;
        default:
          return false;
      }
    }

    const someVariantIdsHaveNoRunSpec = !_.some(
      variantIds,
      variantId => !runVariantSpec[variantId],
    );

    if (wizardStep === EvaluationWizardStep.PostSetup) {
      switch (feedbackType) {
        case ExpertFeedbackType.BINARY:
          return !!binaryTaskDescription && !!binaryGoodInput && !!binaryBadInput;
        case ExpertFeedbackType.RANKING:
          return rankingTaskDescription !== undefined && rankingTaskDescription !== '';
        case EvaluationMethod.AIFeedback:
          return aiFeedbackTaskDescription !== undefined && aiFeedbackTaskDescription !== '';
        case ExpertFeedbackType.CUSTOM_HUMAN_EVAL:
          return !_.some(variantIds, variantId => !runVariantSpec[variantId]);

        default:
          return false;
      }
    }
    return false;
  }, [
    wizardStep,
    dataId,
    humanJobSource,
    data,
    selectedOutputColumn,
    variantIds.length,
    feedbackType,
    binaryTaskDescription,
    binaryGoodInput,
    binaryBadInput,
    rankingTaskDescription,
    aiFeedbackTaskDescription,
    variantIds,
    Object.values(runVariantSpec),
  ]);

  const tooltipText = useMemo(() => {
    if (canClickNextButton) return '';
    if (
      feedbackType === EvaluationMethod.AIFeedback &&
      wizardStep === EvaluationWizardStep.PostSetup
    ) {
      return 'Please specify the criteria for AI feedback';
    }
    if (wizardStep === EvaluationWizardStep.PostSetup) {
      return 'Please specify the task and good/bad parameters';
    }
    switch (feedbackType) {
      case ExpertFeedbackType.BINARY:
        return 'Please select a variant and dataset';
      case ExpertFeedbackType.RANKING:
        return 'Please select a dataset and at least two variants';
      case EvaluationMethod.Classification:
        return 'Please select a variant and a dataset that has an output column';
      case EvaluationMethod.Mauve:
        return 'Please select a variant and a dataset that has an output column and at least 50 rows';
      case EvaluationMethod.AIFeedback:
        return 'Please select a variant and a dataset';
      default:
        return '';
    }
  }, [canClickNextButton, feedbackType]);

  const onClickInitJobSkipDeduplication = useMemo(() => {
    return () => {
      const skipDeduplication = true;
      createExpertFeedbackJob(skipDeduplication);
    };
  }, [createExpertFeedbackJob]);

  const totalSteps = useMemo(() => {
    switch (feedbackType) {
      case ExpertFeedbackType.BINARY:
        return Object.keys(BinaryWizardStep).length + 1;
      case ExpertFeedbackType.RANKING:
        return Object.keys(RankingWizardStep).length + 1;
      case ExpertFeedbackType.CUSTOM_HUMAN_EVAL:
        return Object.keys(CustomHumanEvaluationWizardStep).length + 1;
      case EvaluationMethod.AIFeedback:
        return Object.keys(RankingWizardStep).length + 1;
      case EvaluationMethod.Classification:
      case EvaluationMethod.Mauve:
        return 1;
      default:
        return Object.keys(EvaluationWizardStep).length;
    }
  }, [feedbackType]);

  return (
    <>
      <StepProgressBar
        step={stepNumber}
        maxSteps={totalSteps}
        title={wizardStep}
        onClose={() => {
          onClose();
        }}
      />
      <VSpace s={1} />
      {bodyRender}
      <VSpace s={1} />
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box sx={{ marginLeft: 'auto', marginBottom: 2 }}>
          <EvaluationWizardNavigation
            shouldDisplayBackButton={shouldDisplayBackButton}
            shouldDisplaySubmitButton={shouldDisplaySubmitButton}
            handleClickNextButton={handleClickNextButton}
            handleClickBackButton={handleClickBackButton}
            canClickNextButton={canClickNextButton}
            tooltipText={tooltipText}
          />
        </Box>
        <EvaluationWizardInitJobSkipDeduplicationPopup
          open={shouldShowSubmitEvalWithoutDeduplicationCheckButton}
          handleClose={() => {
            setShouldShowSubmitEvalWithoutDeduplicationCheckButton(false);
          }}
          handleSubmit={onClickInitJobSkipDeduplication}
        />
      </Box>
    </>
  );
};
