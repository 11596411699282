import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Colors } from 'frontend/theme';

const SettingsEditor = styled(Box)(({ theme }) => ({
  minWidth: 220,
  background: Colors.White,
}));

export default SettingsEditor;
