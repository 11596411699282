import { useCallback, useMemo, useState } from 'react';

import _ from 'lodash';
import * as R from 'ramda';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Link, TextField, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { AppVersion, BillingPlan, TaskType } from '@prisma/client';
import { DEFAULT_MODEL_ID } from 'frontend/consts/defaults';

import useApi from 'frontend/api/useApi';
import { TaskTypeSelector } from 'frontend/components/app/TaskTypeSelector';
import { Container } from 'frontend/components/Container';
import { ErrorMessage } from 'frontend/components/ErrorMessage';
import HoverableInfoDialog from 'frontend/components/HoverableInfoDialog';
import ModelSelector from 'frontend/components/model/ModelSelector';
import { VSpace } from 'frontend/components/Spacer';
import { FEATURE_FLAG } from 'frontend/consts/featureFlags';
import { useLLMNavigation } from 'frontend/hooks/useLLMNavigation';
import { useUnleashFlags } from 'frontend/hooks/useUnleashFlags';
import { DEFAULT_TASK_TYPE } from 'frontend/pages/AppsPage/AppCreationWizard/AppCreationWizardTypes';
import { useModelStore } from 'frontend/stores/ModelStore';
import { useUserStore } from 'frontend/stores/UserStore';
import { track } from 'frontend/utils/analytics';

import { PublicAppTermsCheckboxes } from './PublicAppTermsCheckboxes';

const AppCreationContainer = styled(Box)({
  marginTop: 2,
  marginLeft: 'auto',
  marginRight: 'auto',
  width: 640,
});

const AppCreationAction = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
});

export function AppCreationWizard({
  setShowWizard,
}: {
  setShowWizard: (val: boolean) => void;
  onCancel: () => void;
}): JSX.Element {
  const { user, billingPlan } = useUserStore(R.pick(['user', 'billingPlan']));
  if (!user) throw Error('Must be logged in to create an app.');

  const { modelById } = useModelStore(R.pick(['modelById']));
  const { createApp, agreeToPublicApps } = useApi();

  const models = useMemo(
    () => _.sortBy(FEATURE_FLAG.hideModels.filter(Object.values(modelById)), 'createdAt'),
    [modelById],
  );

  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string | null>(null);
  const [modelId, setModelId] = useState<string>(
    (_.find(models, { id: DEFAULT_MODEL_ID }) || models[0]).id,
  );
  const [taskType, setTaskType] = useState<TaskType>(DEFAULT_TASK_TYPE);
  const [agreedToShare, setAgreedToShare] = useState<boolean>(!!user?.agreedToPublicAppsAt);
  const [agreedToTerms, setAgreedToTerms] = useState<boolean>(!!user?.agreedToPublicAppsAt);
  const agreedToAll = useMemo(
    () => !!user?.agreedToPublicAppsAt || (agreedToShare && agreedToTerms),
    [user?.agreedToPublicAppsAt, agreedToShare, agreedToTerms],
  );
  const [isPublic, setIsPublic] = useState(billingPlan === BillingPlan.Free);
  const [appVersion, setAppVersion] = useState<AppVersion>(AppVersion.V2);
  const { checkUnleashFlag } = useUnleashFlags();

  const isFreePlan = useMemo(() => billingPlan === BillingPlan.Free, [billingPlan]);

  const handleCreateApp = useCallback(async () => {
    if (isFreePlan && !agreedToAll) {
      await agreeToPublicApps();
    }

    // Do timer stuff here
    const createdAt = new Date();
    const model = modelById[modelId];

    const app = {
      name,
      shortName: null,
      description,
      readme: null,
      taskType,
      createdAt,
      modifiedAt: createdAt,
      modelId,
      version: appVersion,
      isPublic,
    };
    track('Onboarding App Creation Completed', {
      app_name: name,
      task_type: taskType,
      model: modelId,
      model_name: model?.name,
    });
    createApp.mutateAsync(app).catch(error => {
      track('Onboarding Error Raised', error);
    });
  }, [
    name,
    description,
    taskType,
    modelById,
    modelId,
    isFreePlan,
    agreedToAll,
    appVersion,
    isPublic,
    createApp.mutate,
  ]);

  const handleCloseWizard = useCallback(() => {
    setShowWizard(false);
  }, [setShowWizard]);

  return (
    <AppCreationContainer>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        {/* Title */}
        <Typography variant="h1">App Setup</Typography>
        <Button
          variant="outlined"
          color="info"
          onClick={handleCloseWizard}
          sx={{ borderRadius: 4, aspectRatio: '1', minWidth: 'unset', backgroundColor: 'white' }}
        >
          <FontAwesomeIcon icon="xmark" />
        </Button>
      </Box>
      <VSpace s={2} />
      <Container>
        <Box>
          <Typography variant="h3">Name</Typography>
          <VSpace />
          <TextField
            placeholder="Add super cool app name"
            type="text"
            fullWidth
            value={name}
            onChange={event => setName(event.target.value)}
          />
        </Box>
        <Box>
          <Typography variant="h3">Description</Typography>
          <VSpace />
          <TextField
            placeholder="Add optional description"
            type="text"
            fullWidth
            multiline
            rows={3}
            value={description}
            onChange={event => setDescription(event.target.value)}
          />
        </Box>

        <ErrorMessage error={createApp.error} />
      </Container>
      <VSpace s={2} />
      {isFreePlan && !user?.agreedToPublicAppsAt && (
        <PublicAppTermsCheckboxes
          agreedToShare={agreedToShare}
          setAgreedToShare={setAgreedToShare}
          agreedToTerms={agreedToTerms}
          setAgreedToTerms={setAgreedToTerms}
          isFreePlan={isFreePlan}
        />
      )}
      <VSpace s={2} />
      <AppCreationAction>
        <Tooltip
          followCursor
          title={
            (!name && 'You must name your app first!') ||
            // The following two should theoretically not be possible. Will be overhauled in onboarding v2 anyway.
            (!modelId && 'You must select a model first') ||
            (!taskType && 'You must select a task type first!') ||
            (isFreePlan && !agreedToAll && 'You must agree to the terms first!')
          }
        >
          <span>
            <Button
              color="primary"
              variant="contained"
              disabled={
                !name ||
                !modelId ||
                !taskType ||
                (isFreePlan && !agreedToAll) ||
                createApp.isLoading
              }
              onClick={handleCreateApp}
            >
              Create App
            </Button>
          </span>
        </Tooltip>
      </AppCreationAction>
    </AppCreationContainer>
  );
}
