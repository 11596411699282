import { MouseEventHandler, useCallback, useEffect, useRef, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Divider, Typography } from '@mui/material';

import { Container } from 'frontend/components/Container';
import FlexBox from 'frontend/components/FlexBox';
import { VSpace } from 'frontend/components/Spacer';
import { StoredData, StoredDataColumn } from 'frontend/storesV2/types';
import { Colors } from 'frontend/theme';

import { FineTuningParametersEditor } from './FineTuningPromptParameters';
import { FineTuningPromptTextField } from './FineTuningPromptTextField';

interface FineTuningPageSettingsProps {
  isScaleModel: boolean;
  numEpochs: number;
  setNumEpochs: (epochs: number | ((epochs: number) => number)) => void;
  prompt: string;
  setPrompt: (prompt: string | ((prompt: string) => string)) => void;
  learningRateModifier: number;
  setLearningRateModifier: (
    learningRateModifier: number | ((learningRateModifier: number) => number),
  ) => void;
  trainDataset: StoredData | null;
  outputColumn: StoredDataColumn | undefined;
  setOutputColumn: (newColumn: StoredDataColumn) => void;
  stopSequence: string | undefined;
  setStopSequence: (newStopSequence: string) => void;
}

const minEditorHeight = 100;

export default function FineTuningPageSettings(props: FineTuningPageSettingsProps) {
  const {
    isScaleModel,
    numEpochs,
    setNumEpochs,
    prompt,
    setPrompt,
    learningRateModifier,
    setLearningRateModifier,
    trainDataset,
    outputColumn,
    setOutputColumn,
    stopSequence,
    setStopSequence,
  } = props;

  const [currentNumEpochs, setCurrentNumEpochs] = useState<number>(numEpochs);
  useEffect(() => {
    setCurrentNumEpochs(numEpochs);
  }, [numEpochs]);
  useEffect(() => {
    !Number.isNaN(currentNumEpochs) && setNumEpochs(currentNumEpochs);
  }, [currentNumEpochs, numEpochs, setNumEpochs]);

  const [isGrabbing, setIsGrabbing] = useState<boolean>(false);
  // Drag to adjust height logic -- edits the interal components manually
  const [editorHeight, setEditorHeight] = useState<number>(500);
  const promptEditorRef = useRef<HTMLTextAreaElement>(null);
  const handleDragStart: MouseEventHandler = useCallback(
    e => {
      e.preventDefault();
      const drawerHeightStart = editorHeight;
      const dragStartY = e.clientY;

      const handleDragMove = ((e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
        const dy = dragStartY - e.clientY;
        const newHeight = Math.max(drawerHeightStart - dy, minEditorHeight);
        setEditorHeight(newHeight);
      }) as unknown as EventListener; // TODO fix this later
      setIsGrabbing(true);

      const handleDragEnd = () => {
        setIsGrabbing(false);
        window.removeEventListener('mousemove', handleDragMove);
        window.removeEventListener('mouseup', handleDragEnd);
      };
      window.addEventListener('mousemove', handleDragMove);
      window.addEventListener('mouseup', handleDragEnd);
    },
    [editorHeight, promptEditorRef],
  );

  return (
    <>
      <Typography 
        variant="body1" 
        sx={{ fontSize: 14 }}
      >
        1. The template organizes what input should be passed into the model during training. Fine tuning does not require few-shot examples. 
      </Typography>
      <VSpace s={1} />
      <FlexBox sx={{ width: '100%' }}>
        <Container
          sx={{
            height: editorHeight,
            width: '100%',
            minWidth: 400,
            flexShrink: 1,
            padding: 0,
          }}
        >
          <FineTuningPromptTextField
            value={prompt}
            onChange={setPrompt}
          />
        </Container>
        <Container
          sx={{
            height: editorHeight,
            flexBasis: 350,
            maxWidth: 350,
            minWidth: 260,
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          <FineTuningParametersEditor
            prompt={prompt}
            trainDataset={trainDataset}
            outputColumn={outputColumn}
            setOutputColumn={setOutputColumn}
            isScaleModel={isScaleModel}
            numEpochs={numEpochs}
            setNumEpochs={setNumEpochs}
            learningRateModifier={learningRateModifier}
            setLearningRateModifier={setLearningRateModifier}
            stopSequence={stopSequence}
            setStopSequence={setStopSequence}
          />
        </Container>
      </FlexBox>
      <Box>
        <Divider sx={{ cursor: isGrabbing ? 'grabbing' : 'grab' }} onMouseDown={handleDragStart}>
          <FontAwesomeIcon icon="grip-lines" color={Colors.CoolGray40} />
        </Divider>
      </Box>
    </>
  );
}
