import { useEffect, useMemo, useState } from 'react';

import _ from 'lodash';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, List, ListItem, Typography } from '@mui/material';
import { FrontendFeatureFlag } from '@scale/llm-shared/featureFlags';

import { ActionBanner } from 'frontend/components/ActionBanner';
import { Container } from 'frontend/components/Container';
import FixedWidthCenteredContainer from 'frontend/components/FixedWidthCenteredContainer';
import FlexBox from 'frontend/components/FlexBox';
import LoadingPage from 'frontend/pages/LoadingPage';
import PageTitle from 'frontend/components/PageTitle';
import { VSpace } from 'frontend/components/Spacer';
import { VariantSummary } from 'frontend/components/v2/variant/VariantSummary';
import { useAppStore } from 'frontend/stores/AppStore';
import { useLLMNavigation } from 'frontend/hooks/useLLMNavigation';
import { useUnleashFlags } from 'frontend/hooks/useUnleashFlags';
import { PublicAppShareButton } from 'frontend/pages/ExplorePage/PublicAppShareButton';
import PageContainer from 'frontend/pages/PageContainer';
import { useDataStore } from 'frontend/storesV2/DataStore';
import { useFinetuneStore } from 'frontend/storesV2/FinetuneStore';
import { useSelectionStore } from 'frontend/storesV2/SelectionStore';
import theme, { Colors } from 'frontend/theme';

import { AppEditDialog } from './AppEditDialog';
import * as R from 'ramda';

function ChecklistItem({
  checked,
  to,
  disabled,
  children,
}: {
  checked: boolean;
  to?: string;
  disabled?: boolean;
  children?: React.ReactNode;
}) {
  return (
    <ListItem sx={{ fontSize: 14 }}>
      <FontAwesomeIcon
        icon={checked ? 'check-circle' : ['far', 'circle']}
        style={{
          marginRight: theme.spacing(1),
          color: checked ? Colors.Green : Colors.RemoGreenLight,
        }}
      />
      {to && !disabled && !checked ? <Link to={to}>{children}</Link> : children}
    </ListItem>
  );
}

export function AppOverviewPage() {
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);
  const { finetuneById } = useFinetuneStore();
  const { appsInitLoaded } = useAppStore(R.pick(['appsInitLoaded']));
  const { selectedApp, selectedAppVariants, selectedAppDeployments, selectedAppId } =
    useSelectionStore();
  const { dataById } = useDataStore.getState();
  const { goToAppsPage, goToPromptPage, goToVariantsPage } = useLLMNavigation();
  const { checkUnleashFlag } = useUnleashFlags();

  const hasFinetuned = useMemo(
    () => !!Object.values(finetuneById).find(finetune => finetune.appId === selectedAppId),
    [finetuneById, selectedAppId],
  );

  const publicAppsEnabled = checkUnleashFlag(FrontendFeatureFlag.SpellbookPublicAppsEnabled);

  const exampleVariant = useMemo(
    () => _.maxBy(selectedAppVariants || [], 'updatedAt'),
    [selectedAppVariants],
  );

  if (!appsInitLoaded || !selectedApp) return <LoadingPage />;

  const { createdAt, name, description, shortName } = selectedApp;

  return (
    <PageContainer page="">
      <AppEditDialog app={selectedApp} open={editDialogOpen} setOpen={setEditDialogOpen} />
      <FixedWidthCenteredContainer sx={{ width: 800, maxWidth: 800 }}>
        <PageTitle title="Overview" />
        {Object.keys(selectedAppVariants || []).length === 0 && (
          <>
            <ActionBanner
              icon="wand-sparkles"
              content="We created a prompt for you based on your data."
              buttonText="Check it Out"
              onButtonClick={() => goToPromptPage()}
            />
            <VSpace s={2} />
          </>
        )}
        <Container>
          <FlexBox sx={{ justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <FlexBox sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Typography variant="h3">{name}</Typography>
                {publicAppsEnabled && selectedApp.primaryVariantId && (
                  <Box sx={{ marginLeft: 'auto' }}>
                    <PublicAppShareButton app={selectedApp} variant="outlined" color="primary" />
                  </Box>
                )}
              </Box>
              <Box>
                {description && (
                  <Typography sx={{ whiteSpace: 'pre-wrap' }}>{description}</Typography>
                )}
                {!description && (
                  <Button
                    size="small"
                    variant="text"
                    onClick={() => setEditDialogOpen(true)}
                    sx={{ m: 0, p: 0 }}
                  >
                    <FontAwesomeIcon icon="plus" style={{ marginRight: '1ex' }} />
                    Add a description
                  </Button>
                )}
              </Box>
              <Typography variant="h4">
                Created at {createdAt.toLocaleDateString()} {createdAt.toLocaleTimeString()}
              </Typography>
            </FlexBox>
            <Button size="small" variant="outlined" onClick={() => setEditDialogOpen(true)}>
              Edit
            </Button>
          </FlexBox>
        </Container>
        <VSpace s={2} />
        <Container>
          <Typography variant="h2">Getting Started</Typography>
          <List sx={{ padding: 0 }}>
            <ChecklistItem checked={true}>Create your new application</ChecklistItem>
            <ChecklistItem checked={!!_.values(dataById).length}>
              Define your data inputs and upload your dataset
            </ChecklistItem>
            <ChecklistItem checked={!!selectedAppVariants?.length} to={`/app/${shortName}/prompt`}>
              Create your first prompt and app variant
            </ChecklistItem>
            <ChecklistItem
              to={`/app/${shortName}/prompt`}
              checked={(selectedAppVariants?.length ?? 0) >= 2}
              disabled={!selectedAppVariants?.length}
            >
              Create a second app variant for comparison
            </ChecklistItem>
            <ChecklistItem
              to={`/app/${shortName}/deployments`}
              checked={!!selectedAppDeployments?.length}
              disabled={!selectedAppVariants?.length}
            >
              Deploy your app variant and start making API calls
            </ChecklistItem>
            <ChecklistItem checked={hasFinetuned}>
              Fine-tune your base model for better inference results
            </ChecklistItem>
          </List>
        </Container>
        <VSpace s={2} />
        {exampleVariant && (
          <Container>
            <FlexBox sx={{ justifyContent: 'space-between' }}>
              <Typography variant="h2">Your App Variants</Typography>
              {selectedAppVariants?.length ? (
                <Button variant="contained" onClick={() => goToVariantsPage()}>
                  View All Variants
                </Button>
              ) : (
                <Button variant="contained" onClick={() => goToPromptPage()}>
                  Create a Variant
                </Button>
              )}
            </FlexBox>
            <VariantSummary size="small" variant={exampleVariant} defaultExpanded={true} />
          </Container>
        )}
      </FixedWidthCenteredContainer>
    </PageContainer>
  );
}
