import {
  OpenAiBaseModelDisplayName,
  OpenAiBaseModelFineTuneEndpoint,
  OpenAiBaseModelInternalId,
} from '@scale/llm-shared/modelProviders/openAi/consts';
import { getNumGpt3Tokens } from '@scale/llm-shared/tokenizers/countTokens';
import { ChatMessage } from '@scale/llm-shared/types/openAi';

import type { IOpenAiMultiTurnBaseModel } from '@scale/llm-shared/modelProviders/openAi/types';
const GPT4_TOKENS_PER_MESSAGE = 4;

export const OpenAiGPT4: IOpenAiMultiTurnBaseModel = {
  id: OpenAiBaseModelInternalId.GPT4,
  displayName: OpenAiBaseModelDisplayName[OpenAiBaseModelInternalId.GPT4],
  minTokensAllowed: 0,
  maxTokensAllowed: 8192,
  minTemperatureAllowed: 0,
  maxTemperatureAllowed: 2,
  externalModelId: 'gpt-4',
  costCentsPerMillionPromptTokens: 3000,
  costCentsPerMillionCompletionTokens: 6000,
  finetuneEndpoint: OpenAiBaseModelFineTuneEndpoint[OpenAiBaseModelInternalId.GPT4],

  getNumTokens: (messages: ChatMessage[]) => {
    return (
      getNumGpt3Tokens(messages.map(m => m.content).join('\n')) +
      GPT4_TOKENS_PER_MESSAGE * messages.length
    );
  },
};
