import { useCallback } from 'react';

import { FrontendFeatureFlag } from '@scale/llm-shared/featureFlags';

import { useUserStore } from 'frontend/stores/UserStore';

export function useUnleashFlags() {
  const { booleanFeatureFlags } = useUserStore();
  const checkUnleashFlag = useCallback(
    (flag: FrontendFeatureFlag, defaultValue = false) => {
      const value = booleanFeatureFlags[flag];
      if (value === undefined) {
        return defaultValue;
      }
      return value;
    },
    [booleanFeatureFlags],
  );

  return { checkUnleashFlag };
}
