export const MISSING_VARIANT_INFERENCE_DATA = 'Missing variant input data.';

export const MISSING_INFERENCE_DATA_COLUMNS = 'Missing data columns for inference.';

export const MISSING_STORAGE_DATA_COLUMNS = 'Missing data columns for storage.';

export const WRONG_MODEL_PARAMETER_TYPE = 'Expected different model parameters type.';

export const MAX_ENABLED_DEPLOYMENTS_ERROR_MESSAGE = 'Max number of enabled deployments reached.';

export const NO_PRIVATE_APPS_ERROR_MESSAGE = 'Not allowed to create private apps.';

export const NO_FINE_TUNING_ERROR_MESSAGE = 'Not allowed to fine tune.';

export const NO_PAYMENT_METHOD_ERROR_MESSAGE = 'Missing payment method.';

export const TOO_MANY_INPUT_TOKENS = 'Input contains too many tokens.';
