import {
  OpenAiBaseModelDisplayName,
  OpenAiBaseModelFineTuneEndpoint,
  OpenAiBaseModelInternalId,
} from '@scale/llm-shared/modelProviders/openAi/consts';
import { getNumGpt3Tokens } from '@scale/llm-shared/tokenizers/countTokens';

const CENTS_PER_MILLION_TOKENS = 2000;

import type { IOpenAiSingleTurnBaseModel } from '@scale/llm-shared/modelProviders/openAi/types';
export const OpenAiGPT3TextDavinci003: IOpenAiSingleTurnBaseModel = {
  id: OpenAiBaseModelInternalId.GPT3TextDavinci003,
  displayName: OpenAiBaseModelDisplayName[OpenAiBaseModelInternalId.GPT3TextDavinci003],
  minTokensAllowed: 0,
  maxTokensAllowed: 4096,
  minTemperatureAllowed: 0,
  maxTemperatureAllowed: 2,
  externalModelId: 'text-davinci-003',
  costCentsPerMillionPromptTokens: CENTS_PER_MILLION_TOKENS,
  costCentsPerMillionCompletionTokens: CENTS_PER_MILLION_TOKENS,
  finetuneEndpoint: OpenAiBaseModelFineTuneEndpoint[OpenAiBaseModelInternalId.GPT3TextDavinci003],

  getNumTokens: (prompt: string) => {
    return getNumGpt3Tokens(prompt);
  },
};
