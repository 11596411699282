import { useEffect } from 'react';

import ContentEditable from 'react-contenteditable';

import PromptFieldActions from 'frontend/components/playground/PromptFieldActions';
import PromptFieldLayout from 'frontend/components/playground/PromptFieldLayout';
import {
  IPromptPreset,
  MOBILE_WIDTH_THRESHOLD
} from 'frontend/consts/playground';
import { Colors } from 'frontend/theme';

interface IPromptFieldProps {
  isLoading: boolean;
  prompt: string;
  modelOutput: string;
  selectedPromptPreset: IPromptPreset | undefined;
  onPromptChange: (e: any) => void;
  onSubmit: (e: any) => void;
  onRegenerate: () => void;
  onUndoLast: () => void;
  onKeyDown: (e: any) => void;
}

const PromptField = ({
  isLoading,
  prompt,
  modelOutput,
  selectedPromptPreset,
  onSubmit,
  onUndoLast,
  onRegenerate,
  onPromptChange,
  onKeyDown,
}: IPromptFieldProps) => {
  const onPaste = function (e: any) {
    e.preventDefault();
    const text = e.clipboardData.getData('text/plain');
    document.execCommand('insertText', false, text);
  };

  useEffect(() => {
    const ce = document.querySelector('[contenteditable]');
    if (ce) {
      ce.addEventListener('paste', onPaste);
      return () => {
        ce.removeEventListener('paste', onPaste);
      };
    }
  }, []);

  useEffect(() => {
    const promptFieldElement = document.getElementById('prompt-field');
    if (promptFieldElement) {
      promptFieldElement.scrollTop = promptFieldElement.scrollHeight;
    }
  }, [modelOutput, selectedPromptPreset]);

  return (
    <PromptFieldLayout>
      <ContentEditable
        id="prompt-field"
        className="prompt-field"
        disabled={isLoading}
        placeholder="Spellcast something cool!"
        html={prompt}
        onChange={onPromptChange}
        onKeyDown={onKeyDown}
      />
      <PromptFieldActions
        isLoading={isLoading}
        modelOutput={modelOutput}
        onSubmit={onSubmit}
        onUndoLast={onUndoLast}
        onRegenerate={onRegenerate}
      />
      <style>{`
        [contentEditable=true]:empty:before {
          content: attr(placeholder);
          color: ${Colors.CoolGray50};
        }

        .prompt-field {
          border: 1px solid ${Colors.CoolGray40};
          border-radius: 4px;
          padding: 20px;
          font-size: 18px;
          overflow: auto;
          flex: 1 1 1px;
        }

        @media (max-width: ${MOBILE_WIDTH_THRESHOLD}px) {
          .prompt-field {
            border-radius: 0px;
          }
        }
      `}</style>
    </PromptFieldLayout>
  );
};

export default PromptField;
