import * as R from 'ramda';
import create from 'zustand';
import { persist } from 'zustand/middleware';

import { Model } from '@prisma/client';

import { DEFAULT_STORE_OPTIONS } from 'frontend/clientSideStorage';
import { useSelectionStore } from 'frontend/storesV2/SelectionStore';
import { HasId } from 'frontend/types/types';
import { setStateAction } from 'frontend/utils/store';

interface ModelStore {
  modelById: Record<string, Model>;
  modelsInitLoaded: boolean; // Flag representing if the initial load of models finished

  getModel: (modelId: string) => Model | undefined;
  getModelByName: (name: string) => Model | undefined;
  setModelById: (modelById: Record<string, Model>) => void;
  appendModelById: (modelById: Record<string, Model>) => void;
  setModelByIdInitLoaded: (loaded: boolean) => void;
  createModel: (model: Model) => void;
  updateModel: (model: Partial<Model> & HasId) => void;
  deleteModel: (modelId: string) => void;
}

const indexKey = 'modelById';
const indexLens = R.lensProp<ModelStore, typeof indexKey>(indexKey);
const viewIndex = R.view(indexLens);

export const useModelStore = create<ModelStore>()(
  persist(
    (set, get) => ({
      modelById: {},
      modelsInitLoaded: false,

      getModel: (id: string) => viewIndex(get())[id],
      getModelByName: (name: string) => R.values(viewIndex(get())).find(R.propEq('name', name)),
      setModelById: (modelById: Record<string, Model>) => set({ modelById }),
      appendModelById: (modelById: Record<string, Model>) =>
        set(R.set(indexLens, { ...viewIndex(get()), ...modelById })),
      setModelByIdInitLoaded: (loaded: boolean) => set({ modelsInitLoaded: loaded }),
      createModel: (model: Model) => set(R.set(R.lensPath([indexKey, model.id]), model)),
      updateModel: (model: Partial<Model> & HasId) =>
        set(R.over(R.lensPath([indexKey, model.id]), R.mergeLeft(model))),
      deleteModel: (id: string) => set(R.over(indexLens, R.omit([id]))),
    }),
    {
      ...DEFAULT_STORE_OPTIONS('model-store'),
    },
  ),
);

(window as any).useModelStore = useModelStore;
