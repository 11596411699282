import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';

import { ElevatedContainer } from 'frontend/components/evaluation/EvaluationWizardLayout';
import FlexBox from 'frontend/components/FlexBox';
import theme from 'frontend/theme';

interface InstructionsPanelProps {
  task?: string;
  goodInputExample?: string;
  badInputExample?: string;
}

const InstructionsPanel: React.FC<InstructionsPanelProps> = ({
  task,
  goodInputExample,
  badInputExample,
}) => {
  return (
    <ElevatedContainer>
      <FlexBox justifyContent={'space-between'}>
        <Typography variant="h3"> Instructions </Typography>
        <FontAwesomeIcon
          icon="up-right-and-down-left-from-center"
          size="sm"
          color={theme.palette.primary.main}
          style={{ cursor: 'pointer' }}
        />
      </FlexBox>

      {task ? (
        <Typography> {`Model task: ${task}`} </Typography>
      ) : (
        <Typography fontStyle="italic">
          (Please specify instructions and ranking criteria using the left hand side)
        </Typography>
      )}

      {goodInputExample && (
        <>
          <Typography variant="h3"> Criteria for good output from the model: </Typography>
          <Typography> {goodInputExample} </Typography>
        </>
      )}

      {badInputExample && (
        <>
          <Typography variant="h3"> Criteria for a bad output from the model: </Typography>
          <Typography> {badInputExample} </Typography>
        </>
      )}
    </ElevatedContainer>
  );
};

export default InstructionsPanel;
