// This is a smaller version of the one on scaleapi's create-analytics-wrapper.ts

import { useUserStore } from 'frontend/stores/UserStore';

// helper. TODO: Fill out the full library of actions.
interface IAnalytics {
  identify: (userId: string, traits: any, options?: any) => void;
  page: (category?: string, name?: string, properties?: any, options?: any) => void;
  track: (event: string, properties?: any, options?: any) => void;
}

declare global {
  interface Window {
    analytics: IAnalytics;
  }
}

export function identify(userId: string, traits: any, options?: any) {
  return window.analytics.identify(userId, traits, options);
}

export function page(category?: string, name?: string, properties?: any, options?: any) {
  const userState = useUserStore.getState();
  const email = userState?.user?.metadata?.email;
  return window.analytics.page(category, name, { ...properties, email }, options);
}

export function track(event: string, properties?: any, options?: any) {
  // HACK: Include email in any track calls for integration with Hubspot
  const userState = useUserStore.getState();
  const email = userState?.user?.metadata?.email;
  return window.analytics.track(
    event,
    {
      ...properties,
      pagePath: window.location.pathname,
      email,
    },
    options,
  );
}
