import { useEffect, useMemo, useState } from 'react';

import { useDebounce } from 'react-use';

import { TextField } from '@mui/material';
import { parseQueryVariables } from '@scale/llm-shared/templating';

import FlexBox from 'frontend/components/FlexBox';
import { DataOutputColumnSelector } from 'frontend/components/v2/data/DataColumnNameSelector';
import {
  SettingsContainer,
  SettingsEditor,
  SettingsHeader,
} from 'frontend/pages/v2/PromptPage/PromptParameterSetting';
import { StoredData, StoredDataColumn } from 'frontend/storesV2/types';
import { getDataColumnNames } from 'frontend/utils/data';

import {
  FineTuningPromptVariableChip,
  FineTuningPromptVariableChipProps,
} from './FineTuningPromptVariableChip';

interface FineTuningParametersEditorProps {
  prompt: string;
  trainDataset: StoredData | null;
  outputColumn: StoredDataColumn | undefined;
  setOutputColumn: (newColumn: StoredDataColumn) => void;
  isScaleModel: boolean;
  numEpochs: number;
  setNumEpochs: (epochs: number | ((epochs: number) => number)) => void;
  learningRateModifier: number;
  setLearningRateModifier: (
    learningRateModifier: number | ((learningRateModifier: number) => number),
  ) => void;
  stopSequence: string | undefined;
  setStopSequence: (newStopSequence: string) => void;
}

export function FineTuningParametersEditor(props: FineTuningParametersEditorProps) {
  const {
    prompt,
    trainDataset,
    outputColumn,
    setOutputColumn,
    isScaleModel,
    numEpochs,
    setNumEpochs,
    learningRateModifier,
    setLearningRateModifier,
    stopSequence,
    setStopSequence,
  } = props;

  const [promptVariables, setPromptVariables] = useState<FineTuningPromptVariableChipProps[]>([]);
  const [currentNumEpochs, setCurrentNumEpochs] = useState<number>(numEpochs);
  useEffect(() => {
    setCurrentNumEpochs(numEpochs);
  }, [numEpochs]);
  useEffect(() => {
    !Number.isNaN(currentNumEpochs) && setNumEpochs(currentNumEpochs);
  }, [currentNumEpochs]);

  const dataVariables = useMemo(() => getDataColumnNames(trainDataset), [trainDataset]);

  useDebounce(
    () => {
      const dataVariablesSet = new Set<string>(dataVariables);
      const queryVariablesSet = new Set<string>();
      if (prompt) {
        try {
          const queryVariables = parseQueryVariables(prompt);
          for (const queryVariable of queryVariables) {
            queryVariablesSet.add(queryVariable);
          }
        } catch (err) {
          // Probably an unclosed bracket
        }
      }
      const variables: FineTuningPromptVariableChipProps[] = [];
      for (const queryVariable of queryVariablesSet) {
        variables.push({
          name: queryVariable,
          isDataVariable: dataVariablesSet.has(queryVariable),
          isPromptVariable: true,
        });
      }
      for (const dataVariable of dataVariablesSet) {
        if (!queryVariablesSet.has(dataVariable)) {
          variables.push({
            name: dataVariable,
            isDataVariable: true,
            isPromptVariable: false,
          });
        }
      }
      setPromptVariables(variables);
    },
    200,
    [prompt, dataVariables],
  );
  // Used to retroactively change bottom padding
  const bottomPadding = isScaleModel ? 0 : 3;

  return (
    <FlexBox sx={{ flexDirection: 'column', alignItems: 'stretch', gap: 3, width: 260 }}>
      <SettingsContainer>
        <SettingsHeader
          title="Variables"
          tooltip={
            <>
              Variables are used to parameterize your prompt. They are pieces of text that can be
              used as stand-ins for different values extracted from your data, or when calling your
              app from the API.
            </>
          }
        />
        <SettingsEditor>
          <FlexBox sx={{ gap: 1 }} flexWrap="wrap">
            {promptVariables.map(variable => (
              <FineTuningPromptVariableChip
                key={variable.name + variable.isDataVariable + variable.isPromptVariable}
                {...variable}
                isLinkRequired={!!prompt}
              />
            ))}
          </FlexBox>
        </SettingsEditor>
      </SettingsContainer>
      <SettingsContainer>
        <SettingsHeader
          title="Output Column"
          tooltip={<>An output column helps specify your models behavior to any input.</>}
        />
        <SettingsEditor>
          <DataOutputColumnSelector
            variant="outlined"
            columns={trainDataset ? trainDataset.columns : undefined}
            value={outputColumn}
            onChange={setOutputColumn}
          />
        </SettingsEditor>
      </SettingsContainer>
      <SettingsContainer sx={{ maxWidth: 160 }}>
        <SettingsHeader
          title="Epochs"
          tooltip={
            <>
              The number of times the entire dataset is used as training. This determines how long
              your training will take. If your dataset is small, this number should be higher.
            </>
          }
        />
        <SettingsEditor>
          <TextField
            size="small"
            type="number"
            value={Number.isNaN(currentNumEpochs) ? '' : currentNumEpochs}
            onChange={e => setCurrentNumEpochs(parseInt(e.target.value))}
            onBlur={() => setCurrentNumEpochs(numEpochs)}
          />
        </SettingsEditor>
      </SettingsContainer>
      <SettingsContainer sx={{ paddingBottom: bottomPadding }}>
        <SettingsHeader
          title="Stop Sequence"
          tooltip={
            <>
              A sequence that will tell the fine-tuned model when to stop generating further tokens. The returned
              text will not contain the stop sequence.
            </>
          }
        />
        <SettingsEditor>
          <TextField
            value={stopSequence}
            onChange={e => {
              setStopSequence(e.target.value);
            }}
            size="small"
          />
        </SettingsEditor>
      </SettingsContainer>

      {isScaleModel && (
        <SettingsContainer sx={{ maxWidth: 160 }}>
          <SettingsHeader
            title="Learning Rate Modifier"
            tooltip={
              <>
                The learning rate modifier. Multiplicatively modifies the default recommended
                learning rate. Smaller learning rates are more stable but require more steps to
                converge, while larger learning rates converge faster but are more instable.
              </>
            }
          />
          <SettingsEditor>
            <TextField
              style={{ maxWidth: 160, paddingBottom: 16 }}
              size="small"
              type="number"
              value={learningRateModifier}
              fullWidth
              onChange={e => setLearningRateModifier(Number(e.target.value))}
            />
          </SettingsEditor>
        </SettingsContainer>
      )}
    </FlexBox>
  );
}
