import {
  ChangeEvent,
  ClipboardEvent,
  KeyboardEvent,
  KeyboardEventHandler,
  MutableRefObject,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import 'react-phone-number-input/style.css';

import _ from 'lodash';
import * as R from 'ramda';
import PhoneInput, {
  isValidPhoneNumber,
  parsePhoneNumber,
  Value as E164Number,
} from 'react-phone-number-input';
import { useToggle } from 'react-use';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from '@mui/material';
import { VERIFIED_PHONE_CREDIT_STR } from '@scale/llm-shared/consts/financial';
import { useMutation } from '@tanstack/react-query';

import { client } from 'frontend/api/trpc';
import { ErrorMessage } from 'frontend/components/ErrorMessage';
import theme, { Colors } from 'frontend/theme';

function SingleCharInput({
  refs,
  value,
  index,
  onChange,
  onPaste,
}: {
  refs?: MutableRefObject<HTMLInputElement[]>;
  value: string;
  index: number;
  onChange: (value: string, index: number) => void;
  onPaste: (value: string, index: number) => void;
}): JSX.Element {
  const [focused, setFocused] = useState(false);
  const setFocusedTrue = useCallback(() => setFocused(true), [setFocused]);
  const setFocusedFalse = useCallback(() => setFocused(false), [setFocused]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Backspace') {
        if (value) {
          onChange('', index);
        } else {
          onChange(event.key, index);
        }
      } else if (event.key === 'ArrowLeft') {
        onChange(event.key, index);
      } else if (event.key === 'ArrowRight') {
        onChange(event.key, index);
      } else if (!event.metaKey) {
        onChange(event.key, index);
      }
    },
    [index, onChange, value],
  );

  const appendRefs = useCallback(
    (ref: HTMLInputElement) => {
      if (refs?.current) {
        refs.current[index] = ref;
      }
    },
    [refs?.current, index],
  );

  const handlePaste = useCallback(
    (event: ClipboardEvent) => {
      const text = event.clipboardData.getData('text');
      onPaste(text, index);
    },
    [onPaste, index],
  );

  return (
    <input
      ref={appendRefs}
      value={value}
      style={{
        border: 'none',
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        borderBottom: `2px solid ${
          focused || value ? theme.palette.primary.main : Colors.CoolGray30
        }`,
        backgroundColor: Colors.CoolGray10,
        width: '1.5ch',
        boxSizing: 'content-box',
        padding: 8,
        color: theme.palette.primary.main,
        outline: 'none',
        textAlign: 'center',
        fontWeight: 'bold',
      }}
      maxLength={1}
      onKeyDown={handleKeyDown}
      onPaste={handlePaste}
      onFocus={setFocusedTrue}
      onBlur={setFocusedFalse}
    />
  );
}

function focusPrev(array: HTMLElement[], index: number) {
  const element = _.findLast(array.slice(0, index), e => e) as any;
  if (element) {
    element.focus();
    element.setSelectionRange(1, 1);
  }
}

function focusNext(array: HTMLElement[], index: number) {
  const element = _.find(array.slice(index + 1), e => e) as any;
  if (element) {
    element.focus();
    element.setSelectionRange(1, 1);
  }
}

function ShortCodeInput({
  count,
  onChange,
}: {
  count: number;
  onChange: (value: string) => void;
}): JSX.Element {
  const [input, setInput] = useState<string[]>([]);
  const inputRefs = useRef<HTMLInputElement[]>([]);

  useEffect(() => {
    setInput(_.fill(Array(count), ''));
  }, [count]);

  useEffect(() => {
    onChange(input.join(''));
  }, [input]);

  const handleChange = useCallback(
    (value: string, index: number) => {
      if (value === 'Backspace') {
        // Focus previous
        focusPrev(inputRefs.current, index);
        return;
      }

      if (value === 'ArrowRight') {
        focusNext(inputRefs.current, index);
        return;
      }

      if (value === 'ArrowLeft') {
        // Focus previous
        focusPrev(inputRefs.current, index);
        return;
      }

      setInput(input => {
        input[index] = value;
        return [...input];
      });
      if (value && index !== input.length - 1) {
        // Focus next element
        focusNext(inputRefs.current, index);
      }
    },
    [setInput, inputRefs, onChange],
  );

  const handlePaste = useCallback(
    (text: string, index: number) => {
      setInput(input => {
        const truncText = text.slice(0, Math.max(count, text.length + index));
        input.splice(index, truncText.length, ...truncText);
        focusPrev(inputRefs.current, text.length + index + 1);
        return [...input];
      });
    },
    [setInput],
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', flexShrink: 0, gap: '4px' }}>
      {R.range(0, count).map(i => {
        return (
          <SingleCharInput
            key={i}
            refs={inputRefs}
            index={i}
            value={input[i]}
            onPaste={handlePaste}
            onChange={handleChange}
          />
        );
      })}
    </Box>
  );
}

/**
 * Controlled phone number verfication modal
 */
export function PhoneVerificationModal({
  open,
  onClose,
  onVerify,
}: {
  open: boolean;
  onClose: () => void;
  onVerify: () => void;
}): JSX.Element {
  // State

  const [phoneNumber, setPhoneNumber] = useState<E164Number | undefined>();
  const validPhoneNumber = useMemo(() => {
    return phoneNumber ? isValidPhoneNumber(phoneNumber) : false;
  }, [phoneNumber]);

  const [otpSent, setOtpSent] = useState(false);
  const setOtpSentTrue = useCallback(() => setOtpSent(true), [setOtpSent]);
  const setOtpSentFalse = useCallback(() => setOtpSent(false), [setOtpSent]);

  const [verified, setVerified] = useState(false);
  const setVerifiedTrue = useCallback(() => setVerified(true), [setVerified]);
  const setVerifiedFalse = useCallback(() => setVerified(false), [setVerified]);

  const [code, setCode] = useState('');
  const validCode = code.length === 6;

  // Api

  const sendOtpMutation = useMutation({
    mutationFn: ({ e164 }: { e164: E164Number | undefined }) => {
      const parsed = parsePhoneNumber(e164 as string);
      if (!parsed) {
        return;
      }
      const { countryCallingCode: countryCode, nationalNumber: phoneNumber } = parsed;
      return client.mutation('billing.sendPhoneVerificationOtp', {
        countryCode,
        phoneNumber,
      }) as any;
    },
  });

  const checkOtpMutation = useMutation({
    mutationFn: ({ code, e164 }: { code: string; e164: E164Number | undefined }) => {
      const parsed = parsePhoneNumber(e164 as string);
      if (!parsed) {
        return;
      }
      const { countryCallingCode: countryCode, nationalNumber: phoneNumber } = parsed;
      return client.mutation('billing.checkPhoneVerificationOtp', {
        countryCode,
        phoneNumber,
        verificationCode: code,
      }) as any;
    },
  });

  // Handlers

  const handleSendOtp = useCallback(() => {
    sendOtpMutation.mutateAsync({ e164: phoneNumber }).then(() => {
      setOtpSentTrue();
    });
  }, [setOtpSentTrue, phoneNumber, sendOtpMutation]);

  const handleCheckOtp = useCallback(() => {
    checkOtpMutation.mutateAsync({ e164: phoneNumber, code }).then(() => {
      setVerifiedTrue();
      onVerify();
    });
  }, [setVerifiedTrue, phoneNumber, code, onVerify, checkOtpMutation]);

  const handleClose = useCallback(() => {
    sendOtpMutation.reset();
    checkOtpMutation.reset();
    onClose();
  }, [onClose, sendOtpMutation, checkOtpMutation]);

  const handleOtpSentGoBack = useCallback(() => {
    sendOtpMutation.reset();
    checkOtpMutation.reset();
    setOtpSentFalse();
  }, [setOtpSentFalse, sendOtpMutation, checkOtpMutation]);

  // Render

  if (verified) {
    return (
      <Dialog open={open} onClose={onClose} maxWidth="xs">
        <DialogTitle>Verification Successful!</DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            You've been successfully verified!
            <br />
            We've put {VERIFIED_PHONE_CREDIT_STR} in free credit into your account.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  if (otpSent) {
    return (
      <Dialog open={open} onClose={onClose} maxWidth="xs">
        <DialogTitle>One-Time Password Sent</DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            Please enter code sent to your number.
          </Typography>
          <Box>
            <ShortCodeInput count={6} onChange={setCode} />
          </Box>
          <ErrorMessage error={checkOtpMutation.error} />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            disabled={!validCode || checkOtpMutation.isLoading}
            onClick={handleCheckOtp}
          >
            Continue
          </Button>
          <Button color="info" variant="text" onClick={handleOtpSentGoBack}>
            Go back
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs">
      <DialogTitle>Verify your phone number</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography variant="body1">
          Unlock {VERIFIED_PHONE_CREDIT_STR} in free credit once you've been verified!
          <br />
          We will text you a code to verify your number.
        </Typography>
        <Box
          sx={{
            width: 'min-content',
            '& > .PhoneInput': {
              borderRadius: 1,
              border: `1px solid ${theme.palette.info.light}`,
            },
            '& > .PhoneInput > *': {
              padding: 1,
            },
            '& .PhoneInputInput': {
              border: 'none',
              borderRadius: 1,
            },
          }}
        >
          <PhoneInput
            withCountryCallingCode
            countryCallingCodeEditable
            defaultCountry="US"
            value={phoneNumber}
            onChange={setPhoneNumber}
          />
        </Box>
        <ErrorMessage error={sendOtpMutation.error} />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          disabled={!validPhoneNumber || sendOtpMutation.isLoading}
          onClick={handleSendOtp}
        >
          Send code
        </Button>
        <Button color="info" variant="text" onClick={onClose}>
          Skip for now
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/**
 * Uncontrolled phone number verification modal
 */
export function VerifyPhoneNumberButton({ onVerify }: { onVerify: () => void }): JSX.Element {
  const [open, toggleOpen] = useToggle(false);

  return (
    <>
      <Button color="secondary" variant="contained" onClick={toggleOpen}>
        Verify Phone Number
      </Button>
      <PhoneVerificationModal open={open} onClose={toggleOpen} onVerify={onVerify} />
    </>
  );
}
