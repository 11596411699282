export const Ai21BaseModelInternalId = {
  J1Large: 'J1Large',
  J1Grande: 'J1Grande',
  J1Jumbo: 'J1Jumbo',
  J2Large: 'J2Large',
  J2Grande: 'J2Grande',
  J2Jumbo: 'J2Jumbo',
  J2GrandeInstruct: 'J2GrandeInstruct',
  J2JumboInstruct: 'J2JumboInstruct',
} as const;

export type Ai21BaseModelInternalId =
  typeof Ai21BaseModelInternalId[keyof typeof Ai21BaseModelInternalId];

export const Ai21BaseModelDisplayName: Record<Ai21BaseModelInternalId, string> = {
  J1Large: 'AI21 J1 Large',
  J1Grande: 'AI21 J1 Grande',
  J1Jumbo: 'AI21 J1 Jumbo',
  J2Large: 'AI21 J2 Large',
  J2Grande: 'AI21 J2 Grande',
  J2Jumbo: 'AI21 J2 Jumbo',
  J2GrandeInstruct: 'AI21 J2 Grande Instruct',
  J2JumboInstruct: 'AI21 J2 Jumbo Instruct',
} as const;

export type Ai21BaseModelDisplayName =
  typeof Ai21BaseModelDisplayName[keyof typeof Ai21BaseModelDisplayName];

export function isAi21BaseModelInternalId(modelId: string): modelId is Ai21BaseModelInternalId {
  return modelId in Ai21BaseModelInternalId;
}
