import * as R from 'ramda';
import { useLocalStorage } from 'react-use';

import { Alert, Box, Link, Snackbar } from '@mui/material';
import { styled, SxProps, ThemeProvider } from '@mui/material/styles';
import { FrontendVariantFeatureFlag } from '@scale/llm-shared/featureFlags';

import { GlobalSnackbar } from 'frontend/components/GlobalSnackbar';
import { PageName } from 'frontend/components/navigation';
import { LOGIN_REDIRECT_URL } from 'frontend/consts/authRedirectUrls';
import { ApiState, useApiConnectError } from 'frontend/hooks/useApiConnectError';
import useAppHydrated from 'frontend/hooks/useAppHydrated';
import { useLLMNavigation } from 'frontend/hooks/useLLMNavigation';
import LoadingPage from 'frontend/pages/LoadingPage';
import LoggedOutPage from 'frontend/pages/LoggedOutPage';
import PageNavigationDrawer from 'frontend/pages/PageContainer/PageNavigationDrawer';
import { usePageErrorStore } from 'frontend/stores/PageErrorStore';
import { useSelectionStore } from 'frontend/storesV2/SelectionStore';
import { useUserStore } from 'frontend/stores/UserStore';
import theme from 'frontend/theme';
import { useEffect } from 'react';
import { useAppStore } from 'frontend/stores/AppStore';

const drawerWidth = 250;

const ContentMain = styled('main', { shouldForwardProp: prop => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  padding: theme.spacing(6),
  ...(open
    ? {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: `${drawerWidth}px`,
        width: `calc(100% - ${drawerWidth}px)`,
      }
    : {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0,
        width: '100%',
      }),
}));

export default function PageContainer({
  page,
  hideSidebar,
  noRedirect,
  noRequireApp,
  children,
  sx,
}: {
  page: PageName;
  hideSidebar?: boolean;
  // Redirects user to logged out page if not true
  noRedirect?: boolean;
  // Redirects user to apps page if true
  noRequireApp?: boolean;
  children?: React.ReactNode;
  sx?: SxProps;
}) {
  const { appsInitLoaded } = useAppStore(R.pick(['appsInitLoaded']));
  const [drawerOpen, setDrawerOpen] = useLocalStorage<boolean>('spellbook-drawer-open', true);
  const { canLoadSpellbook, user } = useAppHydrated();
  const { stringFeatureFlags } = useUserStore();
  const { connectionState } = useApiConnectError();
  const { pageError, setPageError, hideBanner, setHideBanner } = usePageErrorStore();
  const { goToHomePage } = useLLMNavigation();
  const { selectedApp, selectedAppId } = useSelectionStore();

  if (!canLoadSpellbook) {
    return <LoadingPage />;
  }

  if (canLoadSpellbook && !user && !noRedirect) {
    return <LoggedOutPage />;
  }

  // Requires app and no selected app, then redirect
  if (!noRequireApp && !selectedAppId) {
    goToHomePage();
    return <></>;
  }
  const bannerMessage = stringFeatureFlags[FrontendVariantFeatureFlag.SpellbookBannerMessage];

  const userMadeUnauthorizedRequest = connectionState === ApiState.Unauthorized;

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            display: 'flex',
            minHeight: '100vh',
            bgcolor: theme.palette.background.default,
          }}
        >
          <Snackbar
            open={userMadeUnauthorizedRequest}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert elevation={5} severity="error">
              Your Scale session has expired.{' '}
              <Link href={LOGIN_REDIRECT_URL} sx={{ textDecoration: 'none' }}>
                Please log in to continue.
              </Link>
            </Alert>
          </Snackbar>
          <Snackbar
            open={!userMadeUnauthorizedRequest && pageError !== null}
            onClose={() => setPageError(null)}
            autoHideDuration={15000}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert elevation={5} severity="warning">
              {pageError}
            </Alert>
          </Snackbar>
          <Snackbar
            open={!!bannerMessage && !hideBanner}
            onClose={() => setHideBanner(true)}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert elevation={5} severity="warning">
              {bannerMessage}
            </Alert>
          </Snackbar>
          <ContentMain open={!hideSidebar && drawerOpen} sx={sx}>
            {children}
          </ContentMain>
          <PageNavigationDrawer
            currentPage={page}
            drawerWidth={drawerWidth}
            open={!!drawerOpen}
            setDrawerOpen={setDrawerOpen}
            hidden={!!hideSidebar}
          />
          <Snackbar open={connectionState === ApiState.Retrying}>
            <Alert elevation={5} severity="error">
              Server connection lost. Attempting to reconnect...
            </Alert>
          </Snackbar>
          <GlobalSnackbar />
        </Box>
      </ThemeProvider>
    </>
  );
}
