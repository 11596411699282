import { useCallback, useEffect, useMemo, useState } from 'react';

import _, { isNil } from 'lodash';
import * as R from 'ramda';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  FormControl,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  OutlinedInput,
  Paper,
  Skeleton,
  styled,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DEPLOYMENT_ROUTE, DEPLOYMENT_ROUTE_V2 } from '@scale/llm-shared/consts';
import { parseQueryVariables } from '@scale/llm-shared/templating/promptsV2';
import { useQuery } from '@tanstack/react-query';

import { absoluteApiHref, client } from 'frontend/api/trpc';
import { Code } from 'frontend/components/Code';
import { Container } from 'frontend/components/Container';
import FlexBox from 'frontend/components/FlexBox';
import { HSpace, VSpace } from 'frontend/components/Spacer';
import { MultiInput } from 'frontend/pages/v2/PromptPage/types';
import { useDeploymentStore } from 'frontend/storesV2/DeploymentStore';
import { StoredDeployment } from 'frontend/storesV2/types';
import { useVariantStore } from 'frontend/storesV2/VariantStore';
import theme, { Colors } from 'frontend/theme';

const DEPLOYMENT_URL = absoluteApiHref + '/' + DEPLOYMENT_ROUTE;

// We use double quotes to surround the outside, so we only need to escape double quotes and other troublesome json characters like \
// Note that we still need to fix usage for characters like @ and an intentional double backslash (\\)
function escapeCharacters(s: string): string {
  const escaped = s.replace(/`/g, '\\`').replace(/\\/g, '\\\\\\');
  return escaped;
}

function formatBash(input: MultiInput): string {
  const columns = Object.entries(input).map(
    ([columnName, value]) =>
      `    \\"${escapeCharacters(columnName)}\\": \\"${escapeCharacters(value)}\\"`,
  );
  return '{\n  \\"input\\": {\n' + columns.join(',\n') + '\n  }\n}';
}

function formatPython(input: MultiInput): string {
  const columns = Object.entries(input).map(
    ([columnName, value]) => `  "${escapeCharacters(columnName)}": "${escapeCharacters(value)}"`,
  );
  return '{\n' + columns.join(',\n') + '\n}';
}

function curlDeploymentCli(uri: string, accessKey: string, input: MultiInput) {
  const curlStr = `
curl ${uri} \\
  -H "Content-Type: application/json" \\
  -H "Authorization: Basic ${accessKey}" \\
  -d \\
"${formatBash(input)}"
`.trim();
  return curlStr;
}

function pythonCodeSample(uri: string, accessKey: string, input: MultiInput) {
  const uriString = JSON.stringify(uri);
  const dataString = JSON.stringify({ input }, null, 2);
  const headers = JSON.stringify({ Authorization: `Basic ${accessKey}` });
  return `
import requests

data = ${dataString}
headers = ${headers}
response = requests.post(
  ${uriString},
  json=data,
  headers=headers
)
print(response.json())
  `.trim();
}

function nodeCodeSample(uri: string, accessKey: string, input: MultiInput) {
  const uriString = JSON.stringify(uri);
  const dataString = JSON.stringify({ input }, null, 2);
  return `
import fetch from 'node-fetch';

const data = ${dataString};

fetch(${uriString}, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Basic ${accessKey}'
  },
  body: JSON.stringify(data) }
).then(resp => resp.json()
).then(inference => console.log(inference));
  `.trim();
}

type Language = 'cURL' | 'Python' | 'Node (fetch)';

interface CodeSample {
  language: Language;
  syntax: string;
  displayFunction: (uri: string, accessKey: string, input: MultiInput) => string;
}

const CodeSamples: CodeSample[] = [
  {
    language: 'cURL',
    syntax: 'sh',
    displayFunction: curlDeploymentCli,
  },
  {
    language: 'Python',
    syntax: 'python',
    displayFunction: pythonCodeSample,
  },
  {
    language: 'Node (fetch)',
    syntax: 'javascript',
    displayFunction: nodeCodeSample,
  },
];

const CardHeader = styled(Box)({
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: 2,
  backgroundColor: Colors.CoolGray10,
  borderBottom: `1px solid ${Colors.CoolGray20}`,
});

export function DeploymentMultiInputApiCodeSample({
  deployment,
}: {
  deployment: StoredDeployment;
}) {
  const { uri, accessKey: deploymentAccessKey } = deployment;
  const deploymentUri = absoluteApiHref + '/' + DEPLOYMENT_ROUTE_V2 + '/' + uri;

  const [testInput, setTestInput] = useState<{ [columnName: string]: string }>({});

  const [showCopied, setShowCopied] = useState<boolean>(false);

  const [selectedLanguage, setSelectedLanguage] = useState<Language>('cURL');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const exampleLangOpen = Boolean(anchorEl);

  const { getVariant } = useVariantStore(R.pick(['getVariant']));
  const variantQuery = useQuery(['getVariant', deployment.variantId], () =>
    getVariant(deployment.variantId),
  );

  const queryVariables = useMemo(() => {
    if (!variantQuery.data) {
      return [];
    }
    const queryVariables = parseQueryVariables(variantQuery.data.prompt.template);
    const emptyInput = R.fromPairs(queryVariables.map(v => [v, '']));
    setTestInput(emptyInput);
    return queryVariables;
  }, [variantQuery.data, deployment.id]);

  const selectedCode = useMemo(
    () => _.find(CodeSamples, { language: selectedLanguage }) as CodeSample,
    [selectedLanguage],
  );

  const handleOpenMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      setAnchorEl(null);
    },
    [setAnchorEl],
  );

  const deploymentStatsQuery = useQuery(
    ['v2.deployment.getDeploymentStats', deployment.id],
    () => client.query('v2.deployment.getDeploymentStats', { deploymentId: deployment.id }),
    { enabled: false },
  );
  const logsQuery = useQuery(
    ['v2.deployment.getRecentLogs', deployment.id],
    () => client.query('v2.deployment.getRecentLogs', { deploymentId: deployment.id }),
    { enabled: false },
  );

  const testDeploymentQuery = useQuery(
    ['testDeployment', deployment.id],
    () => {
      return fetch(deploymentUri, {
        method: 'post',
        headers: {
          Accept: 'application/json',
          Authorization: `Basic ${deploymentAccessKey}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ input: testInput, testMode: true }),
      }).then(response => {
        return response.text().then(text => {
          if (!response.ok) {
            throw new Error(text || response.statusText);
          }
          return text;
        });
      });
    },
    { enabled: false, keepPreviousData: false, retry: false },
  );
  const handleTestDeployment = useCallback(
    (e?: React.SyntheticEvent) => {
      e?.preventDefault();
      testDeploymentQuery.refetch().then(() => {
        deploymentStatsQuery.refetch();
        logsQuery.refetch();
      });
    },
    [testDeploymentQuery.refetch, deploymentStatsQuery.refetch, logsQuery.refetch],
  );

  const copyCodeToClipboard = useCallback(
    (e: React.MouseEvent) => {
      const code = selectedCode.displayFunction(deploymentUri, deploymentAccessKey, testInput);
      navigator.clipboard.writeText(code);
      setShowCopied(true);
      setTimeout(() => setShowCopied(false), 2000);
      e.stopPropagation();
    },
    [selectedCode, deploymentUri, deploymentAccessKey, testInput],
  );

  const outputElement = useMemo(() => {
    if (testDeploymentQuery.isFetching) {
      return <CircularProgress size="1em" />;
    }
    if (testDeploymentQuery.isSuccess) {
      try {
        const returnObj = JSON.parse(testDeploymentQuery.data);
        const rendered = JSON.stringify(returnObj, null, 2);
        return <Code style={{ width: '100%' }} value={rendered} language="json" useDiv />;
      } catch (err) {
        return <Code style={{ width: '100%' }} value={(err as Error).message} language="plaintext" useDiv/>;
      }
    }
    if (testDeploymentQuery.isError || testDeploymentQuery.error) {
      return <Code value={String(testDeploymentQuery.error)} language="plaintext" />;
    }
    return (
      <Typography variant="subtitle2">Try out your deployment by pressing Run Example!</Typography>
    );
  }, [testDeploymentQuery]);

  return (
    <FlexBox sx={{ width: '100%', height: 'min-content', alignItems: 'stretch' }}>
      <Card variant="outlined" sx={{ width: '33%' }}>
        <CardHeader sx={{ justifyContent: 'space-between', height: 40 }}>
          <Menu anchorEl={anchorEl} open={exampleLangOpen} onClose={handleClose}>
            <MenuList dense disablePadding>
              {CodeSamples.map(cs => (
                <MenuItem
                  key={cs.language}
                  value={cs.language}
                  selected={selectedLanguage === cs.language}
                  onClick={() => {
                    setSelectedLanguage(cs.language);
                    setAnchorEl(null);
                  }}
                >
                  {cs.language}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
          <Button color="info" size="small" onClick={handleOpenMenuClick}>
            {selectedLanguage}
            <FontAwesomeIcon icon="caret-down" style={{ marginLeft: '1ex' }} />
          </Button>
          <Tooltip title={showCopied ? 'Copied!' : 'Copy code to clipboard'} placement="top">
            <IconButton onClick={copyCodeToClipboard}>
              <FontAwesomeIcon icon={['far', 'copy']} style={{ fontSize: 12 }} />
            </IconButton>
          </Tooltip>
        </CardHeader>
        <CardContent>
          <Box p={0.5}>
            <Code
              style={{ width: '100%' }}
              value={
                selectedCode.displayFunction(deploymentUri, deploymentAccessKey, testInput) + '\n\n'
              }
              language={selectedCode.syntax}
              useDiv
            />
          </Box>
        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ width: '33%' }}>
        <CardHeader sx={{ justifyContent: 'flex-start', height: 40 }}>
          <Typography variant="h4">Example input</Typography>
        </CardHeader>
        <div>
          <form onSubmit={handleTestDeployment}>
            <CardContent>
              <FlexBox sx={{ flexDirection: 'column', alignItems: 'stretch', gap: 2 }} gap={2}>
                {queryVariables.map(columnName => (
                  <Box key={columnName}>
                    <Typography variant="code1">{columnName}</Typography>
                    <VSpace s={0.5} />
                    <TextField
                      key={columnName}
                      variant="outlined"
                      value={testInput[columnName] || ''}
                      onChange={e =>
                        setTestInput({
                          ...testInput,
                          [columnName]: e.target.value,
                        })
                      }
                      autoComplete="off"
                      aria-autocomplete="none"
                      size="small"
                      fullWidth
                      placeholder={columnName}
                      InputProps={{
                        sx: { fontFamily: 'monospace' },
                      }}
                    />
                  </Box>
                ))}
              </FlexBox>
            </CardContent>
            <CardActions sx={{ justifyContent: 'center' }}>
              <Button type="submit" size="small" variant="outlined">
                Run Example
              </Button>
            </CardActions>
          </form>
        </div>
      </Card>

      <Card variant="outlined" sx={{ width: '33%' }}>
        <CardHeader sx={{ justifyContent: 'flex-start', height: 40 }}>
          <Typography variant="h4">Example output</Typography>
        </CardHeader>
        <CardContent>
          <FlexBox
            sx={{
              justifyContent: 'space-between',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Box sx={{ alignSelf: 'flex-start' }}>{outputElement}</Box>
          </FlexBox>
        </CardContent>
      </Card>
    </FlexBox>
  );
}

function useHandleEvent<T extends string>(setFn: (val: T) => void) {
  return useCallback(
    (e: { target: { value: string } }) => {
      setFn(e.target.value as T);
    },
    [setFn],
  );
}
