import React, { useMemo, useState } from 'react';

import _ from 'lodash';

import { Box, Button, TextField, Typography } from '@mui/material';

import {
  ElevatedContainer,
  EvaluationWizardCell,
  EvaluationWizardGrid,
} from 'frontend/components/evaluation/EvaluationWizardLayout';
import FlexBox from 'frontend/components/FlexBox';
import { VSpace } from 'frontend/components/Spacer';
import {
  useBinaryWizardState,
  useEvaluationWizardState,
} from 'frontend/models/v2/useEvaluationWizardState';
import { useDataStore } from 'frontend/storesV2/DataStore';
import { useVariantStore } from 'frontend/storesV2/VariantStore';
import theme from 'frontend/theme';

import InstructionsPanel from '../InstructionsPanel';
import PricingEstimateTable from '../PricingEstimateTable';
import { COST_PER_ITEM, HOURS_PER_ITEM } from './BinaryWizardContainer';

const BinaryWizardInstructionStep: React.FC = () => {
  return (
    <EvaluationWizardGrid>
      <BinaryWizardInstructionHeader />
      <BinaryWizardInstructionBody />
    </EvaluationWizardGrid>
  );
};

const BinaryWizardInstructionHeader: React.FC = () => {
  return (
    <>
      <EvaluationWizardCell sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography variant="h2"> Define Instructions </Typography>
        <Typography variant="body2"> Input tasks and good / bad parameters </Typography>
      </EvaluationWizardCell>
      <EvaluationWizardCell sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Typography variant="h2"> Evaluation Preview </Typography>
        <Typography variant="body2">
          Based on the fields on the left, this is what the expert will see
        </Typography>
      </EvaluationWizardCell>
    </>
  );
};

const BinaryWizardInstructionBody: React.FC = () => {
  const {
    taskDescription,
    setTaskDescription,
    goodInputExample,
    setGoodInputExample,
    badInputExample,
    setBadInputExample,
  } = useBinaryWizardState();

  const { dataId } = useEvaluationWizardState();
  const { dataById } = useDataStore();

  const { variantIds } = useEvaluationWizardState();

  const datasetSize = useMemo(() => {
    if (!dataId) {
      return 0;
    }
    const data = dataById[dataId];
    return _.size(data.rowByIndex);
  }, [dataId, dataById]);

  return (
    <>
      <EvaluationWizardCell>
        <Typography variant="body1"> 1. What is the main task of your app? (required) </Typography>
        <VSpace s={1} />
        <TextField
          value={taskDescription}
          multiline
          fullWidth
          rows={5}
          onChange={e => {
            setTaskDescription(e.target.value);
          }}
          placeholder="Insert task here, 500 character limit"
          InputProps={{
            style: {
              fontFamily: "'IBM Plex Mono', 'Consolas', monospace",
            },
          }}
        />
        <VSpace s={4} />
        <Typography variant="body1"> 2. What would you consider to be a good output? </Typography>
        <VSpace s={1} />
        <TextField
          value={goodInputExample}
          multiline
          fullWidth
          rows={5}
          onChange={e => {
            setGoodInputExample(e.target.value);
          }}
          placeholder={`* is true, *and* \n* is logical, *and* \n* accurately responds to the task provided to the computer`}
          InputProps={{
            style: {
              fontFamily: "'IBM Plex Mono', 'Consolas', monospace",
            },
          }}
        />
        <VSpace s={4} />
        <Typography variant="body1"> 2. What would you consider to be a bad output? </Typography>
        <VSpace s={1} />
        <TextField
          value={badInputExample}
          multiline
          fullWidth
          rows={5}
          onChange={e => {
            setBadInputExample(e.target.value);
          }}
          placeholder={`* is incorrect, *or* \n* is gibberish, *or* \n* contains profanity *or* \n* inaccurately responds to the task provided to the computer`}
          InputProps={{
            style: {
              fontFamily: "'IBM Plex Mono', 'Consolas', monospace",
            },
          }}
        />
      </EvaluationWizardCell>
      <EvaluationWizardCell>
        <InstructionsPanel
          task={taskDescription}
          goodInputExample={goodInputExample}
          badInputExample={badInputExample}
        />
        <VSpace s={4} />
        <Box>
          <VSpace s={1} />
          <Box
            sx={{
              backgroundColor: theme.palette.info.light,
              border: `1px dashed ${theme.palette.info.main}`,
              borderRadius: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '150px',
              padding: 1,
            }}
          >
            <Typography> Generated output here </Typography>
          </Box>
        </Box>
        <VSpace s={4} />
        <ElevatedContainer>
          <Typography variant="h3">
            Based on the instructions provided, please determine whether the model output is good or
            bad
            <VSpace s={4} />
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', gap: 3 }}>
              <Button variant="outlined" size="large">
                Good
              </Button>
              <Button variant="outlined" size="large">
                Bad
              </Button>
            </Box>
          </Typography>
        </ElevatedContainer>
        <VSpace s={4} />
        <PricingEstimateTable
          ratePerItem={COST_PER_ITEM}
          timePerItem={HOURS_PER_ITEM}
          evaluationItems={datasetSize ?? 0}
          totalVariants={variantIds.length}
        />
      </EvaluationWizardCell>
    </>
  );
};

export default BinaryWizardInstructionStep;
