import { Button, Typography, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PromptFieldActionsLayout from 'frontend/components/playground/PromptFieldActionsLayout';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

interface IPromptFieldActions {
  isLoading: boolean;
  modelOutput: string;
  onSubmit: (e: any) => void;
  onRegenerate: () => void;
  onUndoLast: () => void;
}

const PromptFieldActions = ({
  isLoading,
  onSubmit,
  modelOutput,
  onRegenerate,
  onUndoLast,
}: IPromptFieldActions) => {
  const metaKey = navigator.platform.includes('Mac') ? '⌘' : 'Ctrl';

  return (
    <PromptFieldActionsLayout>
      <Tooltip
        title={
          <Typography
            sx={{ fontSize: 16, color: 'white' }}
          >{`Submit (${metaKey} + Enter)`}</Typography>
        }
        placement="top"
        arrow
      >
        <span>
          <Button
            disabled={isLoading}
            variant="contained"
            onClick={onSubmit}
            sx={{ height: '100%' }}
          >
            {isLoading ? <CircularProgress size="1.5rem" /> : 'Submit'}
          </Button>
        </span>
      </Tooltip>
      <Tooltip
        title={
          <Typography
            sx={{ fontSize: 16, color: 'white' }}
          >{`Undo last (${metaKey} + ⇧ + U)`}</Typography>
        }
        placement="top"
        arrow
      >
        <span>
          <Button
            disabled={isLoading || !modelOutput}
            color="secondary"
            variant="contained"
            onClick={onUndoLast}
            sx={{ height: '100%' }}
          >
            <FontAwesomeIcon icon="rotate-left" />
          </Button>
        </span>
      </Tooltip>
      <Tooltip
        title={
          <Typography sx={{ fontSize: 16, color: 'white' }}>
            {`Regenerate (${metaKey} + ⇧ + Enter)`}
          </Typography>
        }
        placement="top"
        arrow
      >
        <span>
          <Button
            disabled={isLoading || !modelOutput}
            color="secondary"
            variant="contained"
            onClick={onRegenerate}
            sx={{ height: '100%' }}
          >
            <FontAwesomeIcon icon="arrows-rotate" />
          </Button>
        </span>
      </Tooltip>
    </PromptFieldActionsLayout>
  );
};

export default PromptFieldActions;
