import { useMemo } from 'react';

import { format } from 'date-fns';

import { Box, Button, LinearProgress, Typography } from '@mui/material';
import { HumanJobSource, JobStatus } from '@prisma/client';
import { PolledJobType } from '@scale/llm-shared/interfaces/job';

import { Container } from 'frontend/components/Container';
import FlexBox from 'frontend/components/FlexBox';
import { HSpace } from 'frontend/components/Spacer';
import VariantName from 'frontend/components/v2/variant/VariantName';
import { useLLMNavigation } from 'frontend/hooks/useLLMNavigation';
import { useDataStore } from 'frontend/storesV2/DataStore';
import { StoredEvaluation, StoredPolledJob } from 'frontend/storesV2/types';
import { useVariantStore } from 'frontend/storesV2/VariantStore';
import { round } from 'frontend/utils';

import { DataNameChip } from './DataNameChip';
import { EvaluationStatus } from './EvaluationStatus';
import { EvaluationTypeChip } from './EvaluationTypeChip';

interface JobDisplayProps {
  job: StoredPolledJob;
  evaluation: StoredEvaluation;
}

// This component is for jobs that are not completed. This includes pending/running/cancelled/errored
// Note: The job and evaluation should have the same id and type
export function JobStatusDisplay(props: JobDisplayProps) {
  const { job, evaluation } = props;
  const { variantById } = useVariantStore();
  const { dataInfoById } = useDataStore();
  const { spoofAmpersandQuery } = useLLMNavigation();

  const variant = variantById[evaluation.variantId];
  const dataId = evaluation.inputDataId;
  const dataInfo = dataInfoById[dataId];

  const progress = Math.min(job.progress, 100); // TODO: Why is this returning values > 100
  const createdAtString = format(evaluation.createdAt, 'M/d/yyyy h:mm:ss a');

  // Using progress here so that we can communicate that evaluation can take some
  // extra time while the inferences are technically done
  // EQ note: job progress has a few different definitions depending on the job type currently:
  //  For programmatic evaluations, it is the inference status
  //  For human evaluation it is the % of tasks completed
  const inferenceStatus = progress === 100 ? JobStatus.Completed : job.status;

  const scaleAiRedirect = useMemo(() => {
    if (
      job.type === PolledJobType.HumanEvaluation &&
      evaluation.type === PolledJobType.HumanEvaluation &&
      job.status !== JobStatus.Pending &&
      job.metadata &&
      job.metadata.scaleProjectAndBatch
    ) {
      const scaleInfo = job.metadata.scaleProjectAndBatch;
      const humanJobSource = evaluation.humanJobSource;
      if (humanJobSource === HumanJobSource.ScaleRapid) {
        return {
          buttonText: 'View tasks in Scale Rapid',
          outboundUrl: `https://dashboard.scale.com/rapid/batches?batchId=${scaleInfo.batchId}&project=${scaleInfo.projectId}${spoofAmpersandQuery}`,
        };
      }
      if (humanJobSource === HumanJobSource.ScaleStudio) {
        return {
          buttonText: 'Annotate tasks in Scale Studio',
          outboundUrl: `https://dashboard.scale.com/studio/batches?batchId=${scaleInfo.batchId}&project=${scaleInfo.projectId}${spoofAmpersandQuery}`,
        };
      }
    }
    return undefined;
  }, [job, evaluation]);

  return (
    <Container>
      <Box sx={{ padding: '8px' }}>
        <FlexBox sx={{ justifyContent: 'space-between', marginBottom: '16px' }}>
          <Typography sx={{ fontWeight: 600 }}>
            <VariantName name={variant?.name || 'Unknown Variant'} />
          </Typography>
          <Typography variant="h4">Created At: {createdAtString}</Typography>
        </FlexBox>
        <FlexBox sx={{ justifyContent: 'space-between' }}>
          <FlexBox sx={{ gap: 3 }}>
            <EvaluationStatus
              inferenceStatus={inferenceStatus}
              evaluationStatus={evaluation.status}
            />
            <EvaluationTypeChip type={evaluation.type} />
            {dataInfo?.name && <DataNameChip name={dataInfo.name} />}
          </FlexBox>
          <FlexBox>
            {scaleAiRedirect && (
              <>
                <HSpace s={2} />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      window?.open(scaleAiRedirect.outboundUrl, '_blank')?.focus();
                    }}
                  >
                    {scaleAiRedirect.buttonText}
                  </Button>
                </Box>
              </>
            )}
          </FlexBox>
          <FlexBox>
            <Box sx={{ width: 280 }}>
              <LinearProgress
                variant="determinate"
                value={progress}
                sx={{ height: 8, borderRadius: 6 }}
              />
            </Box>
            <Typography variant="h4" sx={{ width: '5ex' }}>
              {round(progress, 2)}%
            </Typography>
          </FlexBox>
        </FlexBox>
      </Box>
    </Container>
  );
}
