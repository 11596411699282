export function getSpoofFromSearchParams(searchParams?: string) {
  if (!searchParams) {
    return;
  }
  const params = new URLSearchParams(searchParams);
  return params.get('spoof') || undefined;
}

/**
 * Create search param string from object
 */
export function searchParamsStr(obj: Record<string, any>) {
  const searchParams = new URLSearchParams();
  Object.entries(obj).forEach(([key, value]) => {
    searchParams.set(key, String(value));
  });
  return searchParams.toString();
}
