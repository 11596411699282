import { z } from 'zod';

import { JobStatus } from '@prisma/client';
import {
  CompletedMetadataByEvaluationType,
  EvaluationType,
  SpecsByEvaluationType,
} from '@scale/llm-shared/interfaces/evaluation';
import { FinetuneType } from '@scale/llm-shared/interfaces/finetune';
import {
  ErroredOrCancelledJobStatus,
  IncompleteJobStatus,
  RunningOrCompletedJobStatus,
} from '@scale/llm-shared/types';

export const PolledJobType = {
  ...EvaluationType,
  ...FinetuneType,
} as const;

export type PolledJobType = typeof PolledJobType[keyof typeof PolledJobType];

export const RunningMetadataByJobType = {
  [PolledJobType.ClassificationEvaluation]: z.object({}),
  [PolledJobType.MauveEvaluation]: z.object({}),
  [PolledJobType.AIFeedback]: z.object({}),
  [PolledJobType.HumanEvaluation]: z.object({
    scaleProjectAndBatch: z.object({
      projectId: z.string(),
      batchId: z.string(),
    }),
  }),
  [PolledJobType.OpenAIFinetune]: z.object({}),
  [PolledJobType.ScaleTrainFinetune]: z.object({}),
};

export const OptionalMetadataByJobType = {
  [PolledJobType.ClassificationEvaluation]: z.object({}),
  [PolledJobType.MauveEvaluation]: z.object({}),
  [PolledJobType.AIFeedback]: z.object({}),
  [PolledJobType.HumanEvaluation]: z.object({
    scaleProjectAndBatch: z
      .object({
        projectId: z.string(),
        batchId: z.string(),
      })
      .optional(),
  }),
  [PolledJobType.OpenAIFinetune]: z.object({}),
  [PolledJobType.ScaleTrainFinetune]: z.object({}),
};

// This enables us to more easily define types with the metadata we want. It gives an illusion of a generic type so that typescript can to metadata scoping

function createGenericJobBaseType<JobType extends PolledJobType>(jobType: JobType) {
  return z.object({
    id: z.string(),
    type: z.literal(jobType),
    progress: z.number(),
    // TODO: add started at and stuff?
  });
}

function createGenericErroredOrCancelledPolledJobType<JobType extends PolledJobType>(
  jobType: JobType,
) {
  const baseType = createGenericJobBaseType(jobType);
  return baseType.extend({
    status: z.nativeEnum(ErroredOrCancelledJobStatus),
    metadata: OptionalMetadataByJobType[jobType],
  });
}

function createGenericRunningOrCompletedPolledJobType<JobType extends PolledJobType>(
  jobType: JobType,
) {
  const baseType = createGenericJobBaseType(jobType);
  return baseType.extend({
    status: z.nativeEnum(RunningOrCompletedJobStatus),
    metadata: RunningMetadataByJobType[jobType],
  });
}

export const PendingClassificationJob = createGenericJobBaseType(
  PolledJobType.ClassificationEvaluation,
).merge(
  z.object({
    type: z.nativeEnum(PolledJobType),
    status: z.literal(JobStatus.Pending),
  }),
);

export const PendingMauveJob = createGenericJobBaseType(PolledJobType.MauveEvaluation).merge(
  z.object({
    type: z.nativeEnum(PolledJobType),
    status: z.literal(JobStatus.Pending),
  }),
);

export const PendingAIFeedbackJob = createGenericJobBaseType(PolledJobType.AIFeedback).merge(
  z.object({
    type: z.nativeEnum(PolledJobType),
    status: z.literal(JobStatus.Pending),
  }),
);

export const PendingOpenAIFinetuneJob = createGenericJobBaseType(
  PolledJobType.OpenAIFinetune,
).merge(
  z.object({
    type: z.nativeEnum(PolledJobType),
    status: z.literal(JobStatus.Pending),
  }),
);

export const PendingScaleTrainFinetuneJob = createGenericJobBaseType(
  PolledJobType.ScaleTrainFinetune,
).merge(
  z.object({
    type: z.nativeEnum(PolledJobType),
    status: z.literal(JobStatus.Pending),
  }),
);

export const PendingJob = z.union([
  PendingClassificationJob,
  PendingMauveJob,
  PendingAIFeedbackJob,
  PendingOpenAIFinetuneJob,
  PendingScaleTrainFinetuneJob,
]);

export type PendingJob = z.infer<typeof PendingJob>;

export const RunningOrCompletedClassificationEvaluationJob =
  createGenericRunningOrCompletedPolledJobType(PolledJobType.ClassificationEvaluation);

export type RunningOrCompletedClassificationEvaluationJob = z.infer<
  typeof RunningOrCompletedClassificationEvaluationJob
>;

export const RunningOrCompletedMauveEvaluationJob = createGenericRunningOrCompletedPolledJobType(
  PolledJobType.MauveEvaluation,
);

export type RunningOrCompletedMauveEvaluationJob = z.infer<
  typeof RunningOrCompletedMauveEvaluationJob
>;

export const RunningOrCompletedAIFeedbackEvaluationJob =
  createGenericRunningOrCompletedPolledJobType(PolledJobType.AIFeedback);

export type RunningOrCompletedAIFeedbackEvaluationJob = z.infer<
  typeof RunningOrCompletedAIFeedbackEvaluationJob
>;

export const RunningOrCompletedHumanEvaluationJob = createGenericRunningOrCompletedPolledJobType(
  PolledJobType.HumanEvaluation,
);

export type RunningOrCompletedHumanEvaluationJob = z.infer<
  typeof RunningOrCompletedHumanEvaluationJob
>;

export const RunningOrCompletedOpenAIFinetuneJob = createGenericRunningOrCompletedPolledJobType(
  PolledJobType.OpenAIFinetune,
);

export type RunningOrCompletedOpenAIFinetuneJob = z.infer<
  typeof RunningOrCompletedOpenAIFinetuneJob
>;

export const RunningOrCompletedScaleTrainFinetuneJob = createGenericRunningOrCompletedPolledJobType(
  PolledJobType.ScaleTrainFinetune,
);

export type RunningOrCompletedScaleTrainFinetuneJob = z.infer<
  typeof RunningOrCompletedScaleTrainFinetuneJob
>;

export const ErroredOrCancelledClassificationEvaluationJob =
  createGenericErroredOrCancelledPolledJobType(PolledJobType.ClassificationEvaluation);

export type ErroredOrCancelledClassificationEvaluationJob = z.infer<
  typeof ErroredOrCancelledClassificationEvaluationJob
>;

export const ErroredOrCancelledMauveEvaluationJob = createGenericErroredOrCancelledPolledJobType(
  PolledJobType.MauveEvaluation,
);

export type ErroredOrCancelledMauveEvaluationJob = z.infer<
  typeof ErroredOrCancelledMauveEvaluationJob
>;

export const ErroredOrCancelledAIFeedbackEvaluationJob =
  createGenericErroredOrCancelledPolledJobType(PolledJobType.AIFeedback);

export type ErroredOrCancelledAIFeedbackEvaluationJob = z.infer<
  typeof ErroredOrCancelledAIFeedbackEvaluationJob
>;

export const ErroredOrCancelledHumanEvaluationJob = createGenericErroredOrCancelledPolledJobType(
  PolledJobType.HumanEvaluation,
);

export type ErroredOrCancelledHumanEvaluationJob = z.infer<
  typeof ErroredOrCancelledHumanEvaluationJob
>;

export const ErroredOrCancelledOpenAIFinetuneJob = createGenericErroredOrCancelledPolledJobType(
  PolledJobType.OpenAIFinetune,
);

export type ErroredOrCancelledOpenAIFinetuneJob = z.infer<
  typeof RunningOrCompletedOpenAIFinetuneJob
>;

export const ErroredOrCancelledScaleTrainFinetuneJob = createGenericErroredOrCancelledPolledJobType(
  PolledJobType.ScaleTrainFinetune,
);

export type ErroredOrCancelledScaleTrainFinetuneJob = z.infer<
  typeof RunningOrCompletedScaleTrainFinetuneJob
>;

export const RunningOrCompletedJob = z.union([
  RunningOrCompletedClassificationEvaluationJob,
  RunningOrCompletedMauveEvaluationJob,
  RunningOrCompletedAIFeedbackEvaluationJob,
  RunningOrCompletedHumanEvaluationJob,
  RunningOrCompletedOpenAIFinetuneJob,
  RunningOrCompletedScaleTrainFinetuneJob,
]);

export type RunningOrCompletedJob = z.infer<typeof RunningOrCompletedJob>;

export const ErroredOrCancelledJob = z.union([
  ErroredOrCancelledClassificationEvaluationJob,
  ErroredOrCancelledMauveEvaluationJob,
  ErroredOrCancelledAIFeedbackEvaluationJob,
  ErroredOrCancelledHumanEvaluationJob,
  ErroredOrCancelledOpenAIFinetuneJob,
  ErroredOrCancelledScaleTrainFinetuneJob,
]);

export type ErroredOrCancelledJob = z.infer<typeof ErroredOrCancelledJob>;

export const PolledJob = z.union([PendingJob, ErroredOrCancelledJob, RunningOrCompletedJob]);

export type PolledJob = z.infer<typeof PolledJob>;

export const GetPolledJob = z.object({
  id: z.string(),
  type: z.nativeEnum(PolledJobType),
});

export type GetPolledJob = z.infer<typeof GetPolledJob>;
