/**
 * Used as default in switch statements that want to enforce all cases be dealt with.
 */
export function neverfail<E extends Error>(value: never, err?: string | E): never {
  if (err instanceof Error) {
    throw err;
  } else {
    throw Error(err || `Case ${value} has undefined behavior`);
  }
}

export class ValidationError extends Error {
  // This is referred to inconsistently in the codebase
  public status = 400;
  public statusCode = 400;
}
