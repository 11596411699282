import { EvaluationMethod, InferenceValue } from '@prisma/client';

export enum ExpertFeedbackType {
  'BINARY' = 'Binary',
  'RANKING' = 'Ranking',
  'CUSTOM_HUMAN_EVAL' = 'Custom Human Evaluation',
}

type BaseEvaluationMethod = Exclude<
  EvaluationMethod,
  typeof EvaluationMethod.ClassificationStripWhitespace | typeof EvaluationMethod.AIFeedback
>;

type BaseEvaluationMethodV2 = Exclude<
  EvaluationMethod,
  typeof EvaluationMethod.ClassificationStripWhitespace
>;

export type BaseEvaluationType = ExpertFeedbackType | BaseEvaluationMethod;

export type BaseEvaluationTypeV2 = ExpertFeedbackType | BaseEvaluationMethodV2;

export interface InferenceValueColumnCreate {
  name: string;
  values: {
    value: string;
    row: string;
    status: InferenceValue['status'];
  }[];
}
