import { Slider } from '@mui/material';
import SettingsContainer from 'frontend/components/playground/SettingsContainer';
import SettingsEditorWithNoBackground from 'frontend/components/playground/SettingsEditorWithNoBackground';
import SidebarNumberInputHeader from 'frontend/components/playground/SidebarNumberInputHeader';

interface IMaxTokensSliderProps {
  maxTokens: number;
  minMaxTokens: number;
  maxMaxTokens: number;
  onChangeMaxTokens: (e: any, val: any) => void;
}

const MaxTokensSlider = ({
  maxTokens,
  minMaxTokens,
  maxMaxTokens,
  onChangeMaxTokens,
}: IMaxTokensSliderProps) => {
  return (
    <SettingsContainer>
      <SidebarNumberInputHeader
        title="Max tokens"
        value={maxTokens}
        onChangeInput={onChangeMaxTokens}
      />
      <SettingsEditorWithNoBackground>
        <Slider
          value={maxTokens}
          step={1}
          min={minMaxTokens}
          max={maxMaxTokens}
          size="medium"
          onChange={onChangeMaxTokens}
        />
      </SettingsEditorWithNoBackground>
    </SettingsContainer>
  );
};

export default MaxTokensSlider;
