import { useCallback, useState } from 'react';

import _ from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { JobStatus } from '@prisma/client';
import { DataColumn, InferenceColumn } from '@scale/llm-shared/interfaces';
import { EvaluationType } from '@scale/llm-shared/interfaces/evaluation';

import { client } from 'frontend/api/trpc';
import { track } from 'frontend/utils/analytics';
import { downloadAsCsv } from 'frontend/utils/downloadAsCsv';

function convertClassificationEvaluationOutputsToCsv({
  inputColumns,
  inferenceOutputColumns,
  expectedOutputColumn,
}: {
  inputColumns: DataColumn[];
  inferenceOutputColumns: InferenceColumn[];
  expectedOutputColumn: DataColumn;
}) {
  const isMatch = _.zip(inferenceOutputColumns[0].values, expectedOutputColumn.values).map(
    ([actualOutput, expectedOutput]) => {
      return actualOutput?.value === expectedOutput?.value ? 'TRUE' : 'FALSE';
    },
  );
  const columnValues: string[][] = _.compact([
    ...inputColumns.map(col => col.values.map(v => v.value)),
    expectedOutputColumn.values.map(v => v.value),
    ...inferenceOutputColumns.map(col => col.values.map(v => v.status || JobStatus.Completed)),
    ...inferenceOutputColumns.map(col => col.values.map(v => v.value)),
    isMatch,
    inferenceOutputColumns[0].values.map(v =>
      v.tokenProbs && v.tokenProbs.token_probs.length > 0
        ? v.tokenProbs.token_probs[0].toString()
        : 'N/A',
    ),
  ]);
  const rows = _.zip(...columnValues);
  const headers = _.compact([
    ...inputColumns.map(column => `Input: ${column.name}`),
    `Expected Output: ${expectedOutputColumn.name}`,
    ...inferenceOutputColumns.map(() => 'Inference Status'),
    ...inferenceOutputColumns.map(() => 'Output'),
    'Matching',
    'Token Probability',
  ]);
  return [headers, ...rows];
}

function convertMauveEvaluationOutputsToCsv({
  inputColumns,
  inferenceOutputColumns,
  expectedOutputColumn,
}: {
  inputColumns: DataColumn[];
  inferenceOutputColumns: InferenceColumn[];
  expectedOutputColumn: DataColumn;
}) {
  const columnValues: string[][] = _.compact([
    ...inputColumns.map(col => col.values.map(v => v.value)),
    expectedOutputColumn.values.map(v => v.value),
    ...inferenceOutputColumns.map(col => col.values.map(v => v.status || JobStatus.Completed)),
    ...inferenceOutputColumns.map(col => col.values.map(v => v.value)),
  ]);
  const rows = _.zip(...columnValues);
  const headers = _.compact([
    ...inputColumns.map(column => `Input: ${column.name}`),
    `Expected Output: ${expectedOutputColumn.name}`,
    ...inferenceOutputColumns.map(() => 'Inference Status'),
    ...inferenceOutputColumns.map(() => 'Output'),
  ]);
  return [headers, ...rows];
}

function convertHumanEvaluationOutputsToCsv({
  inputColumns,
  inferenceOutputColumns,
  humanEvaluationOutputColumn,
}: {
  inputColumns: DataColumn[];
  inferenceOutputColumns: DataColumn[];
  humanEvaluationOutputColumn?: DataColumn;
}) {
  const columns: DataColumn[] = _.compact([
    ...inputColumns,
    ...inferenceOutputColumns,
    humanEvaluationOutputColumn,
  ]);
  const columnValues = columns.map(column => column.values.map(v => v.value));
  const rows = _.zip(...columnValues);
  const headers = _.compact([
    ...inputColumns.map(column => `Input: ${column.name}`),
    ...inferenceOutputColumns.map(() => 'Output'),
    `Expert feedback:`,
  ]);
  return [headers, ...rows];
}

function convertAIFeedbackEvaluationOutputsToCsv({
  inputColumns,
  inferenceOutputColumns,
  aiFeedbackOutputColumn,
}: {
  inputColumns: DataColumn[];
  inferenceOutputColumns: DataColumn[];
  aiFeedbackOutputColumn: DataColumn;
}) {
  const columns: DataColumn[] = _.compact([
    ...inputColumns,
    ...inferenceOutputColumns,
    aiFeedbackOutputColumn,
  ]);
  const columnValues = columns.map(column => column.values.map(v => v.value));
  const rows = _.zip(...columnValues);
  const headers = _.compact([
    ...inputColumns.map(column => `Input: ${column.name}`),
    ...inferenceOutputColumns.map(column => 'Output'),
    `AI feedback:`,
  ]);
  return [headers, ...rows];
}

function convertOutputsToCsv({
  type,
  inputColumns,
  inferenceOutputColumns,
  expectedOutputColumn,
  humanEvaluationOutputColumn,
  aiFeedbackOutputColumn,
}: {
  type: EvaluationType;
  inputColumns: DataColumn[];
  inferenceOutputColumns: InferenceColumn[];
  expectedOutputColumn?: DataColumn;
  humanEvaluationOutputColumn?: DataColumn;
  aiFeedbackOutputColumn?: DataColumn;
}) {
  switch (type) {
    case EvaluationType.ClassificationEvaluation:
      return convertClassificationEvaluationOutputsToCsv({
        inputColumns,
        inferenceOutputColumns,
        expectedOutputColumn: expectedOutputColumn!,
      });
    case EvaluationType.MauveEvaluation:
      return convertMauveEvaluationOutputsToCsv({
        inputColumns,
        inferenceOutputColumns,
        expectedOutputColumn: expectedOutputColumn!,
      });
    case EvaluationType.HumanEvaluation:
      return convertHumanEvaluationOutputsToCsv({
        inputColumns,
        inferenceOutputColumns,
        humanEvaluationOutputColumn: humanEvaluationOutputColumn!,
      });
    case EvaluationType.AIFeedback:
      return convertAIFeedbackEvaluationOutputsToCsv({
        inputColumns,
        inferenceOutputColumns,
        aiFeedbackOutputColumn: aiFeedbackOutputColumn!,
      });
  }
}

export default function DownloadEvaluationResultsButton({
  evaluationId,
  evaluationType,
  disabled = false,
}: {
  evaluationId: string;
  evaluationType: EvaluationType;
  disabled?: boolean;
}) {
  const [downloading, setDownloading] = useState<boolean>(false);

  const downloadLogsAsCsv = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation(); // prevents button from triggering detailed results expand/collapse
      setDownloading(true);
      try {
        const inputOutputData = await client.query('v2.evaluation.getEvaluationAllInputOutputs', {
          id: evaluationId,
          type: evaluationType,
        });
        const data = convertOutputsToCsv({ type: evaluationType, ...inputOutputData });
        const filename = `evaluation-results-${evaluationId}.csv`;
        track('Evaluation Results Downloaded', { evaluation: evaluationId });
        downloadAsCsv(data, filename);
      } finally {
        setDownloading(false);
      }
    },
    [evaluationId, evaluationType],
  );

  return (
    <Button
      size="small"
      variant="outlined"
      disabled={disabled || downloading}
      onClick={downloadLogsAsCsv}
    >
      <FontAwesomeIcon size="sm" icon="download" style={{ marginRight: '1ex' }} />
      Download Full Results
    </Button>
  );
}
